import {factory} from "./ClassLoader"
var OneshotRecord = function () {
	let OneshotRecord = class extends factory.classes.Record {

	}
	return OneshotRecord;
}
OneshotRecord.function = true
export {
	OneshotRecord
}