export default {
  methods: {
    resolveElement(element) {
      if (typeof element == 'string') {
        return this.$el.querySelector(element);
      }
      return element;
    },
    elementIsVisible(element) {
      element = this.resolveElement(element)
      if (!element) return null;
      var viewport = window.visualViewport;

      var screenTop = viewport.pageTop
      var screenBottom = screenTop + viewport.height

      var rect = element.getBoundingClientRect()

      var top = rect.top
      var bottom = rect.bottom 

      return (top>0 && top <=viewport.height) ||  // top is on screen
             (bottom>0 && bottom<=viewport.height) ||
             (top<=0 && bottom>=viewport.height)
    },
    scrollToElement(element) {
      element = this.resolveElement(element)
      if (!element) return null;
      var rect = element.getBoundingClientRect()
      var height = rect.height;
      if (height>500) {
        var offSet = 100;
      } else {
        var offSet = 500/5;
      } // we need an offset so things don't end up rammed against the top of the screen or under the navbar
      var top = rect.top;
      var scrollY = window.scrollY;      
      window.scrollTo(0,scrollY+top-offSet)
    },
    scrollToElementIfNeeded(element) {
      element = this.resolveElement(element)
      if (!element) return null;
      if (!this.elementIsVisible(element)) {
        this.scrollToElement(element);
        return true;
      } else {
        return false;
      }
    },
  }
}