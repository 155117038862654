import {factory} from "../utils/ClassLoader"
import helpers from "../utils/helpers"
let Collection = factory.classes.Collection;
let LookupCollection = class extends Collection {
	constructor(lookups) {
		super((new Collection(lookups)).keyBy(keyByFunc))
	}
	
}
var keyByFunc = function(item) {
	var prefix;
	if (item.model) {
		if (item.searchRoute) {
			prefix = "A-"
		} else {
			prefix = "T-"
		}
	} else {
		prefix = "L-"
	}
	return prefix + item.id
}
export {
	LookupCollection
}
