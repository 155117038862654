<template>
    <div class="skintone-chart-container">
        <div v-if="request.loading" class="progress my-4" role="progressbar" aria-label="Basic example"
            :aria-valuenow="request.percentageComplete" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar" :style="'width:' + request.percentageComplete + '%'"></div>
        </div>

        <chart ref="chart" :width="width" :height="height" :margin="{ left: 0, right: 0, top: 0, bottom: 0 }"
            class="skintone-chart">
            <treemap-diagram :data="skintoneByCountry" :colours="skinToneColourArray" :width="width" :height="height"
                tiling="squarify" :paddingInner="2" :paddingTop="38"></treemap-diagram>
        </chart>

    </div>
</template>

<script>
import { value } from 'lodash-es';
import { mapActions, mapState } from 'vuex'
import treemapDiagram from '~/components/charts/treemap.vue'
export default {
    name: 'skintone-diagram',
    components: {
        treemapDiagram
    },
    mounted() {
        if (!this.request.loaded && !this.request.loading) this.fetchAgeData()
        if (this.request.loaded) this.chartMounted()
    },
    data() {
        return {
            width: 0,
            height: 0,
            ro: null,
        }
    },
    beforeDestroy() {
        console.log('destroying')
        this.cancelFetch(this.resourceId)
    },
    computed: {
        ...mapState('resources/admin', ['dashboard']),
        ...mapState('resources/questionnaires', ['questionnaire']),
        resourceId() {
            return 'patient.skintone'
        },
        countryData() {
            if (this.dashboard.data.countryData)
                return this.dashboard.data.countryData
            else return []
        },
        patients() {
            if (this.dashboard.stream.patients)
                return this.dashboard.stream.patients
            else return []
        },
        skinToneColourArray() {
            var skintoneLookup = this.questionnaire.lookups.find(lookup => lookup.name === 'skin_tone');
            if (skintoneLookup) {
                return skintoneLookup.items.map(item => {
                    return { value: item.value, label: item.label, colour: item.json.hex }
                })
            }
            return []
        },
        skintoneTotals() {
            // each patient has a s property that will be an integer
            if (this.request) {
                // return an array with key (p.s) and value (count)
                var totalsObj = this.patients.reduce((acc, patient) => {
                    acc[patient.s] = acc[patient.s] ? acc[patient.s] + 1 : 1
                    return acc
                }, {})
                var totalsArr = []
                for (var key in totalsObj) {
                    if (key !== "null" && key !== null && key !== "" && key !== "undefined")
                        totalsArr.push({ key: key, value: totalsObj[key], label: this.skinToneColourArray.find(colour => colour.value === key).label })
                }
                return totalsArr
            }
            return []
        },
        skintoneByCountry() {
            if (this.request) {
                // This is for a tree-diagram, so it needs to return an array of objects with key (country id) and value (total count per country)
                // return an array of objects with key (country id) and value (total count per country) then in each object, return an array called children with objects with key (skintone) and value (count)
                var totalsObj = this.patients.reduce((acc, patient) => {
                    acc[patient.c] = acc[patient.c] ? acc[patient.c] : {}
                    if (typeof patient.s !== 'undefined' && patient.s !== null)
                        acc[patient.c][patient.s] = acc[patient.c][patient.s] ? acc[patient.c][patient.s] + 1 : 1
                    return acc
                }, {})
                var totalsArr = []
                for (var key in totalsObj) {
                    var country = this.countryData.find(country => country.id === Math.round(key))
                    var countryObj = { key: key, title: country ? country.name : 'Unknown', children: [] }
                    for (var skintone in totalsObj[key]) {
                        countryObj.children.push({ key: skintone, value: totalsObj[key][skintone], name: this.skinToneColourArray.find(colour => colour.value === skintone).label, fill: this.skinToneColourArray.find(colour => colour.value === skintone).colour })
                    }
                    totalsArr.push(countryObj)
                }
                return { name: 'skintone', children: totalsArr }

            } else return {}

        },
        request() {
            if (this.dashboard.stream.requests[this.resourceId])
                return this.dashboard.stream.requests[this.resourceId]
            else return {
                loading: false,
                loaded: false
            }
        }
    },
    methods: {
        ...mapActions('resources/admin', ['fetchAsStream', 'cancelFetch']),
        fetchAgeData() {
            this.fetchAsStream({
                id: this.resourceId,
                resource: 'Patient',
                key: 'patients',
                initial: false,
                params: {
                    include: ['latestDemographics'],
                    attrs: ['skintone'],
                    chunk: 1000
                }
            }).then(() => {
                this.chartMounted()
            })
        },
        defineResizeObserver() {
            if (!this.$refs.chart || !this.$refs.chart.$el) return
            this.ro = new ResizeObserver(() => {
                requestAnimationFrame(() => {
                    this.setChartSize();
                });
            });
            this.ro.observe(this.$refs.chart.$el);
        },
        chartMounted() {
            requestAnimationFrame(() => {
                this.setChartSize();
            });

            this.mounted = true;

        },
        setChartSize() {
            if (!this.$refs.chart) return
            var outerBB = this.$el.getBoundingClientRect();
            var bbox = this.$refs.chart.$el.getBoundingClientRect()
            console.log('setting chart size', bbox.width, bbox.height, outerBB.width, outerBB.height)

            this.width = bbox.width
            this.height = bbox.height
        },

    },
    watch: {
        'request.loaded'() {
            this.defineResizeObserver();

            this.setChartSize()
        }
    }

}
</script>
