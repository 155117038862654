<template>
  <card>
    <div class="d-flex align-items-center">
      <img :alt="$t('public.accessibility.icon-alt')" :src="'/images/home/accessibility.png'" class="circle_icon me-4">
      <h1 class="display-2 flex-grow-1" v-html="$t('public.accessibility.title')"></h1>
    </div>
    <i18n path="public.accessibility.p1" class="lead">
      <a :href="$t('public.accessibility.link1')" v-t="'public.accessibility.link1'"></a>
    </i18n>
    <p class="lead" v-t="'public.accessibility.p2'"></p>
    <h4 v-t="'public.accessibility.subtitle1'" class="mt-4" />
    <p class="lead" v-t="'public.accessibility.p3'"></p>
    <ul>
      <li v-for="(item, n) in $t('public.accessibility.list1')" :key="'item' + n" v-html="item"></li>
    </ul>
    <p class="lead" v-t="'public.accessibility.p4'"></p>
    <h4 v-t="'public.accessibility.subtitle2'" class="mt-4" />
    <i18n path="public.accessibility.p5" class="lead">
      <a :href="$t('public.accessibility.p5-link')" v-t="'public.accessibility.p5-link-text'"></a>
    </i18n>
    <ul>
      <li v-for="(item, n) in $t('public.accessibility.list2')" :key="'item' + n">
        <a :href="item.link" v-html="item.linktext"></a>
      </li>
    </ul>
    <h4 v-t="'public.accessibility.subtitle3'" class="mt-4" />
    <p class="lead" v-t="'public.accessibility.p6'"></p>
    <ul>
      <li v-for="(item, n) in $t('public.accessibility.list3')" :key="'item' + n" v-html="item"></li>
    </ul>
    <i18n path="public.accessibility.p7">
      <a :href="$t('public.accessibility.p7-link')" v-t="'public.accessibility.p7-link-text'"></a>
    </i18n>
    <h4 v-t="'public.accessibility.subtitle4'" class="mt-4" />
    <i18n path="public.accessibility.p8">
      <a :href="$t('public.accessibility.p8-link')" v-t="'public.accessibility.p8-link-text'"></a>
    </i18n>
    <h4 v-t="'public.accessibility.subtitle5'" class="mt-4" />
    <p v-t="'public.accessibility.p9'"></p>
    <h4 v-t="'public.accessibility.subtitle6'" class="mt-4" />
    <i18n path="public.accessibility.p10">
      <a :href="$t('public.accessibility.p10-link')" v-t="'public.accessibility.p10-link-text'"></a>
    </i18n>
    <h4 v-t="'public.accessibility.subtitle7'" class="mt-4" />
    <p v-t="'public.accessibility.p11'"></p>
    <i18n path="public.accessibility.p12">
      <a :href="$t('public.accessibility.p12-link')" v-t="'public.accessibility.p12-link-text'"></a>
    </i18n>
    <h4 v-t="'public.accessibility.subtitle8'" class="mt-4" />
    <p v-t="'public.accessibility.p13'"></p>
    <h4 v-t="'public.accessibility.subtitle9'" class="mt-4" />
    <p v-t="'public.accessibility.p14'"></p>
    <h4 v-t="'public.accessibility.subtitle10'" class="mt-4" />
    <p v-t="'public.accessibility.p15'"></p>
    <i18n path="public.accessibility.p16" class="lead">
      <a :href="$t('public.accessibility.link1')" v-t="'public.accessibility.link1'"></a>
    </i18n>
  </card>
</template>
<script>
export default {
  data() {
    return {
      appName: window.config.appName,
      lp: 'public.accessibility.'
    }
  }
}

</script>
