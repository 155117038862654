export default {
    computed: {
      lp() {
        if (this.questionnaire)
          return "questionnaires." + this.questionnaire.name;
        if (this.$route.meta && this.$route.meta.name) 
          return"questionnaires." + this.$route.meta.name;
        if (this.$route.params && this.$route.params.questionnaire) 
          return"questionnaires." + this.$route.params.questionnaire;
          return "questionnaires." + this.$route.name;
      },
      longLocale() {
        if (window.config.locales[this.$i18n.locale]) {
          return window.config.locales[this.$i18n.locale]
        } else {
          return "en_GB";
        }
      }
    },
    methods: {
      i18nDate(dateStr, format, locale){
        if(!locale) {
          locale = this.longLocale;
        }
        return this.$d(new Date(dateStr), format, locale)
      },
      i18nNumber(number, format, locale) {
        if(!locale) {
          locale = this.longLocale;
        }
        return this.$n(number, format, locale)
    }
  }
}
  
