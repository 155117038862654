<template>
    <div class="grid-container">
        <div class="flow-diagram-list list-group list-group-horizontal">
            <div v-for="diagram in diagrams" :key="diagram.name" @click="diagramShown = diagram.name"
                :class="{ active: diagram.name === diagramShown }" class="flow-diagram-item list-group-item">
                {{ diagram.title }}
            </div>
        </div>
        <!-- <transition-group name="fade" tag="div" class="display-contents" appear> -->
        <participantFlow :diagram="activeDiagram" class="grid-item shadow">
        </participantFlow>
        <!-- </transition-group> -->
        <!-- {{ patients.length }} -->
    </div>
</template>

<script>
import participantFlow from '~/components/admin-dashboard/participant-flow.vue'
import countryMixin from '~/mixins/country';
import { mapState } from 'vuex';
export default {
    name: "recruitment-dashboard",
    mixins: [countryMixin],
    components: {
        participantFlow,
    },
    data() {
        return {
            title: 'Participant Flow',
            diagrams: [
                { name: 'RegistrationFlow', title: this.$t('admin.dashboard.diagrams.RegistrationFlow') },
                { name: 'PatientFlow', title: this.$t('admin.dashboard.diagrams.PatientFlow') },
                { name: 'Issue2Flow', title: this.$t('admin.dashboard.diagrams.Issue2Flow') }
            ],
            diagramShown: 'PatientFlow',
        }
    },
    computed: {
        ...mapState('resources/admin', ['dashboard', 'loadingDashboardData', 'loadedDashboardData',]),
        countryData() {
            if (this.dashboard.data.countryData)
                return this.dashboard.data.countryData
            else return []
        },
        activeDiagram() {
            return this.diagrams.find((diagram) => diagram.name === this.diagramShown);
        },
    }
}
</script>
