import axios from 'axios'
import Cookies from 'js-cookie'
import { findIndex } from 'lodash-es'
// state
export const state = {
  user: null,
  token: Cookies.get('token')
}

// getters
export const getters = {
  user: state => state.user,
  token: state => state.token,
  check: state => state.user !== null,
  isGuest: state => {
    return !state.user
  },
  isParticipant: state => {
    if (state.user && state.user.roles) return findIndex(state.user.roles, ['name', 'Participant']) > -1
    else return false
  },
  isConsented: state => {
    if (state.user && state.user.patient) return state.user.patient.isConsented
    else return false
  },
  isTeam: state => {
    if (state.user && state.user.roles) return findIndex(state.user.roles, ['name', 'CentralTeam']) > -1 || getters.isAdmin(state) || getters.isCountryTeam(state);
    else return false
  },
  isCountryTeam: state => {
    return state.user && state.user.roles && findIndex(state.user.roles, ['name', 'CountryTeam']) > -1;
  },
  isAdmin: state => {
    if (state.user && state.user.roles)
      return findIndex(state.user.roles, (o) => ['Admin', 'SuperAdmin', 'ConsoleAdmin'].includes(o.name)) > -1
    else return false
  },
  inSequence: state => {
    if (state.user && state.user.patient) return state.user.patient.in_sequence
    return false
  },
}

// mutations
export const mutations = {
  ['SAVE_TOKEN'](state, { token, remember }) {
    state.token = token
    Cookies.set('token', token, { expires: remember ? 365 : null })
  },

  ['FETCH_USER_SUCCESS'](state, { user }) {
    state.user = user
  },

  ['FETCH_USER_FAILURE'](state) {
    state.token = null
    Cookies.remove('token')
  },

  ['LOGOUT'](state) {
    state.user = null
    state.token = null
    // window.Echo.leave('ActiveUsers')
    Cookies.remove('token')
  },

  ['UPDATE_USER'](state, { user }) {
    state.user = user
  },
  ['UPDATE_USERS_PATIENT'](state, data) {
    state.user.patient = data.patient
  },
}

// actions
export const actions = {
  saveToken({ commit }, payload) {
    commit('SAVE_TOKEN', payload)
  },
  async login({ commit }, { email, password, user }) {
    const { data: { token } } = await axios.post('login', { 'email': email, 'password': password })

    // Save the token.
    this.dispatch('auth/saveToken', { token })

    if (user == undefined) {
      await this.dispatch('auth/fetchUser');
    } else {
      // Update the user.
      await this.dispatch('auth/updateUser', { user: user })
    }
  },
  async fetchUser({ commit, dispatch }) {
    try {
      const { data } = await axios.get('user')
      commit('FETCH_USER_SUCCESS', { user: data })
      if (state.user.patient) {
        this.dispatch('resources/patient/updatePatient', state.user.patient, { root: true })
      }
      // window.Echo.join('ActiveUsers')

    } catch (e) {
      commit('FETCH_USER_FAILURE')
    }
  },
  updateUser({ commit }, payload) {
    commit('UPDATE_USER', payload)
  },
  updateUsersPatient({ commit }, payload) {
    return axios.put('patient/' + payload.id, payload)
      .then((resp) => {
        commit('UPDATE_USERS_PATIENT', resp.data)
      })
  },
  async logout({ commit }) {
    try {
      await axios.post('logout')
    } catch (e) { }

    commit('LOGOUT')
  },
}
export const namespaced = true;
