import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapState('resources/questionnaires', ['routeChanging', 'questionnaireLoaded', 'questionnaireSaving']),
    ...mapGetters('resources/questionnaires', ['getQuestionnaireByName', 'getQuestionnaireDataitems', 'getQuestionnaireDefaultTemplate', 'getQuestionnaireRecords']),
    questionnaire() {
      if (this.questionnaireLoaded)
        return this.getQuestionnaireByName(this.name)
    },
    dataitems() {
      if (this.questionnaireLoaded)
        return this.getQuestionnaireDataitems(this.name)
    },
    defaultTemplate() {
      if (this.questionnaireLoaded)
        return this.getQuestionnaireDefaultTemplate(this.name)
    },
    records() {
      if (this.questionnaireLoaded)
        return this.getQuestionnaireRecords(this.name)
    }
  }
}
