<template>
    <div>
        <card :title="$t('admin.activity.title')" head :body="false" foot>
            <template #header-right>
                <div class="d-flex">
                    <div class="m-auto">
                        <!-- show filter button -->
                        <btn outline @click="showFilters = !showFilters" v-model="showFilters">
                            <fa icon="filter" class="mr-1"></fa>
                            {{ $t('admin.activity.filters.show') }}
                        </btn>

                    </div>
                </div>
            </template>
            <collapse :show="showFilters">
                <div id="filter-form">
                    <!-- Filter type -->
                    <fieldset class="row row-cols-lg-auto g-3 align-items-center mb-2">
                        <legend class="ps-4 col-2">Filter</legend>
                        <form-group class="col-12">
                            <label>{{ $t('admin.activity.filters.user_id') }}</label>
                            <input-group size="sm">
                                <textbox v-model="filters.user_id" />
                                <btn outline @click.native="filters.user_id = null;"
                                    :disabled="filters.user_id == null">
                                    <fa icon="times"></fa>
                                </btn>
                            </input-group>
                        </form-group>
                        <form-group class="col-12">
                            <label>{{ $t('admin.activity.filters.type') }}</label>
                            <input-group size="sm">
                                <dropdown btn-size="sm" btn-outline :items="activitiesFilters.type"
                                    v-model="filters.type" multiple>
                                    <template #btn-inner="{ item }">
                                        <template v-for="selectedType in filters.type">
                                            <badge :key="selectedType" v-text="selectedType" class="me-1"></badge>
                                        </template>
                                    </template>
                                </dropdown>
                                <btn outline @click.native="filters.type = [];" :disabled="filters.type.length == 0">
                                    <fa icon="times"></fa>
                                </btn>
                            </input-group>
                        </form-group>
                        <!-- Filter by date -->
                        <form-group class="col-12">
                            <label>{{ $t('admin.activity.filters.created_at') }} From</label>
                            <input-group size="sm">
                                <datepicker size="sm" v-model="filters.created_at[0]" />
                                <btn outline @click.native="filters.created_at[0] = null;"
                                    :disabled="filters.created_at[0] == null">
                                    <fa icon="times"></fa>
                                </btn>
                            </input-group>
                        </form-group>
                        <form-group class="col-12">
                            <label>{{ $t('admin.activity.filters.created_at') }} To</label>
                            <input-group size="sm">
                                <datepicker size="sm" v-model="filters.created_at[1]" />
                                <btn outline @click.native="filters.created_at[1] = null;"
                                    :disabled="filters.created_at[1] == null">
                                    <fa icon="times"></fa>
                                </btn>
                            </input-group>
                        </form-group>
                    </fieldset>
                    <fieldset class="row row-cols-lg-auto g-3 align-items-center mb-2">

                        <legend class="ps-4 col-2">Group</legend>
                        <form-group class="col-12">
                            <!-- latestPerUser -->
                            <btn-group size="sm">
                                <btn size="sm" outline v-model="groups.latestPerUser" :true-value="true"
                                    :false-value="null">
                                    <fa icon="filter" class="mr-1"></fa>
                                    {{ $t('admin.activity.groups.latestPerUser') }}
                                </btn>
                                <btn size="sm" outline v-model="groups.latestPerUser" :true-value="null"
                                    :false-value="null">
                                    <fa icon="filter" class="mr-1"></fa>
                                    {{ $t('admin.activity.groups.all') }}
                                </btn>
                            </btn-group>
                        </form-group>
                    </fieldset>
                </div>
            </collapse>
            <data-table :data="activities" :columns="columns" class="table-responsive">
                <template #header-column="{ col }">
                    <div v-if="col.sortable" @click="setSorting(sortField(col.name), pagination)"
                        :class="['dt-column dt-column-header dt-column-' + col.name, sortingClass({ name: sortField(col.name) })]">
                        <div class="p-2" v-text="col.label" />
                    </div>
                    <div v-else :class="['dt-column dt-column-header dt-column-' + col.name + '']">
                        <div class="p-2" v-text="col.label" />
                    </div>
                </template>
                <template #cell-user="{ rowData }">
                    <div class="px-1 pt-1" v-if="rowData && rowData.user">
                        <img v-if="rowData.user.photo_url" :src="rowData.user.photo_url" :alt="rowData.user.name"
                            class="rounded-circle profile-photo me-1" />

                        {{ rowData.user.first_name }} {{ rowData.user.last_name }}
                    </div>
                </template>
                <template #cell-created_at="{ rowData }">
                    <div class="p-2">
                        {{ new Date(rowData.created_at).toLocaleString('en-GB') }}
                    </div>
                </template>
                <template #cell-subject="{ rowData }">
                    <div class="p-2">
                        {{ rowData.subject_type }}
                    </div>
                </template>
                <template #cell-properties="{ rowData }">
                    <div class="p-2">
                        {{ rowData.properties }}
                    </div>
                </template>
            </data-table>
            <template #footer-inner>
                <div class="d-flex pt-2">
                    <ul class="pagination m-auto">
                        <li v-for="(link, n) in pagination.links" :key="'pagination' + n"
                            :class="['page-item', { active: link.active, disabled: !link.url }]">
                            <a @click="updatePagination(link, pagination)"
                                :class="['page-link', { 'bg-dark text-white': $root.theme == 'dark' && !link.active }]"
                                v-html="link.label" />
                        </li>
                    </ul>
                    <div class="m-auto" v-if="!loadingActivities">
                        <span
                            v-t="{ path: 'settings.showing', args: { from: pagination.from, to: pagination.to, total: pagination.total } }"></span>
                    </div>
                    <dropdown v-if="!loadingActivities" btn-outline v-model="pagination.per_page"
                        :items="paginationPerPageList" class="m-auto" @input="updatePerPage(pagination)">
                        <template #btn-inner="{ chosenLabel }">
                            <span v-t="{ path: 'settings.per_page', args: { count: chosenLabel } }"></span>
                        </template>
                    </dropdown>
                </div>
            </template>
        </card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import paginationMixin from '~/mixins/pagination'
export default {
    name: "activity-monitor",
    middleware: ['auth', 'team'],
    mixins: [paginationMixin],
    apiMethod: 'getActivities',

    data() {
        return {
            params: {
                include: 'user',
                sort: '-created_at',

            },
            filters: {
                type: [],
                user_id: null,
                created_at: [null, null],
            },
            groups: {
                latestPerUser: true,
            },
            showFilters: false,

        }
    },

    computed: {
        ...mapState('resources/activity', ['activities', 'activitiesFilters', 'pagination', 'loadingActivities']),
        columns() {
            return [
                // type
                // user
                // created_at
                // description
                // subject
                // properties
                // url
                { name: 'user_id', label: this.$t('admin.activity.columns.user_id'), sortable: true, width: 'max-content' },
                { name: 'user', label: this.$t('admin.activity.columns.user'), sortable: true, width: 'max-content' },
                { name: 'type', label: this.$t('admin.activity.columns.type'), sortable: true },
                { name: 'description', label: this.$t('admin.activity.columns.description'), sortable: true, width: 'max-content' },
                { name: 'created_at', label: this.$t('admin.activity.columns.created_at'), sortable: true, width: 'max-content' },
                { name: 'subject', label: this.$t('admin.activity.columns.subject') },
                { name: 'properties', label: this.$t('admin.activity.columns.properties'), width: 'max-content' },
                { name: 'ip_address', label: this.$t('admin.activity.columns.ip_address'), width: 'max-content' },
                { name: 'user_agent', label: this.$t('admin.activity.columns.user_agent'), width: 'max-content' },
                { name: 'url', label: this.$t('admin.activity.columns.url') },



            ]
        }
    },
    methods: {
        ...mapActions('resources/activity', ['getActivities', 'getFilters']),
        sortField(field) {
            var fields = {
                'user_id': 'activities.user_id',
                'user': 'activities.user_id',
                'type': 'type',
                'description': 'description',
                'created_at': 'created_at',

            }
            return fields[field]
        }
    },
    mounted() {
        this.makeRequest(this.params, this.pagination);
        this.getFilters();
    },
    watch: {
        'filters': {
            handler: function (val, oldVal) {
                this.makeRequest(this.params, this.pagination);
            },
            deep: true
        },
        'groups': {
            handler: function (val, oldVal) {
                this.makeRequest(this.params, this.pagination);
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
#filter-form {
    padding: 0 1rem;

    legend {
        font-size: 1.2rem;
        font-weight: 600;
    }
}
</style>