import {factory} from "../utils/ClassLoader"
import helpers from "../utils/helpers"
import {pickBy} from 'lodash'
let Collection = factory.classes.Collection;
let handler = {
	get(target, prop, receiver) {
		if (!target[prop]) {
			console.error("DATAITEM " + prop + " not found")
		}
		return target[prop];
	}
}
let DataitemCollection = class extends Collection {
	constructor(dataitems) {
		super((new Collection(dataitems)).keyBy("name"))
		// return new Proxy(this, handler)
	}
	fromSection(section) {
        if (section===undefined) section = null;
        return new DataitemCollection(pickBy(this, function(dataitem) {
            return dataitem.section == section; 
        }.bind(this)));
    }
	fromGroup(group) {
		return new DataitemCollection(pickBy(this, function(dataitem) {
            return dataitem.json && dataitem.json.group == group; 
        }.bind(this)));
	}
    getSectionData() {
    	var structure = this.getSectionStructure();
        var sections = {};
    	helpers.dot(structure, "*+", function(value, reference, index, fullPath) {
            sections[index] = this.fromSection(fullPath)
    	}.bind(this), {callOn:"everything"})
        return sections;
    }
    getSectionStructure() {
    	var sectionAddresses = this.listSections();
    	var sectionStructure = {};
    	for(var sectionAddress in sectionAddresses) {
    		var addressParts = sectionAddress.split(".");
    		sectionStructure = this.createSectionPathStructure(sectionStructure, addressParts);
    	}
    	return sectionStructure;
    }
    createSectionPathStructure(sectionStructure, addressParts) {
    	var nextPart = addressParts[0];
    	if (!sectionStructure[nextPart]) {
    		sectionStructure[nextPart] = {}
    	}
    	if (addressParts.length>1) {
    		sectionStructure[nextPart] = this.createSectionPathStructure(sectionStructure[nextPart], addressParts.splice(1))
    	} 
   		return sectionStructure;
    }
    listSections() {
        var sectionAddresses = [];
    	for(var name in this) {
			var dataitem = this[name]; 
			if (dataitem.isSection()) {
    			sectionAddresses.push(dataitem.path)
    		}
    	}
    	return sectionAddresses;
    }
	setLookups(lookups) {
		this.forEach((dataitem) => {
			if (dataitem.lookup_id) {
				var prefix;
				if (dataitem.lookup_type=="TableLookup") {
					prefix = "T-" 
				} else if (dataitem.lookup_type=="Lookup") {
					prefix = "L-" 
				} else if (dataitem.lookup_type=="ApiLookup") {
					prefix = "A-"
				}
				dataitem.setLookup(lookups[prefix + dataitem.lookup_id]);
			}
		})
	}
	setChildren() {
        for(var dataitem of this) {
            dataitem.setChildren(this);
        }
    }
	createDefaultRecord(dataitem) {
		var section;
		if (dataitem==undefined) {
			section = null
		} else if (dataitem instanceof Object) {
			section = dataitem.path;
		} else {
			section = dataitem;
		}
		var recordDataitems = this.fromSection(section);
		var record = {};
		for (var name in recordDataitems) {
			var dataitem = recordDataitems[name]
			record[dataitem.name] = dataitem.default;
		}
		return record
	}
}

export {
	DataitemCollection
}
