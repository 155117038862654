export default {
  methods: {

    patientIssueFromID(patient_issue_id, patient_issues) {
      if (patient_issues)
        return patient_issues.find((issue) => issue.id == patient_issue_id)
    },
    prevPatientIssueId(patient_issues) {
      if (patient_issues) {
        var current = this.patientIssueFromID(this.scoring.currentPatientIssueID, patient_issues)
        var index = patient_issues.findIndex((iss) => iss.id == current.id)
        if (index > 0) {
          return patient_issues[index - 1].id
        }
      }
      return null
    },
    nextPatientIssueId(patient_issues) {
      var current = this.patientIssueFromID(this.scoring.currentPatientIssueID, patient_issues)
      var index = patient_issues.findIndex((iss) => iss.id == current.id)
      if (index < patient_issues.length - 1 && index > -1) {
        return patient_issues[index + 1].id
      }
      return null
    },
    patientQuestionnaires(patient_issue) {
      if (patient_issue && patient_issue.patient_questionnaire)
        return patient_issue.patient_questionnaire
          .filter((pq) => { return this.scoreFilters(patient_issue, pq) })
          .sort((a, b) => (this.questionnaireOrder(a) > this.questionnaireOrder(b)) ? 1 : -1);
      if (patient_issue && patient_issue.patient_questionnaire_summary)
        return patient_issue.patient_questionnaire_summary
          .filter((pq) => { return this.scoreFilters(patient_issue, pq) })
          .sort((a, b) => (this.questionnaireOrder(a) > this.questionnaireOrder(b)) ? 1 : -1);
    },
    questionnaireOrder(pq) {
      if (pq.questionnaire_id == 14) {
        return 0;
      } else {
        if (pq.questionnaire.name == 'pest') {
          return 5;
        }
        return pq.questionnaire_id;
      }
    },
    valid_questionnaires(pQs) {
      if (pQs)
        return pQs.filter((pq) => { return pq.valid == 1; });
    },
    invalid_questionnaires(pQs) {
      if (pQs)
        return pQs.filter((pq) => { return pq.valid == 0; });
    },
    percentage_complete(patient_issue) {
      var pc = 0;
      var pQs = this.patientQuestionnaires(patient_issue)
      if (patient_issue && pQs && pQs.length) {
        pc = this.valid_questionnaires(pQs).length / pQs.length * 100;
      }
      return Math.round(pc)
    },
    chartData(patient_issue) {
      var pQs = this.patientQuestionnaires(patient_issue)
      var validQs = this.valid_questionnaires(pQs)
      var invalidQs = this.invalid_questionnaires(pQs)
      return [
        { val: validQs.length },
        { val: invalidQs.length },
        { val: pQs.length - validQs.length - invalidQs.length }
      ]
    },

    scoreFilters(patient_issue, pq) {
      var name = pq.questionnaire ? pq.questionnaire.name : pq.q
      var hasTreatment = patient_issue.other_fields.has_treatment;
      var hasPsa = patient_issue.other_fields.has_psa;
      var pestScore = patient_issue.scores.pest
      var psaidFatigueScore = patient_issue.scores.psaid ? patient_issue.scores.psaid.fatigue : null
      var psaidDepressionScore = patient_issue.scores.psaid ? patient_issue.scores.psaid.depression : null
      var previousPest = patient_issue.other_fields.previous_pest ? patient_issue.other_fields.previous_pest : null
      // if (name == 'tsqm9') return false; // temporarily hide tsqm9
      // if (name == 'pest') return !hasPsa;
      if (name == 'mdhaq') return pestScore >= 3;
      if (name == 'tsqm9') return !([7, 12, 13].includes(this.patient.country_id)) && pestScore < 3 && hasTreatment;
      if (name == 'phq9') return psaidDepressionScore >= 5
      if (name == 'facit') return psaidFatigueScore >= 5
      if (name == 'pest_followup') return patient_issue.issue_id > 1;
      return true
    },
    scoreSpeedoProps(score, key) {
      if (key == 'pest') return { score: { score, min: 0, max: 5 }, domain: [0, 1, 2, 3, 4, 5], colours: ['#04b21b', '#4da518', '#a2b202', '#bc7200', '#af0000'], ticks: 5 }
      if (key == 'psaid') return { score: { score: score.full, min: 0, max: 10 }, domain: [0, 3, 10], colours: ['#198754', '#ffc107', '#dc3545'], ticks: 40 }
      if (key == 'mdhaq') return { score: { score, min: 0, max: 3 }, domain: [0, 0.625, 3], colours: ['#198754', '#ffc107', '#dc3545'], ticks: 30 }
      if (key == 'tsqm9') return { score: { score, min: 0, max: 100 }, domain: [0, 50, 100], colours: ['#dc3545', '#ffc107', '#198754'], ticks: 40 }
      if (key == 'facit') return { score: { score, min: 0, max: 52 }, domain: [0, 26, 52], colours: ['#dc3545', '#ffc107', '#198754'], ticks: 52 }
      if (key == 'phq9') return { score: { score, min: 0, max: 27 }, domain: [0, 5, 10, 15, 20, 27], colours: ['#04b21b', '#4da518', '#a2b202', '#bc7200', '#af0000'], ticks: 5 }
    },
  }

}
