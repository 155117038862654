import axios from 'axios'
// state
export const state = {
  dataExports: [],
  dataExport: null,
  generatedExports: [],
  pagination: {}
}

// getters
export const getters = {
}

// mutations
export const mutations = {
  GET_EXPORTS(state, data) {
    state.dataExports = data.data
    state.pagination = data.meta
  },
  GET_EXPORT(state, data) {
    state.dataExport = data.export
  },
  SET_EXPORT(state, data) {
    state.dataExport = data
  },
  SCHEDULE_EXPORT(state, data) {
    state.generatedExports.push(data)
  }
}

// actions
export const actions = {
  getExports({ commit }, request) {
    return axios.get('export', request)
      .then((resp) => {
        commit('GET_EXPORTS', resp.data)
      })
  },
  getExport({ commit }, request) {
    return axios.get('export/' + request.id, request)
      .then((resp) => {
        commit('GET_EXPORT', resp.data)
      })
  },
  createExport({ commit }, request) {
    commit('SET_EXPORT', request)
  },
  storeExport({ commit }, request) {
    return axios.post('export', request)
      .then((resp) => {
        commit('SET_EXPORT', resp.data)
      })
  },
  updateExport({ commit }, request) {
    return axios.put('export/' + request.id, request)
      .then((resp) => {
        commit('SET_EXPORT', resp.data)
      })
  },
  downloadExport({ commit }, request) {
    return axios.post('export/' + request.export_id + '/download', request)
      .then((resp) => {
        var downloadUrl = '/api/generated_export/' + resp.data.id + '/download'
        window.open(downloadUrl, '_blank');

      })
  },
  scheduleExport({ commit }, request) {
    return axios.post('export/' + request.export_id + '/schedule', request)
      .then((resp) => {
        commit('SCHEDULE_EXPORT', resp.data)
        console.log(resp.data)
      })
  }

}
export const namespaced = true;
