<template>
	<card :title="$t('admin.dashboard.recruitment_over_time.title')" head :body="false"
		class="recruitment-over-time-card">
		<template #header>
			<div class="card-header">
				<h5 class="card-title">
					<fa icon="chart-area"></fa>
					<span class="ms-2">{{ $t('admin.dashboard.recruitment_over_time.title') }}</span>
				</h5>
				<div v-if="request.loading" class="progress my-4" role="progressbar" aria-label="Loading chart"
					:aria-valuenow="request.percentageComplete" aria-valuemin="0" aria-valuemax="100">
					<div class="progress-bar" :style="'width:' + request.percentageComplete + '%'"></div>
				</div>
				<btn btn-class="icon" v-model="zoomPanEnabled" class="p-0 mt-n1 ms-auto">
					<fa icon="search-plus" fixed-width></fa>
				</btn>
				<btn btn-class="icon" v-model="cumulative" :true-value="true" :false-value="false"
					class="p-0 mt-n1 ms-2">
					<fa icon="chart-area" fixed-width></fa>
				</btn>
				<btn-group v-model="selectedTimeframe" class="ms-2 my-n1">
					<btn outline :true-value="timeframe" size="sm" v-for="timeframe in timeframes" :key="timeframe"
						class="py-0">
						{{ $t('admin.dashboard.recruitment_over_time.timeframes.' + timeframe) }}
					</btn>
				</btn-group>
			</div>
		</template>
		<chart ref="chart" v-if="request.loaded && width && height" :nodes="chartNodes" :width="width" :height="height"
			:margin="margin" :zoom-pan-x="zoomPanEnabled" class="recruitment-over-time-chart"
			@hook:mounted="chartMounted" @zoom="zoomed">
			<stacked-area ref="area" type="step" :class="'recruitment-over-time'" :colours="colours" :width="width"
				:height="height">

			</stacked-area>
			<template #outer>
				<template v-for="country in countries">
					<!-- A flag at the bottom showing the start date for each county -->
					<use :key="'country-flag-' + country.code"
						:href="'/images/home/flags-sprite.svg#' + country.code.toLowerCase()"
						class="flag-img rounded-left img-fluid" :alt="country.code.toLowerCase()"
						:x="getXPosition(new Date(country.study_start_date))" :y="height - margin.top - 12" width="24px"
						height="16px">
					</use>
					<!-- A vertical line representing the start date of the study for each country -->
					<line v-if="country.study_start_date" :key="'country-start-' + country.id"
						:x1="getXPosition(new Date(country.study_start_date))"
						:x2="getXPosition(new Date(country.study_start_date))" :y1="0" :y2="height - margin.bottom"
						:stroke="country.json.hex" class="study_start_date_line"></line>

				</template>
			</template>
			<template #axis>
				<template v-for="n in firstsOfMonths">
					<line :x1="getXPosition(n)" :x2="getXPosition(n)" :y1="0" :y2="height - margin.bottom"
						stroke="var(--bs-secondary)" stroke-width="1" stroke-dasharray="1 15" :key="'month' + n"></line>
				</template>
				<axis dimension="x" ref="xAxis" :width="width" :height="height" :margin="margin" :ticks="axisXTicks"
					:date-format="axisXDateFormat" :tick-size-outer="5" />

				<axis dimension="y" position="right" ref="yAxis" :width="width" :height="height" :margin="margin" />

			</template>
		</chart>

		<!-- <popover ref="popover" target="#foreignObject" :show="popoverVisible"
            :title="$t('admin.dashboard.recruitment_over_time.title')" placement="right">
            <template #body>
                <div class="d-flex">
                    <div class="ms-3 mb-2">Date: {{ $d(popoverContent.date, 'numeric', 'en-GB') }}</div>
                </div>
                <div class="d-flex" v-for="country in countriesReverse" :key="'po' + country.id">
                    <div class="ms-n2 me-1 mb-2 rounded"
                        :style="{ 'width': '20px', 'aspect-ratio': 1, backgroundColor: country.json.hex }">
                    </div>
                    <div class="me-2">
                        <i18n path="admin.dashboard.country" tag="div">
                            <template #country>
                                <span>{{ getCountryLang(country).name }}</span>
                            </template>
                            <template #code>
                                {{ country.code }}
                            </template>
                        </i18n>
                    </div>
                    <div>{{ popoverContent[country.id] }}</div>
                </div>
            </template>
        </popover> -->
	</card>
</template>

<script>
import axis from "../charts/axis.vue";
import areaPath from "../charts/area-path.vue";
import stackedArea from "../charts/stacked-area.vue";
import { bisector } from "d3-array";
import { pointer } from "d3-selection";
import { mapActions, mapState } from "vuex";
import countryMixin from "../../mixins/country";
import { debounce } from 'lodash'
export default {
	name: "recruitment-over-time",
	mixins: [countryMixin],
	components: {
		axis,
		stackedArea,
	},
	mounted() {
		this.defineResizeObserver();
		this.setChartSize()

		// this.getRecruitmentOverTime().then(() => {
		// 	this.mounted = true;
		// });

		// setup resizeObserver
		var self = this;
	},
	updated() {
		// if (this.mounted && this.$el && this.ro) {
		// 	this.width = this.$el.offsetWidth;
		// }
	},
	destroyed() {
		if (this.ro) {
			this.ro.disconnect();
		}
	},
	data() {
		return {
			margin: { left: 10, right: 50, top: 10, bottom: 40 },
			width: null,
			height: null,
			popoverVisible: false,
			popoverContent: "",
			popoverPosition: { x: 0, y: 0 },
			mounted: false,
			ro: null,
			cumulative: false,
			timeframes: ["day", "week", "month"],
			selectedTimeframe: "day",
			zoomPanEnabled: false,
			hasZoomed: false,
			zoomTransform: null,
		};
	},
	computed: {
		...mapState("resources/admin", [
			"dashboard",
			"loadingRecruitmentOverTime",
			"loadedRecruitmentOverTime",
		]),
		resourceId() {
			return 'patient.country'
		},
		request() {
			if (this.dashboard.stream.requests[this.resourceId])
				return this.dashboard.stream.requests[this.resourceId]
			else return {
				loading: false,
				loaded: false
			}
		},
		countries() {
			return this.dashboard.data.countryData;
		},
		activeCountries() {
			if (this.countries)
				return this.countries.filter((c) => {
					return c.active;
				});
			else return [];
		},
		countriesReverse() {
			if (this.activeCountries) return this.activeCountries.slice().reverse();
			else return [];
		},
		colours() {
			if (this.countries)
				// map with key of country id and value of hex colour
				return this.countries.reduce((acc, c) => {
					acc[c.id] = c.json.hex;
					return acc;
				}, {});
			else return [];
		},
		chartNodes() {
			if (this.cumulative) return this.patientRecruitmentCumulative;
			else return this.patientRecruitment;
		},
		startDate() {
			var rd = this.dashboard.stream.patients;

			let date = new Date(rd[0].rd);
			return date;
		},
		patientRecruitment() {
			if (!this.request.loaded) return []
			// data now comes in the form of a large array of objects representing patients
			// each object has a date recruited (r) and a country_id (c)
			// we need to convert this to a count of patients recruited by country by date
			// we can then use this to plot the data
			// we can use the d3 stack function to do this
			// we need to create a data structure that looks like this:
			// [
			// 	{ date: date1, country1: count, country2: count, ... },
			// 	{ date: date2, country1: count, country2: count, ... },
			// 	...
			// ]
			var activeCountryIds = this.activeCountries.map((c) => c.id);
			// get the recruitment data
			var rd = this.dashboard.stream.patients;
			if (Object.keys(rd).length == 0 || this.activeCountries.length == 0)
				return [];
			// sort the data by recruitment date
			rd.sort((a, b) => new Date(a.r) - new Date(b.r));

			let data = [];
			let date = new Date(rd[0].rd);
			let layerData = {};

			for (let patient of rd) {
				let patientDate = new Date(patient.rd);
				if (patientDate.getDate() != date.getDate()) {
					// add the layerData to the data array
					data.push({ date, ...layerData });
					// reset the layerData
					layerData = {};
					// update the date
					date = patientDate;
				}
				// update the layerData
				if (this.activeCountries.find((c) => c.id == patient.c)) {
					if (layerData[patient.c]) layerData[patient.c] += 1;
					else layerData[patient.c] = 1;
				}

				for (let countryId of activeCountryIds) {
					layerData[countryId] = layerData[countryId] ? layerData[countryId] : 0;
				}
			}

			// if selectedTimeframe is not day, we need to aggregate the data
			if (this.selectedTimeframe != "day") {
				let aggregatedData = [];
				let aggregatedLayerData = {};
				let date = new Date(data[0].date);
				for (let datum of data) {
					let datumDate = new Date(datum.date);
					if (this.selectedTimeframe == "week") {
						if (datumDate.getDay() == 0) {
							aggregatedData.push({ date, ...aggregatedLayerData });
							aggregatedLayerData = {};
							date = datumDate;
						}
					} else if (this.selectedTimeframe == "month") {
						if (datumDate.getDate() == 1) {
							aggregatedData.push({ date, ...aggregatedLayerData });
							aggregatedLayerData = {};
							date = datumDate;
						}
					}
					for (let countryId of activeCountryIds) {
						aggregatedLayerData[countryId] = aggregatedLayerData[countryId]
							? aggregatedLayerData[countryId] + datum[countryId]
							: datum[countryId];
					}
				}
				data = aggregatedData;
			}

			return data;
		},
		patientRecruitmentCumulative() {
			// data now comes in the form of a large array of objects representing patients
			// each object has a date recruited (r) and a country_id (c)
			// we need to convert this to a cumulative count of patients recruited by country by date
			// we can then use this to plot the data
			// we can use the d3 stack function to do this
			// we need to create a data structure that looks like this:
			// [
			// 	{ date: date1, country1: count, country2: count, ... },
			// 	{ date: date2, country1: count, country2: count, ... },
			// 	...
			// ]

			let countryCumulativeCounts = {};
			// make keys from this.countries
			this.countries.forEach((c) => {
				countryCumulativeCounts[c.id] = 0;
			});
			// get the recruitment data
			var rd = this.dashboard.stream.patients;
			if (Object.keys(rd).length == 0 || this.activeCountries.length == 0)
				return [];
			// sort the data by recruitment date
			rd.sort((a, b) => new Date(a.r) - new Date(b.r));

			let data = [];
			let date = new Date(rd[0].rd);
			let layerData = {};

			for (let patient of rd) {
				let patientDate = new Date(patient.rd);
				if (patientDate.getDate() != date.getDate()) {
					// add the layerData to the data array
					data.push({ date, ...layerData });
					// reset the layerData
					layerData = {};
					// update the date
					date = patientDate;
				}
				// update the layerData
				if (this.activeCountries.find((c) => c.id == patient.c)) {
					countryCumulativeCounts[patient.c] += 1;
				}
				for (let country in countryCumulativeCounts) {
					layerData[country] = countryCumulativeCounts[country];
				}
			}
			return data;

		},
		axisXTicks() {
			var dateRange = new Date() - this.startDate;

			if (this.selectedTimeframe == "day") {
				var days = Math.floor(dateRange / (1000 * 60 * 60 * 24));
				if (days < 30) return days;
				else return 12;
			} else if (this.selectedTimeframe == "week") {
				var months = Math.floor(dateRange / (1000 * 60 * 60 * 24 * 30));
				var weeks = Math.floor(dateRange / (1000 * 60 * 60 * 24 * 7));
				if (weeks < 30) return weeks;
				else return months;
			} else if (this.selectedTimeframe == "month") {

				var months = Math.floor(dateRange / (1000 * 60 * 60 * 24 * 30));
				if (months < 30) return months;
				else return 12;
			}
			return 30;
		},
		axisXDateFormat() {

			// date format defaults to d3 format: MMM YY
			if (this.selectedTimeframe == "day") return "MMM YY"
			else if (this.selectedTimeframe == "week") return "MMMM YY"
			else if (this.selectedTimeframe == "month") return "MMM YY"

			return "MMM YY"


		},
		firstsOfMonths() {
			var dateRange = new Date() - this.startDate;
			var months = Math.floor(dateRange / (1000 * 60 * 60 * 24 * 30));
			var firsts = [];
			for (var i = 0; i <= months; i++) {
				var date = new Date(this.startDate);
				date.setMonth(date.getMonth() + i);
				date.setDate(1);
				firsts.push(date);
			}
			return firsts;
		},
	},
	methods: {
		// ...mapActions("resources/admin", ["getRecruitmentOverTime"]),
		chartMounted() {
			// debounce resize event
			this.defineResizeObserver()

			this.setChartSize();
		},
		setChartSize() {
			// console.log('setting chart size', this.$el)
			if (!this.$el || !this.$el.querySelector) return
			var el = this.$el
			var header = el.querySelector('.card-header')



			var chartComponent = this.$refs.chart

			if (chartComponent) {
				if (chartComponent.$el) {
					chartComponent.$el.style.display = 'none'
				}

				// var chart = this.$refs.chart.$el;

				// if aspect ratio is less than 2:1 zoom in to the later data
				// chartComponent has a zoomed method that can be called to zoom in
				if (this.width / this.height < 2) {
					chartComponent.setZoomManually({ k: 2, x: -this.width, y: 0 });
				} else {
					// if aspect ratio is greater than 2:1 zoom out to the earlier data


				}
				// chartComponent.$el.style.display = 'block'
				this.$nextTick(() => {
					// console.log('setting chart size', this.width, this.height)
					if (chartComponent.$el) { chartComponent.$el.style.display = 'block' }
				})
			} else {
				console.log('no chart component')
			}
			var box = el.getBoundingClientRect()
			this.width = box.width;
			this.height = box.height - (header.offsetHeight * 2);

		},

		defineResizeObserver() {
			if (this.$el && this.request.loaded && !this.ro) {
				this.ro = new ResizeObserver(debounce((entries) => {
					for (const entry of entries) {
						// Extract the size and update the chart
						const { width, height } = entry.contentRect;
						// console.log('resize', width, height)
						requestAnimationFrame(() => {
							this.setChartSize()
						});
					}
				}, 500));
				this.ro.observe(this.$el);
			}

		},
		getXPosition(date) {
			if (this.$refs.chart) {
				var chart = this.$refs.chart;
				const xScale = chart.range.x;
				if (this.hasZoomed) {
					return xScale(date) * this.zoomTransform.k + this.zoomTransform.x;
				} else return xScale(date);
			} else return 0;
		},
		showPopover(event) {
			// Get mouse position
			// Same logic as before for finding closest point
			const [x, y] = pointer(event);
			const bisectDate = bisector((d) => d.date).left;
			var chart = this.$refs.chart;
			const xScale = chart.range.x;
			const x0 = xScale.invert(x);
			const i = bisectDate(chart.nodes, x0, 1);
			const d0 = chart.nodes[i - 1];
			const d1 = chart.nodes[i];
			if (!d0 || !d1) return;
			const d = x0 - d0.date > d1.date - x0 ? d1 : d0;

			// Set popover content and position
			this.popoverContent = this.makePopoverContent(d);
			this.popoverPosition = { x, y };
			if (this.popoverVisible) {
				this.popoverVisible = false;
				this.$refs.popover.destroyPopperInstance();
				this.$nextTick(() => {
					this.$refs.popover.createPopperInstance();
				});
			}
			this.popoverVisible = true;
		},
		hidePopover() {
			this.popoverVisible = false;
		},
		makePopoverContent(d) {
			return d;
		},
		zoomed(transform) {
			this.zoomTransform = transform;
			this.hasZoomed = true;
		},
	},
	watch: {
		'request.loaded': function () {
			this.setChartSize()
			this.defineResizeObserver()
		}
	}
};
</script>
