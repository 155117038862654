<template>
  <div :class="['m-1', { 'was-validated': form?.questionnaire.wasValidated }]" v-if="dataitems && records">
    <h5 :class="['display-5 mb-4 mx-3', { 'mx-md-4': mode == 'first_consent' }]">{{ form.label }}</h5>
    <card v-for="(category, key) in categorizedDataitems" :key="'category' + key" :body="false"
      :class="['mb-4', { 'mx-md-4': mode == 'first_consent' }]">
      <div class="card-header">
        {{ category.title }}
      </div>
      <list-group flush>
        <list-group-item v-for="(di, n) in category.items" :key="'dataitem' + di.name" class="pb-2">
          <form-group :di="di" v-if="di.lookup && di.lookup.name == 'YN'" hide-label hide-error class="row">
            <column :sizes="{ sm: 12, md: 10, lg: 8 }" :offset="{ lg: 1 }">
              <div class="d-md-flex">
                <div class="me-2" style="min-width:2rem;">{{ di.json.order }}.</div>
                <i18n :path="'questionnaires.consent.dataitems.' + di.name + '.label'" tag="label" for="tos"
                  :class="['text-justify', { 'mx-md-4': mode == 'first_consent' }]"
                  style="text-align: justify; text-justify: inter-word;">
                  <template #information_sheet>
                    <a :href="pis[form.name].pis_path" target="_blank"
                      v-t="'questionnaires.consent.dataitems.' + di.name + '.linkLabel'" />
                  </template>
                  <template #pis_version_date>
                    {{ $d(new Date(pis[form.name].pis_version_date), 'numeric', longLocale) }}
                  </template>
                  <template #pis_version_no>{{ $n(pis[form.name].pis_version, "version", longLocale) }}</template>
                </i18n>
              </div>
              <feedback class="d-block mx-md-5 px-md-4" type="invalid" v-if="errors[di.name]">{{ errors[di.name][0] }}
              </feedback>
            </column>
            <column :sizes="{ sm: 6, md: 2 }" :offset="{ sm: 6, md: 0 }" class="d-flex align-items-bottom">
              <btn-group class="ms-auto mt-sm-2">
                <template v-for="item in di.lookup.items">
                  <btn :key="di.name + '_' + item.label" theme="icon" v-model="records[di.name]"
                    :true-value="item.value">
                    <fa :icon="item.value == 1 ? 'check' : 'times'" fixed-width
                      :style="iconStyle(records[di.name], item.value, item.value == 1 ? '--bs-success' : '--bs-danger')"
                      size="2x"></fa>
                  </btn>
                </template>
              </btn-group>
            </column>
          </form-group>
          <form-group hide-label :di="di" v-if="di.type == 'norecord'" :error="errors[di.name]" class="row">
            <column :sizes="{ sm: 12, md: 10, lg: 8 }" :offset="{ lg: 1 }">
              <div class="d-md-flex">
                <div class="me-2" style="min-width:2rem;">{{ di.json.order }}.</div>
                <i18n :path="'questionnaires.consent.dataitems.' + di.name + '.label'" tag="label" for="tos"
                  :class="['text-justify', { 'mx-md-4': mode == 'first_consent' }]"
                  style="text-align: justify; text-justify: inter-word;"></i18n>
              </div>
            </column>
            <column :sizes="{ sm: 6, md: 2 }" :offset="{ sm: 6, md: 0 }" class="d-flex align-items-bottom">
              <span class="ms-auto mt-sm-2">N/A</span>
            </column>
          </form-group>
          <form-group :di="di" v-if="di.lookup && di.lookup.name != 'YN'" :error="errors[di.name]">
            <btn-group v-model="records[di.name]" class="ms-auto" btn-class="secondary text-start" outline :di="di"
              vertical justified>
              <template #btn-label="{ btn }">
                <div class="d-flex align-items-center">
                  <fa :icon="records[di.name] == btn.value ? 'check-square' : 'square'" size="lg" class="me-4" />
                  <span class="flex-grow-1">{{ btn.label }}</span>
                </div>
              </template>
            </btn-group>
          </form-group>
          <template v-for="child in di.children">
            <collapse :show="shouldDisplay(child)">
              <form-group :key="'child' + child.name" :di="child" :error="errors[child.name]">
                <template #default="{ di }">
                  <div class="d-flex" v-if="shouldDisplay(di)">
                    <btn-group v-if="di.type == 'multilookup'" @input="(val) => multilookupToggle(di.name, val)" outline
                      :di="di" class="w-100" btn-class="secondary text-start">
                      <template #btn="{ btn, btnProps }">
                        <btn v-bind="btnProps" v-model="records[di.name][btn.value]" :true-value="true">
                          <div class="d-flex align-items-center">
                            <fa :icon="records[di.name][btn.value] ? 'check-square' : 'square'" size="lg"
                              class="me-4" />
                            <span class="flex-grow-1">{{ btn.label }}</span>
                          </div>
                        </btn>
                      </template>
                    </btn-group>
                    <btn-group v-else-if="di.lookup" :di="di" v-model="records[di.name]" outline class="w-100"
                      btn-class="secondary text-start">
                      <template #btn-label="{ btn }">
                        <div class="d-flex align-items-center">
                          <fa :icon="records[di.name] == btn.value ? 'check-square' : 'square'" size="lg"
                            class="me-4" />
                          <span class="flex-grow-1">{{ btn.label }}</span>
                        </div>
                      </template>
                    </btn-group>
                  </div>
                </template>
              </form-group>
            </collapse>
          </template>
        </list-group-item>
      </list-group>
    </card>
  </div>
</template>
<script>
import { calculateBrackets } from "~/plugins/validator.js"
import { mapGetters, mapActions } from 'vuex'
import { sortBy, groupBy } from 'lodash-es'
export default {
  model: {
    prop: 'records',
    event: 'input'
  },
  props: {
    form: {
      type: Object
    },
    dataitems: {
      type: Object
    },
    errors: {
      type: Object
    },
    disabled: {
      type: Boolean
    },
    mode: {
      type: String
    },
    country: {

    },
    pis: {

    },
    records: Object
    // value: {}
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin']),
    filteredDataitems() {
      var filteredDataitems = {}
      for (var diName in this.dataitems) {
        var di = this.dataitems[diName]
        if (di.json.parent) {
          var parent = this.dataitems[di.json.parent]
          if (!parent.children) parent.children = []
          if (!parent.children.includes(di)) parent.children.push(di)
        } else {
          filteredDataitems[diName] = di
        }
      }
      return filteredDataitems
    },
    longLocale() {
      if (window.config.locales[this.$i18n.locale]) {
        return window.config.locales[this.$i18n.locale]
      } else {
        return "en-GB"
      }
    },
    orderedDataitems() {
      return sortBy(this.filteredDataitems, (di) => di.json.order);
    },
    groupedCategories() {
      return groupBy(this.orderedDataitems, (di) => di.json.category);
    },
    categorizedDataitems() {
      return [
        { title: this.$t('questionnaires.consent.required_items'), items: this.groupedCategories[1] },
        { title: this.$t('questionnaires.consent.optional_items'), items: this.groupedCategories[2] }
      ];
    },
  },
  methods: {
    shouldDisplay(dataitem) {
      return !dataitem.json.requires || calculateBrackets(dataitem.json.requires, this.records);
    },
    iconStyle(val, iconVal, colVar) {
      return { color: val == iconVal ? 'var(' + colVar + ')' : 'var(--bs-gray-500)' }
    },
    multilookupToggle(diName, val) {
      this.records[diName][val] = !this.records[diName][val]
    }
  },
  // watch: {
  //   records:{
  //     handler(){
  //       console.log('watch records') 
  //       this.$emit('input', this.records)
  //     }, deep:true
  //   }
  // }
}

</script>
