<template>
  <row>
    <column :sizes="{ md: 6, lg: 4 }" class="m-auto">
      <form @submit.prevent="login" @keydown="form.onKeydown($event)">
        <card :title="$t('login.title')" head foot class="g-4">
          <!-- Email -->
          <form-group class="mb-2" floating-label :label="$t('login.email')">
            <textbox v-model="form.email" :class="{ 'is-invalid': form.errors.has('email_hash') }" type="email"
              name="email" required :placeholder="$t('login.email')" />
            <feedback type="invalid" v-text="form.errors.get('email') || form.errors.get('email_hash')" />
          </form-group>
          <form-group class="mb-2" floating-label :label="$t('login.password')">
            <textbox v-model="form.password" :class="{ 'is-invalid': form.errors.has('password_hash') }" type="password"
              name="password" required :placeholder="$t('login.password')" />
            <feedback type="invalid" v-text="form.errors.get('email') || form.errors.get('email_hash')" />
          </form-group>
          <!-- Remember Me -->
          <form-group class="my-2">
            <div class="d-flex">
              <checkbox v-model="remember" name="remember">
                {{ $t('login.remember_me') }}
              </checkbox>
              <router-link :to="{ name: 'password.request' }" class="small ms-auto my-auto">
                {{ $t('login.forgot_password') }}
              </router-link>
            </div>
          </form-group>
          <template #footer>
            <div class="card-footer">
              <div class="row">
                <!-- Submit Button -->
                <button class="btn btn-primary" block type="submit" :class="[{ 'btn-loading': form.busy }]">
                  {{ $t('login.title') }}
                </button>
              </div>
            </div>
          </template>
        </card>
      </form>
    </column>
  </row>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import Form from 'vform'
import Cookies from 'js-cookie'
export default {
  middleware: 'guest',
  metaInfo() {
    return { title: this.$t('login.title') }
  },
  data: () => ({
    form: new Form({
      email: '',
      password: ''
    }),
    remember: false
  }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isAdmin', 'isTeam', 'isCountryTeam', 'isParticipant', 'inSequence'])
  },
  methods: {
    async login() {
      // Submit the form.
      const { data } = await this.form.post('login')

      // Save the token.
      this.$store.dispatch('auth/saveToken', {
        token: data.token,
        remember: this.remember
      })

      // Fetch the user.
      await this.$store.dispatch('auth/fetchUser')
      this.ga()
      this.redirect()

    },
    ga() {
      this.$gtag.config({ params: { user_id: this.user.id } });
      this.$gtag.event({
        event_category: 'auth',
        value: 'login',
        event_label: 'User logged in',
      })
    },
    redirect() {
      const intendedUrl = Cookies.get('intended_url')
      if (intendedUrl) {
        Cookies.remove('intended_url')
        this.$router.push({ path: intendedUrl })
      } else {
        var name = this.isAdmin || this.isTeam || this.isCountryTeam ? 'admin.dashboard' : 'home'
        if (this.isParticipant && ['registration'].includes(this.inSequence)) name = 'registration_check'
        this.$router.push({ name })
      }
    }
  }
}
</script>
