<template>
	<g class="sankey">
		<g class="links" v-if="mounted">
			<slot name="links">
				<g class="link" v-for="(link, i) in links" :key="'link' + link.id">
					<path :key="link.id + 'path'" :d="linkGenerator(link)" :stroke-width="Math.max(1, link.width)"
						:stroke="`url(#sankeyLinkGradient-${i})`">
						<title>{{ title(link) }}</title>
					</path>
					<linearGradient :id="`sankeyLinkGradient-${i}`" gradientUnits="userSpaceOnUse" :x1="link.source.x1"
						y1="0" :x2="link.target.x0" y2="0">
						<stop :offset="0" :stop-color="link.source.props.colour"></stop>
						<stop :offset="1" :stop-color="link.target.props.colour"></stop>
					</linearGradient>
					<g
						:style="{ transition: 'transform 1s', transform: `translate(${(link.source.x1 + link.target.x0) / 2}px, ${(link.y1 + link.y0) / 2 + 6}px)` }">
						<text class="link-text" :key="link.id + 'link'">{{ link.value }}</text>
					</g>
				</g>
			</slot>
		</g>
		<g class="nodes" v-if="mounted">
			<slot name="nodes">
				<g class="node" v-for="(node, i) in nodes" :key="'node' + node.id">
					<rect :key="node.id + 'node-rect'" :x="node.x0" :y="node.y0" :width="node.x1 - node.x0"
						:height="node.y1 - node.y0" :fill="node.props.colour"></rect>
					<g
						:style="{ transition: 'transform 1s', transform: `translate(${node.x0 < width / 2 ? node.x1 + 6 : node.x0 - 6}px, ${node.y0 + 12}px)` }">
						<text class="node-text" :key="node.id + 'node-text'"
							:text-anchor="node.x0 < width / 2 ? 'start' : 'end'">{{ node.name }}: {{ node.value
							}}</text>
					</g>
				</g>
			</slot>
		</g>
	</g>
</template>

<script>
import { select } from "d3-selection";
import { sankey, sankeyLinkHorizontal } from "d3-sankey";
export default {
	name: "SankeyDiagram",
	props: {
		// Nodes data array
		nodes: {
			type: Array,
			default: () => [],
			validator: function (value) {
				// Validate that each node has an id
				return value.every((node) => node.hasOwnProperty("id") && node.hasOwnProperty("name"));
			},
		},
		// Links data array
		links: {
			type: Array,
			default: () => [],
			validator: function (value) {
				// Validate that each link has source, target, and value
				return value.every(
					(link) =>
						link.hasOwnProperty("source") &&
						link.hasOwnProperty("target") &&
						link.hasOwnProperty("value")
				);
			},
		},
		// Node width
		nodeWidth: {
			type: Number,
			default: 15,
		},
		// Node padding
		nodePadding: {
			type: Number,
			default: 10,
		},
		// Node sort function
		nodeSort: {
			type: Function,
			default: null,
		},
		// Chart width
		width: {
			type: Number,
			default: 800, // Example default width
		},
		// Chart height
		height: {
			type: Number,
			default: 600, // Example default height
		},
		// Colors for nodes
		nodeColors: {
			type: Array,
			default: () => [
				"#67b7dc",
				"#6794dc",
				"#6771dc",
				"#8067dc",
				"#a367dc",
				"#c767dc",
				"#dc67ce",
				"#dc67ab",
				"#dc6788",
				"#dc6967",
			],
		},
		// Optional: Function for custom color logic
		nodeColorScheme: {
			type: Function,
			default: null,
		},
		// Enable or disable link color matching with source node
		linkColorMatchSource: {
			type: Boolean,
			default: true,
		},
		// Enable tooltips
		enableTooltips: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			mounted: false,
		};
	},
	mounted() {
		this.layoutSankey();
		// this.drawSankey();
	},
	computed: {
		sankeyGroup() {
			return select(this.$el);
		},
		linksGroup() {
			return this.sankeyGroup.select(".links");
		},
		nodesGroup() {
			return this.sankeyGroup.select(".nodes");
		},
		linkGenerator() {
			return sankeyLinkHorizontal();
		},
	},
	methods: {
		layoutSankey() {
			const sankeyLayout = sankey()
				.nodeId((d) => d.id)
				.nodeWidth(this.nodeWidth)
				.nodePadding(this.nodePadding)
				.size([this.width, this.height])
				.nodeSort(this.nodeSort)

			// Create a new sankey diagram
			const sankeyDiagram = sankeyLayout({
				nodes: this.nodes,
				links: this.links,
			});
			this.mounted = true;

		},
		title(link) {
			return JSON.stringify(link.request.filter)
		}
	},
	watch: {
		links: {
			handler(newVal, oldVal) {
				if (newVal.length > 0) {
					this.layoutSankey();
				}
			},
		},
		width() {
			this.layoutSankey();
		},
	},
};
</script>
