<template>
    <svg class="progress-chart">

        <!-- a circle illustrating the target recruitment -->
        <circle cx="50%" cy="50%" :r="targetRadius" class="recruitment-target-circle" stroke-width="2" />
        <!-- a circle illustrating the current recruitment -->
        <circle cx="50%" cy="50%" :r="countRadius" class="recruitment-count-circle" />
        <!-- a circle showing the current date as a circle expanding towards end date -->
        <circle cx="50%" cy="50%" :r="dateRadius" class="today-circle" />


        <text x="50%" y="40%" text-anchor="middle" dominant-baseline="middle" class="count">{{ period.recruitment_count
            }}</text>
        <text x="50%" y="64%" text-anchor="middle" dominant-baseline="middle" class="target">{{ period.target }}</text>
    </svg>
</template>

<script>
// import necessary d3 functions for plotting dates on the x-axis
import { scaleTime, scaleLinear, axisBottom, timeFormat } from 'd3';

export default {
    name: 'country-recruitment-period',
    props: {
        country: {
            type: Object,
            required: true
        },
        period: {
            type: Object,
            default: () => ({})
        },
        radius: {
            type: Number,
            default: 28
        },
        width: {
            type: Number,
            default: 60
        },
        height: {
            type: Number,
            default: 60
        },
    },
    computed: {
        periodNumber() {
            // make a number from the period string
            return parseInt(this.period.period);
        },
        isLatestPeriod() {
            return this.periodNumber === parseInt(this.country.recruitmentData[this.country.recruitmentData.length - 1].period);
        },
        periodStartDate() {
            var studyStartDate = new Date(this.country.study_start_date);
            if (this.periodNumber === 0) {
                return studyStartDate;
            }
            //each period lasts 6 Months so add 6 months for each period
            return new Date(studyStartDate.setMonth(studyStartDate.getMonth() + (6 * this.periodNumber)));
        },
        periodEndDate() {
            // six months from the start date
            var endDate = new Date(this.periodStartDate);
            return new Date(endDate.setMonth(endDate.getMonth() + 6));
        },
        xScale() {
            return scaleTime()
                .domain([this.periodStartDate, this.periodEndDate])
                .range([0, this.radius * 2]);
        },
        xPosToday() {
            if (this.isLatestPeriod) {
                return this.xScale(new Date()) + '%';
            }
            return this.xScale(new Date()) + '%';
        },
        targetRadius() {
            var recruitmentTarget = this.period.target
            var recruitmentCount = this.period.recruitment_count
            var radius = this.radius;
            if (recruitmentCount > recruitmentTarget) {
                // target radius is a proportion of the recruitment count
                radius = (recruitmentTarget / recruitmentCount) * radius;
                return radius
            } else {
                return radius;
            }

        },
        countRadius() {
            var recruitmentCount = this.period.recruitment_count
            var recruitmentTarget = this.period.target
            var radius = this.radius;
            if (recruitmentCount < recruitmentTarget) {
                // target radius is a proportion of the recruitment count
                radius = (recruitmentCount / recruitmentTarget) * radius;
                return radius
            } else {
                return radius;
            }
        },
        dateRadius() {
            if (this.isLatestPeriod) {
                var r = this.xScale(new Date()) + '%';
                if (r < 0) {
                    return 0;
                }
                return r;
            }
            return 0;
        }

    }
}
</script>
