import axios from 'axios'
// state
export const state = {
  sites: [],
  site: {},
  pagination: {}
}

// getters
export const getters = {
}

// mutations
export const mutations = {
  GET_SITES(state, data) {
    state.sites = data.data
    state.pagination = data.meta
  },
  GET_SITE(state, data) {
    state.site = data.site
  }
}

// actions
export const actions = {
  getSites({ commit }, request) {
    axios.get('site', request)
      .then((resp) => {
        commit('GET_SITES', resp.data)
      })
  },
  getSite({ commit }, request) {
    axios.get('site/' + request.id, request)
      .then((resp) => {
        commit('GET_SITE', resp.data)
      })
  }
}
export const namespaced = true;
