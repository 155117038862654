<template>
  <questionnaire :title="$t('questionnaires.phq9.title')">
    <alert type="info" class="fs-5" v-t="'questionnaires.phq9.introduction'"></alert>
    <row>
      <column>
        <template v-for="diName in phq9Items">
          <form-group label-class="fs-5" :error="errors[diName]" :di="dataitems[diName]" v-slot="{ di }" class="mb-4">
            <btn-group outline :readonly="isSubmitted" :di="di" v-model="records[di.name]" justified
              :vertical="['xs', 'sm'].includes($mq)"></btn-group>
          </form-group>
        </template>
      </column>
    </row>
    <card class="h-100" v-if="allScored && isSubmitted" id="psa-msg">
      <row>
        <column :sizes="{ sm: 6, lg: 4 }" :offset="{ sm: 3, lg: 0 }">
          <h4 class="display-4 text-center text-uppercase" v-t="'questionnaires.questionnaire.score'"></h4>
          <score-speedo :score="{ score, min: 0, max: 27 }" :domain="[0, 5, 10, 15, 20, 27]"
            :colours="['#04b21b', '#4da518', '#a2b202', '#bc7200', '#af0000']" :ticks="5"></score-speedo>
        </column>
        <column :sizes="{ lg: 8 }">
          <alert type="info">
            {{ getScoringRange(score)?.label }}
            <!-- <div v-if="allScored && score <= 2">
              <h4 v-t="'questionnaires.pest.feedback.score_lte_2.title'"></h4>
              <p v-t="'questionnaires.pest.feedback.score_lte_2.message'"></p>
            </div>
            <div v-if="allScored && score >= 3">
              <h4 v-t="'questionnaires.pest.feedback.score_3plus.title'"></h4>
              <p v-t="'questionnaires.pest.feedback.score_3plus.message'"></p>
            </div> -->
          </alert>
          <alert v-if="patient_issue.other_fields.phq9_advice">
            <p v-t="'questionnaires.phq9.PHQ9_9.advice'"></p>
          </alert>
        </column>
      </row>
    </card>
  </questionnaire>
</template>
<script>
import questionnaire from "~/mixins/questionnaire.js"
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'phq9',
  mixins: [questionnaire],
  computed: {
    ...mapState('resources/patient', ['patient_issue']),
    phq9Items() {
      return [
        'PHQ9_1',
        'PHQ9_2',
        'PHQ9_3',
        'PHQ9_4',
        'PHQ9_5',
        'PHQ9_6',
        'PHQ9_7',
        'PHQ9_8',
        'PHQ9_9',

      ]
    },
    score() {
      var score = 0
      this.phq9Items.forEach((diName) => {
        if (this.records[diName] !== null) score = parseInt(score + parseInt(this.records[diName]));
      })
      return score
    },
    allScored() {
      var scored = true
      this.phq9Items.forEach((diName) => {
        if (this.records[diName] == null) scored = false
      })
      return scored
    },
    scoreRanges() {
      return [
        { min: 0, max: 4, color: '#198754', label: 'None' },
        { min: 5, max: 9, color: '#ffc107', label: 'Mild' },
        { min: 10, max: 14, color: '#ffc107', label: 'Moderate' },
        { min: 15, max: 19, color: '#dc3545', label: 'Moderately Severe' },
        { min: 20, max: 27, color: '#dc3545', label: 'Severe' },
      ]
    },
    isSubmitted() {
      if (this.patient_issue && this.patient_issue.patient_questionnaire) {
        return this.patient_issue.patient_questionnaire.find((pq) => pq.questionnaire_id == this.questionnaire.id).valid == 1
      }
      else {
        return false
      }
    }
  },
  methods: {
    getScoringRange(score) {
      return this.scoreRanges.find((range) => score >= range.min && score <= range.max)
    }
  }
}

</script>
