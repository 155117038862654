<template>
  <card foot>
    <div class="text-center d-flex flex-column">
      <h3 class="mb-4">{{$t('participant.process.subtitle')}}</h3>
      <fa icon="thumbs-up" size="3x" :class="['mx-auto mb-4']" />
      <!-- <img src="/images/rudy.svg" alt="" class="mx-auto mb-4" style="width:min(80vw,600px)"> -->
      <p>{{$t('participant.process.instructions')}}</p>
    </div>
    <template #footer>
      <div class="card-footer d-flex">
        <btn btn-class="success" size="lg" class="m-auto" @click.native="getStarted" v-t="'participant.process.btn'" :disabled="submitting"></btn>
      </div>
    </template>
  </card>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  middleware: ['auth', 'check-registration'],
  name: "registration_check",
  data() {
    return {
      submitting: false
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('auth', ['updateUsersPatient']),
    ...mapActions('resources/patient', ['assignPatientIssue']),
    getStarted() {
      this.submitting = true
      this.updateUsersPatient({ id: this.user.patient.id, status: 'consented', 'in_sequence': null })
        .then(() => {
          var params = { patient_id: this.user.patient.id, issue: 1 }
          this.assignPatientIssue({ params })
            .then((resp) => {
              this.submitting = false
              this.$router.push({ name: 'registration_check' })
            })
        })
    }
  }
}

</script>
