<template>
  <div>
    <card head v-if="questionnaire" :title="$t('admin.questionnaire.title', { title: this.questionnaire.title })"
      class="mb-4">
      <template #header-left>
        <btn size="sm" class="me-2" is-router-link :to="{ name: 'admin.questionnaires' }">
          <fa icon="chevron-left" />
        </btn>
      </template>
      <h3><small>Title: </small><span v-text="questionnaire.title" /></h3>
      <h3><small>Name: </small>
        <badge v-text="questionnaire.name" />
      </h3>
      <card head :title="$t('admin.questionnaire.dataitems')" :body="false">
        <data-table v-if="questionnaire.dataitems" :data="questionnaire.dataitems" :columns="dataitemColumns" paginate
          :paginate-rows="15" :class="['dataitems-datatable table-responsive', 'cols-' + dataitemColumns.length]">
          <template #cell-label="{ rowData }">
            <template v-if="rowData.label">
              <div class="p-2" v-t="'questionnaires.' + questionnaire.name + '.dataitems.' + rowData.name + '.label'" />
            </template>
          </template>
          <template #cell-lookup="{ rowData }">
            <template v-if="rowData.lookup_id">
              <div class="p-2">
                <label class="cell-label">Lookup:</label>
                <scope :lookup="getLookup(rowData.lookup_id)" v-slot="{ lookup }">
                  <badge v-for="(item, n) in lookup.items" :key="'lu' + rowData.lookup_id + '_' + item.value"
                    class="me-1"
                    v-t="'questionnaires.' + questionnaire.name + '.lookupitems.' + lookup.name + '.' + item.value + '.label'">
                  </badge>
                </scope>
              </div>
            </template>
          </template>
          <template #cell-validation="{ rowData }">
            <scope :validation="rowData.validation_rules" v-slot="{ validation }">
              <list-group flush v-if="validation" :items="validation" :class="['validation-' + rowData.name]">
                <template #item="{ item }">
                  <div class="list-group-item d-flex align-items-center">
                    <fa icon="exclamation-circle" class="me-2" />
                    <span v-text="item.rule" />
                    <badge pill class="ms-auto" v-text="errorCount(item, rowData)" />
                  </div>
                </template>
              </list-group>
            </scope>
          </template>
          <template #cell-json="{ rowData }">
            <template v-if="rowData.json">
              <span></span>
              <!-- <label class="cell-label">JSON:</label>
              <div class="p-2">{{ rowData.json }}</div> -->
            </template>
          </template>
        </data-table>
      </card>
    </card>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import paginationMixin from '~/mixins/pagination'
export default {
  name: "questionnaire",
  metaInfo() {
    if (this.questionnaire)
      return { title: this.$t('admin.questionnaire.title', { title: this.questionnaire.title }) }
  },
  layout: 'fullscreen',
  middleware: ['auth', 'team'],
  mixins: [paginationMixin],
  apiMethod: 'getQuestionnaire',
  data() {
    return {
      name: this.$route.params.name,
      params: {
        append: 'lookups,validation,errors',
        include: 'dataitems',
      }
    }
  },
  mounted() {
    var name = this.name
    var params = this.params
    this.getQuestionnaire({ name, params });
  },
  computed: {
    ...mapState("resources/questionnaires", ["questionnaire"]),
    dataitemColumns() {
      return [
        this.dataitemsHaveSections ? { name: 'section', label: 'Section', Type: String, sortable: true, width: 'min-content' } : false,
        { name: 'id', label: 'ID', Type: String, sortable: true, width: 'max-content' },
        { name: 'name', label: 'Name', Type: String, sortable: true, width: 'max-content' },
        { name: 'label', label: 'Label', Type: String, sortable: true, width: '1fr' },
        { name: 'lookup', label: 'Lookup', Type: String, sortable: true, width: '1fr' },
        { name: 'validation', label: 'Validation', Type: String, sortable: true, width: 'min-content' },
        { name: 'type', label: 'Type', Type: String, sortable: true, width: 'min-content' },
        { name: 'json', label: 'JSON', Type: JSON, sortable: true, width: 'min-content' },
      ].filter(Boolean)
    },
    dataitemsHaveSections() {
      if (this.questionnaire && this.questionnaire.dataitems)
        return this.questionnaire.dataitems.filter((r) => r.section != null).length > 0
      return false
    },
    validationRuleErrors() {
      var errors = {}
      this.questionnaire.errors.map((e) => {
        var errArr = JSON.parse(e.json_errors);
        var occurrences = e.occurrences
        errArr.map((err) => {
          if (!errors[err]) errors[err] = occurrences
          else errors[err] += occurrences
        })
      })
      return errors
    }
  },
  methods: {
    ...mapActions("resources/questionnaires", ["getQuestionnaire"]),
    getLookup(lookup_id) {
      return this.questionnaire.lookups.find((lu) => lu.id == lookup_id)
    },
    getValidation(dataitem) {
      return this.questionnaire.validation.rules[dataitem.name]
    },
    errorCount(item, di) {
      // console.log(item.id, this.validationRuleErrors)
      return this.validationRuleErrors[item.id]
    }
  },
}

</script>
