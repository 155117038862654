import Vue from 'vue'
import axios from 'axios'
import construction from "~/helpers/construction"
import store from '~/store'
import { each } from 'lodash'
export const state = {
  questionnairesSchema: {
    questionnaires: [],
    request: {}
  },
  questionnaireData: {},
  questionnairePreviews: {},
  // lookupitems: [],
  questionnaires: null,
  profileQuestionnaires: null,
  todoQuestionnaires: null,
  mwQuestionnaires: {},
  questionnaire: false,
  defaultTemplate: {},
  pagination: {},
  errors: {},
  serverErrors: {},
  records: {},

  routeChanging: false,
  questionnairesLoaded: false,
  questionnairesLoading: false,
  questionnaireLoading: false,
  questionnaireLoaded: false,
  questionnaireSaving: false,
  questionnaireSubmitting: false,
  questionnaireRecordsChanged: false,
}

// getters
export const getters = {
  getQuestionnaireByName: (state) => (name) => {
    if (state.questionnaireData[name]) return state.questionnaireData[name]
  },
  getQuestionnairesByName: (state) => (name, collection = 'questionnaires') => {
    if (state[collection] && state[collection].length) {
      var found = state[collection].find((q) => name == q.name)
      if (found) return found
    }
    return {}
  },
  getQuestionnaireDataitems: (state) => (qname) => {
    if (state.questionnaireData[qname]) {
      return state.questionnaireData[qname].dataitems;
    }
  },
  getMwQuestionnaires: (state) => (mw) => {
    if (state.mwQuestionnaires[mw]) return state.questionnaireData[mw]
  },
  getQuestionnaireValidation: (state) => (qname) => {
    if (state.questionnaireData[qname]) return state.questionnaireData[qname].validation
  },
  getQuestionnaireRecords: (state) => (qname) => {
    if (state.questionnaireData[qname]) return state.questionnaireData[qname].records
  },
  getQuestionnaireDefaultTemplate: (state) => (qname) => {
    if (state.questionnaireData[qname]) return state.questionnaireData[qname].defaultTemplate
  },
  getQuestionnaireErrors: (state) => (qname) => {
    if (state.questionnaireData[qname]) return state.questionnaireData[qname].errors
  },
  getQuestionnaireExtraData: (state) => (qname) => {
    if (state.questionnaireData[qname]) return state.questionnaireData[qname].extraData
  },
}

// mutations
export const mutations = {
  GET_QUESTIONNAIRES(state, data) {
    // state.questionnaires = data.data
    var questionnaires = construction.constructObjects({
      '*': construction.Questionnaire
    }, data.data);
    if (data.request.params.mw) {
      state.mwQuestionnaires[data.request.params.mw] = questionnaires
      state[data.request.params.mw + 'Questionnaires'] = questionnaires
    } else {
      state.questionnaires = questionnaires
    }
    if (data.meta) state.pagination = data.meta
    state.questionnairesLoading = false
    state.questionnairesLoaded = true
  },
  GET_QUESTIONNAIRES_SCHEMA(state, resp) {
    state.questionnairesSchema.questionnaires = resp.data.data
    state.questionnairesSchema.request = resp.request
  },
  GET_QUESTIONNAIRE(state, data) {
    /* For admin datatable */
    state.questionnaire = data.questionnaire
    state.lookupitems = data.questionnaire.lookupitems
    state.validation = data.questionnaire.validation
  },
  GET_QUESTIONNAIRE_DATA(state, data) {
    var questionnaire = construction.constructObjects({
      'questionnaire.lookups.*.items.*': construction.LookupItem,
      'questionnaire.lookups.*': construction.Lookup,
      'questionnaire.lookups': construction.LookupCollection,
      'questionnaire.dataitems.*.validation_rules.*': construction.ValidationRule,
      'questionnaire.dataitems.*.validation_rules': construction.ValidationRuleCollection,
      'questionnaire.dataitems.*': construction.Dataitem,
      'questionnaire.dataitems': construction.DataitemCollection,
      // 'questionnaire.records': construction.Record,
      'questionnaire': construction.Questionnaire,
    }, data, false).questionnaire;

    const { dataitems, records, lookups, defaultTemplate, extraData } = questionnaire
    questionnaire.validation = {}
    if (questionnaire.validationRules) {
      questionnaire.validation.rules = questionnaire.validationRules.getRules()
      questionnaire.validation.messages = questionnaire.validationRules.getMessages()
    }
    questionnaire.recordsChanged = false

    questionnaire.originalRecords = JSON.stringify(records)
    var errors = {}
    questionnaire.errors = errors;
    questionnaire.wasValidated = false;
    state.questionnaireData[questionnaire.name] = questionnaire;
    state.records = questionnaire.records
    state.questionnaireLoading = false
    state.questionnaireRecordsChanged = false
    state.questionnaireLoaded = true
    state.routeChanging = false
    state.errors = {};
  },
  GET_QUESTIONNAIRE_PREVIEW(state, data) {
    var questionnaire = construction.constructObjects({
      'questionnaire.dataitems.*': construction.Dataitem,
      'questionnaire.dataitems': construction.DataitemCollection,
      'questionnaire.records': construction.Record,
      'questionnaire': construction.Questionnaire,
    }, data, false).questionnaire;
    state.questionnairePreviews[questionnaire.name] = questionnaire
  },
  SAVE_QUESTIONNAIRE_RECORDS(state, resp) {
    // if (resp.data.saved) {
    state.questionnaireData[resp.name].setRecords(resp.data.records)
    state.records = state.questionnaireData[resp.name].records;
    // }

    state.questionnaireSaving = false
    state.questionnaireRecordsChanged = false
    var data = resp.data

    state.questionnaireData = { ...state.questionnaireData };
    state.questionnaireData[resp.name].extraData = { ...state.questionnaireData[resp.name].extraData, ...data.extraData }
    if (data.errors) {
      state.serverErrors = state.questionnaireData[resp.name].validationRules.serverErrors(data.errors);
      state.errors = { ...state.questionnaireData[resp.name].errors, ...state.serverErrors }
      state.questionnaireData[resp.name].errors = state.errors
    }
    state.questionnaireData[resp.name].originalRecords = JSON.stringify(state.records)

  },
  SUBMIT_QUESTIONNAIRE_RECORDS(state, resp) {
    var data = construction.constructObjects({
      'questionnaire.records': construction.Record,
    }, resp.data, false)
    state.questionnaireSaving = false
    Vue.set(state.questionnaireData[resp.name], 'records', data.records)
    state.questionnaireData[resp.name].extraData = data.extraData
    state.records = resp.data.records
  },
  SET_ERRORS(state, data) {
    if (data.wasValidated) state.questionnaireData[data.name].wasValidated = true
    var existing = state.questionnaireData[data.name].errors
    var errors = data.merge ? { ...existing, ...data.errors } : data.errors

    state.questionnaireData[data.name].errors = errors
    Vue.set(state.questionnaireData[data.name], 'errors', errors)
    state.errors = errors
  },
  UPDATE(state, data) {
    each(data, (value, field) => {
      state[field] = value
    })
  },
  UPDATE_VALIDATION(state, data) {
  },
  UPDATE_QUESTIONNAIRE_EXTRA_DATA(state, request) {
    for (var key in request.data) {
      // console.log(key, request.data[key])
      state.questionnaireData[request.name].extraData = {
        ...state.questionnaireData[request.name].extraData,
        [key]: request.data[key]
      };
      Vue.set(state.questionnaireData[request.name].extraData, key, request.data[key]);
    }
  }
}

// actions
export const actions = {
  /* Admin / datatable version */
  getAllQuestionnaires({ commit }, request) {
    return axios.get('questionnaire', request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRES', { data: resp.data.data, request })
      })
  },
  getQuestionnairesSchema({ commit }, request) {
    return axios.get('questionnaire', request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRES_SCHEMA', { data: resp.data, request })
      })
  },
  getQuestionnaire({ commit }, request) {
    request.patient_questionnaire = true;
    return axios.get('questionnaire/' + request.name, request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRE', resp.data)
      })
  },
  /* Patient version */
  getQuestionnaires({ commit }, request) {
    state.questionnairesLoading = true
    state.questionnairesLoaded = false
    var isTeam = store.getters['auth/isTeam']
    if (isTeam && request.patient_id) {
      var url = "patient/" + request.patient_id + "/questionnaires"
    } else {
      var url = "questionnaires"
    }
    return axios.get(url, request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRES', { data: resp.data.data, request })
      })

  },
  getQuestionnaireData({ commit }, request) {
    state.questionnaireLoading = true
    state.questionnaireLoaded = false
    var prefix = request.prefix ? request.prefix : ''
    var path = request.developPreview ? 'questionnaire' : 'questionnaires'
    return axios.get(prefix + path + '/' + request.name, request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRE_DATA', resp.data)
      })
  },
  getQuestionnairePreview({ commit }, request) {
    var prefix = request.prefix ? request.prefix : ''
    return axios.get(prefix + 'questionnaires/' + request.name, request)
      .then((resp) => {
        commit('GET_QUESTIONNAIRE_PREVIEW', resp.data)
      })
  },
  setErrors({ commit }, request) {
    commit('SET_ERRORS', request)
  },
  update({ commit }, request) {
    commit('UPDATE', request)

  },
  updateValidation({ commit }, request) {
    commit('UPDATE_VALIDATION', request)
  },
  updateQuestionnaireExtraData({ commit }, request) {
    commit('UPDATE_QUESTIONNAIRE_EXTRA_DATA', request)
  },
  saveQuestionnaireRecords({ commit }, request) {
    state.questionnaireSaving = true
    var isTeam = store.getters['auth/isTeam'];
    var url = "";
    if (isTeam) {
      if (request.patient_id) {
        url = 'patient/' + request.patient_id + '/questionnaires/' + request.name, request;
      } else {
        url = 'questionnaires/' + request.name, request;
      }
    } else {
      url = 'questionnaires/' + request.name, request
    }
    return axios.put(url, request)
      .then((resp) => {
        state.questionnaireSaving = false
        commit('SAVE_QUESTIONNAIRE_RECORDS', { name: request.name, data: resp.data })
        commit('resources/patient/GET_PATIENT_ISSUE', { patient_issue: resp.data.patient_issue }, { root: true })
      })
  },
  submitQuestionnaireRecords({ commit }, request) {
    state.questionnaireSaving = true
    var isTeam = store.getters['auth/isTeam'];
    var url = "";
    if (isTeam) {
      url = 'patient/' + request.patient_id + '/questionnaires/submit' + request.name, request;
    } else {
      url = 'questionnaires/submit' + request.name, request
    }
    return axios.put(url, request)
      .then((resp) => {
        commit('SUBMIT_QUESTIONNAIRE_RECORDS', { name: request.name, data: resp.data })
        if (request.issue) {
          commit('resources/patient/GET_PATIENT_ISSUE', resp.data, { root: true })
        }
      })
  },
}
export const namespaced = true;
