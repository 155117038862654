<template>
  <div class="public-page">
    <div class="container-xxl">
      <row>
        <column :sizes="{ md: 6 }">
          <img id="mainHPOSLogo" :src="'/images/hpos_logo.svg'" :alt="$t('public.home.hpos_logo_alt')"
            class="img-fluid" />
        </column>
        <column :sizes="{ md: 6 }" v-if="!inAvailableCountry">
          <alert type="primary" class="mt-xl-5">
            <row class="g-3 align-items-center">
              <column :sizes="[3]">
                <svg class="flag-img card-img rounded-left img-fluid" :alt="country.name">
                  <use :href="'/images/home/flags-sprite.svg#' + location.countryCode.toLowerCase()">
                  </use>
                </svg>

              </column>
              <column :sizes="[9]">
                <p>{{ $t('public.home.countries.not_available_in_location', { country: location.countryName }) }}</p>
              </column>
            </row>

          </alert>
          <p v-t="'public.home.countries.currently_available'" class="mx-4 mt-5"></p>

          <row class="g-1 mb-4">
            <column v-for="country in availableInCountries" :sizes="[12, { md: 12, lg: 12 }]"
              :key="country.countryCode">
              <card :body="false" class="country-card">
                <row class="g-0 align-items-center">
                  <column :sizes="[3, { xs: 3, sm: 3, md: 3 }]">
                    <svg class="flag-img card-img rounded-left img-fluid" :alt="country.name">
                      <use :href="'/images/home/flags-sprite.svg#' + country.countryCode.toLowerCase()">
                      </use>
                    </svg>

                  </column>
                  <column :sizes="[9, { xs: 9, sm: 9, md: 9 }]">
                    <div class="card-body d-flex  align-items-center py-2">
                      {{ country.name }}
                    </div>
                  </column>
                </row>
              </card>

            </column>
            <btn block size="lg" is-router-link :to="{ name: 'public.keep-me-posted' }" class="d-flex mt-4">
              <fa icon="envelope-circle-check" size="2x" class="me-2"></fa>
              <div class="flex-grow-1 text-center fs-3" v-t="'public.keep-me-posted.title'"></div>
            </btn>
          </row>
        </column>
        <column :sizes="{ md: 6 }" v-if="inAvailableCountry" class="d-flex flex-column">
          <div class="my-5"></div>
          <card :body="false" class="country-card my-4">
            <row class="g-0 align-items-center">
              <column :sizes="[3, { xs: 4, sm: 4, md: 3 }]">
                <svg class="flag-img card-img rounded-left img-fluid" :alt="location.countryCode.toLowerCase()">
                  <use :href="'/images/home/flags-sprite.svg#' + location.countryCode.toLowerCase()">
                  </use>
                </svg>

              </column>
              <column :sizes="[9, { xs: 8, sm: 8, md: 9 }]">
                <div class="card-body d-flex  align-items-center py-2">
                  {{ $t('public.home.countries.is_available_in_location', { country: location.countryName }) }}
                </div>
              </column>
            </row>
          </card>
          <btn block size="lg" is-router-link :to="{ name: 'register' }" class="mb-4">
            <div class="fs-3" v-t="'public.home.register_now'"></div>
          </btn>
        </column>
        <column :sizes="{ xl: 12 }" v-if="!inAvailableCountry">
          <p v-t="'public.home.countries.launching_soon'" class="mx-4"></p>
          <row class="g-1 mb-4 justify-content-xl-between">
            <column v-for="country in comingSoonCountries" auto :sizes="[6, { sm: 4, md: 3, lg: 2, xl: 1 }]"
              :key="country.countryCode">
              <card :body="false" class="country-card">
                <row class="g-1 align-items-center">
                  <column :sizes="[4, { xs: 4, sm: 4, lg: 5, xl: 'auto' }]">
                    <svg class="flag-img card-img rounded-left" :alt="country.countryCode.toLowerCase()">
                      <use :href="'/images/home/flags-sprite.svg#' + country.countryCode.toLowerCase()">
                      </use>
                    </svg>

                  </column>
                  <column :sizes="[8, { xs: 8, sm: 8, lg: 7 }]">
                    <div class="card-body d-flex text-center px-2 py-1">
                      {{ country.name }}
                    </div>
                  </column>
                </row>
              </card>
            </column>
          </row>
        </column>
      </row>
    </div>
    <div :class="['mb-4', 'container-xxl']">
      <row class="g-2">
        <column :sizes="{ md: 7 }" class="card-group p-1">
          <card class="text-center card-radial-bg rounded-lg shadow-lg">
            <div class="d-flex flex-column h-100">
              <img :src="'/images/home/bulb.png'" :alt="$t('public.home.story_0.image_alt')" style="max-width: 128px;"
                class="circle-image mb-4 mx-auto">
              <h2 v-t="'public.home.story_0.heading'"></h2>
              <p class="welcome-para text-justify" v-t="'public.home.story_0.para_1'"></p>
              <btn is-router-link :to="{ name: 'public.more-info', hash: '#what_is_hpos' }" block
                v-t="'public.home.find_out_more'" class="gradient-btn mt-auto" name="find_out_more"></btn>
            </div>
          </card>
        </column>
        <column :sizes="{ md: 5 }" class="card-group p-1">
          <card class="text-center card-radial-bg rounded-lg shadow shadow-lg">
            <div class="d-flex flex-column h-100">
              <img :src="'/images/home/tactics.png'" :alt="$t('public.home.story_1.image_alt')"
                style="max-width: 128px;" class="circle-image mb-4 mx-auto">
              <h2 v-t="'public.home.story_1.heading'"></h2>
              <p class="welcome-para text-justify" v-t="'public.home.story_1.para_1'"></p>
              <btn is-router-link :to="{ name: 'public.more-info', hash: '#who_are_we_interested_in' }" block
                v-t="'public.home.find_out_more'" class="gradient-btn mt-auto" name="find_out_more"></btn>
            </div>
          </card>
        </column>
        <column :sizes="{ lg: 6 }" class="card-group p-1">
          <card class="text-center card-radial-bg rounded-lg shadow">
            <force-diagram class="force-diagram" :alt="$t('public.home.story_2.image_alt')" v-bind="{
              type: 'svg',
              width: 400,
              height: 200,
              nodes,
              radius: 2,
              padding: 2,
              chargeStrength: 0.01,
              clusterStrength: 0.1,
              collideStrength: 0.4,
              centerActive: true,
              chargeActive: true,
              clusterActive: true,
              collideActive: true,
              nodeFill: 'var(--hpos-lightblue)'
            }">
              <template #behind>
                <circle cx="200" cy="100" r="100"></circle>

              </template>
              <filter x="0" y="0" width="1" height="1" id="highlight">
                <feFlood flood-color="rgba(0,173,211,1)" />
                <feComposite in="SourceGraphic" />
              </filter>
              <template #infront>
                <text class="total" x="200" y="100" v-text="patientCount"></text>
              </template>
            </force-diagram>
            <div class="d-flex flex-column">
              <h2 v-t="'public.home.story_2.heading'"></h2>
              <div class="welcome-para mb-2" v-html="$t('public.home.story_2.para_1')" />
              <btn name="find_out_more" is-router-link :to="{ name: 'public.more-info', hash: '#how_far_have_we_come' }"
                block v-t="'public.home.find_out_more'" class="gradient-btn mt-auto"></btn>
            </div>
          </card>
        </column>
        <column :sizes="{ lg: 6 }" class="card-group p-1">
          <card class="text-center card-radial-bg rounded-lg shadow">
            <div class="d-flex flex-column h-100">
              <img :src="'/images/home/search.png'" alt="search" style="max-width: 128px;"
                class="circle-image mb-4 mx-auto">
              <h2 v-t="'public.home.story_3.heading'"></h2>
              <p class="welcome-para text-justify  flex-grow-1 mb-4" v-t="'public.home.story_3.para_1'"></p>
              <btn name="find_out_more" is-router-link
                :to="{ name: 'public.more-info', hash: '#what_will_i_have_to_do' }" block
                v-t="'public.home.find_out_more'" class="gradient-btn mt-auto"></btn>
            </div>
          </card>
        </column>
        <column :sizes="{ md: 6, lg: 4 }" class="card-group p-1">
          <card class="text-center card-radial-bg rounded-lg shadow">
            <div class="d-flex flex-column h-100">
              <img :src="'/images/home/doctor.png'" style="max-width: 128px;" :alt="$t('public.home.story_4.image_alt')"
                class="circle-image mb-4 mx-auto">
              <h2 v-t="'public.home.story_4.heading'"></h2>
              <p class="welcome-para text-justify  flex-grow-1 mb-4" v-t="'public.home.story_4.para_1'"></p>
              <btn name="find_out_more" is-router-link :to="{ name: 'public.more-info', hash: '#who_are_we' }" block
                v-t="'public.home.find_out_more'" class="gradient-btn mt-auto"></btn>
            </div>
          </card>
        </column>
        <column :sizes="{ md: 6, lg: 4 }" class="card-group p-1">
          <card class="text-center card-radial-bg rounded-lg shadow">
            <div class="d-flex flex-column h-100">
              <img :src="'/images/home/money2.png'" style="max-width: 128px;" alt="$t('public.home.story_5.image_alt')"
                class="circle-image mb-4 mx-auto">
              <h2 v-t="'public.home.story_5.heading'"></h2>
              <div class="welcome-para text-justify flex-grow-1 mb-4" v-html="$t('public.home.story_5.para_1')" />
              <btn name="find_out_more" is-router-link :to="{ name: 'public.more-info', hash: '#who_funds_us' }" block
                v-t="'public.home.find_out_more'" class="gradient-btn mt-auto"></btn>
            </div>

          </card>
        </column>
        <column :sizes="{ lg: 4 }" class="card-group p-1">
          <card class="text-center card-radial-bg rounded-lg shadow">
            <div class="d-flex flex-column h-100">
              <img :src="'/images/home/click.png'" style="max-width: 128px;" :alt="$t('public.home.story_6.image_alt')"
                class="circle-image mb-4 mx-auto">
              <h2 v-t="'public.home.story_6.heading'"></h2>
              <btn name="find_out_more" is-router-link :to="{ name: 'public.more-info', hash: '#more_info' }" block
                v-t="'public.home.find_out_more'" class="gradient-btn mt-auto"></btn>
            </div>
          </card>
        </column>
      </row>
      <socialShareButtons :meta="metaData" class="mt-4 fs-5"></socialShareButtons>

    </div>

    <card class="text-center">
      <h2 v-t="'public.home.partners.title'"></h2>
      <template v-for="p in $t('public.home.partners.list')"><a :href="p.url" class="m-2"><img :src="p.img"
            :alt="p.title" class="m-2" style="max-height:100px;"></a></template>
    </card>
    <card class="text-center mt-2">
      <h2 v-t="'public.home.special_thanks.title'"></h2>
      <template v-for="p in $t('public.home.special_thanks.list')"><a :href="p.url" target="_blank" class="m-2"><img
            :src="p.img" :alt="p.title" class="m-2" :width="p.width"></a></template>
    </card>
    <footer class="footer text-center" style="padding:1em;">
      <router-link :to="{ name: 'public.accessibility' }" class="text-decoration-none">
        <span v-t="'public.home.accessibility'"></span>
      </router-link>
      <span class="mx-2">|</span>
      <router-link :to="{ name: 'public.cookie-policy' }" class="text-decoration-none">
        <span v-t="'public.home.cookie_policy'"></span>
      </router-link>
    </footer>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import Vue from 'vue'
import { gsap } from 'gsap';
import hposLogo from '/resources/assets/svg/hpos_logo.svg?raw'
import socialShareButtons from '~/components/social-share-buttons.vue'
export default {
  name: 'welcome',
  components: { socialShareButtons },
  metaInfo() {
    return { title: this.$t(this.langPath + 'title') }
  },

  data() {
    return {
      currentYear: new Date().getFullYear(),
      langPath: 'public.home.',
      title: window.config.appName,
      metaData: {
        title: this.$t('public.home.title'),
        description: this.$t('public.home.story_0.para_1'),
        url: window.location.href,
        image: window.location.href + '/images/hpos_logo.svg',
      },
    }
  },
  mounted() {
    this.getPublicPatientCount()
    this.$nextTick(() => {
      if (this.$el.querySelector('#mainHPOSLogo')) {
        this.$el.querySelector('#mainHPOSLogo').parentElement.innerHTML = hposLogo
        this.animateSvg();
        window.addEventListener('mousemove', this.handleMouseMove)
      }

    })
  },
  beforeUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
  },
  computed: {
    ...mapState('resources/patient', ['public']),
    ...mapGetters({
      authenticated: 'auth/check'
    }),
    patientCount() {
      return this.public.count
    },
    base_url() {
      return window.config.base_url
    },
    nodes() {
      var arr = []
      var count = this.patientCount > 500 ? 500 : this.patientCount
      for (var i = 0; i < count; i++) arr.push({})
      return arr
    },
    availableInCountries() {
      return this.$t('public.home.countries.ca_list')
    },
    comingSoonCountries() {
      return this.$t('public.home.countries.ls_list')
    },
    location() {
      return window.config.country
    },
    inAvailableCountry() {
      return Object.values(this.availableInCountries).findIndex((c) => c.countryCode == this.location.countryCode) > -1
    },
  },
  methods: {
    ...mapActions('resources/patient', ['getPublicPatientCount']),
    animateSvg() {
      const svg = this.$el.querySelector('#mainHPOSLogo');
      // Example of rotating the <g> elements
      gsap.from('#petrie-dish', {
        opacity: 0,
        scale: 0.9,
        transformOrigin: '50% 50%', // center
        rotation: 5,
        duration: 1,
        ease: 'ease-in',
      });

      gsap.from('#moons', {
        opacity: 0,
        scale: 0.9,
        rotation: 10,
        transformOrigin: '50% 50%',
        ease: 'ease-in',
        duration: 1,
      });

      gsap.from('#letterH', {
        opacity: 0,
        scale: 0.9,
        transformOrigin: '50% 50%',
        duration: 0.3,
      });
      gsap.from('#letterP', {
        opacity: 0,
        scale: 0.9,
        transformOrigin: '50% 50%',
        duration: 0.3,
        ease: 'ease-in',
      });
      gsap.from('#letterS', {
        opacity: 0,
        scale: 0.9,
        transformOrigin: '50% 50%',
        duration: 0.3,
        ease: 'ease-in',
      });
    },
    handleMouseMove(event) {
      const { clientX, clientY } = event;
      const svg = this.$el.querySelector('#mainHPOSLogo');
      if (svg == null) return;
      const rect = svg.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      // Calculate the direction vector from the center of SVG to the mouse
      let dx = clientX - centerX;
      let dy = clientY - centerY;

      // Calculate the length of the direction vector
      const length = Math.sqrt(dx * dx + dy * dy);

      // Normalize the direction vector
      dx /= length;
      dy /= length;

      // Scale the shadow distance based on the distance from the SVG's center,
      // limiting it to a maximum of 20 pixels.
      const maxShadowLength = 24;
      const shadowLength = Math.min(length / 10, maxShadowLength);
      dx *= -shadowLength;
      dy *= -shadowLength;

      // Scale the blur based on the distance, up to a maximum of 4 pixels.
      const maxBlur = 6;
      const blur = Math.min(length / 50, maxBlur);
      const alpha = (0.4 - Math.min(shadowLength / 100, 1)) / 2;
      // Update the CSS variables
      svg.style.setProperty("--ff-dropshadow-x", `${dx}px`);
      svg.style.setProperty("--ff-dropshadow-y", `${dy}px`);
      svg.style.setProperty("--ff-dropshadow-blur", `${blur}px`);
      svg.style.setProperty("--ff-dropshadow-alpha", alpha);
    }
  }
}

</script>
