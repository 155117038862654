/**
 * @param  {Object} modules
 * @return {Object}
 */
export function resolveImport(modules, path, regex) {
    return Object.keys(modules)
        .map(file =>
            [file.replace(path, '').replace(regex, ''), modules[file]]
        )
        .reduce((components, [name, component]) => {
            components[name] = component.default || component
            return components
        }, {})
}
/**
 * @param  {Object} modules
 * @return {Object}
 */
export function resolveLang(modules) {
    return Object.keys(modules)
        .map(file =>
            [file.replace('./', '').replace(/(^.\/)|(\.json$)/g, ''), modules[file]]
        )
        .reduce((components, [name, component]) => {
            var nameArr = name.split('/')
            if (nameArr.length > 1) {
                if (!components[nameArr[0]]) components[nameArr[0]] = {}
                components[nameArr[0]][nameArr[1]] = component.default || component
            } else {
                components[name] = component.default || component
            }
            return components
        }, {})
}