<template>
    <card :body="false" head class="questionnaire-completion-card">
        <template #header>
            <div class="card-header d-flex">
                <h5 class="card-title flex-grow-1">
                    {{ activeDiagram.title }}
                </h5>
                <div class="ms-auto me-2">
                    <!-- issue selection -->
                    <btn-group v-model="requestIssue">
                        <btn outline v-for="issue in issues" :key="issue.id" :true-value="issue.id"
                            :false-value="false">
                            {{ issue.label }}
                        </btn>
                    </btn-group>
                </div>
                <div v-for="country in countries" class="ms-auto" :key="country.json.hex">
                    <div class="d-inline-block me-2" :key="'ro' + country.id">
                        <div @click="country.active = !country.active" class="country-btn me-1 rounded"
                            :style="{ backgroundColor: country.json.hex, opacity: country.active ? 1 : 0.1 }">
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="h-100" ref="container">
            <chart ref="chart" :chartData="chartData" :width="width" :height="height" :margin="margin"
                class="questionnaire-completion-chart">
                <treemap :data="chartData" :width="treemapWidth" :height="treemapHeight" tiling="slice-dice"
                    :paddingInner="2" :paddingTop="38">
                </treemap>
            </chart>
        </div>
    </card>
</template>


<script>
import { mapState, mapActions } from "vuex";
import treemap from "../charts/treemap.vue";
// the different d3 tiling methods are called:  binary, slice, dice, slice-dice and squarify
export default {
    name: "questionnaire-completion",
    components: {
        treemap
    },
    data() {
        return {
            diagrams: [
                { name: 'QuestionnaireCompletion', title: this.$t('admin.dashboard.diagrams.QuestionnaireCompletion') }
            ],
            diagramShown: 'QuestionnaireCompletion',
            mounted: false,
            isTransitioning: false,
            chartData: { nodes: [], links: [] },
            margin: { left: 10, right: 10, top: -30, bottom: 0 },
            width: 0,
            height: 0,
            treemapWidth: 0,
            treemapHeight: 0,
            diagram: null,
            requestIssue: 1,
            ro: null,
            colours: {
                total: '#f00',
                valid: 'var(--bs-success)',
                invalid: 'var(--bs-danger)',
                unstarted: 'var(--bs-body-bg)'
            },
            issues: [
                { id: 1, label: 'Issue 1' },
                { id: 2, label: 'Issue 2' },
                { id: 3, label: 'Issue 3' },
            ]
        };
    },
    mounted() {
        this.getData();
    },
    computed: {
        ...mapState('resources/admin', ["dashboard"]),
        countries() {
            return this.dashboard.data.countryData;
        },
        activeCountries() {
            return this.countries.filter((country) => country.active);
        },
        activeDiagram() {
            return this.diagrams.find((diagram) => diagram.name === this.diagramShown);
        },
        values() {
            return ['valid', 'invalid', 'unstarted'];
        }
    },
    methods: {
        ...mapActions('resources/admin', ["getDiagramData"]),
        getData() {
            var request = {
                params: {
                    'diagram': this.diagramShown,
                    'filter[country_id]': this.activeCountries.map((country) => country.id).join(','),
                    'filter[patientIssue.issue_id]': this.requestIssue ? this.requestIssue : 1

                }
            }
            this.getDiagramData(request).then(() => {
                this.makeChartData();
                this.defineResizeObserver();

                console.log('data loaded')
            });
        },
        makeChartData() {
            var d = this.dashboard.data.diagrams[this.diagramShown]
            if (!d) return
            console.log(d)
            d.nodes.forEach((node) => {
                node.title = this.$t('questionnaires.' + node.name + '.title');
                // node.value = node.total;
                node.children = this.values.map((value) => {
                    return {
                        name: value,
                        value: node[value],
                        fill: this.colours[value]
                    }
                });
            });
            this.chartData = {
                name: d.diagram.name,
                children: d.nodes,
                links: d.links,

            };
            this.diagram = d
        },
        chartMounted() {
            // requestAnimationFrame(() => {
            //     this.setChartSize();
            // });

            this.mounted = true;

        },
        defineResizeObserver() {
            console.log('define resize observer')
            var el = this.$refs.container;

            this.ro = new ResizeObserver(() => {
                this.isTransitioning = true;
                // setTimeout(() => {
                this.isTransitioning = false;
                this.setChartSize();

                // }, 1000);

            });
            this.ro.observe(el);

        },
        setChartSize() {
            var container = this.$refs.container;
            this.$refs.chart.$el.style.display = 'none';
            var bbox = container.getBoundingClientRect()
            var outerBB = this.$el.getBoundingClientRect();
            this.$refs.chart.$el.style.display = 'block';

            var header = this.$el.querySelector('.card-header').getBoundingClientRect()
            var maxHeight = outerBB.height - header.height - 20;
            if (this.width == bbox.width && (this.height == (height > maxHeight) ? maxHeight : bbox.height)) {
                // console.log('same', this.isTransitioning, bbox.width, bbox.height, this.width, this.height, maxHeight)
                return;
            } else {
                // console.log('diff', bbox.width, bbox.height, maxHeight)
            }

            this.width = bbox.width;
            var height = bbox.height;
            if (height > maxHeight) height = maxHeight;
            this.height = height;
            requestAnimationFrame(() => {
                this.treemapWidth = this.width - this.margin.left - this.margin.right;
                this.treemapHeight = this.height - this.margin.top - this.margin.bottom - 20;
            });

        },
    },
    watch: {
        chartData: {
            handler() {
                this.chartMounted();
            },
            deep: true,
        },
        activeCountries() {
            this.getData();
        },
        diagramShown() {
            this.getData();
        },
        requestIssue() {
            this.getData();
        }
    },
}
</script>

<style lang="scss" scoped></style>