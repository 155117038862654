import {factory} from "../utils/ClassLoader"
import * as questionnaireFactory from "./ClassLoader"
import moment from 'moment';
let Dataitem = class extends factory.classes.AttributeBag {
	constructor(attributes)  {
		super(attributes);
		if(this.lookup) {
			this.setLookup(this.lookup)
		}
		if (this.validation_rules) {
			this.setValidationRules(this.validation_rules)
		}
		return this;
	}
	setLookup(lookup) {
		this.lookup = lookup;
		this.lookup.setDataitem(this);
	}
	setValidationRules(rules) {
		this.validation_rules = rules
		this.validation_rules.setDataitem(this);
	}
	setQuestionnaire(questionnaire) {
		this.questionnaire = questionnaire;
	}
	toJSON() {
		var object = {...this}
		delete object.questionnaire;
		delete object.parent
		return object;
	}
	isSection() {
		return this.type=="collection" || this.type=="pu_one_to_one"
	}
	createSection(parent) {
		return questionnaireFactory.factory.classes.SectionRecord.makeRecord(this.children.createDefaultRecord(this), parent, this);
	}
	setChildren(dataitems) {
		if (this.isSection()) {
			// console.log(dataitems.size, this.path)
        	// console.log("this.path " + this.path, dataitems.fromSection(this.path), dataitems)
        	this.children = dataitems.fromSection(this.path);
        	for(var child of this.children) {
        	    child.parent = this;
        	}
		}
    }
	formatForDisplay(records) {
		if (records && typeof records == "object") { // not null, is object
			var value = records[this.name];
		} else {
			var value = records;
		}
		if (value===null) return value;
		if (this.lookup) {
			if (value) {
				if (this.lookup.lookUp(value)) {
					return this.lookup.formatForDisplay(value)
				} else {
					return value + " - not part of recognized lookup (ERROR)"
				}
			} else {
				return null;
			}
		}
		else if (this.type=="date") {
			return moment(records[this.name]).format("DD/MM/YYYY")
		} else {
			return value;
		}
	}
	get path() {
		if (this.section) {
			return this.section + "." + this.name;		 
		} else {
			return this.name;
		}
	}
}

export {
	Dataitem
}