<template>
    <div v-if="!inChart">
        <template v-for="(p,n) in participantsByCountry">
{{findCountry(p[0]).name}}: {{p[1]}}

</template>
    </div>
    <g v-else>
        <slot>
            <template v-for="(p,n) in participantsByCountry">
                <g>

                </g>
            </template>
        </slot>
    </g>
</template>

<script>
import { forceSimulation, forceManyBody, forceCenter, forceCollide, forceX, forceY } from 'd3-force';
import { pack } from 'd3-hierarchy';
import { rollup } from 'd3-array';
import { mapActions, mapState } from 'vuex';
export default {
    name: 'participant-country',
    inject: ['width', 'height'],
    props: {
        nodes: {
            type: Array,
            default: () => []
        },
        inChart: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            clusters: {
                1: { x: this.width / 4, y: this.height / 4 },
                2: { x: this.width / 4 * 3, y: this.height / 4 },
                3: { x: this.width / 4, y: this.height / 4 * 3 },

            }
        }
    },
    mounted() {
        console.log('participant-country mounted');
        // this.updateSimulationForces();
        this.participantsByCountryPacked(1);
    },
    computed: {
        ...mapState('resources/admin', ['dashboard']),
        countries() {
			return this.dashboard.data.countryData;
		},
        participantCountByCountry() {
            return rollup(this.nodes, v => v.length, d => d.c);
        },
        participantsByCountry() {
            var participantsByCountry = {};
            this.countries.forEach(c => {
                participantsByCountry[c.id] = this.nodes.filter(n => n.c === c.id);
            });
        return participantsByCountry;
        }

    },
    methods: {
        findCountry(id) {
            return this.countries.find(c => c.id === id);
        },
        participantsByCountryPacked(country_id) {
            return pack()
                .size([this.width, this.height])
                .padding(3)
                .radius(d => d[1] * 2)
                (this.participantsByCountry[country_id]);
                
        },
        updateSimulationForces() {
            var chargeStrength = -30 + Math.min(20, this.nodes.length / 50);
            // keep the nodes within the canvas
            this.$emit('forcesChanged', [
                { name: 'charge', force: forceManyBody().strength(-20) },
                { name: 'center', force: forceCenter(this.width / 2, this.height / 2).strength(1) },
                { name: 'collide', force: forceCollide().radius(5) },
                { name: 'x', force: forceX().strength(0.2).x(d => this.width / 2) },
                { name: 'y', force: forceY().strength(0.2).y(d => this.height / 2) },
                { name: 'cluster', force: this.forceCluster() }

            ]);
        },
        forceCluster() {
            const strength = 0.2;
            return alpha => {
                this.nodes.forEach(d => {
                    const cluster = this.clusters[d.c];
                    d.vx += (cluster.x - d.x) * alpha * strength;
                    d.vy += (cluster.y - d.y) * alpha * strength;
                });
            };

        },

    },

}
</script>
