<template>
    <g class="pie">
        <path class="pie-slice" v-for="(d, i) in pieData" :key="'arc' + i" :d="arc(d)" :fill="colorScale(i)" />
        <text class="pie-slice-label" v-for="(d, i) in pieData" :key="'text' + i"
            :transform="`translate(${arc.centroid(d)})`" dy=".35em">
            {{ d.data.value }}
        </text>
    </g>
</template>

<script>
import { select, arc, scaleOrdinal, pie, interpolate, interpolateRound, color } from 'd3';
import { initial } from 'lodash-es';
var tau = 2 * Math.PI;

export default {
    name: 'pie-chart',
    props: {
        data: {
            type: Array,
            default: () => []
        },

        width: {
            type: Number,
            default: 500
        },
        height: {
            type: Number,
            default: 500
        },
        colors: {
            type: Array,
            default: () => ['#98abc5', '#8a89a6', '#7b6888', '#6b486b', '#a05d56', '#d0743c', '#ff8c00']
        },
    },
    data() {
        return {
            shown: false
        }
    },
    created() {
        var arcData = this.pie(this.data)
        this._currentArcs = { M: arcData[0], F: arcData[1] }
        this._currentTextTransforms = {
            M: `translate(${this.arc.centroid(this.data[0])})`,
            F: `translate(${this.arc.centroid(this.data[1])})`
        };
        this._currentTextValues = { M: this.data[0].value, F: this.data[1].value }
    },
    mounted() {
        this.shown = true
    },
    computed: {
        arc() {
            return arc()
                .innerRadius(0)
                .outerRadius(Math.min(this.width, this.height) / 2 - 1);
        },
        pie() {
            return pie()
                .sort(null)
                .value(d => d.value);
        },
        colorScale() {
            return scaleOrdinal()
                .range(this.colors);
        },
        pieData() {
            return this.pie(this.data);
        }
    },
    methods: {
        redraw() {
            if (this.shown) {
                const g = select(this.$el);
                const path = g.selectAll('path.pie-slice')
                    .data(this.pie(this.data));
                path.transition()
                    .duration(750)
                    .attrTween('d', this.arcTween)
                const text = g.selectAll('text.pie-slice-label')
                    .data(this.pie(this.data));
                text.transition()
                    .duration(750)
                    .textTween((d) => this.textTweenValue(d))
                    .attrTween('transform', this.textTweenTransform)
                    ;
            }
        },
        arcTween(a) {
            var self = this
            var i = interpolate(this._currentArcs[a.data.label], a);
            this._currentArcs[a.data.label] = i(0);
            return function (t) {
                return self.arc(i(t));
            };
        },
        textTweenTransform(a) {
            var i = interpolate(this._currentTextTransforms[a.data.label], `translate(${this.arc.centroid(a)})`);
            this._currentTextTransforms[a.data.label] = i(0);
            return function (t) {
                return i(t);
            };

        },
        textTweenValue(a) {
            var i = interpolateRound(this._currentTextValues[a.data.label], a.data.value);
            this._currentTextValues[a.data.label] = i(0);

            return function (t) {
                return i(t);
            };
        },
    },
    watch: {
        data: {
            handler() {
                this.shown = true
                this.redraw()
            },
            deep: true
        }
    }

}
</script>
<style>
.pie path {
    stroke: var(--bs-body-color);
}

.pie-slice-label {
    font-size: 1rem;
    fill: var(--bs-body-color);
    text-anchor: middle;
    transition: x 0.5s, y 0.5s;
}
</style>