<template>
  <div>
    <!-- <patient-breadcrumbs></patient-breadcrumbs> -->
    <card head class="mb-4" :body="false">
      <template #header>
        <div class="card-header p-1 ps-3 d-flex align-items-center">
          <i18n path="admin.patients.issue_overview.title" class="mb-0 flex-grow-1" tag="h6">
            <template #code><span class="patient-code"> {{ patient.code }}</span></template>
            <template v-if="patient_issue && patient_issue.issue" #issue><span>{{ $t('issues.' +
              patient_issue.issue.name
              + '.title') }}</span></template>
            <template v-if="patient_issue && patient_issue.issue" #due_at><span class="text-sm">{{ $d(new
              Date(patient_issue.due_at), 'numeric', 'en-GB')
                }}</span></template>
          </i18n>

          <donut-progress class="donut" v-if="patient_issue && patient_issue.issue"
            :label="percentage_complete(patient_issue)" :chart-data="chartData(patient_issue)"></donut-progress>
        </div>
      </template>
      <list-group flush class="patient-issue-nav">
        <router-link class="questionnaire-link" v-for="pq in patientQuestionnaires(patient_issue)"
          v-if="pq.questionnaire" :key="'lgi_' + pq.questionnaire.name"
          :to="{ name: routePrefix + pq.questionnaire.name, params: { patient_id: patient.id, issue: patient_issue.issue_id } }"
          :class="[listGroupItemClass(pq)]" active-class="questionnaire-active" exact>
          <span class="questionnaire-title flex-grow-1" v-t="'questionnaires.' + pq.questionnaire.name + '.title'">
          </span>
          <span class="updated_at">
            <template v-if="pq.valid == 1 && pq.updated_at">
              <small>{{ $t('admin.patients.issue_overview.questionnaire_updated_at', {
                questionnaire_updated_at: $d(new
                  Date(pq.updated_at), 'numeric', 'en-GB')
              }) }}</small>
            </template>
          </span>
        </router-link>
      </list-group>
      <div class="card-header">
        <h5>Summary</h5>
      </div>
      <row class="g-1 m-1" v-if="patient_issue.scores && Object.keys(patient_issue.scores).length">
        <column v-for="(score, key) in patient_issue.scores" v-if="key != 'tsqm9'" :key="'column_' + key"
          :sizes="{ sm: 6, md: 4, lg: 3 }">
          <router-link
            :to="{ name: routePrefix + key, params: { patient_id: patient.id, issue: patient_issue.issue_id } }"
            class="text-decoration-none">
            <card class="h-100 text-center" :body="false">
              <div class="card-body d-flex flex-column">
                <h5 v-text="$t('questionnaires.' + key + '.title')" class="flex-grow-1 mb-4"></h5>
                <score-speedo v-bind="scoreSpeedoProps(score, key)" class="mt-auto"></score-speedo>
              </div>
            </card>
          </router-link>
        </column>
        <column v-if="patient_issue.scores.tsqm9" :sizes="{ sm: 12, md: 9, lg: 9 }">
          <router-link
            :to="{ name: routePrefix + 'tsqm9', params: { patient_id: patient.id, issue: patient_issue.issue_id } }"
            class="text-decoration-none">
            <card :title="$t('questionnaires.tsqm9.title')" class="text-center" :body="false">
              <div class="card-body">
                <h5 v-text="$t('questionnaires.tsqm9.title')" class="flex-grow-1 mb-4"></h5>
                <row>
                  <column :sizes="{ sm: 4 }">
                    <div class="d-flex flex-column">
                      <h5 class="text-center" v-t="'questionnaires.tsqm9.effectiveness'"></h5>
                      <score-speedo
                        :score="{ score: Math.round(patient_issue.scores.tsqm9.effectiveness), min: 0, max: 100 }"
                        :domain="[0, 50, 100]" :colours="['#dc3545', '#ffc107', '#198754']" :ticks="40"></score-speedo>
                    </div>
                  </column>
                  <column :sizes="{ sm: 4 }">
                    <div class="d-flex flex-column">
                      <h5 class="text-center" v-t="'questionnaires.tsqm9.convenience'"></h5>
                      <score-speedo
                        :score="{ score: Math.round(patient_issue.scores.tsqm9.convenience), min: 0, max: 100 }"
                        :domain="[0, 50, 100]" :colours="['#198754', '#ffc107', '#dc3545']" :ticks="40"></score-speedo>
                    </div>
                  </column>
                  <column :sizes="{ sm: 4 }">
                    <div class="d-flex flex-column">
                      <h5 class="text-center" v-t="'questionnaires.tsqm9.satisfaction'"></h5>
                      <score-speedo
                        :score="{ score: Math.round(patient_issue.scores.tsqm9.globalSatisfaction), min: 0, max: 100 }"
                        :domain="[0, 50, 100]" :colours="['#198754', '#ffc107', '#dc3545']" :ticks="40"></score-speedo>
                    </div>
                  </column>
                </row>
              </div>
            </card>
          </router-link>
        </column>
      </row>
    </card>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import patientIssueMixin from '~/mixins/patient_issue'
export default {
  name: "patient-issue-overview",
  metaInfo() {
    return { title: this.$t('admin.patients.patient_overview.title') }
  },
  middleware: ['auth', 'team'],
  mixins: [patientIssueMixin],
  data() {
    return {
      params: {
        patient_id: this.$route.params.patient_id,
        include: 'user',
        append: 'code,issues'
      }
    }
  },
  mounted() {
    var params = { ...this.$route.params }
    this.getPatientOverview({ params });
    if (!params.issue) params.issue = 'current'
    params.issues_include = 'questionnaires,scores'
    this.getPatientIssue({ params });
  },
  computed: {
    ...mapState("resources/patient", ["patient", "patient_issue"]),
    ...mapGetters('auth', ['isTeam']),
    routePrefix() { return this.isTeam ? 'admin.' : '' }

  },
  methods: {
    ...mapActions("resources/patient", ["getPatientOverview", "getPatientIssue"]),
    patientOverviewClass() {
      return [
        'd-flex  list-group-item link-group-item-action',
        {
          'issue-valid': this.patient_issue.valid == 'valid',
          'issue-invalid': this.patient_issue.valid == 'started'
        }
      ]
    },
    listGroupItemClass(pq) {

      return [
        'd-flex list-group-item link-group-item-action',
        {
          'questionnaire-valid': pq.valid == 1,
          'questionnaire-invalid': pq.valid == 0
        }
      ]
    },
    scoreSpeedoProps(score, key) {
      if (key == 'pest') return { score: { score, min: 0, max: 5 }, domain: [0, 2, 4], colours: ['#198754', '#ffc107', '#dc3545'], ticks: 5 }
      if (key == 'psaid') return { score: { score: score.full, min: 0, max: 10 }, domain: [0, 3, 10], colours: ['#198754', '#ffc107', '#dc3545'], ticks: 40 }
      if (key == 'mdhaq') return { score: { score: score, min: 0, max: 3 }, domain: [0, 0.625, 3], colours: ['#198754', '#ffc107', '#dc3545'], ticks: 30 }
      if (key == 'tsqm9') return { score: { score: score, min: 0, max: 3 }, domain: [0, 0.625, 3], colours: ['#198754', '#ffc107', '#dc3545'], ticks: 30 }
      if (key == 'facit') return { score: { score: score, min: 0, max: 52 }, domain: [0, 26, 52], colours: ['#dc3545', '#ffc107', '#198754'], ticks: 52 }
    }
  },
}

</script>
