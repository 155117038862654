import store from '~/store'
import { getSequence, checkRoute } from '~/router/sequences'
export default async (to, from, next) => {
  // checks that the route is not in the future of the sequence
  var {code, intendedRoute, expectedRoute} = checkRoute(to, from)
  if (to.name == 'registration_check') {
    next(expectedRoute)
  }
  else if (code=="current") {
    next()
  } else if (code=="previous") {
    next()
  } else if (code=="future") {
    next(expectedRoute)
  } else if (code=="unknown") {
    next()
  } else if (code=="null") {
    next()
  }
}
