import {ClassRegistry} from "../utils/ClassRegistry"
var factory = new ClassRegistry(); 


factory.setClasses({})
import {Questionnaire} from "./Questionnaire"
import {Dataitem} from "./Dataitem"
import {Record} from "./Record"
import {SectionRecord} from "./SectionRecord"
import {Lookup} from "./Lookup"
import {LookupItem} from "./LookupItem"
import {DataitemCollection} from "./DataitemCollection"
import {LookupCollection} from "./LookupCollection"
import {QuestionnaireCollection} from "./QuestionnaireCollection"
import {ValidationRule} from "./ValidationRule"
import {ValidationRuleCollection} from "./ValidationRuleCollection"


factory.setClass("Questionnaire", Questionnaire);
factory.setClass("Dataitem", Dataitem);
factory.setClass("DataitemCollection", DataitemCollection);
factory.setClass("QuestionnaireCollection", QuestionnaireCollection);

factory.setClass("Record", Record);
factory.setClass("SectionRecord", SectionRecord);
factory.setClass("LookupCollection", LookupCollection);
factory.setClass("Lookup", Lookup);
factory.setClass("LookupItem", LookupItem);
factory.setClass("ValidationRule", ValidationRule);
factory.setClass("ValidationRuleCollection", ValidationRuleCollection);



import {OneshotRecord} from "./OneshotRecord"
factory.setClass("OneshotRecord", OneshotRecord);
import {ScheduledRecord} from "./ScheduledRecord"
factory.setClass("ScheduledRecord", ScheduledRecord);
import {DynamicRecord} from "./DynamicRecord"
factory.setClass("DynamicRecord", DynamicRecord);
// import {BoundDataitem} from "./BoundDataitem"
// factory.setClass("BoundDataitem", BoundDataitem)

export {factory}

export default {
	factory
}
