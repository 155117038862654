import axios from 'axios'
import Vue from 'vue'
// state
export const state = {
  dashboard: {
    data: {
      patientCount: 0,
      diagrams: {},
      consentedPatientCount: 0,
      countryData: [],
      latestRecruitment: [],
      firstRecruitmentDate: '2023-06-01',
      recruitmentData: [],
      patients: [],
    },
    stream: {
      patients: [],
      requests: {},
      abortControllers: {}
    }
  },
  loadingPatients: false,
  loadedPatients: false,
  loadingDashboardData: false,
  loadingDiagramData: false,
  loadingRecruitmentOverTime: false,
  loadedDashboardData: false,
  loadedDiagramData: false,
  loadedRecruitmentOverTime: false,
}

// getters
export const getters = {
}

// mutations
export const mutations = {
  GET_DASHBOARD_DATA(state, data) {
    state.dashboard.data.patientCount = data.patientCount
    state.dashboard.data.consentedPatientCount = data.consentedPatientCount
    state.dashboard.data.countryData = data.countryData
    state.dashboard.data.latestRecruitment = data.latestRecruitment
    state.loadedDashboardData = true
    state.loadingDashboardData = false

  },
  GET_RECRUITMENT_OVER_TIME(state, data) {

    state.dashboard.data.firstRecruitmentDate = data.firstRecruitmentDate
    state.dashboard.data.recruitmentData = data.recruitmentData
    state.loadingRecruitmentOverTime = false
    state.loadedRecruitmentOverTime = true
  },
  GET_DIAGRAM_DATA(state, data) {
    console.log(data.diagram.name, data)
    state.dashboard.data.diagrams[data.diagram.name] = data
    state.loadedDiagramData = true
    state.loadingDiagramData = false
  },
  FINALIZE_DATA(state, patientsData) {
    state.patients = patientsData
    state.loadingPatients = false;
    state.loadedPatients = true;
  },
  GET_STORED_DASHBOARD_DATA(state, data) {
    state.dashboard.data.patients = data
    state.loadedDashboardData = true
    state.loadingDashboardData = false
  },
  RESET_STREAM_DATA(state, key) {
    state.dashboard.stream[key] = []
  },
  UPDATE_STREAM_DATA(state, resp) {
    var key = resp.request.key
    var initial = resp.request.initial
    if (initial) state.dashboard.stream[key] = state.dashboard.stream[key].concat(resp.data)
    else {
      let data = resp.data
      // data will be an array of patients, match by id and update the patient
      data.forEach((pd) => {
        var index = state.dashboard.stream[key].findIndex((p) => p.i == pd.i)
        var patient = state.dashboard.stream[key][index]
        if (index > -1) {
          state.dashboard.stream[key][index] = { ...patient, ...pd }
        }
      })
    }
    // state.dashboard.stream[key] = Object.freeze([...state.dashboard.stream[key], resp.data.data])
  },
  UPDATE_REQUEST_STATUS(state, { requestId, status }) {
    Vue.set(state.dashboard.stream.requests, requestId, status);
  },
  SET_ABORT_CONTROLLER(state, { requestId, controller }) {
    console.log('setting abort controller', requestId, controller)
    Vue.set(state.dashboard.stream.abortControllers, requestId, controller);
  },
  CLEAR_ABORT_CONTROLLER(state, requestId) {
    console.log('clearing abort controller', requestId)
    Vue.delete(state.dashboard.stream.abortControllers, requestId);
  }
}

// actions
export const actions = {
  getDashboardData({ commit, state }, request) {
    state.loadingDashboardData = true
    state.loadedDashboardData = false
    return axios.get('admin/dashboard', request)
      .then((resp) => {
        commit('GET_DASHBOARD_DATA', resp.data)
      })
  },
  getRecruitmentOverTime({ commit, state }, request) {
    state.loadingRecruitmentOverTime = true
    state.loadedRecruitmentOverTime = false
    return axios.get('admin/dashboard/recruitment', request)
      .then((resp) => {
        commit('GET_RECRUITMENT_OVER_TIME', resp.data)
      })
  },
  getDiagramData({ commit, state }, request) {
    state.loadingDiagramData = true
    state.loadedDiagramData = false
    return axios.get('admin/dashboard/diagram', request)
      .then((resp) => {
        commit('GET_DIAGRAM_DATA', resp.data)
      })
  },
  createTeamMember({ commit }, request) {
    axios.post('admin/team', request)
      .then((resp) => {
        console.log(resp)
      })
      .catch((err) => {
        console.error(err)
      })
  },
  updateTeamMember({ commit }, request) {
    axios.put('admin/team/' + request.id, request)
      .then((resp) => {
        console.log(resp)
      })
      .catch((err) => {
        console.error(err)
      })
  },
  loadDashboardDataFromFile({ commit, state }, request) {
    state.loadingDashboardData = true
    state.loadedDashboardData = false
    axios.get('admin/dashboard/load', request)
      .then((resp) => {
        commit('GET_STORED_DASHBOARD_DATA', resp.data)
      })
  },
  fetchAsStream({ commit, state, rootState }, request) {
    // Get token from auth module in your vuex store
    const token = rootState.auth.token
    const headers = new Headers({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json', // Set content type if necessary
    });

    const abortController = new AbortController();
    commit('SET_ABORT_CONTROLLER', { requestId: request.id, controller: abortController });
    // laravel stream response
    if (request.initial) {
      commit('UPDATE_REQUEST_STATUS', {
        requestId: request.id,
        status: { loading: true, percentageComplete: 0, ...request }
      });
      commit('RESET_STREAM_DATA', request.key);
    }
    // let patientsData = [];
    var params = new URLSearchParams(request.params);
    fetch('/api/admin/dashboard/stream?' + params, {
      signal: abortController.signal,
      headers
    })
      .then(response => {
        const reader = response.body.getReader();
        let receivedLength = 0; // received that many bytes at the moment
        let chunks = []; // array of received binary chunks (comprises the body)

        const decoder = new TextDecoder();
        let buffer = ''; // Accumulate received data in a buffer
        return new ReadableStream({
          async start(controller) {
            while (true) {
              const { done, value } = await reader.read();
              if (done) {
                commit('UPDATE_REQUEST_STATUS', {
                  requestId: request.id,
                  status: { loading: false, loaded: true, percentageComplete: 100, ...request }
                });
                commit('CLEAR_ABORT_CONTROLLER', request.id);
                break;
              }
              chunks.push(value);
              receivedLength += value.length;
              const decoded = decoder.decode(value, { stream: true });
              buffer += decoded; // Add the new chunk to the buffer

              let lines = buffer.split('\n'); // Split the buffer into lines
              buffer = lines.pop(); // Keep the last line in the buffer (it may be incomplete)

              for (const line of lines) {
                if (line.trim()) { // Process each complete line
                  try {
                    const parsedData = JSON.parse(line);
                    const stats = parsedData[request.key].stats;
                    const data = parsedData[request.key].data;
                    const percentageComplete = Math.round(((stats.offset + data.length) / stats.total) * 100);

                    commit('UPDATE_REQUEST_STATUS', {
                      requestId: request.id,
                      status: {
                        loading: true,
                        loaded: false,
                        percentageComplete,
                        ...request
                      }
                    });

                    commit('UPDATE_STREAM_DATA', { data, request });
                    controller.enqueue(decoded);
                  } catch (e) {
                    console.error('Error parsing JSON line:', e, line);
                  }
                }
              }
            }
          }
        });
      })
      .catch(error => {
        console.error('Stream fetch error:', error);
      });

  },
  cancelFetch({ commit, state }, requestId) {
    if (state.dashboard.stream.abortControllers[requestId]) {

      state.dashboard.stream.abortControllers[requestId].abort();
      commit('CLEAR_ABORT_CONTROLLER', requestId);

      commit('UPDATE_REQUEST_STATUS', {
        requestId, status: {
          loading: false,
          loaded: false,
          percentageComplete: 0
        }
      })
    }
  }
}
export const namespaced = true;

