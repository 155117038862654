<template>
  <div class="mt-2">
    <patient-breadcrumbs></patient-breadcrumbs>
    <transition name="page" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: "PatientWrapper",
  middleware: ['auth'],
  computed: {
    ...mapState("resources/patient", ["patient"]),
  }
}

</script>
