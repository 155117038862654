<template>
  <card>
    <div class="d-sm-block d-md-flex">
      <h2 class="display-2 flex-grow-1" v-html="$t('public.more-info.title')"></h2>
      <div class="flex-sm-fill flex-md-row align-items-center ms-auto mb-5">
        <a name="Oxford University" href="https://www.ox.ac.uk/" class="btn">
          <img alt="Oxford University Logo" :src="'/images/partners/oxford-uni.jpg'" style="width:100px;">
        </a>
        <a name="University College Dublin" href="https://www.ucd.ie/" class="btn">
          <img alt="University College Dublin Logo" :src="'/images/partners/Universitycollegedublinlogo.png'"
            style="width:100px;">
        </a>
      </div>
    </div>
    <section id="what_is_hpos" class="m-xl-5 mt-xl-1 mb-5">
      <h2 class="flex-grow-1" v-html="$t('public.home.story_0.heading')"></h2>
      <h3 class="fw-normal lead" v-t="'public.home.story_0.para_1'"></h3>
      <p v-t="'public.more-info.what_is_hpos.para_2'"></p>
    </section>
    <section id="who_are_we_interested_in" class="m-xl-5 mb-5">
      <h2 class="flex-grow-1" v-html="$t('public.home.story_1.heading')"></h2>
      <h3 class="fw-normal lead" v-t="'public.home.story_1.para_1'"></h3>
      <p v-t="'public.more-info.who_are_we_interested_in.para_2'"></p>
    </section>
    <section id="how_far_have_we_come" class="m-xl-5 mb-5">
      <h2 class="flex-grow-1" v-html="$t('public.home.story_2.heading')"></h2>
      <h3 class="fw-normal lead" v-t="'public.home.story_2.para_1'"></h3>
    </section>
    <section id="what_will_i_have_to_do" class="m-xl-5 mb-5">
      <h2 class="flex-grow-1" v-html="$t('public.home.story_3.heading')"></h2>
      <h3 class="fw-normal lead" v-t="'public.home.story_3.para_1'"></h3>
      <p v-t="'public.more-info.what_will_i_have_to_do.para_2'"></p>
      <p v-t="'public.more-info.what_will_i_have_to_do.para_3'"></p>
      <alert type="info">
        <ol>
          <li v-for="item in $t('public.more-info.what_will_i_have_to_do.list')">{{ item }}</li>
        </ol>
      </alert>
    </section>

    <section id="who_are_we" class="m-xl-5 mb-5">
      <h2 name="who_are_we" class="flex-grow-1" v-html="$t('public.home.story_4.heading')"></h2>
      <h3 class="fw-normal lead" v-t="'public.home.story_4.para_1'"></h3>
      <p v-t="'public.more-info.who_are_we.para_2'"></p>
      <div>
        <div v-for="staff in $t('public.more-info.who_are_we.staff')" class="d-flex" :key="'div_' + staff.title">
          <figure class="figure mx-auto d-block">
            <img :src="staff.photo" :alt="staff.title" class="figure-img img-fluid rounded">
            <figcaption class="figure-caption"><a :href="staff.link" v-text="staff.title"></a></figcaption>
          </figure>
        </div>
      </div>
    </section>
    <br>

    <section id="who_funds_us" class="m-xl-5 mb-5">
      <h2 name="who_funds_us" class="flex-grow-1" v-html="$t('public.home.story_5.heading')"></h2>
      <h3 class="fw-normal lead" v-t="'public.home.story_5.para_1'"></h3>
      <p v-t="'public.more-info.who_funds_us.para_2'"></p>
      <i18n path="public.more-info.who_funds_us.support">
        <template #link>
          <a target="_blank" :href="$t('public.more-info.who_funds_us.link')"
            v-t="'public.more-info.who_funds_us.linkLabel'"></a>
        </template>
      </i18n>
    </section>
    <br>

    <section id="more_info" class="mx-xl-5 mb-5">
      <h2 class="flex-grow-1" v-html="$t('public.home.story_6.heading')"></h2>
      <p v-t="'public.more-info.more_info.para_1'"></p>
      <list-group>
        <list-group-item>
          <h4 v-t="'public.more-info.more_info.PIS.title'"></h4>
          <i18n path="public.more-info.more_info.PIS.description">
          </i18n>
          <div v-if="activeCountry">
            <svg class="flag-img" style="width:32px" :alt="getCountryLang(activeCountry).name">
              <use :href="'/images/home/flags-sprite.svg#' + activeCountry.code.toLowerCase()">
              </use>
            </svg>
            {{ getCountryLang(activeCountry).name }}: <a target="_blank" :href="activeCountry.pis_path"
              v-t="'public.more-info.more_info.PIS.linkLabel'"></a>
          </div>

          <template v-if="loadedCountries">
            <div v-for="country in inactiveCountries" :key="'div_' + country.code">
              <svg v-if="getCountryLang(country).name" class="flag-img" style="width:32px"
                :alt="getCountryLang(activeCountry).name">
                <use :href="'/images/home/flags-sprite.svg#' + country.code.toLowerCase()">
                </use>
              </svg>
              {{ getCountryLang(country).name }}: <a target="_blank" :href="country.pis_path"
                v-t="'public.more-info.more_info.PIS.linkLabel'"></a>
            </div>
          </template>
        </list-group-item>
        <list-group-item>
          <h4 v-t="'public.more-info.more_info.Consent.title'" class="mt-2"></h4>
          <i18n path="public.more-info.more_info.Consent.description">
          </i18n>
          <div v-if="activeCountry">
            <svg class="flag-img" style="width:32px" :alt="getCountryLang(activeCountry).name">
              <use :href="'/images/home/flags-sprite.svg#' + activeCountry.code.toLowerCase()">
              </use>
            </svg>

            {{ getCountryLang(activeCountry).name }}: <a target="_blank" :href="activeCountry.consent_path"
              v-t="'public.more-info.more_info.Consent.linkLabel'"></a>
          </div>

          <template v-if="loadedCountries">
            <div v-for="country in inactiveCountries" :key="'div_' + country.code">
              <svg v-if="getCountryLang(country).name" class="flag-img" style="width:32px"
                :alt="getCountryLang(country).name">
                <use :href="'/images/home/flags-sprite.svg#' + country.code.toLowerCase()">
                </use>
              </svg>

              {{ getCountryLang(country).name }}: <a target="_blank" :href="country.consent_path"
                v-t="'public.more-info.more_info.Consent.linkLabel'"></a>
            </div>
          </template>
        </list-group-item>
        <list-group-item>
          <h4 v-t="'public.more-info.more_info.BloodStudy.title'"></h4>
          <i18n path="public.more-info.more_info.BloodStudy.description" class="mb-2">
            <a :href="$t('public.more-info.more_info.BloodStudy.link')"
              v-t="'public.more-info.more_info.BloodStudy.link'"></a>
          </i18n>
          <p v-t="'public.more-info.more_info.BloodStudy.para_2'"></p>
        </list-group-item>
      </list-group>




    </section>
  </card>
</template>

<script>
import axios from 'axios'
import countryMixin from '../../mixins/country';

export default {
  name: 'more-info',
  mixins: [countryMixin],
  data() {
    return {
      countries: [],
      loadedCountries: false
    }
  },
  computed: {
    activeCountry() {
      return this.countries.find(function (country) {
        return country.code == window.config.country.countryCode;
      });
    },
    inactiveCountries() {
      return this.countries.filter(function (country) {
        return country.code != window.config.country.countryCode;
      });
    }
  },
  mounted() {
    axios.get('countries')
      .then(((resp, request) => {
        this.countries = resp.data;
        this.loadedCountries = true;
      }).bind(this))
  },

}

</script>
