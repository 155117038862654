<template>
    <g>
        <g class="stackedPaths" v-if="nodes.length">
            <path class="stackedPath" v-for="(layer, i) in stackData()" :key="'stackedPath' + i" :d="area(layer)"
                :fill="'url(#stackedPathsGradient-' + i + ')'">
            </path>
            <linearGradient v-for="(layer, i) in stackData()" :key="i" :id="'stackedPathsGradient-' + i"
                gradientUnits="userSpaceOnUse" :x1="range.x(min(layer, d => d[1]))" :y1="range.y(min(layer, d => d[1]))"
                :x2="range.x(max(layer, d => d[1]))" :y2="range.y(max(layer, d => d[1]))">
                <stop offset="0" :stop-color="darken(layer)" stop-opacity="0.3"></stop>
                <stop offset="1" :stop-color="colour(layer)"></stop>
            </linearGradient>
        </g>
        <slot></slot>
    </g>
</template>
<script>
import { select } from 'd3-selection';
import { area, stack, stackOffsetNone, stackOrderNone } from 'd3-shape';
import { bisector, max, min } from 'd3-array';
// import colour tools from d3 to allow darkening of colours
import { rgb } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';


import { curveStepBefore } from "d3-shape"
export default {
    name: 'stacked-area',
    inject: ['subjects'],
    props: {
        type: {
            type: String,
            default: 'linear'
        },
        width: {
            type: Number,
            default: 0
        },
        height: {
            type: Number,
            default: 0
        },
        colours: {
            type: [Array, Object],
            default: () => []
        }

    },
    data() {
        return {
            drawn: false
        }
    },
    mounted() {
        if (this.nodes.length > 0) {
            // this.drawChart()
        }
    },
    computed: {

        nodes() { return this.$parent.nodes },
        range() { return this.$parent.range },
        area() {
            var self = this
            return area()
                .x(d => self.range.x(d.data.date))
                .y0(d => self.range.y(d[0]))
                .y1(d => self.range.y(d[1]))
                .curve(curveStepBefore);
        },
        stack() {
            // let keys = this.nodes.length > 0 ? Object.keys(this.nodes[0]).filter(key => key !== 'date') : [];
            if (this.nodes.length > 0) {
                let keys = []
                this.nodes.forEach(n => {
                    Object.keys(n).filter(key => key !== 'date').forEach(k => {
                        if (!keys.includes(k)) keys.push(k)
                    })
                })
                return stack()
                    .keys(keys)
                    .offset(stackOffsetNone)
                    .order(stackOrderNone)
            }
            else return function () { }
        },
        layers() {
            return this.stackData()
        }
    },
    methods: {
        stackData() {
            if (!this.range || !this.nodes || this.nodes.length == 0) return [];

            const layers = this.stack(this.nodes);

            this.range.y.domain([
                0, // Stacked area charts generally start at 0
                max(layers, l => max(l, d => d[1]))
            ]);
            return layers;
        },
        colour(p) {
            return this.colours[p.key]
        },
        darken(p) {
            return rgb(this.colour(p)).darker(0.5)
        },
        min(layer, accessor) {
            return min(layer, accessor)
        },
        max(layer, accessor) {
            return max(layer, accessor)
        }

    },
}
</script>
<style>
.stackedPath {
    stroke: none;
    transition: d 0.025s, fill 0.025s;
}

g.stackedPaths {
    stroke: none;

    linearGradient {
        transition: stop-color 0.025s, x1 0.025s, y1 0.025s, x2 0.025s, y2 0.025s;

        stop {
            transition: stop-color 0.025s;
        }
    }
}
</style>