<template>
    <div>
        <list-group-item v-for="(file, index) in fileUploads" :key="index">
            <div>{{ file.name }}</div>
            <progress-bar v-if="file.progress" :value="file.progress" max="100"></progress-bar>
        </list-group-item>
        <input :id="$attrs.id" ref="fileInput" type="file" class="form-control" justified
            @change="(e) => handleFileChange(e)" multiple />

    </div>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert2';
export default {
    name: 'file-upload',
    inheritAttrs: false,
    props: {
        apiUrl: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fileUploads: [],
        }
    },
    methods: {
        async handleFileChange(event) {
            const selectedFiles = event.target.files;
            for (let i = 0; i < selectedFiles.length; i++) {
                if (this.isValidFile(selectedFiles[i])) {
                    await this.uploadFile(selectedFiles[i]);
                } else {
                    alert('Invalid file format');
                }
            }

            // reset the input
            this.$refs.fileInput.value = '';
        },
        formatFileSize(bytes) {
            if (bytes >= 1000000000) {
                bytes = (bytes / 1000000000).toFixed(2) + ' GB';
            } else if (bytes >= 1000000) {
                bytes = (bytes / 1000000).toFixed(2) + ' MB';
            } else if (bytes >= 1000) {
                bytes = (bytes / 1000).toFixed(2) + ' KB';
            } else if (bytes > 1) {
                bytes = bytes + ' bytes';
            } else if (bytes == 1) {
                bytes = bytes + ' byte';
            } else {
                bytes = '';
            }
            return bytes;
        },
        isValidFile(file) {
            // Implement file format validation logic
            return true;
        },
        uploadFile(file) {
            let formData = new FormData();
            formData.append('file', file);
            for (let key in this.params) {
                formData.append(key, this.params[key]);
            }
            this.fileUploads.push({ name: file.name, progress: 0 });
            axios.post(this.apiUrl, formData, {

                onUploadProgress: progressEvent => {
                    const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                    this.updateFileProgress(file, progress);
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                },

            })
                .then(response => {
                    this.updateFileProgress(file, 100);
                    this.updateFileUploads(file, response);
                })
                .catch(error => {
                    this.updateFileProgress(file, 0);
                    this.$emit('error', error);
                    this.updateErrors(file, error.response.data.errors);
                    console.log(error);
                })
                .finally(() => {
                    this.updateFileProgress(file, 0);
                });


        },
        updateFileUploads(file, response) {
            const index = this.fileUploads.findIndex(f => f.name === file.name);
            if (index !== -1) {
                // remove from array
                this.fileUploads.splice(index, 1);

            }
            this.$emit('uploaded', response);

        },
        updateFileProgress(file, progress) {
            const index = this.fileUploads.findIndex(f => f.name === file.name);
            if (index !== -1) {
                this.fileUploads[index].progress = progress;
            }
        },
        updateErrors(file, errors) {
            // Implement error handling logic
            const index = this.fileUploads.findIndex(f => f.name === file.name);
            if (index !== -1) {
                this.fileUploads[index].errors = errors;
            } else {
                this.fileUploads.push({ name: file.name, errors });
            }
            // show swal alert then remove the uploaded file
            swal.fire({
                title: 'Error!',
                text: errors.file[0],
                icon: 'error',
                confirmButtonText: 'OK'
            })
                .then(() => {
                    this.fileUploads.splice(index, 1);
                })
        }
    },
}
</script>

<style lang="scss" scoped></style>