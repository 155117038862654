import {factory} from "./ClassLoader"
var ScheduledRecord = function () {
	let ScheduledRecord = class extends factory.classes.Record {

	}
	return ScheduledRecord;
}
ScheduledRecord.function = true
export {
	ScheduledRecord
}