import routes from './make-routes.js'
import navJSON from './navigation.json'
import store from '~/store'

export let navigation = []
export let newRoutes = []
export let filters = {}
export function makeNavigation(rootApp){
    filters = getFilters(rootApp)
    navigation = [...filterNavigation(navJSON),...filterNavigation(newRoutes)]
    return navigation
}
export function addRoutes(routes){
    newRoutes.push(...routes)
}
export function getFilters(rootApp){
    return {
        check:store.getters['auth/check'],
        isGuest:store.getters['auth/isGuest'],
        isAdmin:store.getters['auth/isAdmin'],
        isTeam: store.getters['auth/isTeam'],
        isParticipant: store.getters['auth/isParticipant'],
        devMode: rootApp.devMode,
    }
}
export function filterNavigation(navJSON){
    return navJSON.filter((route)=>{
        var routeShouldBeAdded = true;

        if(route.filters){
            for (let index = 0; index < route.filters.length; index++) {
                const filter = route.filters[index];
                if(!filters[filter]) routeShouldBeAdded = false;
            }
        }
        return routeShouldBeAdded
    })
}

export function breadcrumbs(route){
    return {}
}

export function getBreadcrumbTrailByName(routeName, routes) {
    let trail = [];
    function findRoute(routeArray) {
      for (const route of routeArray) {
        if (route.to && route.to.name === routeName) {
          trail.unshift(route);
          return true;
        } else if (route.children) {
          if (findRoute(route.children)) {
            trail.unshift(route);
            return true;
          }
        }
      }
  
      return false;
    }
  
    findRoute(routes);
    return trail;
  }

//   // Sample current route name
// const currentRouteName = 'mission';

// // Get the breadcrumb trail for the current route
// const breadcrumbTrail = getBreadcrumbTrailByName(currentRouteName, routes);

// // Log the breadcrumb trail to the console
// console.log(breadcrumbTrail);