let ClassRegistry = class {
	constructor() {
		this.classReals = {};
		this.classFuncs = {};
		this.classes = new Proxy(this.classReals, new proxyHandler(this));
		// return new Proxy(this, )
	}
	setClasses(classes) {
		for(var name in classes) {
			this.setClass(name, classes[name]);
		}
	}
	setClass(name, constructor) {
		if (constructor.function) {
			this.classFuncs[name] = constructor;
		} else {
			this.classReals[name] = constructor;
		}
	}
	hasClass(name) {
		return !!(this.classReals[name] || this.classFuncs[name]);
	}
	getClass(name) {
		if (this.classReals[name]) {
			return this.classReals[name];
		} else if (this.classFuncs[name]) {
			var actual = this.classFuncs[name]();
			this.classReals[name] = actual;
			return actual
		}
	}
}
let proxyHandler = class {
	constructor(registry) {
		this.registry = registry;
	}
	get (target, prop, receiver) {
		return this.registry.getClass(prop);
	}
	has (target, prop, receiver) {
		return this.registry.hasClass(prop);
	}
}
export {
	ClassRegistry
}