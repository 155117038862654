<template>
  <questionnaire :title="$t('questionnaires.facit.title')">
    <alert type="info" v-html="$t('questionnaires.facit.optional_questionnaire')"></alert>
    <alert type="info" v-html="$t('questionnaires.facit.instructions')"></alert>
    <row class="g-2">
      <template v-for="diName in facitFatigueDataitems">
        <column :sizes="{ md: 6 }">
          <card class="h-100">
            <form-group class="h-100 d-flex flex-column" label-class="fs-5 mb-4 flex-grow-1" :error="errors[diName]"
              :di="dataitems[diName]">
              <btn-group :readonly="isSubmitted" :di="dataitems[diName]" v-model="records[diName]" justified vertical
                outline></btn-group>
            </form-group>
          </card>
        </column>
      </template>
      <column :sizes="{ md: 6 }" v-if="isSubmitted">
        <card class="h-100">
          <h4 class="display-4 text-center text-uppercase" v-t="'questionnaires.questionnaire.score'"></h4>
          <score-speedo :score="{ score, min: 0, max: 52 }" :domain="[0, 26, 52]"
            :colours="['#dc3545', '#ffc107', '#198754']" :ticks="52"></score-speedo>
        </card>
      </column>
    </row>
  </questionnaire>
</template>
<script>
import { mapState } from "vuex";
import questionnaire from "~/mixins/questionnaire.js"

export default {
  name: 'facit',
  mixins: [questionnaire],

  computed: {
    ...mapState('resources/patient', ['patient_issue']),
    facitFatigueDataitems() {
      return ['FACIT_HI7', 'FACIT_HI12', 'FACIT_An1', 'FACIT_An2', 'FACIT_An3', 'FACIT_An4', 'FACIT_An5', 'FACIT_An7', 'FACIT_An8', 'FACIT_An12', 'FACIT_An14', 'FACIT_An15', 'FACIT_An16']
    },
    score() {
      var score = 0;
      var count = 0;
      this.facitFatigueDataitems.forEach((diName) => {
        var rec = this.records[diName]
        if (rec) {
          var lookupitem = this.dataitems[diName].lookup.items.find((lui) => lui.value == rec.toString())
          if (rec !== null && lookupitem) {
            count++;
            rec = parseInt(rec)
            var json = this.dataitems[diName].json
            score = score + (json.start_operator == 'minus' ? json.score_start - rec : json.score_start + rec);
          }
        }
      })
      return ((score * 13) / count).toFixed(2)
    },
    isSubmitted() {
      if (this.patient_issue && this.patient_issue.patient_questionnaire)
        return this.patient_issue.patient_questionnaire.find((pq) => pq.questionnaire_id == this.questionnaire.id).valid == 1
    }
  },


}

</script>
