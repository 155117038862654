<template>
    <div class="demographics-dashboard">
        <div class="diagram-list list-group list-group-horizontal">
            <div v-for="diagram in diagrams" :key="diagram.name" @click="diagramShown = diagram.name"
                :class="{ active: diagram.name === diagramShown }" class="diagram-item list-group-item">
                {{ diagram.title }}
            </div>
        </div>
        <gender-diagram v-if="diagramShown == 'gender'"></gender-diagram>
        <age-diagram v-if="diagramShown == 'age'"></age-diagram>
        <skintone-diagram v-if="diagramShown == 'skintone'"></skintone-diagram>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import genderDiagram from '~/components/admin-dashboard/demographics/gender.vue'
import ageDiagram from '~/components/admin-dashboard/demographics/age.vue'
import skintoneDiagram from '~/components/admin-dashboard/demographics/skintone.vue'
export default {
    name: "demographics-dashboard",
    components: {
        ageDiagram,
        genderDiagram,
        skintoneDiagram
    },
    data() {
        return {
            diagramShown: 'gender',
        }
    },
    mounted() {
        this.fetchDemographicData()
        // if (!this.requestLoading && !this.requestCompleted) this.fetchDemographicData()
    },
    computed: {
        ...mapState('resources/admin', ['dashboard']),
        ...mapState('resources/questionnaires', ['questionnaire']),
        diagrams() {
            return [
                { name: 'gender', title: 'Gender' },
                { name: 'age', title: 'Age' },
                { name: 'skintone', title: 'Skintone' }
            ]
        },
        demographicData() {
            if (this.dashboard.stream.patients)
                return this.dashboard.stream.patients
            else return []
        },
        request() {
            if (this.dashboard.stream.requests['patient.demographics'])
                return this.dashboard.stream.requests['patient.demographics']
            else return {}
        },
        requestLoading() {
            return this.request.loading && !this.request.loaded
        },
        requestCompleted() {
            return this.request.percentageComplete === 100
        }

    },
    methods: {
        ...mapActions('resources/questionnaires', ['getQuestionnaire']),
        fetchDemographicData() {
            console.log('fetching demographic data')
            this.getQuestionnaire({ name: 'demographics', params: { include: ['dataitems'], append: 'lookups' } })
        }
    }
}
</script>
<style>
.demographics-dashboard {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
}
</style>