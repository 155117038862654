// Query Builder Resource Module
import axios from 'axios'
import Vue from 'vue'

var emptyQuery = {
    model: '',
    fields: [],
    filters: {},
    sorts: [],
    appends: [],
    includes: [],
}
// state
export const state = {
    models: [],
    queries: [],
    query: {
        model: '',
        fields: [],
        filters: {},
        sorts: [],
        appends: [],
        includes: [],
    },
    queryBuilder: {},
    queryBuilderLoading: false,
    queryBuilderLoaded: false,
    queryResults: null,
    runQueryRequest: null,
    queryBuilderRequest: null,
    abortControllers: {}

}

// getters
export const getters = {
    flattenedQueryBuilder: (state) => {
        if (state.queryBuilder) {
            return flattenObject(state.queryBuilder, '', [], state.query.includes);
        }
        return [];
    }

}

// mutations
export const mutations = {
    GET_QUERIES(state, resp) {
        state.queries = resp
    },
    GET_QUERY(state, resp) {
        state.query = resp
    },
    GET_MODELS(state, resp) {
        state.models = resp
    },
    GET_QUERY_BUILDER(state, resp) {
        state.queryBuilder = resp
        state.queryBuilderLoading = false;
        state.queryBuilderLoaded = true;
    },
    UPDATE_QUERY_BUILDER(state, resp) {
        // resp is object keyed with relationship name, it could be dot notation
        // we need to update the query builder with the relationship data by finding the relationship in the query builder relationships array
        // and updating it with the new data
        let keys = Object.keys(resp);
        keys.forEach((key) => {
            let parts = key.split('.');
            let data = resp[key];
            let relationships = state.queryBuilder.relationships;
            let relationshipIndex = relationships.findIndex((rel) => {
                return rel.name == parts[0];
            });
            // we need to use Vue set with the state.queryBuilder to trigger reactivity so need an array of indexes to set the value
            if (relationshipIndex > -1) {
                if (parts.length > 1) {
                    // we need to drill down to the relationship
                    let rel = relationships[relationshipIndex];
                    for (let i = 1; i < parts.length; i++) {
                        let subRel = rel.relationships.find((r) => {
                            return r.name == parts[i];
                        });
                        if (subRel) {
                            rel = subRel;
                        }
                    }
                    // now we have the relationship we need to update the data

                } else {
                    Vue.set(relationships, relationshipIndex, data);

                }
            }
        });



    },
    GET_QUERY_RESULTS(state, resp) {
        state.queryResults = resp
    },
    SET_ABORT_CONTROLLER(state, { requestId, controller }) {
        Vue.set(state.abortControllers, requestId, controller);
    },
    CLEAR_ABORT_CONTROLLER(state, requestId) {
        Vue.delete(state.abortControllers, requestId);
    }
}

// actions
export const actions = {
    fetchQueries({ commit }) {
        return axios.get('query_builder')
            .then((resp) => {
                commit('GET_QUERIES', resp.data)
            })
    },
    fetchQuery({ commit }, request) {
        return axios.get('query_builder/' + request.params.id, request)
            .then((resp) => {
                commit('GET_QUERY', resp.data)
            })
    },
    clearQuery({ commit }) {
        console.log('clearQuery', emptyQuery.model);
        var eq = Object.assign({}, emptyQuery);
        commit('GET_QUERY', eq)
        commit('GET_QUERY_RESULTS', null)
        commit('GET_QUERY_BUILDER', {})
    },
    saveQuery({ commit }, request) {
        if (request.query.id) {
            return axios.put('query_builder/' + request.query.id, request)
                .then((resp) => {
                    commit('GET_QUERY', resp.data)
                })
        } else {
            return axios.post('query_builder', request)
                .then((resp) => {
                    commit('GET_QUERY', resp.data)
                })
        }

    },
    fetchModels({ commit }) {
        return axios.get('query_builder/models')
            .then((resp) => {
                commit('GET_MODELS', resp.data)
            })
    },
    fetchQueryBuilderData({ commit }, request) {
        // console.log('fetchQueryBuilderData', JSON.stringify(request), state.queryBuilderRequest, state.queryBuilderLoading);
        if (state.queryBuilderRequest &&
            state.queryBuilderRequest == JSON.stringify(request) &&
            (state.queryBuilderLoading || !state.queryBuilderLoaded)) {
            return;
        }
        if (state.abortControllers[request.params.model]) {
            state.abortControllers[request.params.model].abort();
        }
        state.queryBuilderRequest = JSON.stringify(request);
        state.queryBuilderLoading = true;
        state.queryBuilderLoaded = false;
        const abortController = new AbortController();
        commit('SET_ABORT_CONTROLLER', { requestId: request.params.model, controller: abortController });

        return axios.get('query_builder/data', request)
            .then((resp) => {
                commit('GET_QUERY_BUILDER', resp.data)
                commit('CLEAR_ABORT_CONTROLLER', request.params.model);
            })
    },
    fetchQueryBuilderRelationships({ commit }, request) {
        console.log('fetchQueryBuilderRelationships', request);
        return axios.get('query_builder/relationships', request)
            .then((resp) => {
                commit('UPDATE_QUERY_BUILDER', resp.data)
            })
    },
    runQuery({ commit }, request) {
        if (state.runQueryRequest && state.runQueryRequest == JSON.stringify(request)) {
            return;
        }
        state.runQueryRequest = JSON.stringify(request);
        return axios.get('query_builder/run', request)
            .then((resp) => {
                commit('GET_QUERY_RESULTS', resp.data)
            })
    }

}

function flattenObject(obj, path = '', flattened = [], includes = []) {
    if (obj.fields) {
        flattened.push({
            ...obj,
            key: path || obj.name,
        });
    }

    // If the object has relationships, iterate through them and flatten each one
    if (obj.relationships && obj.relationships.length > 0) {
        obj.relationships.forEach(relationship => {
            const newPath = path ? `${path}.${relationship.name}` : relationship.name;
            if (includes.includes(newPath)) {
                relationship.inIncludes = true;
            }
            flattenObject(relationship, newPath, flattened);
        });
    }
    return flattened;
}


export const namespaced = true;
