<template>
  <row>
    <column :sizes="{md:3}">
      <card head :body="false" :title="$t('settings.title')" class="settings-card mb-2">
        <list-group flush class="rounded-bottom">
          <router-link v-for="tab in tabs" :key="tab.route" :to="{ name: tab.route }"
          active-class="active" class="list-group-item d-flex">
            <div>
              <fa :icon="tab.icon" fixed-width class="me-2"/>
              <span>{{ tab.name }}</span>
            </div>
          </router-link>
        </list-group>
      </card>
    </column>
    <column :sizes="{md:9}">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </column>
  </row>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Settings',
  middleware: 'auth',
  computed: {
    ...mapGetters('auth', ['isParticipant']),
    tabs() {
      var tabs = []
      if (this.isParticipant) tabs.push({ icon: 'user', name: this.$t('questionnaires.personal_details.title'), route: 'personal_details' })
      if (this.isParticipant) tabs.push({ icon: 'list-check', name: this.$t('questionnaires.consent.title'), route: 'settings.consent' })
      tabs.push({ icon: 'file-signature',  name: this.$t('settings.account'),  route: 'settings.account' })
      tabs.push({ icon: 'lock',  name: this.$t('settings.password'),  route: 'settings.password' })
      
      return tabs
    }
  }
}

</script>
