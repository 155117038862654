<template>
  <list-group-item :class="{ active: isSelected }" :key="record.id">
    <h5>{{ record.sectionDataitem.label }}</h5>
    <small>{{record.sectionDataitem.name}}</small></br>
    <i18n path="questionnaires.questionnaire.saved_at" tag="small">
      <time slot="datetime">
        {{date}}
      </time>
    </i18n>
  </list-group-item>
</template>
<script>
import lang from "~/mixins/lang.js";
export default {
    name: 'consent-entry',
    mixins: [lang],
	props: {
        record:{},
        isSelected:{}
	},
    computed: {
      date() {  
        return this.i18nDate(this.record.created_at)
      }
    },
    methods: {  
    },
}
</script>