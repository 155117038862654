<template>
  <div>
    <!-- <btn outline v-if="canUpgrade" @click.native="startUpgrade">
            Upgrade your consent
        </btn> -->
    <card :title="questionnaire.trans('current_consent')" head :body="false">
      <list-group flush>
        <consent-entry v-for="(rec, index) in records.activeForms" @click.native="setSelectedFormRecord(rec)"
          :record="rec" :is-selected="isFormRecordSelected(rec)">
        </consent-entry>
      </list-group>
    </card>
    <br />
    <card v-if="showHistory && records.inactiveForms.length > 0" :title="questionnaire.trans('inactive_consent')" head
      :body="false">
      <list-group flush>
        <consent-entry v-for="(rec, index) in records.inactiveForms" @click.native="setSelectedFormRecord(rec)"
          :record="rec" :is-selected="isFormRecordSelected(rec)">
        </consent-entry>
      </list-group>
    </card>
  </div>
</template>
<script>
import consentEntry from '~/pages/questionnaires/consent/consent-entry.vue'
export default {
  name: 'consent-list',
  components: { consentEntry },
  props: {
    consent: {},
    showHistory: {
      default: true
    },
    records: {},
    selected: {}
  },
  computed: {
    questionnaire() {
      return this.records.questionnaire;
    },
    dataitems() {
      return this.records.dataitems;
    },
    selectedSectionName() {
      if (this.selectedSectionOveride) {
        return this.selectedSectionOveride
      }
      if (this.selected.sectionName) {
        return this.selected.sectionName;
      }
      else if (this.isFirstConsent) {
        return this.questionnaire.extraData.activeConsent;
      } else {
        return this.records.activeForms[0].sectionDataitem.name;
      }
    },
    // canUpgrade() {
    //     return  this.records.activeForms[0] && this.records.activeForms[0].sectionDataitem.name != this.questionnaire.extraData.activeConsent;
    // },
    // which id section is currently selected
    // either the active one, or the manually selected one
    selectedIndex() {
      if (this.selected.index == null) {
        return this.records.activeForms[0].id;
      }
      return this.selected.index;
    },

  },
  methods: {
    isFormRecordSelected(record) {
      return record.sectionDataitem.name == this.selectedSectionName && record && record.id == this.selectedIndex;
    },
    setSelectedFormRecord(record) {
      this.selected.sectionName = record.sectionDataitem.name;
      this.selected.index = record.id;
    },
    // setNewSelectedForm(sectionDataitem) {
    //   this.selected.sectionName = sectionDataitem.name;
    //   this.selected.index = -1
    // },
    // startUpgrade() {
    //   var sectionDataitem = this.dataitems[this.questionnaire.extraData.activeConsent];
    //   this.setNewSelectedForm(sectionDataitem);
    // },
  }
}
</script>