<template>
    <div class="h-100">
        <div v-if="request.loading" class="progress my-4" role="progressbar" aria-label="Basic example"
            :aria-valuenow="request.percentageComplete" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar" :style="'width:' + request.percentageComplete + '%'"></div>
        </div>
        <template v-if="request.loading || request.loaded">
            <chart ref="chart" class="age-chart" :width="width" :height="height" :nodes="ages"
                :margin="{ left: 100, right: 100, top: 30, bottom: 30 }" :subjects="{ x: 'a', y: 'c' }" :scale-range="{
                    x: [100, width - 200],
                    y: [height - 200, 100]
                }" v-slot="{ range }">
                <scatter :width="width" :height="height"></scatter>
                <!-- <axis ref="xAxis" dimension="x" :label="'Age'" :width="width" :height="height">
                </axis> -->
                <template v-for="country in countryData">
                    <g :key="country.id">
                        <text :x="range.x(16)" :y="range.y(country.id)" style="font-size: 12px">{{ country.name
                            }}</text>
                        <use :href="'/images/home/flags-sprite.svg#' + country.code.toLowerCase()" :x="range.x(16)"
                            :y="range.y(country.id) - 50" preserveAspectRatio="none" width="42" height="36"></use>
                    </g>
                </template>
                <template v-for="age in ageRangeArray">
                    <g :key="'age' + age">
                        <line :x1="range.x(age)" :x2="range.x(age)" :y1="0" :y2="height"
                            style="stroke:rgb(0,0,0);stroke-width:0.5"></line>
                        <text :x="range.x(age) - 5" :y="height - 100" style="font-size: 12px">{{ age }}</text>
                    </g>
                </template>

            </chart>
        </template>
    </div>
</template>

<script>
// patientAges is an array of nodes with age (a) and country (c) properties
// we can use this to create a diagram of patient ages
// we will have a node for each patient with the x axis representing age.
// a row for each country will be created and the nodes will be coloured by country
// the y axis will be the country
// the x axis will be the age
// the size of the node will be the number of patients with that age
import scatter from '~/components/charts/scatter.vue'
import { mapActions, mapState } from 'vuex'
import axis from "~/components/charts/axis.vue";
export default {
    name: 'age-diagram',
    components: {
        axis,
        scatter
    },
    data() {
        return {
            width: 0,
            height: 0,
            chartWidth: 0,
            chartHeight: 0,
            margin: { left: 100, right: 0, top: 30, bottom: 30 },
            ro: null,
        }
    },
    mounted() {
        if (!this.request.loaded && !this.request.loading) this.fetchAgeData()
        requestAnimationFrame(() => {
            this.setChartSize();
            this.defineResizeObserver();
        });

    },
    beforeDestroy() {
        this.cancelFetch(this.resourceId)
    },
    computed: {
        ...mapState('resources/admin', ['dashboard']),
        resourceId() {
            return 'patient.age'
        },
        elBB() {
            return this.$el.getBoundingClientRect()
        },
        countryData() {
            if (this.dashboard.data.countryData)
                return this.dashboard.data.countryData
            else return []
        },
        patients() {
            if (this.dashboard.stream.patients)
                return this.dashboard.stream.patients
            else return []
        },
        minMaxAges() {
            if (this.request)
                return this.patientAges.reduce((acc, patient) => {
                    if (patient.a < acc.min) acc.min = patient.a
                    if (patient.a > acc.max) acc.max = patient.a
                    return acc
                }, { min: Infinity, max: -Infinity })
            else return { min: 0, max: 100 }
        },
        ageRangeArray() {
            if (this.request)
                return Array.from({ length: this.minMaxAges.max - this.minMaxAges.min + 1 }, (v, i) => i + this.minMaxAges.min)
            else return []
        },
        patientAges() {
            // each patient has a dob field that is a date string
            // we can calculate the age by subtracting the dob from the current date
            // and then dividing by 365.25
            if (this.request)
                return this.patients.map(patient => {
                    let dob = new Date(patient.d)
                    let age = (new Date() - dob) / (1000 * 60 * 60 * 24 * 365.25)
                    return { a: Math.floor(age), c: patient.c }
                })
            else return []
        },
        patientAgesByCountry() {
            if (this.request)
                return this.patientAges.reduce((acc, patient) => {
                    acc[patient.c] = acc[patient.c] ? acc[patient.c].concat(patient) : [patient]
                    return acc
                }, {})
            else return {}
        },
        ages() {
            if (this.request) {
                // we need an array of age, country and count
                // we can use this to create a scatter plot
                return Object.keys(this.patientAgesByCountry).reduce((acc, country) => {
                    let countryAges = this.patientAgesByCountry[country]
                    let ageCounts = countryAges.reduce((acc, patient) => {
                        acc[patient.a] = acc[patient.a] ? acc[patient.a] + 1 : 1
                        return acc
                    }, {})
                    let ages = Object.keys(ageCounts).map(age => {
                        return { a: parseInt(age), c: country, count: ageCounts[age] }
                    })
                    return acc.concat(ages)
                }, [])

            }
            else return {}
        },
        request() {
            if (this.dashboard.stream.requests[this.resourceId])
                return this.dashboard.stream.requests[this.resourceId]
            else return {
                loading: false,
                loaded: false
            }
        }
    },
    methods: {
        ...mapActions('resources/admin', ['fetchAsStream', 'cancelFetch']),
        fetchAgeData() {
            this.fetchAsStream({
                id: this.resourceId,
                resource: 'Patient',
                key: 'patients',
                initial: false,
                params: {
                    include: ['personal_details'],
                    attrs: ['dob'],
                    chunk: 1000

                }
            })
        },
        setChartSize() {
            console.log('setting chart size')
            if (!this.$refs.chart || !this.$refs.chart.$el) return
            var bbox = this.$refs.chart.$el.getBoundingClientRect()
            this.width = bbox.width
            this.height = bbox.height
        },
        defineResizeObserver() {
            if (!this.$refs.chart || !this.$refs.chart.$el) return
            this.ro = new ResizeObserver(() => {
                requestAnimationFrame(() => {
                    this.setChartSize();
                });
            });
            this.ro.observe(this.$refs.chart.$el);
        },
    },
    watch: {
        'request.loaded'(val) {
            if (val) {
                this.setChartSize()
            }
        }
    }
}
</script>
<style>
.age-chart {
    width: 100%;
    height: 100%;
}
</style>