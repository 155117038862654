<template>
    <card head foot :body="false">
        <template #header>
            <div class="card-header d-flex">
                <h5 class="mb-0">
                    <fa icon="filter" /> Filters
                </h5>
            </div>
        </template>
        <list-group flush v-if="queryBuilderLoaded">
            <list-group-item v-for="(filter, filterName, filterIndex) in filters" :key="filterName"
                :class="['d-flex align-items-center', { 'is-invalid': !filterIsValid(filter, filterName, filterIndex) }]">
                <div v-if="Object.keys(filter).length > 0 && !Object.keys(filter).includes('parameters')"
                    class="d-flex align-items-center">
                    <div v-for="(opFilter, op, opIndex) in filter" :key="filterName + op"
                        class="d-flex align-items-center">
                        <label class="me-2">
                            {{ filterName }}
                        </label>
                        <dropdown btn-outline :items="filterOperators" :value="op"
                            @input="(v) => changeFilterOperator(v, filterName, opIndex)" class="mx-2" />
                        <scope v-if="opFilter.parameters" :filterModel="findFilterFromKeyList(filterName)"
                            v-slot="{ filterModel }" class="d-flex">
                            <template v-for="(param, pIndex) in opFilter.parameters">
                                <scope :el="suitableComponent(filterModel, param, pIndex, op, opIndex)"
                                    :key="filterName + op + 'params-' + pIndex" v-slot="{ el }">
                                    <!-- {{ el }} -->
                                    <component :is="el.name" class="me-2" v-bind="el.bindings" v-on="el.events" />
                                </scope>
                            </template>

                        </scope>
                    </div>
                </div>
                <div v-if="Object.keys(filter).includes('parameters')" class="d-flex align-items-center">
                    <label class="me-2">
                        {{ filterName }}
                    </label>
                    <scope v-if="filter.parameters" :filterModel="findFilterFromKeyList(filterName)"
                        v-slot="{ filterModel }" class="d-flex align-items-center">
                        <template v-for="(param, pIndex) in filter.parameters">
                            <scope :el="suitableComponent(filterModel, param, pIndex)" v-slot="{ el }" class="d-flex"
                                :key="filterName + 'params-' + pIndex">
                                <component :is="el.name" class="me-2" v-bind="el.bindings" v-on="el.events" />
                            </scope>
                        </template>
                    </scope>
                </div>
                <div>
                    <btn theme="danger" @click.native="deleteFilter(filterName)" class="my-n1 p-1 ms-2">
                        <fa icon="trash" fixed-width></fa>
                    </btn>
                </div>
            </list-group-item>
        </list-group>
        <div class="card-body">
            <input type="text" v-model="filterKey" class="form-control" ref="filterKeyInput" @focus="showList"
                @blur="hideList" @input="onInput" @keyup.down.prevent="onCursorDown" @keyup.up.prevent="onCursorUp"
                @keyup.enter.prevent="onEnter" aria-haspopup="listbox" aria-expanded="isOpen"
                :aria-activedescendant="activedescendant" role="combobox" aria-autocomplete="list"
                aria-controls="dropdownMenu">
            <div class="list-group dd-menu" ref="dropdownMenu" @click="toggle" @mouseenter="handleMouseEnter"
                @mouseleave="handleMouseLeave" v-show="isOpen" role="listbox" :aria-labelledby="filterKeyInputId">
                <!-- <nested-list v-for="(filter, index) in nestedKeyList" :key="index" :item="filter" /> -->
                <div :class="['list-group-item d-flex align-items-center', { 'active': index === cursor }]"
                    :id="'list-item-' + index" v-for="(filter, index) in filteredKeyList" :key="filter.key"
                    @click="setFilterKey(filter)" @mouseover="onMouseOver(index)">
                    <!-- icons for type -->
                    <fa :icon="suitableIcon(filter)" class="me-2" v-if="filter.isField" />
                    <fa icon="filter" class="me-2" v-if="filter.isScope" />
                    <fa icon="diagram-project" class="me-2" v-if="filter.isRelationship" />
                    {{ filter.key }}
                </div>
            </div>
        </div>
        <!-- {{ filters }} -->
    </card>

</template>

<script>
// this component will be responsible for rendering the filters based on the query and its filters array
// the filters array is an array of objects with a name and in certain conditions can have an array of parameters
// the component will render an array of filters and allow adding and deleting filters
// the filters should follow the expected style of spatie query builder filters that allows for dot notation signifying a relation, eg user.id or user.name,
// the last part of the dot notation could be a field or a scope.
// the query model (queryBuilder) is a nested object that will be used to build the query, it has properties named fields, scopes and relationships
// the fields property is an array of objects with a name and a type, it also contains the table name (table)
// the scopes property is an object with keys for each scope, each one has a name and optional parameters (name,type,default)
// the relationships property is an array of direct relationships from the given model (name, type, model etc)
// and if a request has been sent to the server to get the given relationship, it should also contain the fields, scopes and relationships properties for that relationship

// the query object is the object that will be sent to the server
// 
import { mapState, mapActions } from 'vuex';
import nestedList from './nested-list.vue';
import { createPopper } from '@popperjs/core';

import country_id from './custom-filters/country_id.vue';
import { filter } from 'lodash';


export default {
    name: "query-filters",
    props: {
        filters: Object,
        properties: Array
    },
    components: {
        nestedList,
        country_id
    },
    data() {
        return {
            filterKey: '',
            isOpen: false,
            cursor: -1,
            filterKeyInputId: 'filterKeyInput',
            popperInstance: null,
            customComponents: {},
            activedescendant: '',
            activeIndex: -1,

        }
    },
    mounted() {
        this.createPopperInstance();
    },
    computed: {
        ...mapState('resources/QueryBuilder', ['query', 'queryBuilder', 'queryResults', 'queryBuilderLoaded', 'queryBuilderLoading']),
        keyList() {
            // produce a complete list of each level of the query object, should include fields (name, type, table), scopes (name, type, default, parameters), relationships (name, type, model)
            //each should have a unique key that can be used to filter the list, it should be a dot notation of the path to the object
            // so at the root level for fields it should be  name, type, table
            // whereas in a relationship it should be {relationship}.name, {relationship}.type, {relationship}.model
            // if it is a nested relationship it should be {relationship}.{nestedRelationship}.name, {relationship}.{nestedRelationship}.type, {relationship}.{nestedRelationship}.model

            // the keyList should be an array of objects with a key and a value, the key should be the dot notation of the path to the object and the value should be the object itself

            // the keyList should be filtered based on the filterKey
            // the filterKey should be a string that is used to filter the keyList
            var filterKey = this.filterKey;
            // trim until last dot

            var keyList = this.mapModelToKeyList(this.queryBuilder)
                .sort((a, b) => a.key.localeCompare(b.key))
                .filter((node, index, self) =>
                    index === self.findIndex((t) => (
                        t.key === node.key
                    ))
                );

            return keyList;
        },
        filteredKeyList() {
            return this.keyList.filter(node => {
                if (this.filterKey.includes('.')) {
                    var keyArr = this.filterKey.split('.');
                    return node.key.includes(this.filterKey);
                } else {
                    return node.key.includes(this.filterKey) && !node.key.includes('.');
                }
                // if (node.key.includes(this.filterKey)) console.log('Filtering key list', node.key, this.filterKey);
                return node.key.includes(this.filterKey);
            })
                // sort by isField, isScope, isRelationship
                .sort((a, b) => {
                    if (a.isField && !b.isField) return -1;
                    if (b.isField && !a.isField) return 1;
                    if (a.isScope && !b.isScope) return -1;
                    if (b.isScope && !a.isScope) return 1;
                    if (a.isRelationship && !b.isRelationship) return -1;
                    if (b.isRelationship && !a.isRelationship) return 1;
                    return 0;
                });
        },
        nestedKeyList() {
            // map from keys with dot notation to nested objects
            // this will be used to filter the keyList based on the filterKey
            var nestedObject = {};
            this.filteredKeyList.forEach(node => {
                // split the key into an array
                var keyArr = node.key.split('.');
                // loop through the array and create nested objects
                for (var i = 0; i < keyArr.length; i++) {
                    // create a nested object
                    if (!nestedObject[keyArr[i]]) {
                        nestedObject[keyArr[i]] = {};
                    }
                    // if it is the last element in the array, assign the value
                    if (i === keyArr.length - 1) {
                        nestedObject[keyArr[i]] = node;
                    }
                }
                // return the nested object
            });
            return nestedObject;
        },
        unqueriedRelationships() {
            // get a list of relationships that have not been queried
            // this will be used to fetch the relationships from the server
            var unqueriedRelationships = [];
            this.keyList.forEach(node => {
                if (node.isRelationship && !node.value.queried) {
                    unqueriedRelationships.push(node.key);
                }
            });
            console.log('Unqueried relationships', unqueriedRelationships);
            return unqueriedRelationships;
        },
        filterOperators() {
            return [
                { label: 'Partial', value: 'partial', params: 1 },
                { label: 'Exact', value: 'eq', params: 1 },
                { label: 'Not Equal', value: 'ne', params: 1 },
                { label: 'Greater Than', value: 'gt', params: 1 },
                { label: 'Greater Than or Equal', value: 'gte', params: 1 },
                { label: 'Less Than', value: 'lt', params: 1 },
                { label: 'Less Than or Equal', value: 'lte', params: 1 },
                { label: 'In', value: 'in', params: 1 },
                { label: 'Not In', value: 'nin', params: 1 },
                { label: 'Between', value: 'between', params: 2 },
                { label: 'Not Between', value: 'notbetween', params: 2 },
                { label: 'Is Null', value: 'isnull', params: 0 },
                { label: 'Is Not Null', value: 'isnotnull', params: 0 }

            ]

        },
        componentDefinitions() {
            return {
                checkbox: {
                    name: 'checkbox',
                    bindings: { trueValue: true, falseValue: false },
                },
                dropdown: {
                    name: 'dropdown',
                    bindings: {
                        valueName: 'id',
                        labelName: 'name',
                        btnOutline: true
                    }
                },
                textbox: {
                    name: 'textbox',
                    bindings: {}
                },
                number: {
                    name: 'number',
                    bindings: {}
                },
                datepicker: {
                    name: 'datepicker',
                    bindings: {}
                },


            }
        }
    },

    methods: {
        ...mapActions('resources/QueryBuilder', ['fetchQueryBuilderRelationships']),
        filterIsValid(filter, filterName, filterIndex) {
            var fkl = this.findFilterFromKeyList(filterName);
            console.log('Filter is valid', filterName, filter, fkl);
            // check if the filter is valid
            if (!filter || !fkl) return false;
            if (filter.parameters) {
                return filter.parameters.every(param => param !== '' && param !== null);
            }

            if (fkl.isScope) {
                console.log('Scope is valid', filter, fkl);
                // check if the parameters are valid
                // console.log('Filter is scope', filter, fkl);

                // var isCorrectLength = filter.parameters.length === fkl.value.parameters.length;
                // var isComplete = filter.parameters.every(param => param !== '' && param !== null);
                // return isCorrectLength && isComplete;
            }
            // if it is a scope, check if the parameters are valid
            // if it is a field, check if the parameters are valid
            // if it is a relationship, check if the parameters are valid
            // if it is a custom filter, check if the parameters are valid
            // if it is a global lookup
        },

        showList() {
            this.isOpen = true;
            this.createPopperInstance();
        },
        hideList(e) {
            setTimeout(() => {
                this.isOpen = false;
            }, 200); // Delay to allow click event to register
        },
        onInput() {
            this.cursor = -1;
            this.isOpen = true;
        },
        onCursorDown() {
            if (this.cursor < this.filteredKeyList.length - 1) {
                this.cursor++;
                this.$refs.dropdownMenu.children[this.cursor].scrollIntoView({ block: 'nearest' });
            }
        },
        onCursorUp() {
            if (this.cursor > 0) {
                this.cursor--;
            }
        },
        onEnter() {
            if (this.cursor >= 0) {
                this.setFilterKey(this.filteredKeyList[this.cursor]);
            }
        },
        onMouseOver(index) {
            this.activeIndex = index;
            this.setActiveDescendant();
        },
        setActiveDescendant() {
            this.activedescendant = 'list-item-' + this.activeIndex;
        },
        isGlobalLookup(lookupName) {
            // Define your logic to determine if a lookup is global
            if (!this.queryBuilder.lookups) return false;
            const globalLookups = this.queryBuilder.lookups.map(lookup => lookup.name);
            return globalLookups.includes(lookupName);
        },
        isCustomFilter(filterName) {
            // Define your logic to determine if a filter is custom
            const customFilters = ['country_id', 'country'];
            return customFilters.includes(filterName);
        },

        mapModelToKeyList(model, parentKey = '') {
            // console.log('Mapping model to key list', model, parentKey);
            // this function can be called recursively to map the model to a keyList
            var fields = model.fields;
            var scopes = model.scopes;
            var relationships = model.relationships;
            var keyList = [];
            if (fields) {
                fields.forEach(field => {
                    var key = field.name;
                    if (parentKey) key = parentKey + '.' + key;
                    keyList.push({ key, value: field, isField: true });
                });
            }
            if (scopes) {
                Object.keys(scopes).forEach(scope => {
                    var key = scope;
                    if (parentKey) key = parentKey + '.' + key;
                    keyList.push({ key, value: scopes[scope], isScope: true });
                });
            }
            if (relationships) {
                relationships.forEach(relationship => {

                    var key = relationship.name;
                    if (parentKey) key = parentKey + '.' + key;
                    keyList.push({ key, value: relationship, isRelationship: true });
                    keyList = keyList.concat(this.mapModelToKeyList(relationship, key));
                });
            }
            return keyList;

        },
        setFilterKey(filter) {
            // console.log('Setting filter key', filter);
            if (filter.isScope) {
                var parameters = filter.value.parameters ? filter.value.parameters.map(param => param.default ?? '') : [];
                this.$emit('update:filters', { ...this.filters, [filter.key]: { parameters } });
                this.isOpen = false;
            }
            if (filter.isField) {
                var parameters = filter.value.default ?? filter.value.type == 'integer' ? [1] : [''];
                // set fields to deeper level defaulting to eq operator
                this.$emit('update:filters', { ...this.filters, [filter.key]: { eq: { parameters } } });
                this.isOpen = false;
            }

            if (filter.isRelationship) {
                this.filterKey = filter.key;
                this.$refs.filterKeyInput.focus();
            }



        },
        findFilterFromKeyList(key) {
            if (!this.keyList || this.keyList.length == 0) return null;
            return this.keyList.find(node => node.key === key);
        },

        changeFilterOperator(operator, filterName, index) {
            var was = Object.keys(this.filters[filterName])[index];
            var filter = this.filters[filterName];
            var fkl = this.findFilterFromKeyList(filterName);
            if (fkl && fkl.isField && fkl.value.type) {
                var paramCount = this.filterOperators.find(op => op.value === operator).params;
                var parameters = filter[was].parameters.length == paramCount ? filter[was].parameters : Array(paramCount).fill('');
                filter[operator] = { parameters };

            }
            delete filter[was];
            var filters = { ...this.filters, [filterName]: filter }
            this.$emit('update:filters', filters);
        },
        componentFromScopeParam(scopeParam) {
            var cd = this.componentDefinitions;
            var el = {};
            switch (scopeParam.type) {
                case 'int':
                    el = cd.number;
                    break;
                case 'string':
                    el = cd.textbox;
                    break;
                case 'boolean':
                    el = cd.checkbox;
                    break;
                case 'date':
                    el = cd.datepicker;
                    break;
                case 'datetime':
                    el = cd.datepicker;
                    break;
                case 'time':
                    el = cd.datepicker;
                    break;
                default:
                    el = cd.textbox;

            }
            el.bindings.value = scopeParam.default ?? '';
            return el;
        },
        suitableIcon(filter) {
            if (filter.isField) {
                if (filter.value.type == 'integer' || filter.value.type.startsWith('integer')) return 'hashtag';
                if (filter.value.type == 'string' || filter.value.type.startsWith('string')) return 'font';
                if (filter.value.type == 'boolean' || filter.value.type.startsWith('boolean')) return 'check-square';
                if (filter.value.type == 'date') return 'calendar';
                if (filter.value.type == 'datetime') return 'calendar-alt';
                if (filter.value.type == 'time') return 'clock';
                if ([].includes(filter.value.type)) return 'filter';
            }
            return 'filter';
        },
        suitableComponent(filterModel, param = null, index = -1, operator = null, operatorIndex = -1) {
            if (!filterModel) return {};
            console.log('Suitable component', filterModel, param)
            var cd = this.componentDefinitions;
            // we are going to combine the suitable input, suitable input binding and suitable events into a single object
            // this object will contain the name of the component, the bindings and the events
            // the bindings will be the v-bind for the component
            // the events will be the v-on for the component
            // the name will be the name of the component
            var el = {}

            if (index > -1) {
                if (filterModel.isScope) {
                    // el.bindings.value = param;
                    if (filterModel.value.parameters) {
                        var scopeParam = filterModel.value.parameters[index];
                        el = { ...this.componentFromScopeParam(scopeParam) };
                        if (this.isGlobalLookup(scopeParam.name)) {
                            el = { ...cd.dropdown };
                            const lookup = this.queryBuilder?.lookups?.find(lookup => lookup.name === scopeParam.name);
                            el.bindings = { ...el.bindings, items: lookup.items, value: param, multiple: true };
                        }
                    } else {
                        el = cd.checkbox;
                    }
                }
                if (filterModel.isField) {
                    if (filterModel.value.lookup) {
                        el = cd.dropdown;
                        const field = filterModel.value;

                        const lookup = this.queryBuilder?.lookups?.find(lookup => lookup.name === filterModel.value.lookup.name);
                        // console.log('Lookup', lookup, filterModel.value.lookup.name, param);
                        el.bindings = { ...el.bindings, items: lookup.items, value: param };
                        if (operator && operatorIndex > -1) {
                            if (operator === 'in' || operator === 'nin') {
                                el.bindings.multiple = true;
                            }
                        }

                    }
                    else if (this.isGlobalLookup(filterModel.name)) {
                        el = cd.dropdown;
                        const lookup = this.queryBuilder?.lookups?.find(lookup => lookup.name === filterModel.name);
                        el.bindings.items = { ...lookup.items }
                        if (Array.isArray(param)) {
                            el.bindings.value = param;
                            el.bindings.multiple = true;
                        } else {
                            el.bindings.value = [param];
                        }

                    }
                    else if (param && typeof param == 'string' && param.startsWith('${') && param.endsWith('}')) {
                        el = cd.textbox;
                        el.bindings.value = param;
                    }
                    else if (filterModel.value.type) {
                        switch (filterModel.value.type) {
                            case 'integer':
                                el = cd.number;
                                break;
                            case 'string':
                                el = cd.textbox;
                                break;
                            case 'boolean':
                                el = cd.checkbox;
                                break;
                            case 'date':
                                el = cd.datepicker;
                                break;
                            case 'datetime':
                                el = cd.datepicker;
                                break;
                            case 'time':
                                el = cd.datepicker;
                                break;
                            default:
                                el = cd.textbox;

                        }
                        el.bindings.value = param

                    }
                }
                if (this.isCustomFilter(filterModel.key)) {
                    el.name = filterModel.key;

                    el.bindings.value = typeof param == 'string' || typeof param == 'number' ? [param] : param;
                }
                el.events = {
                    change: (v) => this.updateValue(v, filterModel, index, operator, operatorIndex),
                    input: (v) => this.updateValue(v, filterModel, index, operator, operatorIndex),
                }
            }
            console.log('Suitable component', filterModel, el, el.bindings);
            return el

        },
        updateValue(value, filterModel, index, operator, operatorIndex) {
            var filter = this.filters[filterModel.key];
            console.log('Updating value', value, filterModel, index, operator, operatorIndex);
            if (filter.parameters) {
                filter.parameters[index] = value;
                console.log('filter.parameters', filter.parameters);
                var filters = { ...this.filters, [filterModel.key]: filter }
                this.$emit('update:filters', filters);

            } else {
                if (operator && operatorIndex > -1) {
                    if (typeof value == 'object' && Array.isArray(value) && operator == 'eq') {
                        value = value[0];
                    }
                    // if string or number and operator is in or not in, convert to array
                    if (typeof value == 'string' || typeof value == 'number') {
                        if (operator == 'in' || operator == 'nin') {
                            value = [value];
                        }
                    }
                    if (Object.keys(filter).indexOf(operator) == operatorIndex) {
                        filter[operator].parameters[operatorIndex] = value;
                        var filters = { ...this.filters, [filterModel.key]: filter }
                        this.$emit('update:filters', filters);

                    } else {

                        console.log('Operator different', operator, operatorIndex);
                    }
                    // filter[operator].parameters[index] = v;
                }
            }
        },
        deleteFilter(filterName) {
            var filters = { ...this.filters };
            delete filters[filterName];
            this.$emit('update:filters', filters);
        },
        toggle() {
            this.isOpen = !this.isOpen;
            console.log('Toggling dropdown', this.isOpen);
            this.$nextTick(() => {
                if (this.isOpen) {
                    this.createPopperInstance();
                }
            });
        },
        createPopperInstance() {
            console.log('Creating popper instance');
            this.popperInstance = createPopper(this.$refs.filterKeyInput, this.$refs.dropdownMenu, {
                placement: 'bottom-start',
            });
        },
        handleMouseEnter() {
            // Optional: Open dropdown on hover
        },
        handleMouseLeave() {
            // Optional: Close dropdown on leave
        },
    },
    watch: {
        filterKey(newValue) {
            if (newValue) {
                if (this.unqueriedRelationships.length && this.unqueriedRelationships.includes(newValue)) {
                    var relationList = this.unqueriedRelationships
                        .filter(relationship => relationship.includes(newValue));


                    this.fetchQueryBuilderRelationships({ params: { model: this.query.model, include: relationList } });
                }
            }
        },
        filters: {
            handler(newValue) {
                console.log('Filters changed', newValue);
                this.$emit('update:filters', newValue);
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
.dd-menu {
    //position: absolute;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: 50vh;
    overflow-y: auto;
}
</style>