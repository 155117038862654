import {factory} from "../utils/ClassLoader"
import helpers from "../utils/helpers"
let QuestionnaireCollection = class extends factory.classes.Collection {
	constructor(questionnaires) {
		super(questionnaires)
	}
}

export {
	QuestionnaireCollection
}
