<template>
    <div class="country-leaderboard row g-0">
        <!-- First column shows podium finishers (Top 3 countries) -->
        <column>
            <row class="podium g-0">
                <column class="h-100">
                    <country-card :country="topThree[1]" class="podium-silver shadow-sm"
                        style="--podium-place:2"></country-card>
                </column>
                <column class="h-100">
                    <country-card :country="topThree[0]" class="podium-gold shadow-sm"
                        style="--podium-place:1"></country-card>
                </column>
                <column class="h-100">
                    <country-card :country="topThree[2]" class="podium-bronze shadow-sm"
                        style="--podium-place:3"></country-card>
                </column>
            </row>
        </column>
        <column>
            <!-- List of remaining list (eventually up to 13 total) -->
            <div class="vertical-list">
                <country-card v-for="country in remaining" :country="country" :key="country.id" horizontal
                    class="shadow-sm"></country-card>
            </div>
        </column>

    </div>
</template>

<script>
import countryCard from '~/components/admin-dashboard/country-card.vue'
export default {
    name: "country-leaderboard",
    components: {
        countryCard
    },
    props: {
        countryData: {
            type: Array,
            required: true
        }
    },
    computed: {
        topThree() {
            return this.countryData.slice(0, 3)
        },
        remaining() {
            return this.countryData.slice(3)
        }
    }
}
</script>
