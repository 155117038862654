import {factory} from "../utils/ClassLoader"
let LookupItem = class extends factory.classes.AttributeBag {
	constructor(attributes)  {
	  super(attributes);
	  return this;
	}
	setLookup(lookup) {
		this.lookup = lookup;
	}
	toJSON() {
		var object = {...this}
		delete object.lookup;
		return object;
	}
}

export {
	LookupItem
}