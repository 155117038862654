<template>
  <div>
    <card head :title="$t('admin.patients.overview.title', { code: patient.code })" class="mb-4" foot>
      <row class="mb-4" v-if="!loadingPatientOverview">
        <column>
          <router-link :to="{ name: 'admin.personal_details', params: { patient_id: patient.id } }"
            class="issue-link card p-1 ps-3 d-flex align-items-center text-decoration-none">
            <div class="card-body">
              <fa icon="user" /> {{ $t('admin.patients.overview.personal_details.title', { code: patient.code }) }}
            </div>
          </router-link>
        </column>
        <column>
          <router-link :to="{ name: 'admin.consent', params: { patient_id: patient.id } }"
            :class="['issue-link card p-1 ps-3 d-flex align-items-center text-decoration-none', { 'bg-danger': !patient.isConsented }]">
            <div class="card-body">
              <fa :icon="patient.isConsented ? 'check' : 'times'" /> {{ $t('admin.patients.overview.consent.title', {
                code:
                  patient.code
              }) }}
            </div>
          </router-link>
        </column>
      </row>
      <alert type="danger" v-if="!loadingPatientOverview && patient && !patient.isConsented"
        class="d-flex align-items-middle">
        <fa icon="circle-exclamation" class="me-4" size="2x"></fa>
        <span class="fs-5">{{ $t('questionnaires.consent.is_not_consented') }}</span>
      </alert>

      <card :body="false" class="mb-2"
        v-if="!loading && patient && patient.issues && !loadingPatientOverview && scoring.currentPatientIssueID">
        <scope :issue="patientIssueFromID(scoring.currentPatientIssueID, patient.issues)" v-slot="{ issue }">
          <div class="card-header">
            <row class="align-items-center">
              <column sizes="auto">
                <btn outline @click.native="scoring.currentPatientIssueID = prevPatientIssueId(patient.issues)"
                  :disabled="!prevPatientIssueId(patient.issues)">
                  <fa icon="chevron-left" />
                </btn>
              </column>
              <column>
                <i18n path="admin.patients.issue_overview.title" class="mb-0 flex-grow-1" tag="h6">
                  <template #code><span class="patient-code ms-3"> {{ patient.code }}</span></template>
                  <template #issue><span class="ms-3">{{ $t('issues.' + issue.issue.name + '.title')
                      }}</span></template>
                  <template #due_at><span class="text-sm">{{ $d(new Date(issue.due_at), 'numeric', 'en-GB')
                      }}</span></template>
                </i18n>
              </column>
              <column sizes="auto">
                <btn outline @click.native="scoring.currentPatientIssueID = nextPatientIssueId(patient.issues)"
                  :disabled="!nextPatientIssueId(patient.issues)">
                  <fa icon="chevron-right" />
                </btn>

              </column>
            </row>
          </div>
          <template>
            <row class="g-1 m-1" v-if="issue.scores && Object.keys(issue.scores).length">
              <template v-for="(score, key) in issue.scores">
                <column v-if="key != 'tsqm9'" :key="'column_' + key" :sizes="{ sm: 6, md: 4, lg: 3 }">
                  <router-link
                    :to="{ name: routePrefix + key, params: { patient_id: patient.id, issue: issue.issue_id } }"
                    class="text-decoration-none">
                    <card class="h-100 text-center" :body="false">
                      <div class="card-body d-flex flex-column">
                        <h5 v-text="$t('questionnaires.' + key + '.title')" class="flex-grow-1 mb-4"></h5>
                        <score-speedo v-bind="scoreSpeedoProps(score, key)" class="mt-auto"></score-speedo>
                      </div>
                    </card>
                  </router-link>
                </column>
              </template>
              <column v-if="issue.scores.tsqm9" :sizes="{ sm: 12, md: 9, lg: 9 }">
                <router-link
                  :to="{ name: routePrefix + 'tsqm9', params: { patient_id: patient.id, issue: issue.issue_id } }"
                  class="text-decoration-none">
                  <card :title="$t('questionnaires.tsqm9.title')" class="text-center" :body="false">
                    <div class="card-body">
                      <h5 v-text="$t('questionnaires.tsqm9.title')" class="flex-grow-1 mb-4"></h5>
                      <row>
                        <column :sizes="{ sm: 4 }">
                          <div class="d-flex flex-column">
                            <h5 class="text-center" v-t="'questionnaires.tsqm9.effectiveness'"></h5>
                            <score-speedo
                              v-bind="scoreSpeedoProps(Math.round(issue.scores.tsqm9.effectiveness), 'tsqm9')"></score-speedo>
                          </div>
                        </column>
                        <column :sizes="{ sm: 4 }">
                          <div class="d-flex flex-column">
                            <h5 class="text-center" v-t="'questionnaires.tsqm9.convenience'"></h5>
                            <score-speedo
                              v-bind="scoreSpeedoProps(Math.round(issue.scores.tsqm9.convenience), 'tsqm9')"></score-speedo>
                          </div>
                        </column>
                        <column :sizes="{ sm: 4 }">
                          <div class="d-flex flex-column">
                            <h5 class="text-center" v-t="'questionnaires.tsqm9.satisfaction'"></h5>
                            <score-speedo
                              v-bind="scoreSpeedoProps(Math.round(issue.scores.tsqm9.globalSatisfaction), 'tsqm9')"></score-speedo>
                          </div>
                        </column>
                      </row>
                    </div>
                  </card>
                </router-link>
              </column>
            </row>
          </template>
        </scope>
      </card>
      <template v-if="patient.issues">
        <card v-for="pIssue in patient.issues" :key="'patient-issue-nav-' + pIssue.issue.name" head :body="false"
          class="mb-2">
          <template #header>
            <router-link class="issue-link card-header p-1 ps-3 d-flex align-items-center text-decoration-none"
              :to="{ name: 'admin.patient_issue_overview', params: { patient_id: patient.id, issue: pIssue.issue_id } }"
              :class="[patientOverviewClass(pIssue)]" active-class="overview-active" exact key="patient_issue_overview">
              <i18n path="admin.patients.issue_overview.title" class="mb-0 flex-grow-1" tag="h6">
                <template #code><span class="patient-code ms-3"> {{ patient.code }}</span></template>
                <template #issue><span class="ms-3">{{ $t('issues.' + pIssue.issue.name + '.title') }}</span></template>
                <template #due_at><span class="text-sm">{{ $d(new Date(pIssue.due_at), 'numeric', 'en-GB')
                    }}</span></template>
              </i18n>
              <badge pill class="ms-auto" v-if="pIssue.notifications_sent > 0">
                <fa icon="envelope" class="ms-auto" />
                <span> {{ pIssue.notifications_sent }}</span>
              </badge>

              <donut-progress class="donut" :label="percentage_complete(pIssue)" :chart-data="chartData(pIssue)">
              </donut-progress>
            </router-link>
          </template>
          <list-group flush class="patient-issue-nav">
            <router-link class="questionnaire-link" v-for="pq in patientQuestionnaires(pIssue)" v-if="pq.questionnaire"
              :key="'lgi_' + pq.questionnaire.name"
              :to="{ name: routePrefix + pq.questionnaire.name, params: { patient_id: patient.id, issue: pIssue.issue_id } }"
              :class="[listGroupItemClass(pq)]" active-class="questionnaire-active" exact>
              <span class="questionnaire-title flex-grow-1" v-t="'questionnaires.' + pq.questionnaire.name + '.title'">
              </span>
              <span class="updated_at">
                <template v-if="pq.valid == 1 && pq.updated_at">
                  <small>{{ $t('admin.patients.issue_overview.questionnaire_updated_at', {
                    questionnaire_updated_at: $d(new
                      Date(pq.updated_at), 'numeric', 'en-GB')
                  }) }}</small>
                </template>
              </span>
            </router-link>
          </list-group>

        </card>
      </template>
      <card :title="$t('admin.notifications.title')" head :body="false" class="mb-2">
        <template #header-right>
          <badge pill class="me-2">
            <fa icon="envelope" class="me-1" />
            {{ hasNotifications }}
          </badge>
          <btn outline @click.native="showNotifications = !showNotifications">
            <fa icon="chevron-right" :transform="showNotifications ? 'rotate-270' : 'rotate-90'"
              style="transition:all 1s ease;" />
          </btn>
        </template>
        <collapse :show="showNotifications">
          <list-group v-if="patient && patient.user" :items="patient.user.notifications" flush>
            <template #item="{ item, k, value }">
              <list-group-item class="flex-column align-items-start">
                <div class="d-flex w-100 justify-content-between">
                  <h5 class="mb-1">{{ notificationType(item) }}</h5> <!-- Notification Type -->
                  <small>{{ $d(new Date(item.created_at), 'numeric', 'en-GB') }}</small>
                </div>
                <p>
                <ul class="list-inline">
                  <template v-for="(d, k) in item.data">
                    <badge :key="k + d" class="list-inline-item">{{ k }}: {{ d }}</badge>
                  </template>
                </ul>
                </p>
                <!-- <small v-if="item.read_at">Read</small>
              <small v-else class="text-muted">Unread</small> -->
              </list-group-item>
            </template>
            <list-group-item class="flex-column align-items-start"
              v-if="showNotifications && patient.user.notifications.length == 0">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{ $t('admin.notifications.no_notifications') }}</h5>
              </div>
            </list-group-item>
          </list-group>
        </collapse>
      </card>
      <!-- card for documents -->
      <card :title="$t('admin.patients.columns.documents')" head :body="false" class="mb-2">
        <template #header-right>
          <btn outline @click.native="verifyDocuments" class="me-2">
            <fa icon="check" />
            Verify
          </btn>
          <btn outline theme="danger" @click.native="markDocumentsInvalid">
            <fa icon="times" />
            Mark Invalid
          </btn>
        </template>
        <list-group-item v-for="(file, index) in patient.documents" :key="index" class="d-flex align-items-center p-2"
          :class="{ 'list-group-item-danger': file.invalidated_at }">
          <a @click="openDocument(file)" class="icon-link">
            <fa icon="file-alt" size="2x" class="me-2" />
          </a>
          <div class="flex-grow-1">
            <a @click="openDocument(file)" class="file-name mx-3">{{ file.name }}</a>
            <badge type="success" v-if="file.verified_at">
              <fa icon="check" />
              Verified
            </badge>
            <badge type="danger" v-else-if="file.invalidated_at">
              <fa icon="times" />
              Invalid
            </badge>
          </div>
          <!-- verified -->

          <!-- output size as kb -->
          <small class="mx-1">Uploaded: {{ $d(new Date(file.created_at), 'short', 'en-GB') }}</small>
          <small class="mx-1">Size: {{ formatFileSize(file.size) }}</small>
          <!-- admin verify button -->

          <btn outline theme="danger" @click.native="deleteFile(file)" size="sm" class="mx-1">
            <fa icon="trash" />
            Delete Document
          </btn>
        </list-group-item>
      </card>
      <template #footer-right>
        <!-- Add a delete patient button -->
        <btn v-if="isAdmin" outline @click.native="deletePatientWarning(patient.id)" class="me-2" theme="danger">
          <fa icon="trash" /> {{ $t('admin.patients.overview.delete.title') }}
        </btn>
      </template>
    </card>

    <!-- <router-view></router-view> -->
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import patientIssueMixin from '~/mixins/patient_issue'
import swal from 'sweetalert2'
import axios from 'axios'
export default {
  name: "patient-overview",
  metaInfo() {
    return { title: this.$t('admin.patients.overview.title', { code: this.patient.code }) }
  },
  middleware: ['auth', 'team'],
  mixins: [patientIssueMixin],
  data() {
    return {
      loading: false,
      showNotifications: false,
      scoring: {
        currentPatientIssueID: null,
      },
      params: {
        patient_id: this.$route.params.patient_id,
        include: ['consent', 'user.latestActivity', 'user.notifications', 'patientIssue.issue', 'patientIssue.patientQuestionnaire.questionnaire', 'documents'],
        issues_include: 'questionnaires,scores',
      }
    }
  },
  mounted() {
    var params = this.params
    this.loading = true
    this.getPatientOverview({ params })
      .then(() => {
        if (!this.scoring.currentPatientIssueID && this.latestIssue) {
          this.scoring.currentPatientIssueID = this.latestIssue.id
        }
        this.loading = false

      });
  },
  computed: {
    ...mapState('resources/patient', ['patient', 'loadingPatientOverview']),
    ...mapGetters('auth', ['isTeam', 'isAdmin']),
    routePrefix() { return this.isTeam ? 'admin.' : '' },
    latestIssue() {
      if (this.patient && this.patient.issues)
        return this.patient.issues[this.patient.issues.length - 1]
      else return null
    },
    scores() {
      return this.patient.issues.map((issue) => {
        return { patient_issue_id: issue.id, ...issue.scores }
      })
    },

    hasNotifications() {
      if (this.loadingPatientOverview) return false
      return this.patient && this.patient.user && this.patient.user.notifications && this.patient.user.notifications.length
    },
  },
  methods: {
    ...mapActions('resources/patient', ['getPatientOverview', 'deletePatient']),
    patientOverviewClass(issue) {
      return [
        {
          'issue-valid': issue.valid == 'valid',
          'issue-invalid': issue.valid == 'started'
        }
      ]
    },
    listGroupItemClass(pq) {

      return [
        'd-flex list-group-item link-group-item-action',
        {
          'questionnaire-valid': pq.valid == 1,
          'questionnaire-invalid': pq.valid == 0
        }
      ]
    },
    // scoreSpeedoProps(score, key) {
    //   if (key == 'pest') return { score: { score, min: 0, max: 5 }, domain: [0, 1, 2, 3, 4, 5], colours: ['#04b21b', '#4da518', '#a2b202', '#bc7200', '#af0000'], ticks: 5 }
    //   if (key == 'psaid') return { score: { score: score.full, min: 0, max: 10 }, domain: [0, 3, 10], colours: ['#198754', '#ffc107', '#dc3545'], ticks: 40 }
    //   if (key == 'mdhaq') return { score: { score, min: 0, max: 3 }, domain: [0, 0.625, 3], colours: ['#198754', '#ffc107', '#dc3545'], ticks: 30 }
    //   if (key == 'tsqm9') return { score: { score, min: 0, max: 100 }, domain: [0, 50, 100], colours: ['#dc3545', '#ffc107', '#198754'], ticks: 40 }
    //   if (key == 'facit') return { score: { score, min: 0, max: 52 }, domain: [0, 26, 52], colours: ['#dc3545', '#ffc107', '#198754'], ticks: 52 }
    //   if (key == 'phq9') return { score: { score, min: 0, max: 27 }, domain: [0, 5, 10, 15, 20, 27], colours: ['#04b21b', '#4da518', '#a2b202', '#bc7200', '#af0000'], ticks: 5 }
    // },
    notificationType(notification) {
      var suffix = ''
      if (notification.data && notification.data.reminder && notification.data.reminder > 0) suffix = ' - Reminder'
      return notification.type + suffix
    },
    deletePatientWarning(patient_id) {
      // show swal warning
      swal.fire({
        title: this.$t('admin.patients.overview.delete.title'),
        text: this.$t('admin.patients.overview.delete.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('admin.patients.overview.delete.confirm'),
        cancelButtonText: this.$t('admin.patients.overview.delete.cancel'),
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePatient({ params: { patient_id } }).then(() => {
            this.$router.push({ name: 'admin.patients' })
          })
        }
      })
    },
    openDocument(file) {
      var fullUrl = this.$root.config.api_url + '/' + file.path;
      window.open(fullUrl, '_blank');
    },
    formatFileSize(bytes) {
      if (bytes >= 1000000000) {
        bytes = (bytes / 1000000000).toFixed(2) + ' GB';
      } else if (bytes >= 1000000) {
        bytes = (bytes / 1000000).toFixed(2) + ' MB';
      } else if (bytes >= 1000) {
        bytes = (bytes / 1000).toFixed(2) + ' KB';
      } else if (bytes > 1) {
        bytes = bytes + ' bytes';
      } else if (bytes == 1) {
        bytes = bytes + ' byte';
      } else {
        bytes = '';
      }
      return bytes;
    },
    async deleteFile(file) {
      // Implement file deletion logic, update `fileUploads` accordingly
      const index = this.patient.documents.findIndex(f => f.name === file.name);
      if (index !== -1) {
        this.patient.documents.splice(index, 1);
      }
      await axios.delete(this.$root.config.api_url + '/delete/document', {
        params: {
          path: file.path,
        },

      })
        .then(response => {
          this.patient.documents = response.data.documents
          // this.updateQuestionnaireExtraData({ name: this.name, data: { documents: response.data.documents } })
        })

    },
    markDocumentsInvalid() {
      // mark all documents as invalid
      axios.post(this.$root.config.api_url + '/invalidate/documents', {
        patient_id: this.patient.id,
      })
        .then(response => {
          this.patient.documents = response.data.documents
          // this.updateQuestionnaireExtraData({ name: this.name, data: { documents: response.data.documents } })
        })
    },
    verifyDocuments() {
      // verify all documents
      axios.post(this.$root.config.api_url + '/verify/documents', {
        patient_id: this.patient.id,
      })
        .then(response => {
          this.patient.documents = response.data.documents
          // this.updateQuestionnaireExtraData({ name: this.name, data: { documents: response.data.documents } })
        })
    }
  },
  watch: {
    loadingPatientOverview() {
      if (!this.loadingPatientOverview) {
        if (!this.scoring.currentPatientIssueID && this.latestIssue) {
          this.scoring.currentPatientIssueID = this.latestIssue.id
        }
      }
    }
  }
}

</script>
