export default {
  computed: {
    visitDate(){
      return new Date()
    }
  },
  methods: {

  },

}
