<template>
    <div>
        <dropdown justified btn-outline :items="other.countriesArr" v-model="filtersCopy['patients.country_id']"
            value-name="id" @input="filterCountryId" label-name="name" multiple>
            <template #list-item-prepend="{ item }">
                <svg class="flag-img rounded-left img-fluid ms-n1 me-1" style="width:1.6rem">
                    <use :href="'/images/home/flags-sprite.svg#' + item.countryCode.toLowerCase()"></use>
                </svg>

            </template>
        </dropdown>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "dashboard-filters",
    props: {
        other: {
            type: Object,
        }
    },
    data() {
        return {
            filtersCopy: {

                'patients.country_id': [],


            },
        }
    },
    computed: {
        ...mapState('resources/admin', ["dashboard"]),
        countries() {
            return this.dashboard.data.countryData;
        },
    },
    methods: {
        filterCountryId(arr) {
            this.countries.forEach((country) => {
                if (arr.includes(country.id)) {
                    country.active = true;
                } else {
                    country.active = false;
                }
            });

        }
    }
}
</script>

<style lang="scss" scoped></style>