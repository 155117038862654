import axios from 'axios'
import store from '~/store'
// state
export const state = {
  public: {
    count: 0
  },
  patients: [],
  patient: {},
  patient_issues: [],
  patient_issue: {},
  filter_data: {},
  pagination: {},
  loadingPatients: false,
  loadingPatientOverview: false,
  patientRequestController: null,

}

// getters
export const getters = {}

// mutations
export const mutations = {
  GET_PATIENTS(state, resp) {
    state.patients = resp.data.data
    state.pagination = resp.data.meta
    state.loadingPatients = false
  },
  GET_PATIENT_OVERVIEW(state, resp) {
    state.patient = resp.data.patient
    state.loadingPatientOverview = false
  },
  GET_PATIENT_FILTER_DATA(state, resp) {
    state.filter_data = resp
  },
  UPDATE_PATIENT(state, data) {
    state.patient = data.patient
  },
  GET_PATIENT_ISSUES(state, resp) {
    state.patient_issues = resp.data
  },
  GET_PATIENT_ISSUE(state, resp) {
    state.patient_issue = resp.patient_issue
  },
  SAVE_PATIENT_ISSUE_ENTRY_DATE() {
    state.patient_issue
  },
  GET_PUBLIC_PATIENT_COUNT(state, resp) {
    state.public.count = resp
  },
  setFetchPatientsController(state, controller) {
    // If there's an existing controller, abort the request
    if (state.patientRequestController) {
      state.patientRequestController.abort();
    }
    // Update the controller in state
    state.patientRequestController = controller;
  },
}

// actions
export const actions = {
  getPatients({ commit }, request) {
    state.loadingPatients = true
    const controller = new AbortController();
    const { signal } = controller;
    request.signal = signal;
    commit('setFetchPatientsController', controller);
    return axios.get('patients', request)
      .then((resp) => {
        commit('GET_PATIENTS', resp)
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Request aborted', error);
        }

      })

  },
  getPatientOverview({ commit }, request) {
    state.loadingPatientOverview = true
    state.patient = {}
    return axios.get('patients/' + request.params.patient_id, request)
      .then((resp) => {
        commit('GET_PATIENT_OVERVIEW', resp)
      })
  },
  updatePatient({ commit }, patient) {
    commit('UPDATE_PATIENT', { patient })
  },
  getPatientIssues({ commit }, request) {
    var isTeam = store.getters['auth/isTeam'];
    var url = "";
    if (isTeam) {
      url = 'patients/' + request.params.patient_id + '/issue/' + request.params.issue;
    } else {
      url = 'patient/issue/';
    }
    return axios.get(url, request)
      .then((resp) => {
        commit('GET_PATIENT_ISSUES', resp.data)
      })
  },
  getPatientFilterData({ commit }, request) {
    return axios.get('patients/filters', request)
      .then((resp) => {
        commit('GET_PATIENT_FILTER_DATA', resp.data)
      })
  },
  assignPatientIssue({ commit }, request) {
    axios.post('patient/' + request.params.patient_id + '/issue', request)
      .then((resp) => {
        commit('GET_PATIENT_ISSUES', resp.data)
      })
  },
  getPatientIssue({ commit }, request) {
    var isTeam = store.getters['auth/isTeam'];
    var url = "";
    if (isTeam) {
      url = 'patients/' + request.params.patient_id + '/issue/' + request.params.issue;
    } else {
      url = 'patient/issue/';
    }
    return axios.get(url, { params: request.params })
      .then((resp) => {
        commit('GET_PATIENT_ISSUE', resp.data)
      })
  },
  savePatientIssueEntryDate({ commit, state }, request) {
    return axios.put('patients/' + request.params.patient_id + '/issue/' + request.params.issue, request.params)
      .then((resp) => {
        resp.request = request
      });
  },
  getPublicPatientCount({ commit }, request) {
    return axios.get('public/count', request)
      .then((resp) => {
        commit('GET_PUBLIC_PATIENT_COUNT', resp.data)

      });
  },
  deletePatient({ commit }, request) {
    return axios.delete('patients/' + request.params.patient_id, request)
      .then((resp) => {
        resp.request = request

      });
  },
}

export const namespaced = true;
