export default {
  hardCopy(record) {
    return JSON.parse( JSON.stringify( record ))
  },
  isObjectOrArray(variable) {
    return  variable instanceof Array || variable instanceof Object;
  },
  evalComparator(value1, comparator, value2) {
    switch(comparator) {
      case '=':
        return value1==value2;
      case '<':
        return value1<value2;
      case '>':
        return value1>value2;
      case '!=':
      case '<>':
        return value1!=value2;
      case '>=':
        return value1>=value2;
      case '<=':
        return value1<=value2;
    }
  },
  dotConstruct(reference, path, constructor) {
    this.dot(reference, path, function(value, parent, index, fullPath) {
        if (value!=null) {
          parent[index] = new constructor(value); 
        }
    })
  },
  dotGet(reference, path) {
    var result;
    this.dot(reference, path, function (value, parent, index, fullPath, options) {
      if (result===undefined) {
        if (options.hasMultiCall)  {
          result = [value];
        } else {
          result = value;
        }
      } else {
        result.push(value);
      }
    })
    return result; 
  },
  dotSet(reference, path, newValue) {
    this.dot(reference, path, function (value, parent, index, fullPath, options) {
      parent[index] = newValue;
    })
  },
  dot(reference, path, callback, options) {
      var parts = path.split(".");
      if (options===undefined) options = {};
      this.dotRecurse(reference, parts, callback, "", options);
  },
  dotRecurse(reference, parts, callback, fullPath, options) {
      if (fullPath==undefined) fullPath = "";
      var head = parts[0];
      var runCallBack = false;
      if (head[head.length-1]=="+") {
        parts[parts.length] = head;
        runCallBack = true
      }
      var tail = parts.slice(1);
      var runCallBack = runCallBack || tail.length == 0;

       if (head=="*"||head=="*+") {
         options.hasMultiCall = true;
           this.dotWildCard(reference, head, tail, callback, fullPath, runCallBack, options)
       } 
       else {
           this.dotPath(reference, head, tail, callback, fullPath, runCallBack, options)
       }
  },
  dotPath(reference, nextPart, parts, callback, fullPath, runCallBack, options) {
    if (fullPath=="") {
      var subPath = nextPart;
    } else {
      var subPath = fullPath + "."+ nextPart;
    }
       if (runCallBack) {
         callback(reference[nextPart], reference, nextPart, subPath, options);
       }
       if (parts.length>0 && this.isObjectOrArray(reference[nextPart])) {
            this.dotRecurse(reference[nextPart], parts, callback, subPath, options);
       }
  },
  dotWildCard(reference, nextPart, parts, callback, fullPath, runCallBack, options) {
    for(var index in reference) {
           this.dotPath(reference, index, parts, callback, fullPath, runCallBack, options)
       }
  },
  download(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}

