import Validator from 'validatorjs'
import { mapActions, mapGetters, mapState } from 'vuex'
import { has } from 'lodash-es'
export default {
  data() {
    return {
      isValid: false,
    }
  },
  computed: {
    ...mapState('resources/questionnaires', ['errors', 'questionnaireData']),
    ...mapGetters('resources/questionnaires', ['getQuestionnaireValidation', 'getQuestionnaireErrors']),
    validation() {
      if (this.questionnaireLoaded) {
        return this.getQuestionnaireValidation(this.name)
      }
    },
  },
  methods: {
    ...mapActions('resources/questionnaires', ['setErrors']),
    filterRules(diArray) {
      var filteredRules = {}
      var ruleKeys = Object.keys(this.validation.rules)
      for (var i = ruleKeys.length - 1; i >= 0; i--) {
        var ruleKey = ruleKeys[i]
        var diName = ruleKey.split('.').pop()
        if (diArray.includes(diName)) { filteredRules[ruleKey] = this.validation.rules[ruleKey] }
      }
      return filteredRules
    },
    validate_questionnaire() {
      var records = { ...this.records, extraData: this.questionnaire.extraData }
      var validator = new Validator(records, this.validation.rules, this.validation.messages);
      var errors = {}
      var name = this.questionnaire.name
      var wasValidated = true
      if (validator.fails()) {
        errors = validator.errors.errors
        this.isValid = false
      } else {
        this.isValid = true
      }
      this.setErrors({ name, errors, wasValidated })
    },
    validate_dataitem(diArray, index) {
      var records = { ...this.records, extraData: this.questionnaire.extraData }
      var rules = this.filterRules(diArray)
      var validator = new Validator(records, rules, this.validation.messages);
      var errors = {}
      var name = this.questionnaire.name
      if (validator.fails()) {
        errors = validator.errors.errors
        this.setErrors({ name, errors, merge: true })
      } else {
        for (var i = diArray.length - 1; i >= 0; i--) {
          var diName = diArray[i]
          var di = this.dataitems[diName]
          var diKey = diName
          if (di.section) diKey = di.section + '.' + index + '.' + diName
          errors[diKey] = []
        }
        this.setErrors({ name, errors, merge: true })
      }
    },
    // page_rules: function() {
    //   var rules = {};
    //   var baseRules = this.validationRules
    //   for (var key in baseRules) {
    //     if (this.dataitems.hasOwnProperty(key) && this.dataitems[key].section == null) rules[key] = baseRules[key]
    //   }
    //   return rules;
    // },
    // collection_rules: function() {
    //   var rules = {};
    //   var baseRules = this.validationRules
    //   for (var key in baseRules) {
    //     if (this.dataitems.hasOwnProperty(key) && this.dataitems[key].section !== null) rules[key] = baseRules[key]
    //   }
    //   return rules;
    // },
    hasError: function (di_name) { if (this.errors[di_name] && this.errors[di_name].length) return true; return false; },
    firstError: function (di_name) { if (this.errors.hasOwnProperty(di_name)) { return this.errors[di_name]; } },
    diErrors(di_name, index = null) {
      var di = this.dataitems[di_name]
      if (di.section) {
        var path = di.section + '.' + index + '.' + di.name
        if (has(this.errors, path))
          return this.errors[path]
      }
      if (has(this.errors, di.name)) return this.errors[di.name]
    },
  }

}
