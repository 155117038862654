<template>
  <div>
  </div>
</template>

<script>

export default {
  middleware: ['auth', 'check-registration'],
  name: "registration_check",
}
</script>
