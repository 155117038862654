<template>
  <questionnaire :title="$t('questionnaires.tsqm9.title')" type="multi-page" :pageInfo="pageInfo"
    :nav-bar-props="{ vertical: $mq != 'xs', fill: false, pills: true, class: ['col-auto border-0', { 'd-none': !shownGuidance }] }"
    tab-container-class="row g-1" :tab-content-props="{ id: 'tsqm-9-tab-content', class: 'col' }">
    <template #intro>
      <alert v-if="!shownGuidance" type="info" class="fs-5 pb-md-5">
        <p v-html="$t('questionnaires.tsqm9.guidance')"></p>
        <btn block outline @click.native="shownGuidance = true"
          class="mt-4 d-flex align-items-center justify-content-center">
          <fa icon="play" size="2x" class="me-2"></fa>
          <h5 class="mb-0" v-t="'questionnaires.questionnaire.next'"></h5>
        </btn>
      </alert>
      <alert v-else type="info" class="fs-5 pb-md-5">
        <p v-html="$t('questionnaires.tsqm9.introduction_p1')"></p>
        <p v-html="$t('questionnaires.tsqm9.introduction_p2')"></p>
        <p v-html="$t('questionnaires.tsqm9.introduction_p3')"></p>
      </alert>
      <card class="text-center">
        <h2 v-t="'public.home.special_thanks.title'"></h2>
        <template v-for="p in $t('public.home.special_thanks.list')">
          <a :href="p.url" class="m-2" v-if="p.title == 'IQVIA'"><img :src="p.img" :alt="p.title" class="m-2"
              :width="p.width"></a></template>
      </card>
    </template>
    <template #q1>
      <form-group :error="errors.tsqm_1" :di="dataitems.tsqm_1" v-slot="{ di }" class="mb-4" label-class="fs-5 mb-4">
        <btn-group outline :readonly="isSubmitted" vertical :di="di" v-model="records[di.name]" justified v-clear>
        </btn-group>
      </form-group>
    </template>
    <template #q2>
      <form-group :error="errors.tsqm_2" :di="dataitems.tsqm_2" v-slot="{ di }" class="mb-4" label-class="fs-5 mb-4">
        <btn-group outline :readonly="isSubmitted" vertical :di="di" v-model="records[di.name]" justified v-clear>
        </btn-group>
      </form-group>
    </template>
    <template #q3>
      <form-group :error="errors.tsqm_3" :di="dataitems.tsqm_3" v-slot="{ di }" class="mb-4" label-class="fs-5 mb-4">
        <btn-group outline :readonly="isSubmitted" vertical :di="di" v-model="records[di.name]" justified v-clear>
        </btn-group>
      </form-group>
    </template>
    <template #q4>
      <form-group :error="errors.tsqm_4" :di="dataitems.tsqm_4" v-slot="{ di }" class="mb-4" label-class="fs-5 mb-4">
        <btn-group outline :readonly="isSubmitted" vertical :di="di" v-model="records[di.name]" justified v-clear>
        </btn-group>
      </form-group>
    </template>
    <template #q5>
      <form-group :error="errors.tsqm_5" :di="dataitems.tsqm_5" v-slot="{ di }" class="mb-4" label-class="fs-5 mb-4">
        <btn-group outline :readonly="isSubmitted" vertical :di="di" v-model="records[di.name]" justified v-clear>
        </btn-group>
      </form-group>
    </template>
    <template #q6>
      <form-group :error="errors.tsqm_6" :di="dataitems.tsqm_6" v-slot="{ di }" class="mb-4" label-class="fs-5 mb-4">
        <btn-group outline :readonly="isSubmitted" vertical :di="di" v-model="records[di.name]" justified v-clear>
        </btn-group>
      </form-group>
    </template>
    <template #q7>
      <form-group :error="errors.tsqm_7" :di="dataitems.tsqm_7" v-slot="{ di }" class="mb-4" label-class="fs-5 mb-4">
        <btn-group outline :readonly="isSubmitted" vertical :di="di" v-model="records[di.name]" justified v-clear>
        </btn-group>
      </form-group>
    </template>
    <template #q8>
      <form-group :error="errors.tsqm_8" :di="dataitems.tsqm_8" v-slot="{ di }" class="mb-4" label-class="fs-5 mb-4">
        <btn-group outline :readonly="isSubmitted" vertical :di="di" v-model="records[di.name]" justified v-clear>
        </btn-group>
      </form-group>
    </template>
    <template #q9>
      <form-group :error="errors.tsqm_9" :di="dataitems.tsqm_9" v-slot="{ di }" class="mb-4" label-class="fs-5 mb-4">
        <btn-group outline :readonly="isSubmitted" vertical :di="di" v-model="records[di.name]" justified v-clear>
        </btn-group>
      </form-group>
    </template>
    <template #after-pages>
      <card class="mt-2" v-if="allScored && isSubmitted">
        <row>
          <column :sizes="{ lg: 4 }">
            <h4 class="display-6 text-center" v-t="'questionnaires.tsqm9.effectiveness'"></h4>
            <score-speedo :score="{ score: Math.round(effectiveness), min: 0, max: 100 }" :domain="[0, 50, 100]"
              :colours="['#dc3545', '#ffc107', '#198754']" :ticks="40"></score-speedo>
          </column>
          <column :sizes="{ lg: 4 }">
            <h4 class="display-6 text-center" v-t="'questionnaires.tsqm9.convenience'"></h4>
            <score-speedo :score="{ score: Math.round(convenience), min: 0, max: 100 }" :domain="[0, 50, 100]"
              :colours="['#dc3545', '#ffc107', '#198754']" :ticks="40"></score-speedo>
          </column>
          <column :sizes="{ lg: 4 }">
            <h4 class="display-6 text-center" v-t="'questionnaires.tsqm9.satisfaction'"></h4>
            <score-speedo :score="{ score: Math.round(globalSatisfaction), min: 0, max: 100 }" :domain="[0, 50, 100]"
              :colours="['#dc3545', '#ffc107', '#198754']" :ticks="40"></score-speedo>
          </column>
        </row>
      </card>
      <div class="text-center">
        <small class="text-muted" v-t="'questionnaires.tsqm9.copyright'"></small>
      </div>
    </template>
  </questionnaire>
</template>
<script>
import questionnaire from "~/mixins/questionnaire.js"
export default {
  name: 'tsqm9',
  mixins: [questionnaire],
  data() {
    return {
      shownGuidance: false,
    }
  },
  computed: {
    pageInfo() {
      var pageinfo = [{ key: 'intro' }]
      for (var i = 1; i < 10; i++) {
        pageinfo.push({ key: 'q' + i })
      }
      return pageinfo
    },
    effectiveness() {
      var count = !!this.records.tsqm_1 + !!this.records.tsqm_2 + !!this.records.tsqm_3;
      var total = (new Number(this.records.tsqm_1)) + (new Number(this.records.tsqm_2)) + (new Number(this.records.tsqm_3))
      //  return {count, total}
      if (count == 2) {
        return ((total - 2) / 12) * 100;
      } else if (count == 3) {
        return ((total - 3) / 18) * 100;
      }
      return null;
    },
    convenience() {
      var count = !!this.records.tsqm_4 + !!this.records.tsqm_5 + !!this.records.tsqm_6;
      var total = (new Number(this.records.tsqm_4)) + (new Number(this.records.tsqm_5)) + (new Number(this.records.tsqm_6))
      // return {count, total}
      if (count == 2) {
        return ((total - 2) / 12) * 100;
      } else if (count == 3) {
        return ((total - 3) / 18) * 100;
      }
      return null;
    },
    globalSatisfaction() {
      var count = !!this.records.tsqm_7 + !!this.records.tsqm_8 + !!this.records.tsqm_9;
      var total = (new Number(this.records.tsqm_7)) + (new Number(this.records.tsqm_8)) + (new Number(this.records.tsqm_9))
      // return {count, total}
      if (count == 2) {
        if (!this.records.tsqm_14) {
          return ((total - 2) / 8) * 100;
        } else {
          return ((total - 2) / 10) * 100;
        }
      } else if (count == 3) {
        return ((total - 3) / 14) * 100;
      }
      return null;
    },
    score() {
      return {
        effectiveness: this.effectiveness,
        convenience: this.convenience,
        globalSatisfaction: this.globalSatisfaction
      }
    },
    allScored() {
      return this.effectiveness !== null && this.convenience !== null && this.globalSatisfaction !== null;
    },
    isSubmitted() {
      return this.records.valid == 1
    }
  },
  mounted() {
    if (this.records.id) {
      this.shownGuidance = true
    }
  },
  methods: {

  }
}

</script>
<style lang="scss">
#tsqm-9-tab-content.tab-content>.tab-pane {
  min-height: 100%;
  transition: all 0.6s ease;
  opacity: 0;

  &.show {
    opacity: 1;
  }
}
</style>