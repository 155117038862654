<template>
  <div>
    <table class="table table-bordered" v-if="schedule">
      <thead>
        <tr>
          <th>
            Subject_id
          </th>
          <th v-for="booker in timepoints">
            {{ booker.name }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(subjectGrouping, index) in bookings">
          <td class="cell">
            <div class="text-justify">
              {{ index }}
            </div>
          </td>
          <td v-for="booker in timepoints">
            <span v-for="bk in bookersInTimepoint(booker.sortKey)">
              <fa icon="calendar" size="2x" v-if="subjectGrouping[bk.sortKey]"
                :title="cellText(subjectGrouping[bk.sortKey])"
                :class="[iconClass(subjectGrouping[bk.sortKey]), getColourClass(subjectGrouping[bk.sortKey])]"></fa>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
// import * as actions from '../../vuex/actions.js'
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
  name: 'schedule',
  middleware: ['auth', 'team'],
  mounted() {
    this.getScheduleData();
  },
  computed: {
    ...mapGetters("resources/schedule", ["schedule"]),
    now() {
      return window.moment().format("YYYY-MM-DD")
    },
    bookers() {
      return this.schedule.bookers
    },
    bookings() {
      return this.schedule.bookings
    },
    timepoints() {
      if (this.bookers)
        return this.bookers.filter((booker) => {
          return !booker.sortKey.includes('reminder');
        })
    },
  },
  methods: {
    ...mapActions("resources/schedule", ["getScheduleData"]),
    getColourClass(booking) {
      if (!booking) return false
      if (this.inPast(booking)) {
        if (booking.alreadyDone) {
          return "text-success";
        }
        else if (!booking.invalid) {
          return "text-danger";
        }
        else {
          return "text-info";
        }
      }
      else if (this.inDate(booking)) {
        if (booking.alreadyDone) {
          return "text-success";
        }
        else if (!booking.invalid) {
          return "text-warning";
        }
        else {
          return "text-info";
        }
      }
      else if (this.inFuture(booking)) {
        if (!booking.invalid) {
          return "text-warning";
        }
        else {
          return "text-info";
        }
      }
    },
    iconClass(booking) {
      if (booking.alreadyDone) {
        return "calendar-check";
      }
      else if (this.inDate(booking) && !booking.invalid) {
        return "calendar";
      }
      else if (this.inFuture(booking) && !booking.invalid) {
        return "calendar-plus";
      }
      else if (this.inPast(booking) && !booking.invalid) {
        return "calendar-times";
      }

    },
    cellText(booking) {
      if (booking)
        if (booking.alreadyDone) {
          return "sent on " + this.formattedDate(booking.dateDone.date);
        }
        else if (this.inDate(booking) && !booking.invalid) {
          return "due now " + "(" + this.formattedDate(booking.startDate) + " - " + this.formattedDate(booking.endDate) + ")";
        }
        else if (this.inFuture(booking) && !booking.invalid) {
          return "due " + this.formattedDate(booking.startDate)
        }
        else if (this.inPast(booking) && !booking.invalid) {
          return "timeframe expired on " + this.formattedDate(booking.endDate);;
        }
    },
    inPast(booking) {
      if (this.now > booking.endDate) {
        return true
      } else {
        return false
      }
    },
    inFuture(booking) {
      if (this.now < booking.startDate) {
        return true
      } else {
        return false
      }
    },
    inDate(booking) {
      if (this.now >= booking.startDate && (!booking.endDate || this.now <= booking.endDate)) {
        return true
      }
      else {
        return false
      }
    },
    bookersInTimepoint(key) {
      return this.bookers.filter((booker) => {
        return booker.sortKey.startsWith(key)
      })
    },
    formattedDate(date) {
      return window.moment(date).format("DD/MM/YYYY")
    }
  },
}
</script>
<style scoped>
table.table {
  /* background: white; */
}

.cell {
  margin: -8px;
  min-height: 40px;
}

.no-padding {
  /*padding: 0px !important;*/
}
</style>
