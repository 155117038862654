<template>
    <svg :class="['progress-chart', { horizontal }]" width="100%" height="100%">
        <!-- {{ period.recruitment_count }} / {{ period.target }} -->
        <rect x="0" y="0" class="bg-rect" />
        <rect x="0" y="0" :width="period.recruitment_count / period.target * 100 + '%'" class="recruitment-rect" />
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle">
            {{ period.recruitment_count }} / {{ period.target }}
        </text>
        <!-- show dotted vertical line for today -->
        <line v-if="isLatestPeriod" :x1="xPosToday" :x2="xPosToday" y1="0" y2="100%" class="today-line" />
    </svg>
</template>

<script>
// import necessary d3 functions for plotting dates on the x-axis
import { scaleTime, scaleLinear, axisBottom, timeFormat } from 'd3';

export default {
    name: 'country-recruitment-period',
    props: {
        country: {
            type: Object,
            required: true
        },
        period: {
            type: Object,
            default: () => ({})
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        periodNumber() {
            // make a number from the period string
            return parseInt(this.period.period);
        },
        isLatestPeriod() {
            return this.periodNumber === parseInt(this.country.recruitmentData[this.country.recruitmentData.length - 1].period);
        },
        periodStartDate() {
            var studyStartDate = new Date(this.country.study_start_date);
            if (this.periodNumber === 0) {
                return studyStartDate;
            }
            //each period lasts 6 Months so add 6 months for each period
            return new Date(studyStartDate.setMonth(studyStartDate.getMonth() + (6 * this.periodNumber)));
        },
        periodEndDate() {
            // six months from the start date
            var endDate = new Date(this.periodStartDate);
            return new Date(endDate.setMonth(endDate.getMonth() + 6));
        },
        xScale() {
            return scaleTime()
                .domain([this.periodStartDate, this.periodEndDate])
                .range([0, 100]);
        },
        xPosToday() {
            if (this.isLatestPeriod) {
                return this.xScale(new Date()) + '%';
            }
            return this.xScale(new Date()) + '%';
        }

    }
}
</script>
