import Vue from 'vue'
import Vuex from 'vuex'
import { resolveImport } from '../helpers/module-importer.js'

Vue.use(Vuex)

// Load store modules dynamically.
const modulesImport = import.meta.globEager('./modules/**/*.js')
const modules = resolveImport(modulesImport, './modules/', /(^.\/)|(\.js$)/g)
export default new Vuex.Store({
  modules
})
