import { makeQuestionnaireRoutes } from './questionnaires.js'
import { resolveImport } from '../helpers/module-importer.js'
const pageImport = import.meta.glob('../pages/**/*.vue')
const pages = resolveImport(pageImport, '../pages/')
import notificationsPackageRoutes from '../../../vendor/formfactory/notifications/resources/js/router/routes.js'
function page(path) {
    return pages[path]
}
// Group routes from json
const routeJson = import.meta.globEager('./routes/*.json')
const routes = []
var allRoutes = []
const routeGroups = {}

for (const path in routeJson) {
    var routeGroup = routeJson[path]
    var newPath = path.replace('./routes/', '').replace(/(^.\/)|(\.json$)/g, '')
    allRoutes = allRoutes.concat(routeGroup.default)
    routeGroups[newPath] = routeGroup.default
}

const namedCollectionRoutes = {
    adminQuestionnaires: makeQuestionnaireRoutes({ name_prefix: 'admin.', filter: { type: 'Oneshot' }, middleware: ['auth', 'role:isTeam'] }),
    adminScheduledQuestionnaires: makeQuestionnaireRoutes({ name_prefix: 'admin.', filter: { type: 'Scheduled' }, middleware: ['auth', 'role:isTeam'] }),
    participantQuestionnaires: makeQuestionnaireRoutes({ middleware: ['auth', 'role:Participant'] }),
    notificationsPackageRoutes
}
var rootRoutes = allRoutes.filter((r) => r.root && r.root == true)
rootRoutes.forEach((r) => routes.push(findRoute(r.name)))



routeGroups.wrappers.forEach((wrapper) => {
    if (wrapper.name) wrapper = findRoute(wrapper.name)
    else if (wrapper.component_path) {
        wrapper.component = page(wrapper.component_path)
        if (!wrapper.meta) wrapper.meta = {}
        wrapper.meta.component_path = wrapper.component_path
    }
    if (wrapper.children) {
        if (typeof wrapper.children == 'object') wrapper.children = findRoutesFromArray(wrapper.children)
        if (typeof wrapper.children == 'string') wrapper.children = findRoutesFromNamedCollection(wrapper.children)
    }
    routes.push(wrapper)
})
// notificationsPackageRoutes.forEach((r) => routes.push(r))

export function findRoute(name) {
    if (name) {
        var routeConfig = allRoutes.find((r) => r.name == name)
        if (!routeConfig) {
            console.error("route name: " + name + " cannot be not found");
        }
        routeConfig.component = page(routeConfig.component_path)
        routeConfig.props = props
        if (!routeConfig.meta) routeConfig.meta = {}
        if (routeConfig.middleware) routeConfig.meta.middleware = routeConfig.middleware
        return routeConfig
    }
}
export function findRoutesFromArray(arr) {

    return arr.map((a) => {
        return findRoute(a)
    })
}
export function findRoutesFromNamedCollection(name) {
    return namedCollectionRoutes[name]
}
function props(route) {
    var name = route.meta && route.meta.name ? route.meta.name : route.name
    var middleware = route.middleware
    return { name, ...route.params, middleware }
}
export default routes