<template>
    <card :body="false">
        <div class="card-header d-flex">
            <h5 class="card-title">Data Explorer</h5>
            <btn-group class="ms-auto" size="sm">
                <!-- change the scene -->
                <btn v-for="s in scenes" :key="s.name" :true-value="s.name" v-model="scene" outline size="sm">{{ s.label }}
                </btn>
            </btn-group>
        </div>
        <!-- <simulation-component :nodes="nodes" :simulation-forces="simulationForces" @ticked="ticked"></simulation-component> -->
        <component :is="scene" :nodes="nodes" @forcesChanged="simulationForces = $event"></component>

        <chart :width="width" :height="height" :subjects="{ x: 'age', y: 'y' }" scaleX="linear" zoom-pan>
            <component :is="scene" in-chart :nodes="nodes" v-bind="{ countryColours }"></component>
            <!-- <g class="node-container">
                <template v-for="( node, n ) in  nodes ">
                    <circle :key="'node' + n" :cx="node.x" :cy="node.y" :r="node.r"
                        :fill="countryColours[node.country_id]" />

                </template>
            </g> -->
            <!-- <template #axis>
                <axis dimension="x" ref="xAxis" />
                <axis dimension="y" ref="yAxis" />
            </template> -->
        </chart>

    </card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import participantCountry from './participant-country.vue';
import participantAge from './participant-age.vue';
import participantGender from './participant-gender.vue';
import simulationComponent from '../../charts/simulation.vue';
import axis from '../../charts/axis.vue'
export default {
    name: 'data-explorer',
    components: {
        participantCountry,
        participantAge,
        participantGender,
        simulationComponent,
        axis,
    },
    data() {
        return {
            width: 800,
            height: 600,
            nodes: [],
            simulation: false,
            simulationForces: [],
            scenes: [
                { label: 'Participant Country', name: 'participant-country' },
                { label: 'Participant Age', name: 'participant-age', axis: ['top'] },
                { label: 'Participant Gender', name: 'participant-gender' },
            ],
            scene: 'participant-country'
        }
    },
    provide() {
        return {
            width: this.width,
            height: this.height
        }
    },
    mounted() {
        console.log('data-explorer mounted');
        if (!this.loadedPatients) {
            // defer the fetch until the component is mounted
            setTimeout(() => {
                this.fetchPatients();
            }, 100);
        }
    },
    computed: {
        ...mapState('resources/admin', ['dashboard', 'loadingDashboardData', 'loadingPatients', 'loadedPatients']),
        patients() {
            return this.dashboard.data.patients;
        },
        countryColours() {
            if (this.dashboard.data) {
                return this.dashboard.data.countryData.reduce((acc, c) => {
                    acc[c.id] = c.json.hex;
                    return acc;
                }, {});
            } else {
                return {}
            }
        },
    },
    methods: {
        ...mapActions('resources/admin', ['loadDashboardDataFromFile']),
        fetchPatients() {
            return this.loadDashboardDataFromFile()
                .then(() => {
                    console.log('fetched patients', this.patients.length);
                })
        },
        ticked(tick) {
            // console.log('tick', tick);
        }
    },
    watch: {
        patients(newPatients, oldPatients) {
            if (newPatients.length > oldPatients.length) {
                // console.log('New patients added', newPatients.length, oldPatients.length, newPatients.length - oldPatients.length);
                // New nodes have been added
                const newNodes = newPatients.slice(oldPatients.length).map(p => ({ ...p, x: Math.random() * this.width, y: Math.random() * this.height, r: 4 }));;
                this.nodes = [...this.nodes, ...newNodes];
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.node-container {
    circle {
        stroke: var(--bs-body-color);
        stroke-width: 0.01rem;
        // transition: all 0.3s;
    }
}
</style>