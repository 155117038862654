<template>

    <div name="fade" tag="div" appear class="grid-container" :style="{ '--country-count': countryData.length }">
        <country-leaderboard :country-data="countryDataSorted" v-if="countryDataSorted.length">
            <!-- <country-card v-for="country in countryDataSorted" :country="country" :key="country.code"
                class="grid-item size-1x1 shadow">
            </country-card> -->
        </country-leaderboard>

        <patientCount class="grid-item shadow-sm" key="patientCount"></patientCount>

        <recruitmentOverTime class="grid-item shadow" key="recruitmentOverTime">
        </recruitmentOverTime>
    </div>

</template>

<script>
import recruitmentOverTime from '~/components/admin-dashboard/recruitment-over-time.vue'
import countryMixin from '~/mixins/country';
import countryCard from '~/components/admin-dashboard/country-card.vue';
import countryLeaderboard from '~/components/admin-dashboard/country-leaderboard.vue';
import patientCount from '~/components/admin-dashboard/patient-count.vue'
import { mapActions, mapState } from 'vuex';
export default {
    name: "recruitment-dashboard",
    mixins: [countryMixin],
    components: {
        recruitmentOverTime,
        patientCount,
        countryCard,
        countryLeaderboard
    },
    data() {
        return {
            title: 'Recruitment',
        }
    },
    mounted() {
        // this.fetchRecruitmentDates()
    },
    computed: {
        ...mapState('resources/admin', ['dashboard', 'loadingDashboardData', 'loadedDashboardData',]),
        countryData() {
            if (this.dashboard.data.countryData)
                return this.dashboard.data.countryData
            else return []
        },
        countryDataSorted() {
            return this.countryData.sort((a, b) => b.recruitment_count - a.recruitment_count)
        },

    },
    methods: {
        ...mapActions('resources/admin', ['fetchAsStream']),
        fetchRecruitmentDates() {
            this.fetchAsStream({
                id: 'patient.recruitment',
                resource: 'Patient',
                key: 'patients',
                initial: false,
                params: { attrs: ['recruitment_date', 'country_id'] }
            })
        }
    },

}
</script>
