import {factory} from "../utils/ClassLoader"
let Lookup = class extends factory.classes.AttributeBag {
	constructor(attributes)  {
	  super(attributes);
	  if (this.items) {
	  	this.setItems(this.items)
	  } else {
		this.setItems([]);
	  }
	  return this;
	}
	setItems(items) {
		this.items = items;
		for(var index in items) {
			items[index].setLookup(this);
		}
	}
	addItem(item) {
		this.items.push(item)
	}
	setDataitem(dataitem) {
		this.dataitem = dataitem;
	}
	toJSON() {
		var object = {...this}
		delete object.dataitem;
		return object;
	}
	lookUp(value) {
		return this.items.find(function(item) {
			return item.value == value;
		}.bind(this))
	}
	formatForDisplay(value) {
		var item = this.lookUp(value)
		if (item)
		return item.label;
	}
}

export {
	Lookup
}