<template>
  <div id="app">
    <loading ref="loading" />

    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
    <cookie-consent v-if="!cookieConsentRequested" @consentChanged="consentChanged" />
  </div>
</template>

<script>
import Loading from './Loading.vue'
import DarkModeMixin from '@showjoe/ffui/src/mixins/dark-mode.vue'
import { resolveImport } from '../helpers/module-importer.js'
import { mapGetters } from 'vuex'
import cookieConsent from './cookie-consent.vue'
import { bootstrap } from 'vue-gtag'
// Load layout components dynamically.
const layoutsImport = import.meta.glob('../layouts/**/*.vue')
const layouts = resolveImport(layoutsImport, '../layouts/', /(^.\/)|(\.vue$)/g)
export default {
  el: '#app',

  mixins: [DarkModeMixin],
  components: {
    Loading,
    cookieConsent
  },
  mixins: [DarkModeMixin],
  data: () => ({
    layout: null,
    defaultLayout: 'default',
    offCanvasMenuShow: false,
    cookieConsentRequested: false,
    cookieConsented: false,
    devMode: false,
    hmr: import.meta.hot
  }),

  metaInfo() {
    const { appName } = window.config
    return {
      title: appName,
      titleTemplate: `%s · ${appName}`,
    }
  },

  mounted() {
    this.$loading = this.$refs.loading
    this.devMode = window.config.env == 'local'
    this.setupVueGtag()
    this.checkCookieConsent()
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin', 'isCountryTeam', 'isTeam', 'isParticipant']),
    homepage() {
      if (!this.user) return 'welcome'
      if (this.isAdmin || this.isTeam || this.isCountryTeam) return 'admin.dashboard'
      return 'home'
    },

  },
  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout(layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }

      this.layout = layouts[layout]
    },
    async logout() {
      if (this.cookieConsented && this.$gtag)
        this.$gtag.event({
          event_category: 'auth',
          event_action: 'logout',
          event_label: 'User logged out',
          user_id: this.user.id

        })
      await this.$store.dispatch('auth/logout')
      // Redirect to login.
      this.$router.push({ name: 'login' })

    },
    setupVueGtag() {
      if (this.cookieConsented) {
        bootstrap().then((gtag) => {
          //all done!
        })
      }
    },
    checkCookieConsent() {
      var consentRequested = false
      var consented = false
      var lsCC = localStorage.getItem('cookie-consent')
      var cc = JSON.parse(lsCC)
      if (cc.ttl > Date.now()) {
        consented = cc.value === true
        consentRequested = cc.value === true || cc.value === false;
      }
      else {
        localStorage.setItem('cookie-consent', null)
      }
      this.cookieConsented = consented
      this.cookieConsentRequested = consentRequested
      if (this.cookieConsented) this.setupVueGtag()
      return consentRequested
    },
    consentChanged(consent) {

      if (consent) {
        this.setupVueGtag()
      }
    }
  }
}
</script>
