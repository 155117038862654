import store from '~/store'
import { getSequence, checkRoute } from '~/router/sequences'
// checks that they are not in a sequence
export default async (to, from, next) => {
  // console.log("check")
  var {code, intendedRoute, expectedRoute} = checkRoute(to, from)
  if (code=="future"||code=="unknown") {
    next(expectedRoute)
  }
  else {
    next()
  }
  // future: the route is part of the sequence but after the current status
  // current: the route is the current status 
  // previous: the route is in the status but before the current status
  // unknown: the route isn't in the sequence
  // null: the user doesn't have a sequence
}
