<template>
    <div>
        <slot></slot>
    </div>
</template>
<script>
import { forceSimulation, forceManyBody, forceCenter, forceCollide, forceX, forceY } from 'd3-force';
export default {
    name: 'simulation-component',
    inject: ['width', 'height'],
    props: {
        nodes: {
            type: Array,
            default: () => []
        },
        simulationForces: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            simulation: null,
            tick: 0,
            forcesApplied: []
        }
    },
    mounted() {
        if (this.simulationForces.length > 0)
            this.makeSimulation();
        console.log('simulation-component mounted');

    },
    methods: {
        makeSimulation() {
            this.simulation = forceSimulation(this.nodes);

            this.simulationForces.forEach(force => {
                this.simulation.force(force.name, force.force);
            });

            this.simulation.on('tick', () => {
                this.tick++;
                this.$emit('tick', this.ticked);
            });

            this.$emit('simulation', this.simulation);


        },
        updateSimulation() {
            // console.log('updateSimulation', this.simulation)
            this.simulation.nodes(this.nodes);
            this.simulationForces.forEach(force => {
                this.simulation.force(force.name, force.force);
                if (!this.forcesApplied.includes(force.name))
                    this.forcesApplied.push(force.name);
            });
            // remove forces that are no longer in the simulationForces
            this.forcesApplied.forEach(force => {
                if (!this.simulationForces.map(f => f.name).includes(force)) {
                    this.simulation.force(force, null);
                    this.forcesApplied = this.forcesApplied.filter(f => f !== force);
                }
            });
            this.simulation.alpha(0.5).restart();
        },
        ticked() {
            this.$emit('ticked', this.tick);

        }
    },
    watch: {
        nodes: {
            handler() {
                this.updateSimulation();
            },
        },
        simulationForces: {
            handler(newValue, oldValue) {
                // console.log('simulationForces changed', oldValue, newValue);
                if (oldValue.length === 0 && newValue.length > 0) {
                    this.makeSimulation();
                }
                // console.log('simulationForces changed', newValue);
                this.updateSimulation();
            },
            deep: true
        }
    }

}
</script>