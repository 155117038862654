<template>
  <form @submit.prevent="update" @keydown="form.onKeydown($event)">
    <card head foot :title="$t('settings.your_info')">
      <alert-success :form="form" :message="$t('settings.info_updated')" />
      <!-- First Name -->
      <div class="form-group row mb-2">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('settings.first_name') }}</label>
        <div class="col-md-7">
          <input v-model="form.first_name" :class="{ 'is-invalid': form.errors.has('first_name') }" class="form-control"
            type="text" name="first_name">
          <has-error :form="form" field="first_name" />
        </div>
      </div>
      <!-- Last Name -->
      <div class="form-group row mb-2">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('settings.last_name') }}</label>
        <div class="col-md-7">
          <input v-model="form.last_name" :class="{ 'is-invalid': form.errors.has('last_name') }" class="form-control"
            type="text" name="last_name">
          <has-error :form="form" field="last_name" />
        </div>
      </div>
      <!-- Email -->
      <div class="form-group row mb-2">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('settings.email') }}</label>
        <div class="col-md-7">
          <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" class="form-control"
            type="email" name="email">
          <has-error :form="form" field="email" />
          <has-error :form="form" field="email_hash" />
        </div>
      </div>
      <!-- Preferred Locale -->
      <div class="form-group row mb-2">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('settings.language') }}</label>
        <div class="col-md-7">
          <dropdown :items="localesArray" :value="form.locale" :class="{ 'is-invalid': form.errors.has('locale') }"
            @input="setLocale" name="locale" />
          <has-error :form="form" field="locale" />
        </div>
      </div>
      <!-- Submit Button -->
      <div slot="footer" class="card-footer">
        <div class="col-md-9 ms-md-auto">
          <btn :outline="false" type="submit" :class="[{ 'btn-loading': form.busy }]">
            {{ $t('settings.update') }}
          </btn>
        </div>
      </div>
    </card>
  </form>
</template>
<script>
import Form from 'vform'
import { mapGetters } from 'vuex'
import { loadLanguageAsync } from '~/plugins/i18n'

export default {
  scrollToTop: false,

  metaInfo() {
    return { title: this.$t('settings.title') }
  },

  data: () => ({
    form: new Form({
      first_name: '',
      last_name: '',
      email: '',
      locale: '',
    })
  }),
  computed: {
    ...mapGetters('auth', ['user']),
    roles() { return this.user.roles.map((r) => r.name) },
    ...mapGetters('lang', ['locales', 'locale']),
    localesArray() {
      return Object.keys(this.locales).map((key) => {
        return { value: key, label: this.locales[key] }
      })
    }
  },

  created() {
    // Fill the form with user data.
    this.form.keys().forEach(key => {
      this.form[key] = this.user[key]
    })
  },

  methods: {
    async update() {
      const { data } = await this.form.patch('/settings/account')

      this.$store.dispatch('auth/updateUser', { user: data })
    },
    setLocale(locale) {
      if (this.$i18n.locale !== locale) {
        Promise.all([loadLanguageAsync(locale)])
          .then(() => {
            this.$store.dispatch('lang/setLocale', { locale })
            this.form.locale = locale
          })
      }
    }
  }
}

</script>
