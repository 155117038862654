import axios from 'axios'
// state
export const state = {
    schedule:null
}

// getters
export const getters = {
    schedule: (state) => {
        return state.schedule
    },
}

// mutations
export const mutations = {
  GET_SCHEDULE (state, data) {
	state.schedule = data;
  },
}

// actions
export const actions = {
  getScheduleData ({ commit }, request) {
      return axios.get("schedule/data?&skip=0&take=1000", request)
      .then((resp) => {
        commit('GET_SCHEDULE', resp.data)
      })
  },
}
export const namespaced = true;
