import { isFunction, size, pickBy } from "lodash";
import helpers from "./helpers";

let Collection = class extends Object {
	constructor(items) {
		super();
		for(var name in items) {
			this[name] = items[name];
		}
	}
	where(attr, operator, value) {
		if (value===undefined) {
			value = operator;
			operator = "=";
		}
		return new this.__proto__.constructor(pickBy(this, function(element) {
			var gotValue = helpers.dotGet(element, attr);
			return helpers.evalComparator(gotValue, operator, value);
		}.bind(this)));
	}
	groupBy(attr) {
		var groups = {};
		this.forEach(function(element, key) {
			var gotValue = helpers.dotGet(element, attr);
			if (!groups[gotValue]) {
				groups[gotValue] = new this.__proto__.constructor();
			}
			groups[gotValue][key] = element;
		}.bind(this));
		return groups;
	}
	find(func) {
		for(var name in this) {
			var element = this[name];
			if (func(element, name, this)) {
				return element;
			}
		}
	}
	keyBy(attr) {
		var newItems = new this.__proto__.constructor();
		for(var name in this) {
			if (isFunction(attr)) {
				newItems[attr(this[name])] = this[name]
			} else {
				newItems[helpers.dotGet(this[name], attr)] = this[name]
			}
			// newItems[name] = this[name];
			// delete this[name];
		}
		return newItems;
	}
	*[Symbol.iterator]() { // iterator protocol
		var keys = Object.keys(this)
		for(var key in keys) {
			yield this[keys[key]]
		}
	}
	forEach(func) {
		for(var name in this) {
			func(this[name], name)
		}
	}
	filter(func) {
		var newInstance = new this.__proto__.constructor
		for (var index in this) {
			var element = this[index]
			if (func(element)) {
				newInstance[index] = element;
			}
		}
		return newInstance;
		// console.log(new this.prototype);
	}
	// get length() {
	// 	return Object.keys(this).length
	// }
	get size() {
		return Object.keys(this).length
	}
	// set __proto__(proto) {
	// 	var vueFuncs = ["pop","push","reverse","shift","sort","splice","unshift"]
	// 	console.log(proto, Object.keys(proto))
	// 	window.lemon = proto
	// 	for(var key of vueFuncs) {
	// 		this.__proto__[key] = proto[key]
	// 	}
	// }
	// get __proto__() {
	// 	return Object.getPrototypeOf(this);
	// }
}

export {
	Collection
}