<template>
    <div class="social-share-buttons mb-4">
        <btn @click.native="openSharePopup('facebook', meta)" class="icon-btn card-radial-bg" title="Share on Facebook">
            <fa :icon="['fab', 'facebook']" fixed-width />
            <div class="sr-only" v-t="'public.keep-me-posted.facebook_share'"></div>
        </btn>
        <btn @click.native="openSharePopup('twitter', meta)" class="icon-btn card-radial-bg" title="Share on Twitter">
            <fa :icon="['fab', 'twitter']" fixed-width />
            <div class="sr-only" v-t="'public.keep-me-posted.twitter_share'"></div>
        </btn>
        <btn @click.native="openSharePopup('linkedin', meta)" class="icon-btn card-radial-bg" title="Share on LinkedIn">
            <fa :icon="['fab', 'linkedin']" fixed-width />
            <div class="sr-only" v-t="'public.keep-me-posted.linkedin_share'"></div>
        </btn>
    </div>
</template>

<script>
export default {
    name: 'social-share-buttons',
    props: {
        meta: Object
    },
    metaInfo() {
        const { title, description, url, image } = this.meta;

        return {
            title,
            meta: [
                // Open Graph tags
                { hid: 'og:title', property: 'og:title', content: title },
                { hid: 'og:description', property: 'og:description', content: description },
                { hid: 'og:image', property: 'og:image', content: image },
                { hid: 'og:url', property: 'og:url', content: url },
                { hid: 'og:type', property: 'og:type', content: 'website' },

                // Twitter Card tags
                { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
                { hid: 'twitter:site', name: 'twitter:site', content: '@your_website_twitter_handle' },
                { hid: 'twitter:title', name: 'twitter:title', content: title },
                { hid: 'twitter:description', name: 'twitter:description', content: description },
                { hid: 'twitter:image', name: 'twitter:image', content: image },
            ],
        }
    },
    methods: {
        openSharePopup(platform, meta) {
            const url = encodeURIComponent(meta.url);
            const title = encodeURIComponent(meta.title);
            const description = encodeURIComponent(meta.description);
            const image = encodeURIComponent(meta.image);
            let shareUrl = '';

            switch (platform) {
                case 'facebook':
                    shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`;
                    break;
                case 'twitter':
                    shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;
                    break;
                case 'linkedin':
                    shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${description}&source=${url}`;
                    break;
                default:
                    return;
            }

            const popupWidth = 600;
            const popupHeight = 400;
            const left = (screen.width / 2) - (popupWidth / 2);
            const top = (screen.height / 2) - (popupHeight / 2);
            window.open(shareUrl, '', `status=no,height=${popupHeight},width=${popupWidth},resizable=yes,left=${left},top=${top},screenX=${left},screenY=${top},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`);
        },
    },
}
</script>

<style lang="scss">
.social-share-buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    align-items: center;
    background: none;

    .icon-btn {
        place-self: center;
        padding: 0.5rem;
        font-size: inherit;

        .svg-inline--fa {
            font-size: inherit;
            font-size: 3rem;
        }
    }
}
</style>