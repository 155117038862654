<template>
    <form-group v-if="di.lookup&&di.lookup.name=='YN'" class="row" hide-label hide-error :di="di" :error="errors[di.name]">
		<column :sizes="{ sm: 12, md: 10, lg: 8 }" :offset="{ lg: 1 }">
			<consent-label :consent="consent" :di="di">
			</consent-label>
			<feedback class="d-block mx-md-5 px-md-4" type="invalid" v-if="errors[di.name]">{{ errors[di.name][0] }}
			</feedback>
		</column>
        <column :sizes="{ sm: 6, md: 2 }" :offset="{ sm: 6, md: 0 }" class="d-flex align-items-bottom">
        	<tick-cross v-if="di.lookup.name=='YN'" class="align-self-center" style="width:65px; " :disabled="disabled" v-model="val" :di="di">
        	</tick-cross>
		</column>
		<template #error>
			&nbsp;
		</template>
	</form-group>
    <form-group :di="di" v-else-if="di.lookup" :error="errors[di.name]">
        <btn-group :disabled="disabled" v-if="di.type=='multilookup'" outline :di="di" class="w-100" v-clear btn-class="secondary text-start">
          <template #btn="{btn,btnProps}">
            <btn v-bind="btnProps" v-model="val[btn.value]" :true-value="true">
              <div class="d-flex align-items-center">
                <fa :icon="value[btn.value] ? 'check-square':'square'" size="lg" class="me-4" />
                <span class="flex-grow-1">{{btn.label}}</span>
              </div>
            </btn>
          </template>
        </btn-group>
        <btn-group :disabled="disabled" v-else-if="di.lookup" :di="di" v-model="val" outline class="w-100" v-clear btn-class="secondary text-start">
          <template #btn-label="{btn}">
            <div class="d-flex align-items-center">
              <fa :icon="value==btn.value ? 'check-square':'square'" size="lg" class="me-4" />
              <span class="flex-grow-1">{{btn.label}}</span>
            </div>
          </template>
        </btn-group>
    </form-group>
	<form-group v-else-if="di.type=='norecord'" class="row"  :di="di" hide-label hide-error :error="errors[di.name]">
		<column :sizes="{ sm: 12, md: 10, lg: 8 }" :offset="{ lg: 1 }">
			<consent-label :consent="consent" :di="di">
			</consent-label>
			<feedback class="d-block mx-md-5 px-md-4" type="invalid" v-if="errors[di.name]">{{ errors[di.name][0] }}
			</feedback>
		</column>
        <column :sizes="{ sm: 6, md: 2 }" :offset="{ sm: 6, md: 0 }" class="d-flex align-items-bottom">
            <span class="ms-auto mt-sm-2">N/A</span>
        </column>
	</form-group>
</template>
<script>
import tickCross from "~/pages/questionnaires/consent/tick-cross.vue";
import consentLabel from "~/pages/questionnaires/consent/consent-label.vue";
export default {
	components:{tickCross, consentLabel},
    name: 'consent-item',
	props: {
	    di: {
	    	type: Object
	    },
	    value: {
	    	
	    },
	    errors: {
	    	type: Object
	    },
	    disabled: {
	    	type: Boolean
	    },
		consent: {
			type: Object
		}
	},
	computed: {
	},
	methods:{
	},
	data () {
	  return {
	    val: this.value
	  }
	},
	watch: {
	  val (val) {
	    this.$emit('input', val)
	  },
	  value: { handler (value) {
	    this.val = (value)
	    },
	    deep: true
	  }
	}
}
</script>