<template>
    <card :body="false" head class="country-card" style="">
        <template #header>
            <div class="card-header" :style="{ '--bs-card-cap-bg': country.json.hex }"></div>
        </template>
        <!-- <row class="g-0"> -->
        <!-- <column :sizes="[4, { sm: 4 }]" class="d-flex align-items-middle"> -->
        <!-- <div class="m-auto ms-2"> -->
        <div class="card-body">
            <router-link :to="{ name: 'admin.country', params: { country_id: country.id } }" class="flag-link">
                <svg class="flag-img card-img rounded-left" :style="{ width: horizontal ? '2rem' : '6rem' }">
                    <use :href="'/images/home/flags-sprite.svg#' + country.code.toLowerCase()"></use>
                </svg>
            </router-link>
            <!-- </div> -->
            <!-- </column> -->
            <!-- <column :sizes="[8, { sm: 8 }]"> -->
            <!-- <div class="ps-2 py-2"> -->
            <div class="country-name">{{ getCountryLang(country).name }}</div>
            <span class="country-code">{{ country.code }}</span>
            <div class="stats">
                <span class="country-count"> {{ country.recruitment_count }}</span>
                <span class="country-count-target">
                    / {{ totalTarget }}
                </span>
            </div>
            <!-- <i18n path="admin.dashboard.country_recruitment_start" tag="span" class="card-text-sm">
                <template #recruited_from>
                    {{ $d(new Date(country.study_start_date), 'numeric', 'en-GB') }}
                </template>
            </i18n> -->
            <!-- </div> -->

            <!-- </column> -->
            <!-- </row> -->
            <transition-group v-if="horizontal" name="fade" tag="div" class="country-recruitment-period" appear>
                <country-recruitment-period-rect v-for="period in country.recruitmentData" :key="period.period"
                    :period="period" :country="country" :horizontal="horizontal">
                </country-recruitment-period-rect>
            </transition-group>
            <transition-group v-else name="fade" tag="div" class="country-recruitment-period" appear>
                <country-recruitment-period v-for="period in country.recruitmentData" :key="period.period"
                    :period="period" :country="country" :horizontal="horizontal">
                </country-recruitment-period>
            </transition-group>
        </div>
        <div class="card-footer p-1 ">
            <small class="d-flex gap-1">
                <badge class="">
                    {{ totalToday }} <small>D</small>
                </badge>
                <badge class="">
                    {{ last7Days }} <small>W</small>
                </badge>
                <badge class="">
                    {{ lastMonth }} <small>M</small>
                </badge>
            </small>
        </div>
    </card>
</template>

<script>
import { mapState } from 'vuex';
import countryMixin from '~/mixins/country';
import countryRecruitmentPeriod from '~/components/admin-dashboard/country-recruitment-period.vue';
import countryRecruitmentPeriodRect from '~/components/admin-dashboard/country-recruitment-period-rect.vue';
export default {
    name: 'country-card',
    components: {
        countryRecruitmentPeriod,
        countryRecruitmentPeriodRect
    },
    mixins: [countryMixin],
    props: {
        country: {
            type: Object,
            required: true
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState("resources/admin", ["dashboard"]),
        totalTarget() {
            return this.country.recruitmentData.reduce((acc, period) => acc + period.target, 0);
        },
        totalToday() {
            var rd = this.dashboard.stream.patients;
            var country_id = this.country.id;
            var today = new Date();
            var todayStr = today.toISOString().slice(0, 10);
            var filtered = rd.filter(function (item) {
                return item.c === country_id && item.rd === todayStr;
            });
            return filtered.length;
        },
        last7Days() {
            var rd = this.dashboard.stream.patients;
            var country_id = this.country.id;
            var weekAgo = new Date();
            weekAgo.setDate(weekAgo.getDate() - 7);
            weekAgo = weekAgo.toISOString().slice(0, 10);
            var filtered = rd.filter(function (item) {
                return item.c === country_id && item.rd >= weekAgo;
            });
            return filtered.length;
        },
        lastMonth() {
            var rd = this.dashboard.stream.patients;
            var country_id = this.country.id;
            var monthAgo = new Date();
            monthAgo.setDate(monthAgo.getDate() - 30);
            monthAgo = monthAgo.toISOString().slice(0, 10);
            var filtered = rd.filter(function (item) {
                return item.c === country_id && item.rd >= monthAgo;
            });
            return filtered.length;
        }
    },
    methods: {

    }
}
</script>
