<template>
	<card :body="false" head class="participant-flow-card">
		<template #header>
			<div class="card-header d-flex">
				<h5 class="card-title flex-grow-1">
					{{ diagram.title }}
				</h5>
				<div class="me-auto">
					<fa icon="cog" spin v-if="loadingDiagramData"></fa>
				</div>
				<div v-for="country in countries" class="ms-auto" :key="country.json.hex">
					<div class="d-inline-block ms-2" :key="'ro' + country.id">
						<div @click="country.active = !country.active" class="country-btn me-1 rounded"
							:style="{ backgroundColor: country.json.hex, opacity: country.active ? 1 : 0.1 }"></div>
					</div>
				</div>
			</div>
		</template>
		<chart v-if="mounted" :chartData="chartData" :width="width" :height="height" :margin="margin"
			class="participant-flow-chart">
			<sankey ref="sankey" :nodes="chartData.nodes" :links="chartData.links" :width="sankeyWidth"
				:height="sankeyHeight">
			</sankey>
		</chart>
	</card>
</template>

<script>

import sankey from "../charts/sankey.vue";
import { mapState, mapActions } from "vuex";
export default {
	name: "ParticipantFlow",
	components: {
		sankey,
	},
	props: {
		diagram: {
			type: Object,
			default: () => { return { name: 'PatientFlow', title: 'Patient Flow' } }
		}
	},
	// inject: ["dashboard"],
	data() {
		return {
			mounted: false,
			chartData: { nodes: [], links: [] },
			margin: { left: 10, right: 10, top: 10, bottom: 10 },
			sankeyWidth: 800,
			sankeyHeight: 600,
			width: 820,
			height: 620,
			ro: null,
		};
	},
	mounted() {
		this.setChartSize();
		this.getData();
	},
	computed: {
		...mapState('resources/admin', ["dashboard", 'loadedDiagramData', 'loadingDiagramData']),
		countries() {
			return this.dashboard.data.countryData;
		},
		activeCountries() {
			return this.countries.filter((country) => country.active);
		},
		activeDiagram() {
			return this.dashboard.data.diagrams[this.diagram.name];
		},

	},
	methods: {
		...mapActions('resources/admin', ["getDiagramData"]),
		getData() {
			var request = {
				params: {
					"diagram": this.diagram.name,
					'filter[country_id]': this.activeCountries.map((country) => country.id).join(","),
				}
			}
			this.getDiagramData(request).then(() => {
				var d = this.dashboard.data.diagrams[this.diagramShown]
				this.chartData = {
					nodes: d.nodes,
					links: d.links,

				};
				this.chartMounted();


			});
		},
		chartMounted() {
			this.defineResizeObserver();
			this.setChartSize();

			this.mounted = true;

		},
		defineResizeObserver() {
			// this.ro = new ResizeObserver(() => {
			// 	this.setChartSize();
			// });
			// this.ro.observe(this.$el);
		},
		setChartSize() {
			var el = this.$el
			var header = el.querySelector('.card-header')
			var box = el.getBoundingClientRect()
			this.width = box.width;
			this.height = box.height - (header.offsetHeight * 2);
			this.sankeyWidth = this.width - this.margin.left - this.margin.right;
			this.sankeyHeight = this.height - this.margin.top - this.margin.bottom;
			if (this.$refs.sankey)
				this.$refs.sankey.layoutSankey();
		},
	},
	watch: {
		chartData: {
			handler() {
				this.chartMounted();
			},
			deep: true,
		},
		activeCountries() {
			this.getData();
		},
		diagram() {
			this.getData();
		},
		loadingDiagramData() {
			if (this.loadingDiagramData) {
			} else {
				var d = this.dashboard.data.diagrams[this.diagram.name]
				this.chartData = d;
			}
		},
	},
};
</script>

<style lang="scss">
.country-btn {
	width: 20px;
	aspect-ratio: 1;
}
</style>
