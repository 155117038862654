<template>
	<g ref="axis" :transform="transformAxis" :class="['axis', dimension + '-axis']"></g>
</template>
<script>
import { axisBottom, axisLeft, axisTop, axisRight } from "d3-axis";
import { select } from "d3-selection";
import moment from "moment/min/moment-with-locales";
export default {
	name: "axis",
	props: {
		dimension: {
			type: String,
			default: "x", // Default dimension
			validator: function (value) {
				// This prop must be either 'x' or 'y'
				return ["x", "y"].includes(value);
			},
		},
		width: {
			type: Number,
		},
		height: {
			type: Number,
		},
		position: {
			type: String,
			default: function () {
				if (this.dimension) {
					// This might not work as expected in Vue 2
					return this.dimension == "x" ? "bottom" : "left";
				}
			},
			validator: function (value) {
				// This prop must be either 'top', 'bottom', 'left' or 'right'
				return ["top", "bottom", "left", "right"].includes(value);
			},
		},
		margin: {
			type: Object,
			default: () => ({
				left: 25,
				right: 25,
				bottom: 25,
				top: 25,
			}),
		},
		ticks: {
			type: Number,
		},
		tickSize: {
			type: Number,
		},
		tickSizeOuter: {
			type: Number,
		},

		grid: {
			type: Boolean,
			default: false,
		},
		dateFormat: {
			type: String,
			default: "MMM YY",
		},
	},
	inject: ["subjects"],
	mounted() {
		this.axis = select(this.$el);
		this.drawAxis();
	},
	updated() {
		this.drawAxis();
	},
	data() {
		return {
			axis: null,
		};
	},
	computed: {
		range() {
			return this.$parent.range;
		},
		transformAxis() {
			if (this.margin) {
				if (this.dimension == "x") {
					if (this.position == "bottom")
						return (
							"translate(" +
							this.margin.left +
							"," +
							(this.height - this.margin.bottom) +
							")"
						);
					if (this.position == "top")
						return "translate(" + this.margin.left + "," + this.margin.top + ")";
				}
				if (this.dimension == "y") {
					if (this.position == "left")
						return "translate(" + this.margin.left + "," + this.margin.top + ")";
					if (this.position == "right")
						return (
							"translate(" +
							(this.width - this.margin.right) +
							"," +
							this.margin.top +
							")"
						);
				}
			}
			return false;
		},
		computedTicks() {
			if (this.ticks) return this.ticks;

			if (this.dimension == "x") {
				return this.width / 80;
			}

			if (this.dimension == "y") {
				return this.height / 40;
			}
			return 10;
		},
		computedTickSize() {
			if (this.tickSize) return this.tickSize;
			if (this.grid) {
				return this.dimension == "x" ? -this.height : -this.width;
			} else {
				return this.dimension == "x" ? 6 : 6;
			}
		},
		axisFunc() {
			// console.log("axisFunc");
			var axisFunc = null;
			if (!this.range) return function () { };
			if (this.dimension == "x") {
				//  axisFunc = axisBottom(this.range[this.dimension]).ticks(this.width / 80).tickSizeOuter(0)
				axisFunc =
					this.position == "bottom"
						? axisBottom(this.range[this.dimension])
							.ticks(this.computedTicks)
							.tickSizeOuter(this.tickSizeOuter)
						: axisTop(this.range[this.dimension])
							.ticks(this.computedTicks)
							.tickSizeOuter(this.tickSizeOuter);
			}
			if (this.dimension == "y") {
				axisFunc =
					this.position == "left"
						? axisLeft(this.range[this.dimension])
							.ticks(this.computedTicks)
							.tickSizeOuter(this.tickSizeOuter)
						: axisRight(this.range[this.dimension])
							.ticks(this.computedTicks)
							.tickSizeOuter(this.tickSizeOuter);
			}
			if (this.subject == "date") {
				axisFunc.tickFormat((d) => {
					// console.log("tickFormat", d, this.range[this.dimension]);
					// determine the format of the date based on the range

					return moment(d).locale(this.$i18n.locale).format(this.dateFormat);
				});
			}
			return axisFunc;
		},
		gridAxisFunc() {
			var axisFunc = null;
			if (!this.range) return function () { };
			if (this.dimension == "x") {
				axisFunc =
					this.position == "bottom"
						? axisBottom(this.range[this.dimension])
							.ticks(this.computedTicks)
							.tickSize(this.computedTickSize)
							.tickFormat("")
							.tickSizeOuter(this.tickSizeOuter)
						: axisTop(this.range[this.dimension])
							.ticks(this.computedTicks)
							.tickSize(this.computedTickSize)
							.tickFormat("")
							.tickSizeOuter(this.tickSizeOuter);
			}
			if (this.dimension == "y") {
				axisFunc =
					this.position == "left"
						? axisLeft(this.range[this.dimension])
							.ticks(this.computedTicks)
							.tickSize(this.computedTickSize)
							.tickFormat("")
							.tickSizeOuter(this.tickSizeOuter)
						: axisRight(this.range[this.dimension])
							.ticks(this.computedTicks)
							.tickSize(this.computedTickSize)
							.tickFormat("")
							.tickSizeOuter(this.tickSizeOuter);
			}
			return axisFunc;
		},
		subject() {
			return this.subjects[this.dimension];
		},
	},
	methods: {
		drawAxis() {
			// console.log("draw axis");
			// can we transition the axis when the range changes?
			if (this.grid) {
				this.axis.call(this.gridAxisFunc);
			} else {
				this.axis.call(this.axisFunc);
			}
		},
		updateAxis() {
			// console.log("update axis");
			if (this.grid) {
				this.axis.call(this.gridAxisFunc);
			} else {
				this.axis.call(this.axisFunc);
			}
		},
	},
	watch: {
		width() {
			this.updateAxis();
		},
		height() {
			this.updateAxis();
		},
		ticks() {
			this.updateAxis();
		},
		range() {
			this.updateAxis();
		},
	},
};
</script>
<style>
.line {
	fill: none;
	stroke: steelblue;
	stroke-width: 2px;
}
</style>
