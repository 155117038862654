let AttributeBag = class {
	constructor(items) {
		this.assignContents(items)
	}
	assignContents(items) {
		for(var name in items) {
			this[name] = items[name];
		}
	}
	get doNotIter() {
		return []
	}
	*[Symbol.iterator]() { // iterator protocol
		var keys = Object.keys(this)
		for(var key in keys) {
			if (this.doNotIter.indexOf(key)!==-1) {
				yield this[keys[key]]
			}
		}
	}
	get doNotJson() {
		return []
	}
	toJSON(key) {
		var copy = {};
		for(var index in this) {
			if (this.doNotJson.indexOf(index)===-1) {
				copy[index] = this[index];
			}
		}
		return copy;
    }
}

export {
	AttributeBag
}