<!-- about is the wrapper component for public info pages (other than homepage) -->
<template>
  <div class="container-xxl public-page">
    <row class="mt-4">
      <column :sizes="{ md: 3 }">
        <div class="sticky-top" style="top:78px;z-index:1000">
          <list-group class="shadow" :horizontal="['xs', 'sm'].includes($mq) ? 'sm' : false">
            <router-link tag="a" is-link v-for="item in publicMenuItems"
              class="list-group-item d-flex align-items-center" :to="item.to" :key="item.to.name" :href="item.href"
              :exact="item.exact" active-class="active">
              <fa :icon="item.icon" fixed-width class="ms-n2 me-2" />
              <div class="flex-grow-1 pe-none">
                {{ item.label }}
              </div>
            </router-link>
          </list-group>
        </div>
      </column>
      <column :sizes="{ md: 9 }">
        <router-view class="shadow"></router-view>
        <footer class="footer text-center mt-auto" style="padding:1em;">
          <router-link :to="{ name: 'public.accessibility' }" class="text-decoration-none"
            v-t="'public.accessibility.title'">
          </router-link>
          | <router-link :to="{ name: 'public.cookie-policy' }" class="text-decoration-none"
            v-t="'public.cookie-policy.title'"></router-link>
        </footer>
      </column>
    </row>

  </div>
</template>
<script>
export default {
  name: 'About',
  layout: 'fullscreen',
  components: {
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
  computed: {
    publicMenuItems() {
      var items = [
        { label: this.$t('public.home.title'), to: { name: 'welcome' }, icon: 'home', exact: true },
        { label: this.$t('public.keep-me-posted.title'), to: { name: 'public.keep-me-posted' }, icon: 'envelope-circle-check', exact: false },
        // { label: this.$t('public.more-info.title'), to: { name: 'public.more-info' }, icon: 'question-circle', exact: false },

      ]
      return items
    }
  }
}

</script>
