<template>
    <div>
        <h1>Print</h1>
        <div v-if="questionnairesSchema">
            <demographics name="demographics" non-route :issue="1"></demographics>
            <div v-for="questionnaire in questionnairesSchema.questionnaires" :key="questionnaire.id">
                {{ questionnaire.model_name }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Demographics from '~/pages/questionnaires/Demographics.vue'
export default {
    name: "Print",
    layout: 'basic',
    components: {
        Demographics
    },
    data() {
        return {
            title: 'Print',
        }
    },
    mounted() {
        console.log(this.$route.meta)
        this.getQuestionnairesSchema({
            params: {
                'filter[model_type]': 'Scheduled',
                include: 'dataitems'
            }
        });
    },
    computed: {
        ...mapState('resources/questionnaires', ['questionnairesSchema']),
    },
    methods: {
        ...mapActions('resources/questionnaires', ["getQuestionnairesSchema"]),
        loadComponentAsync(questionnaire) {
            console.log(questionnaire.model_name)
            return import(`~/pages/questionnaires/${questionnaire.model_name}.vue`);
        }
    }

}
</script>

<style lang="scss" scoped></style>