<template>
  <card>
    <div class="d-flex align-items-center">
      <img :alt="$t('public.accessibility.icon-alt')" :src="'/images/home/cookie.png'" class="circle_icon">
      <h1 class="display-2 flex-grow-1" v-html="$t('public.accessibility.known_issues.title')"></h1>
    </div>
    <p class="lead" v-t="lp + 'p1'"></p>
    <h4 v-t="'public.accessibility.known_issues.subtitle1'" />
    <h4 v-t="'public.accessibility.known_issues.subtitle2'" />
    <ul>
      <li v-for="(item, n) in $t('public.accessibility.known_issues.list1')" :key="'item' + n" v-html="item"></li>
    </ul>
    <p class="lead" v-t="'public.accessibility.known_issues.p2'"></p>
    <h4 v-t="'public.accessibility.known_issues.subtitle3'" />
    <ul>
      <li v-for="(item, n) in $t('public.accessibility.known_issues.list2')" :key="'item' + n" v-html="item"></li>
    </ul>
    <p class="lead" v-t="'public.accessibility.known_issues.p3'"></p>
    <h4 v-t="'public.accessibility.known_issues.subtitle4'" />
    <ul>
      <li v-for="(item, n) in $t('public.accessibility.known_issues.list3')" :key="'item' + n" v-html="item"></li>
    </ul>
    <h4 v-t="'public.accessibility.known_issues.subtitle5'" />
    <p class="lead" v-t="'public.accessibility.known_issues.p4'"></p>
    <h4 v-t="'public.accessibility.known_issues.subtitle6'" />
    <p class="lead" v-t="'public.accessibility.known_issues.p5'"></p>
    <ul>
      <li v-for="(item, n) in $t('public.accessibility.known_issues.list4')" :key="'item' + n" v-html="item"></li>
    </ul>
    <p class="lead" v-t="'public.accessibility.known_issues.p6'"></p>
  </card>
</template>
<script>
export default {
  data() {
    return {
      appName: window.config.appName,
      wlp: 'public.home.',
      lp: 'public.accessibility.known_issues.'
    }
  }
}

</script>
