<template>
    <div v-if="!inChart">
    </div>
    <g v-else class="participant-age-g">
        <slot>
            <template v-for="(decade, key, n) in  ages ">
                <g :x="ageWidth * n + '%'">
                    <rect :key="decade.label" :width="ageWidth + '%'" height="100%" :x="ageWidth * n + '%'"
                        class="decade-rect"></rect>
                    <text :width="ageWidth + '%'" y="20" :x="ageWidth * n + (ageWidth * .5) + '%'" text-anchor="middle"
                        font-size="smaller">{{
                            decade.label }}</text>
                </g>
            </template>
            <!-- <g>
                <template v-for="( node, n ) in  nodes ">
                    <circle :key="'node' + n" :cx="node.x" :cy="node.y" :r="node.r"
                        :fill="$attrs.countryColours[node.country_id]" :title="age(node)" :dob="node.dob" />

                </template>
            </g> -->
            <axis dimension="x" ref="xAxis" />
        </slot>

    </g>
</template>

<script>
import { forceSimulation, forceManyBody, forceCenter, forceCollide, forceRadial, forceX, forceY } from 'd3-force';
import { range, scaleLinear, scaleTime } from 'd3'
import axis from '../../charts/axis.vue'

export default {
    name: 'participant-age',
    inject: ['width', 'height'],
    components: { axis },
    props: {
        nodes: {
            type: Array,
            default: () => []
        },
        inChart: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // show ages in years
            ages: {
                0: { label: '0 to 10' },
                1: { label: '10 to 20' },
                2: { label: '20 to 30' },
                3: { label: '30 to 40' },
                4: { label: '40 to 50' },
                5: { label: '50 to 60' },
                6: { label: '60 to 70' },
                7: { label: '70 to 80' },
                8: { label: '80 to 90' },
                9: { label: '90 to 100' },
                10: { label: '100 to 110' },
                11: { label: '110 to 120' }

            },
            domain: { x: [0, 120] }
        }
    },
    mounted() {
        console.log('participant-age mounted');
        this.updateSimulationForces();
    },
    computed: {
        ageWidth() {
            return 100 / Object.keys(this.ages).length
        },
        xScale() {
            return scaleLinear()
                .domain(this.domain.x)
                .range([0, this.width]);
        },
        range() {
            var self = this
            var x = this.xScale.range([0, this.width])
            x.domain([0, 120]);
            return { x }
        }

    },
    methods: {
        updateSimulationForces() {
            // this should cluster around country_id
            this.$emit('forcesChanged', [
                { name: 'charge', force: forceManyBody().strength(-16) },
                // { name: 'collide', force: forceCollide().radius(6) },
                {
                    name: 'x', force: forceX().strength(2).x(d => {
                        return this.xScale(this.age(d))
                    })
                },
                { name: 'y', force: forceY().strength(.4).y(d => this.height / 2) }
            ]);
        },
        age(node) {
            let birthDate = new Date(node.dob);
            let ageDifMs = Date.now() - birthDate.getTime();
            let ageDate = new Date(ageDifMs); // miliseconds from epoch
            return ageDate.getUTCFullYear() - 1970 + (ageDate.getUTCMonth() / 12);
        }

    },

}
</script>
<style>
.decade-rect {
    fill: transparent;
    stroke: var(--bs-body-color);
}

.participant-age-g {
    position: relative;
    width: 100%;
    height: 100%;
    color: var(--bs-body-color);

    text {
        fill: var(--bs-body-color);
    }
}
</style>
