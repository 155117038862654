import {factory} from "./ClassLoader"
var DynamicRecord = function () {
	let DynamicRecord = class extends factory.classes.Record {

	}
	return DynamicRecord;
}
DynamicRecord.function = true
export {
	DynamicRecord
}