<template>
    <div>

    </div>
</template>
<script>
import questionnaireGettersMixin from '~/mixins/questionnaire_getters'
import { mapGetters } from 'vuex'
export default {
    mixins:[questionnaireGettersMixin],
    name: 'consent-header',
	props: {
        consentSection:{}
	},
}
</script>