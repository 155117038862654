import axios from 'axios'
import Vue from 'vue'
import store from '~/store'
import VueI18n from 'vue-i18n'
import { resolveImport } from '../helpers/module-importer.js'
import datepicker from '@showjoe/ffui/src/components/datepicker.vue'
import { date } from 'validatorjs/src/lang/en'

Vue.use(VueI18n)
const dateTimeFormats = {
  'en': {
    numeric: { year: 'numeric', month: 'numeric', day: 'numeric' },
    short: { year: 'numeric', month: 'short', day: 'numeric' },
    long: { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }
  },
  'en-GB': {
    numeric: { year: 'numeric', month: 'numeric', day: 'numeric' },
    short: { year: 'numeric', month: 'short', day: 'numeric' },
    long: { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short', hour: 'numeric', minute: 'numeric' }
  },
  'el-GR': {
      numeric: { year: 'numeric', month: 'numeric', day: 'numeric' }
  },
  'pt-PT': {
      numeric: { year: 'numeric', month: 'numeric', day: 'numeric' }
  },
  'pl-PL': {
      numeric: { year: 'numeric', month: 'numeric', day: 'numeric' }
  },
  'ro-RO': {
      numeric: { year: 'numeric', month: 'numeric', day: 'numeric' }
  },
  'cs-CZ': {
      numeric: { year: 'numeric', month: 'numeric', day: 'numeric' }
  },
  'de-DE': {
      numeric: { year: 'numeric', month: 'numeric', day: 'numeric' }
  },
  'fr-FR': {
      numeric: { year: 'numeric', month: 'numeric', day: 'numeric' }
  },
  'nl-NL': {
      numeric: { year: 'numeric', month: 'numeric', day: 'numeric' }
  },
  'nb-NO': {
      numeric: { year: 'numeric', month: 'numeric', day: 'numeric' }
  },
  'sv-SE': {
      numeric: { year: 'numeric', month: 'numeric', day: 'numeric' }
  },
}
// $locales = [
//   'en' => 'EN',
//   'el' => 'el-GR',
//   'pt' => 'pt-PT',
//   'pl' => 'pl-PL',
//   'ro' => 'ro-RO',

// ];
// if ($langTesting) {
//   $locales = [
//       'en' => 'en-GB',
//       'cs' => 'cs-CZ',
//       'de' => 'de-DE',
//       'el' => 'el-GR',
//       'fr' => 'fr-FR',
//       'nl' => 'nl-NL',
//       'nb' => 'nb-NO',
//       'pl' => 'pl-PL',
//       'pt' => 'pt-PT',
//       'ro' => 'ro-RO',
//       'sv' => 'sv-SE',

//   ];
// }
const numberFormats = {
  'en-GB': {
    version: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
    },
  },
  'el-GR': {
    version: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
    },
  },
  'pt-PT': {
    version: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
    },
  },
  'pl-PL': {
    version: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
    },
  },
  'ro-RO': {
    version: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
    },
  },
  'cs-CZ': {
    version: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
    },
  },
  'de-DE': {
    version: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
    },
  },
  'fr-FR': {
    version: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
    },
  },
  'nl-NL': {
    version: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
    },
  },
  'nb-NO': {
    version: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
    },
  },
  'sv-SE': {
    version: {
      style: 'decimal', minimumSignificantDigits: 2, maximumSignificantDigits: 5
    },
  },
}

var locale = window.config.locale || 'en'
var fallbackLocale = {
  'de-BE': ['de'],
  'nl-BE': ['nl'],
  'fr-BE': ['fr'],
  'de-CH': ['de'],
  'fr-CH': ['fr'],
  'default': ['en'],
}
const loadedLanguages = [] // our default language that is preloaded
const i18n = new VueI18n({
  locale,
  fallbackLocale,
  dateTimeFormats,
  numberFormats,
  messages: {}
})

window.i18n = i18n
export function setI18nLanguage(lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  window.config.locale = lang
  // set datepicker default locale
  datepicker.props.locale.default = lang
  Vue.component('datepicker', datepicker)
  return lang
}

export function loadLanguageAsync(lang) {

  return import(`../lang/${lang}/index.js`)
    .then((resp) => {
      const { default: messages } = resp
      const LocaleMessages = resolveLang(messages)
      loadedLanguages.push(lang)
      i18n.mergeLocaleMessage(lang, LocaleMessages)
      setI18nLanguage(lang)
      store.dispatch('lang/addMessages', { messages: LocaleMessages, locale: lang, prefix: false })
    })
}

/**
 * @param {String} locale
 */
export async function loadMessages(locale) {
  const path = '../lang/' + locale + '/**/*.json'
  const { default: messages } = await import(`../lang/${locale}/index.js`)
  const LocaleMessages = resolveLang(messages)
  // i18n.mergeLocaleMessage(locale, LocaleMessages)
  store.dispatch('lang/addMessages', { messages: LocaleMessages, locale })

  // If the language was already loaded
  if (loadedLanguages.includes(locale)) {
    return Promise.resolve(setI18nLanguage(locale))
  }


}


/**
 * @param  {Object} modules
 * @return {Object}
 */
function resolveLang(modules) {
  return Object.keys(modules)
    .map(file =>
      [file.replace('./', '').replace(/(^.\/)|(\.json$)/g, ''), modules[file]]
    )
    .reduce((components, [name, component]) => {
      var nameArr = name.split('/')
      if (nameArr.length > 1) {
        if (!components[nameArr[0]]) components[nameArr[0]] = {}
        components[nameArr[0]][nameArr[1]] = component.default || component
      } else {
        components[name] = component.default || component
      }
      return components
    }, {})
}

; (async function () {
  locale = window.config.locale
  await loadLanguageAsync(locale)
})()


export default i18n

