import { factory } from "./ClassLoader"
import Validator from 'validatorjs'
import helpers from "../utils/helpers"
var SectionRecord = function () {
	let SectionRecord = class extends factory.classes.Record {
		#parent;
		#sectionDataitem;
		get parent() {
			return this.#parent;
		}
		set parent(value) {
			this.#parent = value;
		}
		get sectionDataitem() {
			return this.#sectionDataitem;
		}
		set sectionDataitem(value) {
			this.#sectionDataitem = value;
		}
		get sectionDataitems() {
			return this.sectionDataitem.children;
		}
		constructor(values, parent, sectionDataitem) {
			super(values)
			this.parent = parent;
			this.sectionDataitem = sectionDataitem;
		}
		copy() {
			var copy = new this.__proto__.constructor(helpers.hardCopy(this))
			copy.sectionDataitem = this.sectionDataitem
			copy.original = this.original
			copy.parent = this.parent
			return copy;
		}
		get questionnaire() {
			return this.parent.questionnaire;
		}
		get validationRules() {
			return this.questionnaire.sectionValidationRules[this.sectionDataitem.name];
		}
		static make(parent, dataitem, values) { // expand this, (generize + model resolving)
			if (!values) {
				values = parent[dataitem.name];
			}
			if (dataitem.type == "collection") {
				var children = [];
				for (var value of values) {
					var record = SectionRecord.makeRecord(value, parent, dataitem)
					children.push(record)
				}
				return children;
			}
			if (dataitem.type == "pu_one_to_one") {

			}
			console.error("Section Record Make Failure " + dataitem.type)
		}
		static makeRecord(value, parent, dataitem) {
			// if (dataitem.type=="collection")
			// return SectionRecord.make(parent, dataitem, value)
			return new SectionRecord(value, parent, dataitem)
		}
		validate() {
			// console.log("validating")
			var rules = this.questionnaire.sectionValidationRules[this.sectionDataitem.name].getRules({flat:true});
			var messages = this.questionnaire.sectionValidationRules[this.sectionDataitem.name].getMessages({flat: true});

			// console.log(this.ha_date_admit, this.ha_date_discharge)

			var validator = new Validator(this, rules, messages);
			if (validator.fails()) {
				this.mergeErrorsIn(validator.errors.errors)
				this.isValid = false
			} else {
				this.mergeErrorsIn({})
				this.isValid = true
			}
			return this.errors;
		}
	}
	return SectionRecord;
}
SectionRecord.function = true
export {
	SectionRecord
}