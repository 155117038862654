<template>
    <g class="shapeContainer">
        <slot></slot>
    </g>
</template>

<script>

export default {
    name: 'force-shape-container',
    inject: ['width', 'height'],
    props: {
        forceName: {
            type: String,
            default: 'contain'
        },
        property: {
            type: String,
            default: 'id'
        },
        propertyValue: {
            required: true
        },
        cluster: {
            type: Object,
            required: true
        },

    },
    computed: {
        shapeProps() {
            var shape = this.$el.childNodes[0];
            return {
                shape,
                dimensions: {
                    x: shape.x.baseVal.value,
                    y: shape.y.baseVal.value,
                    width: shape.width.baseVal.value,
                    height: shape.height.baseVal.value,
                    cx: shape.x.baseVal.value + shape.width.baseVal.value / 2,
                    cy: shape.y.baseVal.value + shape.height.baseVal.value / 2,
                },
            }
        }
    },
    mounted() {
        this.updateContainForce();
    },
    methods: {
        updateContainForce() {
            this.$emit('forceCreated', { name: this.forceName, force: this.forceCluster });
        },
        forceCluster(nodes = []) {
            const strength = 0.2;
            return alpha => {
                nodes.forEach(d => {
                    d.vx += (this.cluster.x - d.x) * alpha * strength;
                    d.vy += (this.cluster.y - d.y) * alpha * strength;
                });
            };

        },
    }
}
</script>

<style lang="scss" scoped></style>