<template>
    <div class="list-group-item" ref="dropdownItem">
        <div @click="toggle" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
            {{ item.value.name ? item.value.name : item.model }}
            <badge>
                {{
                    item.isRelationship ? item.value.type : item.isScope ? 'scope' : 'field'
                }}
            </badge>
        </div>
        <div v-if="isOpen" ref="dropdownMenu">
            <nested-list v-for="(child, index) in item.relationships" :key="child.key" :item="child"
                :level="level + 1" />
        </div>
    </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
export default {
    name: 'NestedDropdownItem',
    props: {
        item: Object,
        level: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            isOpen: false,
            popperInstance: null,
        };
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
            console.log('Toggling dropdown', this.isOpen);
            this.$nextTick(() => {
                if (this.isOpen && !this.popperInstance) {
                    this.createPopperInstance();
                }
            });
        },
        createPopperInstance() {
            console.log('Creating popper instance');
            this.popperInstance = createPopper(this.$refs.dropdownItem, this.$refs.dropdownMenu, {
                placement: 'auto',
            });
        },
        handleMouseEnter() {
            // Optional: Open dropdown on hover
        },
        handleMouseLeave() {
            // Optional: Close dropdown on leave
        },
    },
    watch: {
        isOpen(newValue) {
            if (!newValue && this.popperInstance) {
                this.popperInstance.destroy();
                this.popperInstance = null;
            }
        },
    },
}
</script>

<style scoped>
/* Add styles for dropdown items and nested levels */
</style>