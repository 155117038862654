<template>
  <div>
  Logging in...
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Form from 'vform'
import Cookies from 'js-cookie'
export default {
  middleware: 'guest',
  metaInfo() {
    return { title: this.$t('login.title') }
  },
  data: () => ({
    form: new Form({
      id: '',
      token: ''
    }),
    remember: false
  }),
   mounted() {
    this.form.id = this.$route.params.id;
    this.form.login_token = this.$route.params.token;

    this.login();
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isTeam', 'isCountryTeam', 'isParticipant', 'inSequence'])
  },
  methods: {
    async login () {
      // Submit the form.
      const { data } = await this.form.post('/token_login')

      // Save the token.
      this.$store.dispatch('auth/saveToken', {
        token: data.token,
        remember: this.remember
      })

      // Fetch the user.
      await this.$store.dispatch('auth/fetchUser')
      
      // Redirect home.
      this.redirect();
    },
    redirect() {
      const intendedUrl = Cookies.get('intended_url')
      if (intendedUrl) {
        Cookies.remove('intended_url')
        this.$router.push({ path: intendedUrl })
      } else {
        var name = this.isAdmin || this.isTeam || this.isCountryTeam ? 'admin.dashboard' : 'home'
        if (this.isParticipant && ['registration'].includes(this.inSequence)) name = 'registration_check'
        this.$router.push({ name })
      }
    }
  }
}
</script>