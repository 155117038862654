import {Collection} from "./Collection"
import {AttributeBag} from "./AttributeBag"
import {ClassRegistry} from "../utils/ClassRegistry"
var factory = new ClassRegistry(); 
factory.setClasses({
	Collection, AttributeBag
})

export {
	factory
}