import Vue from 'vue'
import store from '~/store'
import router from '~/router'
import i18n from '~/plugins/i18n'
import App from '~/components/App.vue'
import '../sass/app.scss'
import '~/plugins'
import '~/components'
import '~/directives'

import { makeNavigation } from '~/router/navigation.js'
window.eventBus = new Vue();

// Vue.config.productionTip = false

/* eslint-disable no-new */
const mainApp = new Vue({
  i18n,
  store,
  router,
  ...App,
  computed: {
    ...App.computed,
    config: () => window.config,
    navigation: () => makeNavigation(mainApp)

  }
})

// console.log(mainApp)
if (import.meta.hot) {
  // import('./websockets.js').then((mod) => {
  //   console.log('websockets', mod.websocketsClient)
  // })
  // import.meta.hot.send('my:from-client', { msg: 'Hey!' })
  // import.meta.hot.on('my:greetings', (data) => {
  //   console.log(data.msg) // hello
  // })
}
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js'
// window.Pusher = Pusher;
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     // forceTLS: false,
//     forceTLS: false,
//     wsHost: '127.0.0.1',
//     wsPort: 6001,
//     encrypted: false,
//     authorizer: (channel, options) => {
//       return {
//           authorize: (socketId, callback) => {
//             console.log(socketId)

//               axios.post('broadcasting/auth', {
//                   socket_id: socketId,
//                   channel_name: channel.name
//               })
//               .then(response => {
//                   callback(false, response.data);
//               })
//               .catch(error => {
//                   callback(true, error);
//               });
//           }
//       };
//   },
// });

// import.meta.hot.accept((newModule) => {
//   console.log('accept', newModule)
//     if (newModule) {
//       // newModule is undefined when SyntaxError happened
//       console.log('newModule', newModule)
//     }
//   })
