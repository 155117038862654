<template>
    <dropdown justified btn-outline :items="items" value-name="id" label-name="name" multiple :value="value"
        @input="(v) => $emit('input', v)">
        <template #btn-inner="{ chosenLabel }">
            <div class="d-flex">
                <template v-for="(c_id, i) in value">
                    <scope :country="items.find((c) => c.id == c_id)" v-slot="{ country }" :key="'scope' + c_id">
                        <svg class="flag-img rounded-left img-fluid me-1"
                            style="width:24px; box-shadow: 0 0 1px 1px #ddd;" :alt="country.name" :title="country.name">
                            <use :href="'/images/home/flags-sprite.svg#' + country.code.toLowerCase()">
                            </use>
                        </svg>
                    </scope>
                </template>
                <span v-if="!value">{{ chosenLabel }}</span>
            </div>
        </template>
        <template #list-item-prepend="{ item }">
            <svg class="flag-img rounded-left img-fluid ms-n1 me-1" style="width:24px; box-shadow: 0 0 1px 1px #ddd;"
                :alt="item.name">
                <use :href="'/images/home/flags-sprite.svg#' + item.code.toLowerCase()">
                </use>
            </svg>
        </template>
    </dropdown>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        value: {
            type: [String, Number, Array],
            default: null
        }
    }
}
</script>

<style lang="scss" scoped></style>