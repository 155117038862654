<template>
    <router-view>
        <template #row="{ row, rowIndex }">
            <div class="dt-row">
                <div class="dt-column dt-row-number p-2">{{ rowIndex }}</div>
                <div class="dt-column dt-column-patient_code p-2">{{ row.patient_code }}</div>
                <div class="dt-column dt-column-name p-2">{{ row.name }}</div>
                <div class="dt-column dt-column-email p-2">{{ row.email }}</div>
                <div class="dt-column dt-column-notification p-2">
                    <div class="p-2">
                        {{ row.notification.type }}
                    </div>
                </div>
                <div class="dt-column dt-column-created_at">
                    <div class="p-2">
                        <span>
                            {{ $d(new Date(row.created_at), 'numeric', 'en-GB') }}
                        </span>
                    </div>
                </div>
                <div class="dt-column dt-column-status p-2">

                </div>
                <div class="dt-column dt-column-view">
                    <div class="d-flex p-2 align-items-center m-auto">
                        <router-link :key="'patient_overview-btn-' + row.id"
                            :to="{ name: 'admin.patient_overview', params: { patient_id: row.patient_id } }"
                            class="btn btn-outline-primary btn-block" active-class="active">
                            <fa icon="play" size="lg" fixed-width />
                        </router-link>
                    </div>
                </div>

            </div>
        </template>
    </router-view>
</template>

<script>
export default {
    name: "NotificationsWrapper",
    data() {
        return {};
    },
    created() {
    }
};
</script>

<style lang="scss" scoped></style>