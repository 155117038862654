<template>
    <div class="grid-container">
        <div class="questionnaire-diagram-list list-group list-group-horizontal">
            <div v-for="diagram in diagrams" :key="diagram.name" @click="diagramShown = diagram.name"
                :class="{ active: diagram.name === diagramShown }" class="diagram-item list-group-item">
                {{ diagram.title }}
            </div>
        </div>
        <questionnaire-completion v-if="diagramShown == 'questionnaireCompletion'"
            class="grid-item shadow"></questionnaire-completion>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import questionnaireCompletion from '~/components/admin-dashboard/questionnaire-completion.vue'

export default {
    name: "questionnaire-dashboard",
    components: {
        questionnaireCompletion
    },
    data() {
        return {
            diagramShown: 'questionnaireCompletion',
        }
    },
    mounted() {
        // if (!this.requestLoading && !this.requestCompleted) this.fetchDemographicData()
    },
    computed: {
        ...mapState('resources/admin', ['dashboard']),
        ...mapState('resources/questionnaires', ['questionnaire']),
        diagrams() {
            return [
                { name: 'questionnaireCompletion', title: this.$t('admin.dashboard.diagrams.QuestionnaireCompletion') }
            ]
        },
        demographicData() {
            if (this.dashboard.stream.patients)
                return this.dashboard.stream.patients
            else return []
        },
        request() {
            if (this.dashboard.stream.requests['patient.demographics'])
                return this.dashboard.stream.requests['patient.demographics']
            else return {}
        },
        requestLoading() {
            return this.request.loading && !this.request.loaded
        },
        requestCompleted() {
            return this.request.percentageComplete === 100
        }

    },
    methods: {
        ...mapActions('resources/questionnaires', ['getQuestionnaire']),
        fetchDemographicData() {
            console.log('fetching demographic data')
            this.getQuestionnaire({ name: 'demographics', params: { include: ['dataitems'], append: 'lookups' } })
        }
    }
}
</script>
