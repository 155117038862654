<template>
  <questionnaire :title="$t('questionnaires.other_conditions.title')">
    <fieldset>
      <legend class="mb-4">
        <alert type="info" v-t="'questionnaires.other_conditions.comorb_help'"></alert>
        <alert v-if="records.issue!=1" type="info">{{$t('questionnaires.other_conditions.followup')}}</alert>
      </legend>
      <row class="g-2 divtable table-striped">
        <template v-for="cmName in comorbNames">
          <form-group :cols="[{ sm: 6 }, { sm: 6 }]" class="stripe" :error="errors[cmName]" :di="dataitems[cmName]">
            <btn-group outline justified :disabled="isSubmitted" :di="dataitems[cmName]" v-model="records[cmName]">
            </btn-group>
          </form-group>
        </template>
        <form-group :cols="[{ sm: 6 }, { sm: 6 }]" class="stripe">
          <btn block outline @click.native="noneOfAbove" v-t="'questionnaires.other_conditions.none_of_above'"></btn>
        </form-group>
      </row>
    </fieldset>
  </questionnaire>
</template>
<script>
import questionnaire from "~/mixins/questionnaire.js"
export default {
  name: 'other-conditions',
  mixins: [questionnaire],
  computed: {
    comorbNames() {
      return [
        'como_uveitis',
        'como_bowel_disease',
        'como_depression',
        'como_anxiety',
        'como_cholesterol',
        'como_high_pressure',
        'como_diabetes',
        'como_heart_disease',
        'como_heart_failure',
        'como_stroke_tia',
        'como_hearing_loss',
        'como_asthma',
        'como_ckd',
        'como_atrial_fib',
        'como_chronic_constipation',
        'como_bronc_emph_copd',
        'como_cancer',
        'como_chronic_liver_disease',
        'como_psych_bipo_disorder',
        'como_epilepsy',
        'como_parkinsons',
        'como_multiple_sclerosis',
        'como_hiv_aids',

      ]
    }
  },
  methods: {
    noneOfAbove() {
      for (let index = 0; index < this.comorbNames.length; index++) {
        const diName = this.comorbNames[index];
        const di = this.dataitems[diName]
        this.records[diName] = 1
      }
    }
  }
}

</script>
<style>
.divtable {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
}

.divtable.table-striped>.stripe.form-group {
  padding: 1rem;
  align-items: middle;
}

.divtable.table-striped>.stripe.form-group:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  background-color: var(--bs-table-striped-bg);
}
</style>
