export default {
  computed: {
    paginationPerPageList() {
      return [
        { value: '15', label: '15' },
        { value: '30', label: '30' },
        { value: '45', label: '45' },
      ]
    },
  },
  methods: {
    updatePagination(link, pagination, method = false) {

      // if (link.url.startsWith('/')) {
      //   link.url = this.$root.config.api_url + this.$route.path + link.url
      // }
      var url = new URL(link.url, pagination.path)
      var search = url.search.substring(1);
      var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
      this.$router.replace({ name: this.$route.name, query: { page: params.page } })
      // console.log('updatePagination', params)
      this.makeRequest(params, pagination, method);
    },
    updatePerPage(pagination, method = false) {
      this.setLocalStorage('per_page', pagination.per_page ? pagination.per_page : 15)
      this.makeRequest({}, pagination, method);
    },
    makeRequest(params = {}, pagination, method = false) {
      // console.log('makeRequest', params, pagination, method)
      var filters = this.addFilterKeys()
      if (!method) method = this.$options.apiMethod
      params = { ...this.params, ...this.$route.query, ...filters, ...params, }

      if (this.getLocalStorage('sort')) params.sort = this.getLocalStorage('sort')
      if (this.getLocalStorage('per_page')) params.per_page = this.getLocalStorage('per_page')

      // if (JSON.stringify(params) != JSON.stringify(this.params)) {
      //   //   params = { ...params, ...this.params, ...filters }
      this[method]({ params }).finally((resp) => {
        if (pagination && pagination.last_page < pagination.current_page) {
          this.$router.replace({ name: this.$route.name, query: { page: pagination.last_page } })
        }
      });
      // }

    },
    updateFilters(params = {}, pagination, method = false) {
      this.makeRequest(params, pagination, method);
    },
    addFilterKeys() {
      // console.log('addFilterKeys')
      var filters = {}
      var groups = {}
      for (var item in this.filters) {
        if (this.filters[item]) filters['filter[' + item + ']'] = this.filters[item]
      }
      if (this.groups) {
        for (var item in this.groups) {
          if (this.groups[item]) groups['groups[' + item + ']'] = this.groups[item]
        }
      }
      return { ...filters, ...groups }
    },
    setSorting(column, pagination) {
      var name = this.$route.name
      var params = this.params
      params.sort = params.sort == column ? '-' + column : column

      this.setLocalStorage('sort', params.sort)
      this.makeRequest(params, pagination);
    },
    sortingClass(col) {
      var sort = this.params.sort
      return sort == col.name ? 'sorting' : sort == '-' + col.name ? 'sorting-desc' : ''
    },
    getLocalStorage(field) {
      // console.log('getLocalStorage', field)
      var name = this.$route.name
      return localStorage.getItem(name + '.pagination.' + field)
    },
    setLocalStorage(field, value) {
      var name = this.$route.name
      // console.log('setLocalStorage', 'name', name, 'field', field, 'value', value)
      localStorage.setItem(name + '.pagination.' + field, value)
    }
  },
  watch: {
    '$route.query.page'(newVal, oldVal) {
      // if (this.$route.query.page) this.pagination.current_page = this.$route.query.page
      // this.makeRequest();
    },
    // 'pagination.page'(newVal, oldVal) {
    //   if (oldVal && newVal != oldVal) {
    //     this.makeRequest();
    //   }
    // },
    // pagination: {
    //   handler(newVal, oldVal) {
    //     if (oldVal && newVal != oldVal) {
    //       console.log('pagination changed', newVal, oldVal)
    //       this.makeRequest();
    //     }
    //   }, deep: true
    // }
  },
}
