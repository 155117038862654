import { mapState, mapActions } from 'vuex'
export default {
    computed: {
        ...mapState('resources/countries', ['countries']),
        countriesArr() {
            var cObj = this.countriesObject

            return this.countries.map(country => {
                var c = Object.values(cObj).find(cl => cl.id === country.id)
                return {
                    ...country,
                    ...c,
                }
            })
        },
        countriesObject() {
            return this.$t('public.home.countries.list')
        },
        availableInCountries() {
            var countryLang = Object.values(this.$t('public.home.countries.list'))
            var countryList = this.countries.filter(country => country.active)

            // merge where id matches
            countryList = countryList.map(country => {
                return {
                    ...countryLang.find(c => c.id === country.id),

                    ...country,
                }
            })

            return countryList
        },
        comingSoonCountries() {
            var countryLang = Object.values(this.$t('public.home.countries.list'))
            var countryList = this.countries.filter(country => !country.active)
            countryList = countryList.map(country => {
                return {
                    ...countryLang.find(c => c.id === country.id),
                    ...country,
                }
            })

            return countryList

        },
        countryColours() {
            // combine the available and coming soon countries, colour the available countries green and the coming soon countries red
            const colours = {}
            var aic = this.availableInCountries
            var csc = this.comingSoonCountries
            //aic and csc are arrays of objects
            for (const [key, value] of Object.entries(aic)) {
                colours[value.name] = 'var(--primary-highlight-color)'
            }

            for (const [key, value] of Object.entries(csc)) {
                colours[value.name] = 'var(--secondary-color)'
            }

            // for (const [key, value] in aic) {
            //     colours[value.name] = aic.includes(key) ? 'var(--primary-highlight-color)' : 'grey'
            // }
            // for (const [key, value] in csc) {
            //     colours[value.name] = csc.includes(key) ? 'var(--secondary-color)' : 'grey'
            // }
            // for (const [key, value] of Object.entries(this.availableInCountries)) {
            //     colours[value.name] = Object.keys(this.availableInCountries).includes(key) ? 'var(--primary-highlight-color)' : 'grey'
            // }
            // for (const [key, value] of Object.entries(this.comingSoonCountries)) {
            //     colours[value.name] = Object.keys(this.comingSoonCountries).includes(key) ? 'var(--secondary-color)' : 'grey'
            // }
            return colours

        },
        countryClasses() {
            const classes = {}
            var aic = this.availableInCountries
            var csc = this.comingSoonCountries

            for (const [key, value] of Object.entries(aic)) {
                classes[value.name] = Object.keys(aic).includes(key) ? 'available' : 'coming-soon'
            }
            for (const [key, value] of Object.entries(csc)) {
                classes[value.name] = Object.keys(csc).includes(key) ? 'coming-soon' : 'available'
            }
            // for (const [key, value] of Object.entries(this.availableInCountries)) {
            //     classes[value.name] = Object.keys(this.availableInCountries).includes(key) ? 'available' : 'coming-soon'
            // }
            // for (const [key, value] of Object.entries(this.comingSoonCountries)) {
            //     classes[value.name] = Object.keys(this.comingSoonCountries).includes(key) ? 'coming-soon' : 'available'
            // }
            return classes
        },
    },
    methods: {
        ...mapActions('resources/countries', ['getPublicCountries']),
        patientCountry(patient) {
            var country = this.countries.find(country => country.id == patient.country_id)
            return this.getCountryLang(country)
        },
        getCountryLang(country) {

            var countryLang = Object.values(this.$t('public.home.countries.list'))
            // merge where id matches
            return {
                ...country,
                ...countryLang.find(c => c.id === country.id)
            }

        }
    }
}