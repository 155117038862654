import {factory} from "../utils/ClassLoader"
import helpers from "../utils/helpers"

let ValidationRuleCollection = class extends factory.classes.Collection {
	constructor(validationRules) {
		super(validationRules)
		// return new Proxy(this, handler)
	}
    setDataitem(dataitem) {
        for (var item of this) {
            item.setDataitem(dataitem)
        }
    }
    getRules(options) {
        if (!options) options = {}
        var rules = {};
        for(var rule of this) {
            var ruleKey = rule.getRuleKey(options);
            if (!rules[ruleKey]) {
                rules[ruleKey] = []
            }
		    rules[ruleKey].push(rule.rule);
        }
        return rules;
    }
    getMessages(options) {
        if (!options) options = {}
        var messages = {};
        if (options.id_as_message) {
            var ruleField =  "id"
        } else if (options.rule_as_message) {
            var ruleField =  "rule"
        } else {
            var ruleField =  "message"
        }
        for(var rule of this) {
            if (rule[ruleField]) {
                messages[rule.ruleName+"."+rule.getRuleKey(options)] = rule[ruleField];
            }
        }
        return messages;
    }
    serverErrors(errorDataitems) {
        var newErrors = {}
        for(var dataitem in errorDataitems) {
            for(var ruleText of errorDataitems[dataitem]) {
                var rule = this[dataitem+"."+ruleText]
                if (rule.server_only) {
                    // if (!newErrors[dataitem]) newErrors[dataitem] = [];
                    // newErrors[dataitem].push([rule.message]);
                    newErrors[dataitem] = rule.message
                }
            }
        }
        return newErrors;
    }
}

export {
	ValidationRuleCollection
}
