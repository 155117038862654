<template>
  <div class="d-flex">
    <btn-group class="ms-auto">
      <template v-for="item in di.lookup.items">
        <btn class="tick-cross" :disabled="disabled" :aria-label="item.label" :key="di.name+'_'+item.label" :btn-class="item.value==1 ? 'success-transparent' : 'danger-transparent'" v-model="val" :true-value="item.value">
          <fa :icon="item.value==1 ? 'check':'times'" :style="iconStyle(val, item.value, item.value==1 ? '--bs-success':'--bs-danger')" size="2x"></fa>
        </btn>
      </template>
    </btn-group>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'tick-cross',
  mixins:[],
  props: {
    di: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {

    }
  },
  methods: {
    iconStyle(val, iconVal, colVar) {
      return { color: val == iconVal ? 'var(' + colVar + ')' : 'var(--bs-gray-500)' }
    },
  },
  computed: {

  },
  data () {
    return {
      val: this.value
    }
  },
  watch: {
    val (val) {
      this.$emit('input', val)
    },
    value: { handler (value) {
      this.val = (value)
      },
      deep: true
    }
  }
}
</script>
<style scoped>
.tick-cross {
  --bs-btn-disabled-border-color: #0000;
}
.btn-success-transparent {
  /* --bs-btn-hover-border-color: #00500b; */
  --bs-btn-active-border-color: #0000;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
}
.btn-danger-transparent {
  /* --bs-btn-hover-border-color: #941a1a; */
  --bs-btn-active-border-color: #0000;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
}
.btn-transparent {
  /* --bs-btn-hover-border-color: #1a5b94;     */
  --bs-btn-active-border-color: #0000;
  --bs-btn-focus-shadow-rgb: 128, 64, 64;
}
</style>