import { factory as uFactory } from "../utils/ClassLoader"
import { factory as qFactory } from "./ClassLoader"
import { groupBy } from 'lodash'
let Questionnaire = class extends uFactory.classes.AttributeBag {
	constructor(attributes) {
		super(attributes);
		if (attributes.dataitems) {
			this.setDataitems(attributes.dataitems)
		}
		if (attributes.lookups) {
			this.dataitems.setLookups(attributes.lookups);
		}
		if (attributes.records) {
			this.setRecords(attributes.records)
		}
		return this;
	}
	setRecords(records) {
		this.records = qFactory.classes.Record.make(records, this)
		// if (!this.records||this.records.constructor.name=="Object") {
		// 	this.records = qFactory.classes.Record.make(records, this)
		// } else {
		// 	console.log(this.records)
		// 	this.records.assignContents(records);
		// }
	}
	setDataitems(dataitems) {
		for (var index in dataitems) {
			dataitems[index].setQuestionnaire(this)
		}
		this.buildValidationRules();
		this.dataitems.setChildren();
	}
	buildValidationRules() {
		this.validationRules = new qFactory.classes.ValidationRuleCollection([]);
		this.sectionValidationRules = {};
		for (var dataitem of this.dataitems) {
			if (dataitem.validation_rules) {
				for (var rule of dataitem.validation_rules) {
					this.validationRules[dataitem.name + "." + rule.rule] = rule;
					if (dataitem.section) {
						if (!this.sectionValidationRules[dataitem.section]) {
							this.sectionValidationRules[dataitem.section] = new qFactory.classes.ValidationRuleCollection([]);
						}
						this.sectionValidationRules[dataitem.section][dataitem.name + "." + rule.rule] = rule;
					}
				}
			}
		}
	}
	isSubmitted() {
		if (this.patient_questionnaire) {
			return !!this.patient_questionnaire.submitted_by
		} else {
			return undefined;
		}
	}
	doesSubmit() {
		return false;
	}
}
export {
	Questionnaire
}