import Vue from 'vue'
import dateMixin from '~/mixins/dates'
Vue.directive('clear', {
  unbind: function (el, binding, vnode) {
    // console.log('v-clear') 
    // Find the elements record and clear it
    var record = binding && binding.value ? binding.value : false
    if (vnode.data.model && vnode.data.model.expression) record = vnode.data.model.expression
    if (record && record.startsWith('records.')) {
      record = record.substr(8)
    } else if (binding.arg == 'di') {
      record = vnode.componentInstance.di.name
    }
    if (!vnode.context.routeChanging && !vnode.context.questionnaireSaving) {
      if (vnode.data.model) vnode.data.model.callback()
      // vnode.context.$store.dispatch('questionnaires/updateRecord',{ field: record, value: null })
    }
  }
})
Vue.filter('humanDate', (dateStr) => {
  var date = moment(dateStr)
  var dateDiff = date.diff(moment())
  return moment.duration(dateDiff).humanize(true)
})
Vue.filter('dmy', (value) => {
  if (value)
    return dateMixin.methods.formatDateForDisplay(value);
})
Vue.filter('age', (value) => {
  if (value) {
    var date = moment(value, 'YYYY-MM-DD');
    if (date.isValid()) return moment().diff(date, 'years');
  }
})

Vue.prototype.$filters = Vue.options.filters

const transitionsCanBeUsed = !window.matchMedia('(prefers-reduced-motion: reduce)').matches
// src/directives/scrollSpy.js
Vue.directive('scroll-spy', {
  inserted(el, binding) {
    const defaultOptions = {
      sectionSelector: 'section',
      navLinkSelector: 'a[href^="#"]',
      offset: 50,
      activeClass: 'active'
    };
    const options = { ...defaultOptions, ...binding.value };
    const sections = document.querySelectorAll(options.sectionSelector);
    const navLinks = el.querySelectorAll(options.navLinkSelector);

    function setActiveLink() {
      let index = sections.length;

      while (--index && window.scrollY + options.offset < sections[index].offsetTop) { }
      navLinks.forEach((link) => link.classList.remove(options.activeClass));
      navLinks[index].classList.add(options.activeClass);
      el.style.setProperty('--nav-link-active-left', navLinks[index].offsetLeft + 'px');
      el.style.setProperty('--nav-link-active-width', navLinks[index].offsetWidth + 'px');
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setActiveLink();
        }
      });
    }, { threshold: 0 });

    sections.forEach(section => observer.observe(section));
    window.addEventListener('scroll', setActiveLink);
  },
  unbind() {
    window.removeEventListener('scroll', setActiveLink);
  }
});


// v-scroll-trigger should add an intersection observer to the element and add a class when it is in view
Vue.directive('scroll-trigger', {
  inserted(el, binding) {
    // if (!transitionsCanBeUsed) return
    const options = { threshold: 0, class: 'in-view', ...binding.value };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          el.classList.add(options.class);
        } else {
          el.classList.remove(options.class);
        }
      });
    }, options);
    observer.observe(el);
  },
  unbind(el, binding, vnode, oldVnode, observer) {
    // 
    if (!observer) return
    if (observer && observer.unobserve)
      observer.unobserve(el);
  }
});