<template>
    <card :body="false" class="patient-count-card">
        <router-link v-if="dashboard.data.patientCount" :to="{ name: 'admin.patients' }"
            class="stretched-link text-center text-decoration-none">
            <div class="text-decoration-none">
                <div class="display-3">{{ totalRecruits }}</div><span> / {{ totalTarget }}</span>
            </div>
        </router-link>
        <donut-country-recruitment v-if="dashboard.data.countryData" class="patientCountDonut img-fluid"
            :chart-data="dashboard.data.countryData" :label="label"></donut-country-recruitment>

    </card>
</template>

<script>
import donutCountryRecruitment from './donut-country-recruitment.vue'

export default {
    name: 'patient-count',
    components: {
        donutCountryRecruitment
    },
    inject: ['dashboard'],
    computed: {
        label() {
            if (this.dashboard.data.consentedPatientCount)
                return this.dashboard.data.consentedPatientCount / this.totalTarget * 100
            return 0
        },
        totalTarget() {
            if (this.dashboard.data.countryData) {
                return this.dashboard.data.countryData.reduce((acc, country) => acc + this.countryTotalTarget(country), 0)
            }
            return 25000
        },
        totalRecruits() {
            if (this.dashboard.data.countryData) {
                return this.dashboard.data.countryData.reduce((acc, country) => acc + country.recruitment_count, 0)
            }
            return 0
        },
        chartData() {
            var countries = []
            // get total recruitment for each country
            countries = this.dashboard.data.countryData.map(country => {
                return {
                    ...country,
                    recruitment_count: country.recruitment_count,
                    target: this.countryTotalTarget(country),
                }
            })
            return countries


        },
    },
    methods: {


        countryTotalTarget(country) {
            return country.recruitmentData.reduce((acc, period) => acc + period.target, 0);
        }
    }
}
</script>
