<template>
    <div v-if="!inChart">

    </div>
    <g v-else>
        <force-shape-container force-name="genderM" @forceCreated="(f) => updateForce('male', f)" property="gender"
            propertyValue="M" :cluster="clusters.M">
            <rect x="0" y="0" width="50%" height="100%" fill="white" />
        </force-shape-container>
        <force-shape-container force-name="genderF" @forceCreated="(f) => updateForce('female', f)" property="gender"
            propertyValue="F" :cluster="clusters.F">
            <rect x="50%" y="0" width="50%" height="100%" fill="white" />
        </force-shape-container>

        <g class="gender-icon gender-M" :transform="'scale(0.5) translate(' + groupWidth * 2.2 + ')'">
            <path :d="marsD" />
        </g>
        <line x1="50%" y1="0" x2="50%" y2="100%" stroke="black" stroke-width="1" />
        <g class="gender-icon gender-F" :transform="'scale(0.5)  translate(' + groupWidth * .2 + ')'">
            <path :d="venusD" />
        </g>
        <!-- <g>
            <template v-for="( node, n ) in  nodes ">
                <circle :key="'node' + n" :cx="node.x" :cy="node.y" :r="node.r"
                    :fill="$attrs.countryColours[node.country_id]" />

            </template>
        </g> -->
    </g>
</template>

<script>
import { forceSimulation, forceManyBody, forceCenter, forceCollide, forceRadial, forceX, forceY } from 'd3-force';
import forceShapeContainer from '../../charts/force-shape-container.vue'
const marsD = 'M304 176c0 61.9-50.1 112-112 112s-112-50.1-112-112s50.1-112 112-112s112 50.1 112 112zM224 349.1c81.9-15 144-86.8 144-173.1C368 78.8 289.2 0 192 0S16 78.8 16 176c0 86.3 62.1 158.1 144 173.1V384H128c-17.7 0-32 14.3-32 32s14.3 32 32 32h32v32c0 17.7 14.3 32 32 32s32-14.3 32-32V448h32c17.7 0 32-14.3 32-32s-14.3-32-32-32H224V349.1z'
const venusD = 'M289.8 46.8c3.7-9 12.5-14.8 22.2-14.8H424c13.3 0 24 10.7 24 24V168c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-33.4-33.4L321 204.2c19.5 28.4 31 62.7 31 99.8c0 97.2-78.8 176-176 176S0 401.2 0 304s78.8-176 176-176c37 0 71.4 11.4 99.8 31l52.6-52.6L295 73c-6.9-6.9-8.9-17.2-5.2-26.2zM400 80l0 0h0v0zM176 416c61.9 0 112-50.1 112-112s-50.1-112-112-112s-112 50.1-112 112s50.1 112 112 112z'
export default {
    name: 'participant-gender',
    inject: ['width', 'height'],
    components: { forceShapeContainer },
    props: {
        nodes: {
            type: Array,
            default: () => []
        },
        inChart: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            venusD,
            marsD,
            groupWidth: this.width / 2,
            maleCenter: this.width / 4,
            femaleCenter: this.width * 3 / 4,
            maleForce: null,
            femaleForce: null,
            clusters: {
                M: { x: this.width / 4, y: this.height / 2 },
                F: { x: this.width * 3 / 4, y: this.height / 2 }
            }
        }
    },
    mounted() {
        this.updateSimulationForces();

    },
    methods: {
        updateForce(name, force) {
            console.log('updateForce', name);
            this.updateSimulationForces();
        },
        updateSimulationForces() {
            this.$emit('forcesChanged', [
                { name: 'charge', force: forceManyBody().strength(-20) },
                { name: 'center', force: forceCenter(this.width / 2, this.height / 2).strength(1) },
                { name: 'collide', force: forceCollide().radius(5) },
                { name: 'x', force: forceX().strength(0.2).x(d => this.width / 2) },
                { name: 'y', force: forceY().strength(0.2).y(d => this.height / 2) },
                { name: 'cluster', force: this.forceCluster() }


            ]);
        },
        forceCluster(nodes = []) {
            const strength = 0.2;
            return alpha => {
                this.nodes.forEach(d => {
                    const cluster = this.clusters[d.gender];
                    d.vx += (cluster.x - d.x) * alpha * strength;
                    d.vy += (cluster.y - d.y) * alpha * strength;
                });
            };

        },


    },

}
</script>
<style>
.gender-icon {
    transform-origin: 0% 0%;

}
</style>