<template>
    <g class="treemap">
        <g v-for="parentNode in parentNodes" :key="parentNode.data.name" :style="{ transition: 'transform 1s' }">
            <g class="parentNode" :style="{ transition: 'transform 1s' }">
                <!-- Draw a larger rectangle for the parent node -->
                <rect :x="parentNode.x0" :y="parentNode.y0" :width="parentNode.x1 - parentNode.x0"
                    :height="parentNode.y1 - parentNode.y0" fill="none" stroke="black"
                    :key="'main' + parentNode.data.name">
                </rect>
                <!-- Add text for the parent node -->
                <text :x="parentNode.x0 + 5" :y="parentNode.y0 + 15" class="node-text"
                    :key="'title' + parentNode.data.name">
                    {{ parentNode.data.title }}:
                </text>
                <text :x="parentNode.x0 + 5" :y="parentNode.y0 + 30" class="node-text"
                    :key="'val' + parentNode.data.name">
                    {{ parentNode.value }}
                </text>
                <!-- <clipPath :id="'clip-' + parentNode.data.name">
                    <rect :x="parentNode.x0" :y="parentNode.y0" :width="parentNode.x1 - parentNode.x0"
                        :height="parentNode.y1 - parentNode.y0">
                    </rect>
                </clipPath> -->
                <!-- Iterate through the children (leaves) of the parent node -->
                <g v-for="node in parentNode.children" :key="node.data.name" class="node">
                    <rect v-if="node.data.value > 0" :x="node.x0" :y="node.y0" :width="node.x1 - node.x0"
                        :height="node.y1 - node.y0" :fill="node.data.fill ?? '#fff'" stroke="#ddd"
                        :key="'rect' + node.data.name">
                    </rect>


                    <g :style="{ transition: 'transform 1s' }">
                        <text v-if="node.data.value > 0"
                            :x="textAnchor(node, parentNode) == 'start' ? node.x0 + 4 : node.x1 - 4" :y="node.y0"
                            class="node-text" :width="node.x1 - node.x0" :text-anchor="textAnchor(node, parentNode)"
                            :alignment-baseline="alignmentBaseline(node, parentNode)"
                            :key="'node-text' + node.data.name">
                            {{ node.data.name }}: {{ node.data.value }}
                        </text>
                    </g>
                </g>
            </g>
        </g>
    </g>
</template>

<script>
import { hierarchy, treemap, treemapBinary, treemapDice, treemapSlice, treemapSliceDice, treemapSquarify } from "d3-hierarchy";
export default {
    name: "Treemap",
    props: {
        data: {
            type: Object,
            required: true
        },
        width: {
            type: Number,
            default: 800
        },
        height: {
            type: Number,
            default: 600
        },
        tiling: {
            type: String,
            default: "squarify"
        },
        padding: {
            type: [Number, Function]
        },
        paddingInner: {
            type: [Number, Function]
        },
        paddingOuter: {
            type: [Number, Function]
        },
        paddingTop: {
            type: [Number, Function]
        },
        paddingRight: {
            type: [Number, Function]
        },
        paddingBottom: {
            type: [Number, Function]
        },
        paddingLeft: {
            type: [Number, Function]
        }

    },
    computed: {

        computedTiling() {
            switch (this.tiling) {
                case "binary":
                    return treemapBinary;
                case "dice":
                    return treemapDice;
                case "slice":
                    return treemapSlice;
                case "sliceDice":
                    return treemapSliceDice;
                case "squarify":
                    return treemapSquarify;
                default:
                    return treemapSquarify;
            }
        },
        hierarchy() {
            return hierarchy(this.data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value);
        },
        root() {
            var tm = this.treemap()
            this.makeTreemapPadding(tm);
            tm.round(true);
            return tm(this.hierarchy);
        },
        parentNodes() {
            return this.root.children;  // Accessing the top-level nodes (questionnaires)
        }
    },
    methods: {
        treemap() {
            return treemap()
                .tile(this.computedTiling)
                .size([this.width, this.height])
                .round(true);
        },
        makeTreemapPadding(treemap) {
            if (this.padding) treemap.padding(this.padding);
            if (this.paddingInner) treemap.paddingInner(this.paddingInner);
            if (this.paddingOuter) treemap.paddingOuter(this.paddingOuter);
            if (this.paddingTop) treemap.paddingTop(this.paddingTop);
            if (this.paddingRight) treemap.paddingRight(this.paddingRight);
            if (this.paddingBottom) treemap.paddingBottom(this.paddingBottom);
            if (this.paddingLeft) treemap.paddingLeft(this.paddingLeft);

        },
        textAnchor(node, parentNode) {
            // if the node is on the left side of the parent, anchor the text to the start
            // otherwise, anchor the text to the end
            return node.x0 < parentNode.x0 + (parentNode.x1 - parentNode.x0) / 2 ? "start" : "end";
        },
        alignmentBaseline(node, parentNode) {
            // if the node is shorter than the text, align the text to the middle
            // otherwise, align the text to the top
            return node.y1 - node.y0 < 20 ? "middle" : "hanging";
        }
    }
};
</script>

<style lang="scss"></style>
