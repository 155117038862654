<template>
  <row>
    <column :sizes="{ md: 3 }" v-if="showIssueNav">
      <patient-issue-nav></patient-issue-nav>
    </column>
    <column :sizes="{ md: showIssueNav ? 9 : 12 }">
      <transition name="page" mode="out-in">
        <router-view v-if="questionnaireLoaded || $route.name == 'admin.patient_issue_overview'" />
        <card v-else>
          <div class="d-flex">
            <fa icon="cog" size="2x" spin class="m-auto" />
          </div>
        </card>
      </transition>
    </column>
  </row>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: "Questionnaire-wrapper",
  middleware: ['auth'],
  computed: {
    ...mapState('resources/questionnaires', ['routeChanging', 'questionnaireLoaded', 'questionnaireSaving']),
    ...mapGetters('resources/questionnaires', ['getQuestionnaireByName']),
    ...mapGetters('auth', ['isTeam']),
    questionnaire() {
      return this.getQuestionnaireByName(this.$route.meta.name)
    },
    showIssueNav() {
      return true
      if (this.questionnaireLoaded && this.questionnaire)
        return this.questionnaire.model_type == 'Scheduled'
    }
  }
}

</script>
