import Inputmask from 'inputmask'
Inputmask.extendAliases({
  'mysqldate': {
    regex: "([0-9]{4})-(0[1-9]|1[012]|00)-(0[1-9]|[12][0-9]|3[01]|00)",
  },
  'ffdate': {
    regex: "(0[1-9]|[12][0-9]|3[01]|--)/(0[1-9]|1[012]|--)/([0-9]{4})",
  },
  'ffdate_no_unknowns': {
    regex: "(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[012])/([0-9]{4})",
  },
  'ffdate_de': {
    regex: '([0-9]{4})-(0[1-9]|1[012]|--)-(0[1-9]|[12][0-9]|3[01]|--)',
  },
  'ffdate_de_no_unknowns': {
    regex: '([0-9]{4})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])',
  },
  'ffdate_ja': {
    regex: '([0-9]{4})年(0[1-9]|1[012]|--)月(0[1-9]|[12][0-9]|3[01]|--)日',
  },
  'ffdate_ja_no_unknowns': {
    regex: '([0-9]{4})年(0[1-9]|1[012])月(0[1-9]|[12][0-9]|3[01])日',
  },
  "alphaNumKey": {
    regex: "([A-Za-z]{1})([A-Za-z0-9._-]{1,63})",
  },
  'year': {
    regex: "([0-9]{4})",
  },
});
