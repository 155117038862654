import store from '~/store'

/**
 * This is middleware to check the current user role.
 *
 * middleware: 'role:admin,manager',
 */

export default (to, from, next, roles) => {
  // Grab the user
  const user = store.getters['auth/user']

  // Split roles into an  array
  roles = roles.split(',')
  // Check if the user has one of the required roles...
  var foundRole = false;
  for (var role of user.roles) {
    if (roles.includes(role.name)) {
      foundRole = true;
    }
  }
  if (!foundRole)
    for (var role of roles) {
      if (store.getters['auth/' + role]) {
        foundRole = true;
      }
    }

  if (!foundRole) {
    next('/unauthorized')
  }

  next()
}
