import axios from 'axios'
// state
export const state = {
  countries: [],
  country: {},
  pagination: {}
}

// getters
export const getters = {
}

// mutations
export const mutations = {
  GET_PUBLIC_COUNTRIES(state, data) {
    state.countries = data
  },
  GET_COUNTRIES(state, data) {
    state.countries = data.data
    state.pagination = data.meta
  },
  GET_COUNTRY(state, data) {
    state.country = data.country
  }
}

// actions
export const actions = {
  getPublicCountries({ commit }, request) {
    axios.get('countries', request)
      .then((resp) => {
        commit('GET_PUBLIC_COUNTRIES', resp.data)
      })
  },
  getCountries({ commit }, request) {
    axios.get('country', request)
      .then((resp) => {
        commit('GET_COUNTRIES', resp.data)
      })
  },
  getCountry({ commit }, request) {
    axios.get('country/' + request.country_id, request)
      .then((resp) => {
        commit('GET_COUNTRY', resp.data)
      })
  }
}
export const namespaced = true;
