<template>
    <div>
        <div>
            <div class="card-header d-flex">
                <div class="flex-grow-1 d-flex col-auto">
                    <h5 class="mb-0 me-4">Query: {{ query.name }}</h5>
                    <p>{{ query.description }}</p>
                </div>
                <slot name="header"></slot>
                <btn outline @click.native="saveActiveQuery({ query })" class="ms-auto p-1 mt-n1 me-2">
                    <fa icon="floppy-disk" clas="me-2"></fa>
                    Save Query
                </btn>
                <!-- show query builder btn -->
                <btn outline @click.native="showQueryBuilder = !showQueryBuilder" class="p-1 me-n1 mt-n1">
                    <fa icon="chevron-right" :rotation="showQueryBuilder ? 270 : 90"
                        :style="{ transition: 'all .2s' }" />
                </btn>
            </div>
            <collapse :show="showQueryBuilder">
                <div class="card-body">
                    <row>
                        <row>
                            <!-- <form-group v-if="query.id" :sizes="{ lg: 4 }">
                    <label for="queryName">Query Url</label>
                    <input type="text" class="form-control" id="queryName" :value="$root.config.base_url + $route.path"
                        readonly />
                </form-group> -->
                            <form-group :sizes="{ lg: 4 }">
                                <label for="queryName">Query Name</label>
                                <input type="text" class="form-control" id="queryName" v-model="query.name" />
                            </form-group>
                            <form-group :sizes="{ lg: 4 }">
                                <label for="queryDescription">Query Description</label>
                                <input type="text" class="form-control" id="queryDescription"
                                    v-model="query.description" />
                            </form-group>
                        </row>
                        <div v-if="loadedModels">
                            <form-group>
                                <label for="modelName">Model</label>
                                <dropdown btn-outline :items="models" label-name="name" value-name="name"
                                    :value="query.model" @input="changeModel">
                                </dropdown>
                            </form-group>


                        </div>
                        <row v-if="loadedModels && queryBuilderDataLoaded" class="g-2 m-2">
                            <template v-for="(model, index) in flattenedQueryBuilder">
                                <column auto :key="model.model" v-if="index == 0 || query.includes.includes(model.key)">
                                    <scope tag="div" class="card mb-2" :cardData="cardData(model, index)"
                                        v-slot="{ cardData }">
                                        <div class="card-header d-flex align-items-center">
                                            <fa icon="database" fixed-width class="ms-n2 me-2" />
                                            <p class="me-4 mb-0">{{ model.name ? model.name : model.model }}
                                            </p>
                                            <small class="me-4">{{
                                                model.type }}</small>
                                            <btn outline @click.native="toggleFieldVisibility(model, index)"
                                                class="p-1 me-n1 mt-n1">
                                                <fa icon="chevron-right" :rotation="cardData.fieldsVisible ? 270 : 90"
                                                    :style="{ transition: 'all .2s' }" />
                                            </btn>
                                        </div>
                                        <collapse :show="cardData.fieldsVisible">
                                            <list-group flush>
                                                <template v-for="field in model.fields">
                                                    <list-group-item :key="field.table + '.' + field.name"
                                                        @input="field.type == 'appends' ? toggleAppends(field) : toggleField(field)"
                                                        :class="['d-flex', { active: query.fields?.includes(field.table + '.' + field.name) || query.appends.includes(field.table + '.' + field.name) }]">
                                                        <label class="me-auto">{{ field.name }}</label>
                                                        <small>{{ field.type }}</small>
                                                    </list-group-item>
                                                </template>
                                            </list-group>
                                        </collapse>

                                        <div class="card-footer d-flex">
                                            <dropdown btn-outline :items="model.relationships" label-name="name"
                                                value-name="name" multiple>
                                                <template v-slot:btn="{ toggleDropdown }">
                                                    <fa icon="diagram-project" fixed-width class="me-2"
                                                        @click="toggleDropdown" />
                                                </template>
                                                <template v-slot:list="{ item }">
                                                    <button :id="'dd_' + item.name" :key="item.name"
                                                        :class="['dropdown-item d-flex me-4', { 'active': query.includes?.includes(getInclude(item, model)) }]"
                                                        @click="toggleInclude(item, model)" :value="item">
                                                        <span>{{ item.name }}</span>
                                                        <small class="ms-auto">{{ item.type }}</small>
                                                    </button>
                                                </template>
                                            </dropdown>
                                            <fa v-if="modelHasFiltersSet(model)" class="ms-auto" icon="filter"
                                                fixed-width></fa>
                                        </div>
                                    </scope>
                                </column>
                            </template>
                            <column auto v-if="query.model">
                                <filters :filters="query.filters" @update:filters="updateFilters"
                                    :properties="properties"></filters>
                            </column>
                        </row>

                        <div v-if="queryBuilderDataLoaded">
                            <!-- 
                <alert>

                    <pre v-if="query.includes">{{ query.includes }}</pre>
                </alert> -->
                            <!-- 
                <card :body="false">
                    <data-table :data="queryResults.data" :columns="queryResultsColumns" class="table-responsive">
                        <template #header-column="{ col }">
                            <div v-if="col.sortable" @click="sortField(col)"
                                :class="['dt-column dt-column-header dt-column-' + col.name, sortingClass({ name: col.table + '.' + col.name })]">
                                <div class="p-2" v-text="col.label" />
                            </div>
                            <div v-else :class="['dt-column dt-column-header dt-column-' + col.name + '']">
                                <div class="p-2" v-text="col.label" />
                            </div>
                        </template>
                    </data-table>
                    <div class="card-footer">
                        <div class="d-flex pt-2">
                            <ul class="pagination m-auto">
                                <li v-for="(link, n) in queryResults.links" :key="'pagination' + n"
                                    :class="['page-item', { active: link.active, disabled: !link.url }]">
                                    <a @click="updatePagination(link, queryResults)"
                                        :class="['page-link', { 'bg-dark text-white': $root.theme == 'dark' && !link.active }]"
                                        v-html="link.label" />
                                </li>
                            </ul>
                            <div class="m-auto">
                                <span
                                    v-t="{ path: 'settings.showing', args: { from: queryResults.from, to: queryResults.to, total: queryResults.total } }"></span>
                            </div>
                            <dropdown btn-outline v-model="queryResults.per_page" :items="paginationPerPageList"
                                class="m-auto" @input="updatePerPage">
                                <template #btn-inner="{ chosenLabel }">
                                    <span v-t="{ path: 'settings.per_page', args: { count: chosenLabel } }"></span>
                                </template>
                            </dropdown>
                        </div>
                    </div>
                </card> -->

                        </div>
                    </row>
                    <alert v-if="queryResults">
                        <div v-html="formattedSql"></div>
                    </alert>
                    <!-- <relational-chart v-if="queryBuilderLoaded" :data="flattenData(queryBuilder)"></relational-chart> -->
                </div>
                <div class="card-footer d-flex">
                    <btn @click.native="saveActiveQuery({ query })" class="ms-auto p-1 mt-n1">
                        <fa icon="floppy-disk" class="mr-1"></fa>
                        Save Query
                    </btn>
                </div>
            </collapse>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import filters from '~/components/QueryBuilder/filters.vue';
import relationalChart from '~/components/QueryBuilder/RelationalChart.vue';
const newFilter = { name: '', value: '', }
export default {
    name: "QueryBuilder",
    components: {
        filters,
        relationalChart
    },
    props: {
        queryId: {
            type: Number,
            default: null,
        },
        properties: {
            type: Array,
            default: () => ([]),
        },
    },
    layout: 'fullscreen',
    middleware: ['auth', 'team'],
    data() {
        return {
            loadedModels: false,
            // query: {
            //     fields: [],
            //     filters: [],
            //     sorts: [],
            //     includes: [],
            //     appends: [],
            // },
            newFilter: {
                name: '',
            },
            queryCards: {},
            queryBuilderDataLoaded: false,
            showQueryBuilder: true,
            showNewFilter: false,
        }
    },

    mounted() {
        if (this.$route.name == 'admin.query_builder_edit') {
            this.fetchQuery({ params: { id: this.$route.params.id } })
        }
        // if (this.queryId) {
        //     this.fetchQuery({ params: { id: this.queryId } })
        // }
        this.fetchModels()
            .then(() => {
                this.loadedModels = true;
            });
        // this.fetchQueryBuilderData({ params: { model: this.model.name } });
    },
    computed: {
        ...mapState('resources/QueryBuilder', ['query', 'models', 'queryBuilder', 'queryResults', 'queryBuilderLoaded', 'queryBuilderLoading']),
        ...mapGetters('resources/QueryBuilder', ['flattenedQueryBuilder']),
        paginationPerPageList() {
            return [
                { value: '15', label: '15' },
                { value: '30', label: '30' },
                { value: '45', label: '45' },
            ]
        },

        formattedSql() {
            if (!this.queryResults || !this.queryResults.sql) {
                return '';
            }
            var sql = this.queryResults.sql;
            var query = sql.query;
            var bindings = sql.bindings;
            // sql includes query and bindings
            // replace the bindings with the values

            // replace the ? with the bindings
            var i = 0;
            query = query.replace(/\?/g, function (match) {
                return '<span class="fw-bold">' + bindings[i++] + '</span>';
            });
            // replace the new lines with br

            return query;

        },
        filterList() {
            // filter list should use the flattenedQueryBuilder array to make the filters
            // they should be made up of the fields, scopes and relationships from each object in the array concatenated in a single array with a dot
            // 
            // any object in the flattenedQueryBuilder that has no key prop should omit it
            // any object in the flattenedQueryBuilder that has a key prop should include it as the start of dot notation

            // fields should be made up of the fields from each object in the array concatenated in a single array with a dot
            // scopes should be made up of the scopes from each object in the array
            // relationships should be made up of the relationships from each object in the array concatenated in a single array with a dot
            var fields = [];
            var scopes = [];
            var relationships = [];
            this.flattenedQueryBuilder.forEach(obj => {
                if (obj.fields) {
                    fields = fields.concat(obj.fields.map(field => {
                        return { name: obj.name ? obj.name + '.' + field.name : field.name, type: 'field' }
                    }));
                }
                if (obj.scopes) {
                    scopes = scopes.concat(Object.values(obj.scopes).map(scope => {
                        return { name: obj.name ? obj.name + '.' + scope.name : scope.name, type: 'scope', parameters: scope.parameters ? scope.parameters : [] }
                    }));
                }
                if (obj.relationships) {
                    relationships = relationships.concat(
                        Object.values(obj.relationships).map(relationship => {
                            return { name: obj.name ? obj.name + '.' + relationship.name : relationship.name, type: 'relationship' }
                        })
                    );

                }
            });
            // return an array of strings that are the fields, scopes and relationships from each object in the array 
            return [...fields, ...scopes, ...relationships];
        },
        queryResultsColumns() {
            if (!this.queryBuilder || !this.queryBuilder.fields) {
                return [];
            }
            var combinedFields = [...this.queryBuilder.fields, ...this.query.appends];
            var columns = combinedFields
                .filter(field => this.query.fields.includes(field.table + '.' + field.name) || this.query.appends.includes(field.table + '.' + field.name))
                .map(field => ({
                    name: field.name,
                    label: field.name,
                    type: field.type,
                    table: field.table,
                    sortable: true,
                }));
            if (this.query.includes.length > 0) {
                var relationships = this.flattenedQueryBuilder.filter(relationship => this.query.includes.includes(relationship.key));
                // var relationships = this.queryBuilder.relationships.filter(relationship => this.query.includes.includes(relationship.name));
                relationships.forEach(relationship => {
                    if (!relationship.fields) {
                        return;
                    }
                    relationship.fields.forEach(field => {
                        if (!this.query.fields.includes(field.table + '.' + field.name)) {
                            return;
                        }
                        // remove the last dot and anything following it from the relationship key (if it exists)
                        var relationshipKey = relationship.key.replace(/\.[^\.]*$/, '');
                        var relationship_name = relationship.name.replace(/([A-Z])/g, "_$1").toLowerCase();
                        if (relationshipKey && relationship_name != relationshipKey) relationship_name = relationshipKey + '.' + relationship_name;
                        columns.push({
                            name: relationship_name + '.' + field.name,
                            label: relationship_name + '.' + field.name,
                            type: field.type,
                            table: field.table,
                            sortable: true,
                        });
                    });
                });
            }
            return columns;
        },
        queryFieldsAsParams() {

            // return an RequestParams with the table as the key and the fields as the value
            var fieldArr = this.query.fields.reduce((acc, field) => {
                const [table, name] = field.split('.');
                if (!acc['fields[' + table + ']']) {
                    acc['fields[' + table + ']'] = [];
                }
                if (!acc['fields[' + table + ']'].includes(name)) acc['fields[' + table + ']'].push(name);
                return acc;
            }, {});
            for (const [key, value] of Object.entries(fieldArr)) {
                if (!value.includes('id')) {
                    // value.push('id');
                }
                fieldArr[key] = value.join(',');
            }
            return fieldArr;
        },
        queryParams() {
            return {
                model: this.query.model,
                ...this.cleanFilters(),
                sort: this.cleanSorts(),
                include: this.query.includes.join(','),
                ...this.queryFieldsAsParams
            }
        },
    },
    methods: {
        ...mapActions('resources/QueryBuilder', ['fetchQuery', 'saveQuery', 'clearQuery', 'fetchModels', 'fetchQueryBuilderData', 'runQuery']),
        changeModel(m) {
            var model = this.models.find((model) => model.name === m);
            this.query.model = model.name;
            this.queryBuilderDataLoaded = false
            this.fetchQueryBuilderData({ params: { model: model.name } })
                .then(() => {
                    console.log('queryBuilderDataLoaded', this.queryBuilder.model)
                    this.queryBuilderDataLoaded = true
                    // this.toggleFieldVisibility(this.queryBuilder);
                    // for (const relation of this.query.includes) {
                    //     this.toggleFieldVisibility(relation);
                    // }
                });
        },
        flattenData(data) {
            const nodes = [];
            const links = [];

            function recurse(model, parent = null) {
                nodes.push({ id: model.model });
                if (parent) {
                    links.push({
                        source: parent.model,
                        target: model.model,
                        type: model.type,
                        foreign_key: model.foreign_key
                    });
                }
                if (model.relationships) {
                    model.relationships.forEach(rel => recurse(rel, model));
                }
            }

            recurse(data);
            return { nodes, links };
        },
        modelHasFiltersSet(model) {
            for (var filterKey in this.query.filters) {
                var filter = this.query.filters[filterKey];
                var fmk = this.findModelFromFilterKey(filterKey)
                if (fmk && fmk.model === model.model) return true;

            }
            return false;
        },
        findModelFromFilterKey(key) {
            var keyArr = key.split('.');
            var model = this.queryBuilder;
            for (var i = 0; i < keyArr.length; i++) {
                if (model.scopes) {
                    if (model.scopes[keyArr[i]]) {
                        return model;
                    }
                }
                if (model.fields) {
                    if (model.fields.find(field => field.name == keyArr[i])) {
                        return model;
                    }
                }
                if (model.relationships) {
                    if (model.relationships.find(relationship => relationship.name == keyArr[i])) {
                        return model;
                    }
                }
            }
            return model;
        },
        cardData(model, index) {
            if (this.queryCards[model.key]) {
                return this.queryCards[model.key]
            } else {
                this.$set(this.queryCards, model.key, { fieldsVisible: index == 0 });
            }
        },
        getInclude(relationship, model) {
            return model.name ? model.name + '.' + relationship.name : relationship.name;
        },
        getFilter(name) {
            return this.filterList.find(filter => filter.name === name);
        },
        addFilter() {
            const filter = this.getFilter(this.newFilter.name);
            if (filter) {
                this.query.filters[filter.name]({
                    name: filter.name,
                    parameters: filter.parameters?.map(parameter => ({
                        name: parameter.name,
                        value: parameter.default || null,
                    })) || filter.type == 'field' ? [{ name: filter.name, value: null }] : null
                });
            }
            this.showNewFilter = false;
            this.newFilter = { name: '' };

        },
        updateFilters(filters) {
            this.query.filters = filters;
            this.$emit('query-changed', this.query)
            // this.runCurrentQuery();
        },
        runCurrentQuery() {
            // this.runQuery({
            //     params: {
            //         model: this.query.model,
            //         ...this.cleanFilters(),
            //         sort: this.cleanSorts(),
            //         include: this.query.includes,
            //         ...this.queryFieldsAsParams
            //     }
            // });
        },
        cleanFilters() {
            const query = { ...this.query };
            var filters = {};
            // filters should return an object with the name as the key and the value as the value
            Object.keys(query.filters).forEach((filterKey) => {
                var filter = query.filters[filterKey];
                var name = 'filter[' + filterKey + ']';
                filters[name] = filter.parameters && filter.parameters.length ? filter.parameters.join(',') : true;
            });
            return { ...filters };
        },
        cleanSorts() {
            const query = { ...this.query };
            var sorts = query.sorts.join(',')
            return sorts
        },
        sortField(col) {
            var fullColName = col.table + '.' + col.name;
            if (this.query.sorts.includes(fullColName)) {
                // change to desc
                var sort = this.query.sorts.find(sort => sort === fullColName);
                this.query.sorts.splice(this.query.sorts.indexOf(sort), 1);
                this.query.sorts.push('-' + fullColName);
            } else if (this.query.sorts.includes('-' + fullColName)) {
                // remove sort
                var sort = this.query.sorts.find(sort => sort === '-' + fullColName);
                this.query.sorts.splice(this.query.sorts.indexOf(sort), 1);
            } else {
                // add sort
                this.query.sorts.push(fullColName);
            }
            // this.runCurrentQuery();
        },
        sortingClass({ name }) {
            if (this.query.sorts.includes(name)) {
                return 'sorting';
            }
            if (this.query.sorts.includes('-' + name)) {
                return 'sorting-desc';
            }
            return '';
        },
        toggleField(field) {
            console.log('toggleFields', field)
            if (this.query.fields.includes(field.table + '.' + field.name)) {
                this.query.fields.splice(this.query.fields.indexOf(field.table + '.' + field.name), 1);
            } else {
                this.query.fields.push(field.table + '.' + field.name);
            }
            // this.runCurrentQuery();
        },
        toggleInclude(include, relationship = null) {
            var includeName = include.name;
            if (relationship) {
                var isRoot = relationship && relationship.model == this.query.model;
                includeName = isRoot ? include.name : relationship.name + '.' + include.name;
            }
            if (this.query.includes.includes(includeName)) {
                this.query.includes.splice(this.query.includes.indexOf(includeName), 1);
            } else {
                this.query.includes.push(includeName);
                // if (!this.query.fields.includes(include.primary_key)) {
                //     this.query.fields.push(include.primary_key);
                //     this.query.fields.push(include.foreign_key);

                // }
            }

            // this.runCurrentQuery();
        },
        toggleAppends(field) {
            if (this.query.appends.includes(field.table + '.' + field.name)) {
                this.query.appends.splice(this.query.appends.indexOf(field.table + '.' + field.name), 1);
            } else {
                this.query.appends.push(field.table + '.' + field.name);
            }
            // this.runCurrentQuery();
        },
        toggleFieldVisibility(model, index) {
            this.queryCards[model.key].fieldsVisible = !this.queryCards[model.key].fieldsVisible;
            // this.runCurrentQuery();            
        },
        saveActiveQuery({ query }) {
            var isNew = false
            if (!query.id && !query.created_at) {
                isNew = true
            }
            this.saveQuery({ query })
                .then(() => {
                    if (isNew) {
                        var id = this.query.id
                        if (this.$route.name == 'admin.query_builder_create') {
                            this.$router.push({ name: 'admin.query_builder_edit', params: { id } });
                        } else {
                            this.$emit('query-saved', this.query)
                        }
                    }
                });
        },
        updatePagination(link, pagination, method = false) {
            var url = new URL(link.url, pagination.path)
            var search = url.search.substring(1);
            var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            // this.$router.replace({ name: this.$route.name, query: { page: params.page } })
            // console.log('updatePagination', params)
            this.runQuery({
                params: {
                    ...this.queryParams,
                    page: params.page
                }
            });
        },
        getQueryBuilderData() {
            if (this.query.model) {
                this.fetchQueryBuilderData({ params: this.queryParams })
                    .then(() => {
                        this.queryBuilderDataLoaded = true
                        // this.toggleFieldVisibility(this.queryBuilder);
                        // for (const relation of this.query.includes) {
                        //     this.toggleFieldVisibility(relation);
                        // }
                        this.runCurrentQuery();
                    });
            }
        },
        updatePerPage(per_page) {
            this.runQuery({
                params: {
                    ...this.queryParams,
                    per_page
                }
            });
        },
    },
    watch: {

        'query.model': {
            handler(newVal, oldVal) {
                if (typeof oldVal == 'undefined') return
                // console.log('model changed', this.query.model, this.queryBuilderDataLoaded, newVal, oldVal)
                if (this.query.model)
                    this.getQueryBuilderData()
                else {
                    this.queryBuilderDataLoaded = false
                    this.clearQuery()
                    this.$emit('query-changed', this.query)
                }
            },
            immediate: true,
        },
        'query.includes': {
            handler(newVal, oldVal) {
                // console.log('includes changed', this.query.includes, newVal, oldVal)
                if (this.query.model)
                    this.getQueryBuilderData()
            },
            deep: true,
        },
        'query.filters': {
            handler(newVal, oldVal) {
                this.$emit('query-changed', this.query)
                // if (this.query.model)
                //     this.runCurrentQuery()
            },
            deep: true,
        },
        queryId: {
            handler(newVal, oldVal) {
                // console.log('queryId changed', this.queryId, newVal, oldVal)
                if (this.queryId) {
                    // if (!this.queryBuilderLoaded && !this.queryBuilderLoading)
                    this.fetchQuery({ params: { id: this.queryId } })

                }
                else {
                    this.queryBuilderDataLoaded = false
                    this.clearQuery()
                }
            },
            immediate: true,
        }
    },
}
</script>
