<template>
  <card>
    <div class="d-flex align-items-center mb-4">
      <img :alt="$t('public.cookie-policy.icon-alt')" :src="'/images/home/cookie.png'" class="me-4">
      <h1 class="display-2 flex-grow-1" v-html="$t('public.cookie-policy.title')"></h1>
    </div>
    <p class="lead" v-text="$t('public.cookie-policy.intro_lead', { appName })" />
    <p v-t="'public.cookie-policy.intro'" />
    <h4 v-t="'public.cookie-policy.how_title'" />
    <p v-t="'public.cookie-policy.how_we_use_cookies'" />
    <p v-t="'public.cookie-policy.cookies_used_intro'" />
    <ul>
      <li v-html="$t('public.cookie-policy.cookies_used_1')" />
      <li v-html="$t('public.cookie-policy.cookies_used_2')" />
      <li v-html="$t('public.cookie-policy.cookies_used_3')" />
    </ul>
    <p v-t="'public.cookie-policy.cookie_table_intro'" />
    <table class="table table-responsive">
      <thead>
        <tr>
          <th v-t="'public.cookie-policy.cookie'"></th>
          <th v-t="'public.cookie-policy.purpose'"></th>
          <th v-t="'public.cookie-policy.description'"></th>
          <th v-t="'public.cookie-policy.more_info'"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-t="'public.cookie-policy.laravel_session'"></td>
          <td v-t="'public.cookie-policy.laravel_session_purpose'"></td>
          <td v-t="'public.cookie-policy.laravel_session_description'"></td>
          <td></td>
        </tr>
        <tr>
          <td v-t="'public.cookie-policy._ga'"></td>
          <td v-t="'public.cookie-policy._ga_purpose'"></td>
          <td v-t="'public.cookie-policy._ga_description'"></td>
          <td>
            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
              target="_blank" v-t="'public.cookie-policy.more_info'"></a>
          </td>
        </tr>
      </tbody>
    </table>
    <h4 v-t="'public.cookie-policy.further_info_title'" class="mt-2" />
    <i18n path="public.cookie-policy.further_info" class="mb-2">
      <a target="_blank" :href="$t('public.cookie-policy.further_info_link')"
        v-t="'public.cookie-policy.further_info_link'"></a>
    </i18n>
    <h4 v-t="'public.cookie-policy.persistence_title'" class="mt-2" />
    <p v-t="'public.cookie-policy.persistence'" />
    <h4 v-t="'public.cookie-policy.changes_title'" />
    <i18n path="public.cookie-policy.changes" class="mb-2">
      <a :href="$t('public.cookie-policy.changes-link')" v-t="'public.cookie-policy.changes-link-text'"></a>
    </i18n>
  </card>
</template>
<script>
export default {
  data() {
    return {
      appName: window.config.appName,
    }
  }
}

</script>
