import Vue from "vue";
import componentList from "./components.json";
import { resolveImport } from '../helpers/module-importer.js'

import {
  HasError,
  AlertError,
  AlertSuccess,
} from "vform/components/bootstrap5";
const componentsImport = import.meta.glob("./**/*.vue");
const componentsResolved = resolveImport(componentsImport, './**/', /(^.\/)|(\.vue$)/g)
// Components that are registered globaly.
var path = "./";
componentList.forEach((componentName) => {
  var strBeforeSlash = componentName.replace(/[^/]*$/g, '')
  var cleanName = componentName.replace(strBeforeSlash, '')
  var c = componentsResolved[componentName]
  var Component = c.default || c;
  Vue.component(cleanName, Component);
});
[
  AlertError,
  AlertSuccess,
  HasError
].forEach(Component => {
  Vue.component(Component.name, Component)
})
