<template>
  <questionnaire :title="$t('questionnaires.personal_details.title')">
    <row>
      <form-group :sizes="{ md: 6 }" :error="errors.first_name" :di="dataitems.first_name" class="mb-4">
        <textbox :disabled="isSubmitted" :di="dataitems.first_name" v-model="records.first_name"></textbox>
      </form-group>
      <form-group :sizes="{ md: 6 }" :error="errors.last_name" :di="dataitems.last_name" class="mb-4">
        <textbox :disabled="isSubmitted" v-model="records.last_name"></textbox>
      </form-group>
      <form-group :sizes="{ md: 6 }" :error="errors.dob" :di="dataitems.dob" class="mb-4">
        <datepicker :disabled="isSubmitted" :di="dataitems.dob" v-model="records.dob"></datepicker>
      </form-group>
      <form-group :sizes="{ md: 6 }" :error="errors.gender" :di="dataitems.gender" class="mb-4">
        <btn-group outline :disabled="isSubmitted" :di="dataitems.gender" v-model="records.gender" justified></btn-group>
      </form-group>
      <form-group v-if="isTeam" :sizes="{md:6}" :error="emailError" :di="dataitems.email" class="mb-4">
        <textbox type="email" :disabled="isSubmitted" :di="dataitems.email" v-model="records.email"></textbox>
      </form-group>
    </row>
  </questionnaire>
</template>
<script>
import { mapGetters } from "vuex";
import questionnaire from "~/mixins/questionnaire"
export default {
  mixins: [questionnaire],
  name: 'personal_details',
  computed: {
    ...mapGetters('auth', ['isTeam']),
    emailError() {
      if (this.errors.email && this.errors.email.length) {
        return this.errors.email
      }
      if (this.errors.email_hash && this.errors.email_hash.length) {
        return this.errors.email_hash
      }
    }
  },
  mounted() {
    window.qc = this;
  }
}

</script>
