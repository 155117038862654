<template>
  <questionnaire :title="$t('questionnaires.demographics.title')">
    <fieldset>
      <alert v-if="records.issue != 1" type="info">{{ $t('questionnaires.demographics.followup') }}</alert>
      <form-group v-if="!dataitemLocked(dataitems.height_unit)"
        :error="diErrors('height_cm') || diErrors('height_feet')" class="mb-4">
        <row class="g-1 align-items-center">
          <column :sizes="{ md: 5 }">
            <label for="" v-t="'questionnaires.demographics.height'"></label>
          </column>
          <column :sizes="{ sm: 9, md: 4 }">
            <input-group>
              <template v-if="records.height_unit == 1">
                <textbox :di="dataitems.height_cm" v-model="records.height_cm" v-mask="numericMask" v-clear />
                <span class="input-group-text">{{ dataitems.height_cm.trans("suffix_unit") }}</span>
              </template>
              <template v-if="records.height_unit == 2">
                <number :di="dataitems.height_feet" v-model="records.height_feet" v-mask="numericMask" v-clear :min="0"
                  :max="10" />
                <span class="input-group-text" v-html="dataitems.height_feet.trans('suffix_unit')"></span>
                <number :di="dataitems.height_inches" v-model="records.height_inches" v-mask="numericMask" v-clear
                  :min="0" :max="11" />
                <span class="input-group-text" v-html="dataitems.height_inches.trans('suffix_unit')"></span>
              </template>
            </input-group>
          </column>
          <column :sizes="{ sm: 3, md: 3 }">
            <form-group :error="diErrors('height_unit')">
              <dropdown justified class="d-flex flex-grow-1" btn-class="primary d-flex align-items-center flex-grow-1"
                btn-outline :disabled="isSubmitted" :items="dataitems.height_unit.lookup.items"
                v-model="records.height_unit">
                <template #btn-inner="{ chosenLabel }">
                  <span class="me-auto">{{ dataitems.height_unit.label }}: {{ chosenLabel }}</span>
                </template>
              </dropdown>
            </form-group>
          </column>
        </row>
      </form-group>
      <form-group :error="diErrors('weight_kg') || diErrors('weight_stone') || diErrors('weight_pounds')" class="mb-4">
        <row class="g-1 align-items-center">
          <column :sizes="{ md: 5 }" class="">
            <label for="" v-t="'questionnaires.demographics.weight'"></label>
          </column>
          <column :sizes="{ sm: 9, md: 4 }">
            <input-group>
              <template v-if="records.weight_unit == 1">
                <textbox :key="'weight_kg'" :di="dataitems.weight_kg" v-model="records.weight_kg"
                  v-mask="decimalMask" />
                <span class="input-group-text">{{ dataitems.weight_kg.trans("suffix_unit") }}</span>
              </template>
              <template v-if="records.weight_unit == 2">
                <number :key="'weight_stone'" :di="dataitems.weight_stone" v-model="records.weight_stone"
                  v-mask="numericMask" />
                <span class="input-group-text">{{ dataitems.weight_stone.trans("suffix_unit") }}</span>
                <number :key="'weight_pounds'" :di="dataitems.weight_pounds" v-model="records.weight_pounds"
                  v-mask="numericMask" :min="0" :max="16" />
                <span class="input-group-text">{{ dataitems.weight_pounds.trans("suffix_unit") }}</span>
              </template>
            </input-group>
          </column>
          <column :sizes="{ sm: 3, md: 3 }">
            <form-group :error="diErrors('weight_unit')">
              <dropdown justified class="d-flex flex-grow-1" btn-class="primary d-flex align-items-center flex-grow-1"
                btn-outline :disabled="isSubmitted" :items="dataitems.weight_unit.lookup.items"
                v-model="records.weight_unit">
                <template #btn-inner="{ chosenLabel }">
                  <span class="me-auto">{{ dataitems.weight_unit.label }}: {{ chosenLabel }}</span>
                </template>
              </dropdown>
            </form-group>
          </column>
        </row>
      </form-group>
      <form-group :error="diErrors('waist_cm') || diErrors('waist_inches')" class="mb-4"
        :helptext="$t('questionnaires.demographics.waist_help')">
        <row class="g-1 align-items-center">
          <column :sizes="{ md: 5 }">
            <label for="" v-t="'questionnaires.demographics.waist'"></label>
          </column>
          <column :sizes="{ sm: 9, md: 4 }">
            <input-group>
              <template v-if="records.waist_unit == 1">
                <textbox :key="'waist_cm'" :di="dataitems.waist_cm" v-model="records.waist_cm" v-mask="decimalMask" />
                <span class="input-group-text">{{ dataitems.waist_cm.trans("suffix_unit") }}</span>
              </template>
              <template v-if="records.waist_unit == 2">
                <textbox :key="'waist_inches'" :di="dataitems.waist_inches" v-model="records.waist_inches"
                  v-mask="numericMask" />
                <span class="input-group-text">{{ dataitems.waist_inches.trans("suffix_unit") }}</span>
              </template>
            </input-group>
          </column>
          <column :sizes="{ sm: 3, md: 3 }">
            <form-group :error="diErrors('waist_unit')" row-class="align-items-center">
              <dropdown justified class="d-flex flex-grow-1" btn-class="primary d-flex align-items-center flex-grow-1"
                btn-outline :disabled="isSubmitted" :items="dataitems.waist_unit.lookup.items"
                v-model="records.waist_unit">
                <template #btn-inner="{ chosenLabel }">
                  <span class="me-auto">{{ dataitems.waist_unit.label }}: {{ chosenLabel }}</span>
                </template>
              </dropdown>
            </form-group>
          </column>
        </row>

      </form-group>
      <form-group :error="errors.postcode" :di="dataitems.postcode" :cols="[{ md: 5 }, { md: 4 }]"
        row-class="g-1 align-items-center" class="mb-4">
        <textbox :di="dataitems.postcode" v-model="records.postcode" />
      </form-group>
      <form-group v-if="askEthnicity && !dataitemLocked(dataitems.ethnicity)" :di="dataitems.ethnicity"
        :error="errors.ethnicity" class="my-4">
        <template #label="{ di }">
          <div class="d-flex align-items-center">
            <label class="me-4 h5 mb-0">{{ di.label }}
            </label>
            <fa size="2x" icon="question-circle" @click="advice.ethnicity = !advice.ethnicity"
              class="advice-question-icon" />
          </div>
          <collapse :show="advice.ethnicity">

            <alert type="info" class="mt-2">
              {{ $t('questionnaires.demographics.ethnicity_reason') }}
            </alert>
          </collapse>
        </template>
        <template #default>
          <list-group flush :class="['form-group mb-4', hasError('ethnicity') ? 'is-invalid' : 'is-valid']">
            <template v-if="!records.ethnicity_no_response">
              <list-group-item :key="group" v-for="(items, group) in groupedEthnicities">
                <row>
                  <column :sizes="{ lg: 12 }">
                    <h6>{{ questionnaire.trans('category.' + group) }}</h6>
                  </column>
                  <column :sizes="{ lg: 12 }">
                    <btn-group :vertical="['xs', 'sm'].includes($mq)" justified
                      :class="{ 'btn-group-wrapped': !['xs', 'sm'].includes($mq) }">
                      <btn outline :key="item.label" v-model="records.ethnicity" v-for="item in items"
                        :true-value="item.value" :false-value="null">
                        <span v-if="item.hasTrans('label_' + countryCode)">{{ item.trans('label_' + countryCode)
                          }}</span>
                        <span v-else>{{ item.label }}</span>
                      </btn>
                    </btn-group>
                  </column>
                </row>
              </list-group-item>
            </template>
            <template v-if="displayOtherEthnicity">
              <form-group :error="errors.ethnicity_other" :di="dataitems.ethnicity_other" :cols="[{ md: 5 }, { md: 4 }]"
                row-class="g-1 align-items-center" class="mt-2 mb-4">
                <textbox v-clear :di="dataitems.ethnicity_other" v-model="records.ethnicity_other" />
              </form-group>
            </template>
            <list-group-item v-if="displayNoResponse" style="border-top-width: medium">
              <btn class="btn-block" outline :key="dataitems.ethnicity_no_response.label"
                v-model="records.ethnicity_no_response" :true-value="true" :false-value="null">
                <span v-if="dataitems.ethnicity_no_response.hasTrans('label_' + countryCode)">{{
                  dataitems.ethnicity_no_response.trans('label_' + countryCode) }}</span>
                <span v-else>{{ dataitems.ethnicity_no_response.label }}</span>
              </btn>
            </list-group-item>
          </list-group>
        </template>
      </form-group>
      <form-group :di="dataitems.skin_tone" :error="errors.skin_tone" class="my-4">
        <template #label="{ di }">
          <div class="d-flex align-items-center">
            <label class="me-4 h5  mb-0">{{ di.label }}
            </label>
            <fa icon="question-circle" size="2x" @click="advice.skin_tone = !advice.skin_tone"
              class="advice-question-icon" />
          </div>
          <collapse :show="advice.skin_tone">

            <alert type="info" class="mt-2">
              {{ $t('questionnaires.demographics.skintone_reason') }}
            </alert>
          </collapse>
        </template>
        <template #default="{ di }">
          <row class="g-2">
            <column v-for="lui in di.lookup.items" :key="di.name + lui.value" :sizes="[6, { sm: 4, lg: 2 }]">
              <btn outline block v-model="records[di.name]" :true-value="lui.value" :false-value="null"
                :class="['h-100 card-btn p-0']" :title="lui.json.description">
                <row class="g-1">
                  <column :sizes="{ sm: 12 }">
                    <div class="card-header" :style="{ background: lui.json.hex }"></div>
                  </column>
                  <column :sizes="{ sm: 12 }">
                    <div class="card-body p-2">
                      <label for="">{{ lui.label }}</label>
                      <!-- <small>{{lui.json.description}}</small> -->
                    </div>
                  </column>
                </row>
              </btn>
            </column>
          </row>
        </template>
      </form-group>
      <row class="g-4">
        <form-group :cols="[{ md: 6 }, { md: 6 }]" row-class="align-items-center" :error="errors.history_psoriasis"
          :di="dataitems.history_psoriasis" label-class="d-block">
          <btn-group outline :disabled="isSubmitted" :di="dataitems.history_psoriasis"
            v-model="records.history_psoriasis" justified></btn-group>
        </form-group>
        <form-group :cols="[{ md: 6 }, { md: 6 }]" row-class="align-items-center" :error="errors.history_arthritis"
          :di="dataitems.history_arthritis" label-class="d-block">
          <btn-group outline :disabled="isSubmitted" :di="dataitems.history_arthritis"
            v-model="records.history_arthritis" justified></btn-group>
        </form-group>
      </row>
    </fieldset>
  </questionnaire>
</template>
<script>
import questionnaire from "~/mixins/questionnaire.js"
export default {
  name: 'demographics-questionnaire',
  mixins: [questionnaire],
  data() {
    return {
      advice: {
        ethnicity: false,
        skin_tone: false
      }
    }
  },
  computed: {
    noResponseItem() {
      return this.dataitems.ethnicity.lookup.items.find(function () {
        return
      })
    },
    displayOtherEthnicity() {
      var otherValues = ['36', '17'];
      return (otherValues.includes(this.records.ethnicity + "") && this.countryCode == 'PT')
    },
    displayNoResponse() {
      return this.countryCode == 'PT';
    },
    askEthnicity() {
      return this.questionnaire.extraData.askEthnicity;
    },
    groupedEthnicities() {
      var ethnicities = this.getGroupedEthnicities(this.countryCode)
      if (Object.keys(ethnicities).length == 0) {
        return this.getGroupedEthnicities(FALLBACK_ETHNICITY_COUNTRY);
      } else {
        return ethnicities;
      }
    },
    countryCode() {
      const FALLBACK_ETHNICITY_COUNTRY = "GB"
      if (this.questionnaire.extraData.country) {
        return this.questionnaire.extraData.country;
      } else {
        return FALLBACK_ETHNICITY_COUNTRY;
      }

    },
    decimalMask() {
      return {
        'alias': 'decimal', 'autoGroup': true, 'digits': 2, 'digitsOptional': false, 'placeholder': '0.00', rightAlign: true, clearMaskOnLostFocus: !1
      }
    },
    numericMask() {
      return {
        'alias': 'numeric', 'autoGroup': true, 'digits': 0, 'digitsOptional': false, 'placeholder': '0', rightAlign: true, clearMaskOnLostFocus: !1
      }
    }
  },
  methods: {
    getGroupedEthnicities(countryCode) {
      var groupedEthnicities = {}
      var sortedItems = this.dataitems.ethnicity.lookup.items.sort((item1, item2) => {
        return (item1.json[this.countryCode + "_order"] ?? 0) - (item2.json[this.countryCode + "_order"] ?? 0)
      });
      if (this.dataitems && this.dataitems.ethnicity)
        sortedItems.map((lui) => {
          if (lui.json.group[countryCode]) {
            if (!groupedEthnicities[lui.json.group[countryCode]]) groupedEthnicities[lui.json.group[countryCode]] = []
            groupedEthnicities[lui.json.group[countryCode]].push(lui)
          }
        })
      return groupedEthnicities
    }
  },
}

</script>