<template>
  <card class="text-center">
    <img :src="'/images/hpos_logo.svg'" :alt="$t('public.home.hpos_logo_alt')" style="max-width: 300px;" class="m-auto" />

    <p class="lead my-4" v-t="'participant.home.confirmation.thanks'"></p>
    <btn size="lg" class="mb-4" @click.native="updatePatientAndProgress"
      v-t="'participant.home.confirmation.get_started'"></btn>
  </card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { getNextKeyInSequence } from '~/router/sequences'
export default {
  middleware: 'check-registration',
  mixins: [],
  created() {

  },
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin', 'inSequence']),
    lp() {
      return "participant.confirmation."
    },
    fullName() {
      if (this.user)
        return this.user.first_name + " " + this.user.last_name;
      return "";
    },

  },
  methods: {
    ...mapActions('auth', ['updateUsersPatient']),
    updatePatientAndProgress() {
      if (this.user.patient) {
        var in_sequence = this.user.patient.in_sequence
        if (this.user.patient.status == 'confirmation') {
          var status = getNextKeyInSequence(this.user.patient.in_sequence, 'confirmation')
          in_sequence = null
          status = null
          this.updateUsersPatient({ id: this.user.patient.id, status, in_sequence })
            .then(() => {
              this.$router.push({ name: 'home' })
              this.$gtag.event({
                event_category: 'auth',
                value: 'confirmation',
                event_label: 'User confirmed',
                user_id: this.user.id
              })
            })
        } else {
          this.$router.push({ name: 'home' })
        }
      }
    }
  },
}

</script>
