import Vue from 'vue';
import { library, config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";
import './fontAwesomeImports.js';


config.autoAddCss = false;

Vue.component("fa", FontAwesomeIcon);
Vue.component('fa-layers', FontAwesomeLayers);
Vue.component('fa-layers-text', FontAwesomeLayersText);

export function importIcons(iconArr, iconImport) {
    var icons = {};
    for (const fileName in iconImport) {
        var mod = iconImport[fileName];
        if (iconArr.includes(fileName)) icons[fileName] = mod;
    }
    library.add(icons);
}