<template>
  <svg :width="width" :height="height" :viewBox="'0 0 ' + width + ' ' + height">
    <g :transform="'translate(' + width / 2 + ',' + height / 2 + ')'">
      <!-- Background Circle -->
      <path class="background" :style="'fill:' + backgroundColour" :d="arc({ startAngle: 0, endAngle: tau })"></path>

      <!-- Target Arcs -->
      <path v-for="(d, i) in pieData" :key="'target-' + i" :class="'arc target'" :stroke="d.data.json.hex" fill="none"
        :d="targetArc(d)"></path>

      <!-- Actual Recruitment Arcs -->
      <path v-for="(d, i) in pieData" :key="'actual-' + i" :class="'arc actual'" :fill="d.data.json.hex"
        :d="actualArc(d)"></path>

      <!-- Label -->
      <text y="12" :font-size="'50px'" :fill="'var(--bs-body-color)'" :style="'text-anchor: middle'">{{
    Math.round(label)
        }}%</text>

      <slot></slot>
    </g>
  </svg>
</template>
<script>
import { select, arc, scaleOrdinal, pie, interpolate, interpolateRound } from 'd3';
var tau = 2 * Math.PI;
export default {
  name: 'donut-country-recruitment',
  props: {
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    },
    innerRadius: {
      type: Number,
      default: 80
    },
    outerRadius: {
      type: Number,
      default: 90
    },
    label: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      default: 'test'
    },
    chartData: {
      type: Array
    },
    colours: {
      type: Array,
      default: () => (["var(--bs-success)", "var(--bs-danger)", "rgba(120,120,120,0.1)"])
    },
    backgroundColour: {
      type: String,
      default: 'rgba(120,120,120,0.1)'
    }
  },
  mounted() {
    this.svg = select(this.$el)
    this.$nextTick(function () {
      // this.draw();
    });
  },
  // render(h) {
  //   return h('svg', {
  //     attrs: {
  //       id: this.id,
  //       width: this.width,
  //       height: this.height,
  //       viewBox: "0 0 " + this.width + " " + this.height
  //     }
  //   }, [

  //   ]);
  // },
  watch: {
    // chartData: {
    //   handler(oldVal, val) {
    //     var hasChanged = oldVal[0].val !== val[0].val || oldVal[1].val !== val[1].val || oldVal[2].val !== val[2].val
    //     if (hasChanged)
    //       this.$nextTick(function () {
    //         // this.redraw(oldVal, val);
    //       });
    //   }, deep: true
    // }
  },
  computed: {
    arc() {
      return arc().innerRadius(this.innerRadiusComputed).outerRadius(this.outerRadiusComputed);
    },
    tau() {
      return 2 * Math.PI;
    },
    innerRadiusComputed() {
      return this.innerRadius || this.outerRadius - 10
    },
    outerRadiusComputed() {
      return this.outerRadius || this.width / 2
    },
    targetArc() {
      // Adjust if needed to represent target differently
      return this.arc;
    },

    pieData() {
      return pie()
        .sort(null)
        .value(function (d) { return d.currentTarget; })
        (this.chartData);
    },

  },
  methods: {
    actualArc(d) {
      // Calculate the actual arc based on the proportion of recruitment to target

      let adjustedArc = this.arc;
      let proportion = d.data.recruitment_count / d.data.currentTarget;
      // console.log(proportion, d.data.recruitment_count, d.data.currentTarget, d.data);
      adjustedArc.endAngle(d.startAngle + (d.endAngle - d.startAngle) * proportion);
      return adjustedArc(d);
    }

  }

}

</script>
