<template>
  <questionnaire :title="$t('questionnaires.lifestyle.title')">
    <row class="mb-4 pb-4 border-bottom">
      <form-group :sizes="{ lg: 6 }" :cols="[{ lg: 4 }, { lg: 8 }]" :error="errors.smoking" :di="dataitems.smoking"
        class="mb-4" row-class="align-items-center">
        <btn-group outline :disabled="isSubmitted" :di="dataitems.smoking" v-model="records.smoking" justified
          :vertical="$mq | mq({ sm: true, md: false })"></btn-group>
      </form-group>
      <template v-if="records.smoking == 3">
        <form-group :sizes="{ lg: 6 }" :cols="[{ lg: 4 }, { lg: 8 }]" :error="errors.smoking_how_many"
          :di="dataitems.smoking_how_many" class="">
          <div class="input-group">
            <textbox :di="dataitems.smoking_how_many" v-model="records.smoking_how_many" v-mask="{
              'alias': 'decimal', 'autoGroup': true, 'digits': 2, 'digitsOptional': false, 'placeholder': '0.00', rightAlign: true, clearMaskOnLostFocus: !1
            }">
            </textbox>
            <div class="input-group-append">
              <span class="input-group-text">{{ dataitems.smoking_how_many.trans("suffix_unit") }}</span>
            </div>
          </div>
        </form-group>
      </template>
    </row>
    <row class="mb-4 pb-4 border-bottom">
      <form-group :cols="[{ md: 6 }, { md: 6 }]" :error="errors.alcohol" :di="dataitems.alcohol"
        row-class="align-items-center">
        <div class="d-flex">
          <input-group>
            <textbox :di="dataitems.alcohol" v-model="records.alcohol" v-mask="{
              'alias': 'decimal', 'autoGroup': true, 'digits': 2, 'digitsOptional': false, 'placeholder': '0.00', rightAlign: true, clearMaskOnLostFocus: !1
            }">
            </textbox>
            <template #append><span class="input-group-text">{{ dataitems.alcohol.trans("suffix_unit")
                }}</span></template>
          </input-group>
          <btn theme="-" @click.native="alcoholUnitsInfoShown = !alcoholUnitsInfoShown" class="p-0 m-1">
            <fa icon="question-circle" size="2x" class="advice-question-icon" />
          </btn>
        </div>
      </form-group>
      <collapse :show="alcoholUnitsInfoShown" class="p-4">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>{{ $t('questionnaires.lifestyle.alcohol_units_table.headers.type') }}</th>
              <th>{{ $t('questionnaires.lifestyle.alcohol_units_table.headers.units') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="r in $t('questionnaires.lifestyle.alcohol_units_table.rows')">
              <td>{{ r.type }}</td>
              <td>{{ r.units }}</td>
            </tr>
          </tbody>
        </table>
      </collapse>
    </row>
    <row class="g-2 mb-4">
      <form-group :cols="[{ md: 6 }, { md: 6 }]" :error="errors.recent_trauma" :di="dataitems.recent_trauma"
        row-class="align-items-center">
        <btn-group outline :disabled="isSubmitted" :di="dataitems.recent_trauma" v-model="records.recent_trauma"
          justified></btn-group>
      </form-group>
      <form-group :cols="[{ md: 6 }, { md: 6 }]" :error="errors.recent_infection" :di="dataitems.recent_infection"
        row-class="align-items-center">
        <btn-group outline :disabled="isSubmitted" :di="dataitems.recent_infection" v-model="records.recent_infection"
          justified></btn-group>
      </form-group>
    </row>
  </questionnaire>
</template>
<script>
import questionnaire from "~/mixins/questionnaire.js"
export default {
  name: 'lifestyle',
  mixins: [questionnaire],
  data() {
    return {
      alcoholUnitsInfoShown: false
    }
  }
}

</script>
