<template>
  <questionnaire :title="$t('questionnaires.mdhaq.title')">
    <form-group :cols="[{ xl: 9 }, { xl: 3 }]" :errors="errors.MDHAQ_Required" :di="dataitems.MDHAQ_Required"
      v-slot="{ di }" class="mb-4">
      <btn-group outline :vertical="['xs', 'sm', 'md'].includes($mq)" :di="di" v-model="records[di.name]"
        :readonly="isSubmitted" justified></btn-group>
    </form-group>
    <collapse :show="records.MDHAQ_Required == 1" @hidden="clearRecords(records, mdhaqItems)">
      <alert type="info" class="fs-5" v-t="'questionnaires.mdhaq.instructions'" />
      <row class="g-4">
        <column>
          <template v-for="diName in mdhaqItems">
            <form-group :error="errors[diName]" :di="dataitems[diName]" v-slot="{ di }" class="mb-4">
              <btn-group outline :vertical="['xs', 'sm', 'md'].includes($mq)" :di="di" v-model="records[di.name]"
                justified></btn-group>
            </form-group>
          </template>
        </column>
      </row>
    </collapse>
    <card class="h-100" v-if="isSubmitted && records.MDHAQ_Required == 1">
      <row>
        <column :sizes="{ sm: 6, lg: 4 }" :offset="{ sm: 3, lg: 0 }">
          <h4 class="display-4 text-center text-uppercase" v-t="'questionnaires.questionnaire.score'"></h4>
          <score-speedo :score="{ score, min: 0, max: 3 }" :ticks="30" :colours="['#198754', '#ffc107', '#dc3545']"
            :domain="[0, 0.625, 3]"></score-speedo>
        </column>
        <column :sizes="{ lg: 8 }">
          <alert type="info" v-if="allScored">
            <h4 v-if="score >= 0 && score <= 0.5" v-t="'questionnaires.mdhaq.feedback.score_lte_0pt5.title'"></h4>
            <p v-if="score >= 0 && score <= 0.5" v-t="'questionnaires.mdhaq.feedback.score_lte_0pt5.message'"></p>
            <h4 v-if="score > 0.5 && score < 0.625" v-t="'questionnaires.mdhaq.feedback.score_gt_0pt5-0pt625.title'"></h4>
            <p v-if="score > 0.5 && score < 0.625" v-t="'questionnaires.mdhaq.feedback.score_gt_0pt5-0pt625.message'"></p>
            <h4 v-if="score > 0.625" v-t="'questionnaires.mdhaq.feedback.score_gt_0pt625.title'"></h4>
            <p v-if="score > 0.625" v-t="'questionnaires.mdhaq.feedback.score_gt_0pt625.message'"></p>
          </alert>
        </column>
      </row>
    </card>
  </questionnaire>
</template>
<script>
import questionnaire from "~/mixins/questionnaire.js"
import { mapState } from 'vuex'
export default {
  name: 'mdhaq',
  mixins: [questionnaire],
  computed: {
    ...mapState('resources/patient', ['patient_issue']),
    mdhaqItems() {
      return ['MDHAQ_A', 'MDHAQ_B', 'MDHAQ_C', 'MDHAQ_D', 'MDHAQ_E', 'MDHAQ_F', 'MDHAQ_G', 'MDHAQ_H', 'MDHAQ_I', 'MDHAQ_J']
    },
    score() {
      var score = 0
      var count = 0
      this.mdhaqItems.forEach((diName) => {
        var rec = this.records[diName]
        if (rec) {
          var lookupitem = this.dataitems[diName].lookup.items.find((lui) => lui.value == rec.toString())
          if (rec !== null && lookupitem) {
            count++;
            score = score + lookupitem.json.score
          }
        }
      })
      if (count > 9)
        return score / count
    },
    allScored() {
      var scored = true
      this.mdhaqItems.forEach((diName) => {
        if (!this.records[diName]) scored = false
      })
      return scored
    },
    isSubmitted() {
      if (this.patient_issue && this.patient_issue.patient_questionnaire)
        return this.patient_issue.patient_questionnaire.find((pq) => pq.questionnaire_id == this.questionnaire.id).valid == 1
    }
  }
}

</script>
