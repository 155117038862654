export default [
    {
        path: '/notifications/notified',
        name: 'admin.notifications.notified',
        component: () => import('../pages/Notifications.vue'),

    },
    {
        path: '/notifications/notifiable',
        name: 'admin.notifications.notifiable',
        component: () => import('../pages/Notifications.vue'),

    },
    {
        path: '/notifications/models',
        name: 'admin.notifications.models',
        component: () => import('../pages/Notifications.vue'),

    },
    {
        path: '/notifications/:name',
        name: 'admin.notification',
        component: () => import('../pages/NotificationManager.vue'),

    }
]