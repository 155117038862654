<template>
    <g v-if="geoJson">
        <g v-for="(feature, index) in geoJson.features" :key="index">
            <path :d="pathGenerator(feature)" :class="getClasses(feature)"
                :id="'path_' + feature.properties[colourKey]"></path>
            <slot name="overlay" :feature="feature" :x="projection(geoCentroid(feature))[0]"
                :y="projection(geoCentroid(feature))[1]" :bounds="pathGenerator.bounds(feature)"></slot>

        </g>
        <!-- <rect :x="selectedFeatureViewBox[0]" :y="selectedFeatureViewBox[1]" :width="selectedFeatureViewBox[2]"
            :height="selectedFeatureViewBox[3]" stroke="black"></rect> -->
    </g>
</template>

<script>
import { select } from 'd3-selection';
import { geoPath, geoMercator, geoCentroid } from 'd3-geo';
import { json } from 'd3-fetch';
export default {
    name: "GeoMap",
    props: {
        geoDataUrl: {
            type: String,
            required: true
        },
        colours: {
            type: Object,
            default: () => ({})
        },
        countryClasses: {
            type: Object,
            default: () => ({})
        },
        colourKey: {
            type: String,
            default: 'name'
        },
        flags: {
            type: Object,
            default: () => ({})
        },
        width: {
            type: Number,
            default: 800
        },
        height: {
            type: Number,
            default: 600
        },
        center: {
            type: Array,
            default: () => [13, 52]
        },
        selected: {
            type: String,
            default: ''
        },
        scale: {
            type: Number,
            default: 600
        }
    },
    data() {
        return {
            geoJson: { features: [] }
        };
    },
    mounted() {
        this.fetchGeoData();
        if (this.selectedFeature) {
            const viewBox = this.selectedFeatureViewBox();
            this.$emit('update:viewBox', viewBox);
        }
    },
    updated() {
        if (this.selectedFeature) {
            const viewBox = this.selectedFeatureViewBox();
            this.$emit('update:viewBox', viewBox);
        }
    },
    computed: {
        projection() {
            return geoMercator()
                .center(this.center)
                .scale(this.scale)
                .translate([this.width / 2, this.height / 2]);
        },
        pathGenerator() {
            return geoPath().projection(this.projection);
        },
        selectedFeature() {
            return this.getGeoFeature(this.selected);
        },

    },

    methods: {
        fetchGeoData() {
            json(this.geoDataUrl).then((data) => {
                this.geoJson = data;
            });
        },
        getGeoFeature(name) {
            return this.geoJson.features.find((feature) => feature.properties.name === name);
        },
        getColour(feature) {
            if (feature.properties[this.colourKey] && this.colours[feature.properties[this.colourKey]])
                return this.colours[feature.properties[this.colourKey]]
            return 'grey';
        },
        getClasses(feature) {
            var classes = [];
            // console.log(this.countryClasses, feature.properties[this.colourKey])
            if (feature.properties[this.colourKey] && this.countryClasses[feature.properties[this.colourKey]]) {
                classes.push(this.countryClasses[feature.properties[this.colourKey]])
            }
            if (this.selected === feature.properties.name) {
                classes.push('selected');
            }
            return classes;
        },
        geoCentroid(feature) {
            return geoCentroid(feature);
        },
        selectedFeatureViewBox() {
            if (this.selectedFeature) {

                const bounds = this.pathGenerator.bounds(this.selectedFeature);

                var viewBox = [bounds[0][0], bounds[0][1], bounds[1][0] - bounds[0][0], bounds[1][1] - bounds[0][1]];
                // ensure bounds are within the svg aspect ratio
                if (viewBox[2] / viewBox[3] > this.width / this.height) {
                    const width = viewBox[2];
                    const height = width * this.height / this.width;
                    const y = viewBox[1] - (height - viewBox[3]) / 2;
                    viewBox = [viewBox[0], y, width, height];
                } else {
                    const height = viewBox[3];
                    const width = height * this.width / this.height;
                    const x = viewBox[0] - (width - viewBox[2]) / 2;
                    viewBox = [x, viewBox[1], width, height];
                }
                return viewBox.join(' ');
            }
            return [0, 0, this.width, this.height];
        }
    }
}
</script>

<style lang="scss" scoped></style>