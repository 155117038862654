import axios from 'axios'
// state
export const state = {
  activities: [],
  activitiesFilters: [],
  activity: {},
  pagination: {},
  loadingActivities: false
}

// getters
export const getters = {
}

// mutations
export const mutations = {
  GET_ACTIVITIES(state, data) {
    state.loadingActivities = false
    const { data: activities, ...meta } = data
    state.activities = activities
    state.pagination = meta

  },
  GET_ACTIVITIES_FILTERS(state, data) {
    state.activitiesFilters = data
  },
}

// actions
export const actions = {
  getActivities({ commit, state }, request) {
    state.loadingActivities = true
    axios.get('activity', request)
      .then((resp) => {
        commit('GET_ACTIVITIES', resp.data)
      })
  },
  getFilters({ commit }, request) {
    return axios.get('activity/filters', request)
      .then((resp) => {
        commit('GET_ACTIVITIES_FILTERS', resp.data)
      })
  }

}
export const namespaced = true;
