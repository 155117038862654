import helpers from "./helpers"
var inherit = function(baseConstructor, newConstructor) {
  return function(...args) {
     var baseObj = new baseConstructor(...args);
     var newObj = new newConstructor(baseObj, ...args);
     for (var index in baseObj) {
      this[index] = baseObj[index];
     }

     for (var index in newObj) {
      this[index] = newObj[index];
     }
     return this;
  }
}
export default {
  inherit,
  constructObjects(construction, object, mutate) {
    if (mutate===undefined) {
      mutate = false;
    }
    if (!mutate) {
      object = helpers.hardCopy(object)
    }
    for(var field in construction) {
      this.constructObject(field, object, construction[field], true)
    }
    return object;
  },
  constructObject(path, object, constructor, mutate) {
    if (mutate===undefined) {
      mutate = false;
    }


    if (!mutate) {
      object = helpers.hardCopy(object)
    }
    helpers.dotConstruct(object, path, constructor)
    // var parts = path.split(".")
    // var traversal = object;
    // this.constructObjectPathStep(parts, object, constructor)



    return object;
  },
  constructObjectPathStep(parts, object, constructor) {
      var nextStep = parts[0];
      if (nextStep=="*") {
        for(var index in object) {
          if (parts.length>1) {
            this.constructObjectPathStep(parts.splice(1), object[index], constructor)
          } else {
            object[index] = new constructor(object[index]);
          }
        }
      } else {
        if (parts.length>1) {
          this.constructObjectPathStep(parts.splice(1), object[nextStep], constructor)
        } else {
          object[nextStep] = new constructor(object[nextStep]);
        }
      }
    },
}
