<template>
    <card @click.native="fetchPatients">
        <div>
            <canvas id="webglCanvas" ref="webglCanvas" :width="width" :height="height" style="border:1px solid #000000;
            display:none;
            "></canvas>
            <!-- <div>
                {{ simulatedDate.toLocaleDateString().split('T')[0] }}:
                {{ latestVisibleNodeDate.toLocaleDateString().split('T')[0] }}
                {{ tickN }}
                {{ visibleNodes.length }}
                {{ simulatedNodes.length }}
            </div> -->
            <svg :width="width" :height="height">
                <template v-for="(node, n) in visibleNodes">
                    <circle :key="'node' + n" :cx="node.x" :cy="node.y" :r="node.r" :fill="countryColours[node.country_id]"
                        :opacity="opacityFromAge(node)" />

                </template>
            </svg>
        </div>
    </card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { forceSimulation, forceManyBody, forceCenter, forceCollide, forceX, forceY } from 'd3-force';
export default {
    name: 'patient-explorer',
    data() {
        return {
            gl: null,
            glInitialized: false,
            simulation: null,
            nodes: [],
            nodesAdded: false,
            buffers: null,
            width: 800,
            height: 600,
            startDate: new Date('2023-06-01'),
            simulatedDate: new Date('2023-06-01'),
            currentDate: new Date(),
            tickN: 0,
        }
    },
    mounted() {
        this.initSimulation()
        // defer the  fetchAllPatientsAsStream to allow other data to load
        setTimeout(() => {
            this.fetchPatients()
        }, 1000);

        // this.forceSimulation()


        // Fetch all patients

    },
    updated() {
        // if (this.glInitialized) {
        // this.drawScene(this.gl, this.programInfo, this.buffers);
        // } else {
        // this.initSimulation();
        // }

    },
    computed: {
        ...mapState('resources/admin', ['dashboard', 'loadingDashboardData', 'loadingPatients',]),

        patients() {
            return this.dashboard.data.patients
        },
        programInfo() {
            return {
                program: this.shaderProgram,
                attribLocations: {
                    vertexPosition: this.gl.getAttribLocation(this.shaderProgram, 'aVertexPosition'),
                },
            };
        },
        shaderProgram() {
            return this.initShaderProgram(this.gl, this.vsSource, this.fsSource);
        },
        vsSource() {
            return `
        attribute vec4 aVertexPosition;
        void main() {
          gl_Position = aVertexPosition;
        }
      `;
        },
        fsSource() {
            return `
        void main() {
          gl_FragColor = vec4(1.0, 0.5, 0.0, 1.0);  // Orange color
        }
      `;
        },
        visibleNodes() {
            return this.nodes.filter((n) => {
                // date must be less than or equal to simulated date
                return new Date(n.date) <= this.simulatedDate;

            });
        },
        simulatedNodes() {
            return this.visibleNodes.filter((n) => {
                // find day difference between date and start date
                const age = this.simulatedDate - new Date(n.date);
                const maxAge = 1000 * 60 * 60 * 24 * 30 * 12; // 3 months
                return Math.max(0, 1 - age / maxAge);

            });
        },
        latestVisibleNodeDate() {
            if (this.visibleNodes.length == 0) return new Date(this.startDate)
            return new Date(this.visibleNodes[this.visibleNodes.length - 1].date)
        },
        countryColours() {
            if (this.dashboard.data) {
                return this.dashboard.data.countryData.reduce((acc, c) => {
                    acc[c.id] = c.json.hex;
                    return acc;
                }, {});
            } else {
                return {}
            }
        },
        daysFromEndDate() {
            return Math.floor((this.currentDate - this.simulatedDate) / (1000 * 60 * 60 * 24));
        }

    },
    methods: {
        ...mapActions('resources/admin', ['fetchAllPatientsAsStream']),
        initSimulation() {
            this.nodes = []
            this.tickN = 0
            this.simulatedDate = new Date(this.startDate)
            this.simulation = this.forceSimulation()

        },
        fetchPatients() {
            this.fetchAllPatientsAsStream()
                .then(() => {
                    console.log('fetched patients', this.patients.length);
                })
        },
        initWebGL() {
            const canvas = this.$refs.webglCanvas;
            this.gl = canvas.getContext('webgl');

            if (!this.gl) {
                alert('Unable to initialize WebGL. Your browser may not support it.');
                return;
            }

            this.initBuffers([]);
            // Draw the scene
            this.drawScene(this.gl, this.programInfo, this.buffers);
            this.glInitialized = true;
            if (!this.loadingDashboardData) {
                this.fetchAllPatientsAsStream()
            }
        },
        initBuffers(nodes) {
            const positionBuffer = this.gl.createBuffer();
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, positionBuffer);
            this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(nodes.length * 3 * 2), this.gl.DYNAMIC_DRAW);
            this.buffers = {
                position: positionBuffer,
            };
        },
        updateBuffers(nodes) {
            console.log('Updating buffers', nodes.length);
            // Update WebGL buffer with new nodes data
            const positions = new Float32Array(nodes.length * 3 * 2);
            // Populate positions based on nodes data (similar to updateNodesPosition)
            nodes.forEach((node, i) => {
                const baseIndex = i * 6;
                const size = 5;
                if (i == 0) console.log('Updating buffers', node);
                positions[baseIndex] = node.x;
                positions[baseIndex + 1] = node.y;
                positions[baseIndex + 2] = node.x + size;
                positions[baseIndex + 3] = node.y + size;
                positions[baseIndex + 4] = node.x + size;
                positions[baseIndex + 5] = node.y - size;
            });
            this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.buffers.position);
            // Consider using bufferData to completely replace the data if the buffer size changes
            this.gl.bufferData(this.gl.ARRAY_BUFFER, positions, this.gl.DYNAMIC_DRAW);
        },
        opacityFromAge(node) {
            // Determine opacity based on age
            const age = this.simulatedDate - new Date(node.date);
            const maxAge = 1000 * 60 * 60 * 24 * 30 * 12; // 6 months
            return Math.max(0, 1 - age / maxAge) + 0.5;
        },
        initShaderProgram(gl, vsSource, fsSource) {
            const vertexShader = this.loadShader(gl, gl.VERTEX_SHADER, vsSource);
            const fragmentShader = this.loadShader(gl, gl.FRAGMENT_SHADER, fsSource);

            // Create the shader program
            const shaderProgram = gl.createProgram();
            gl.attachShader(shaderProgram, vertexShader);
            gl.attachShader(shaderProgram, fragmentShader);
            gl.linkProgram(shaderProgram);

            // If creating the shader program failed, alert
            if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
                alert('Unable to initialize the shader program: ' + gl.getProgramInfoLog(shaderProgram));
                return null;
            }

            return shaderProgram;
        },
        loadShader(gl, type, source) {
            const shader = gl.createShader(type);

            // Send the source to the shader object
            gl.shaderSource(shader, source);

            // Compile the shader program
            gl.compileShader(shader);

            // See if it compiled successfully
            if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
                alert('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
                gl.deleteShader(shader);
                return null;
            }

            return shader;
        },
        updateNodesPosition(nodes) {
            // Convert nodes data to a flat array of positions
            const positions = new Float32Array(nodes.length * 3 * 2);
            nodes.forEach((node, i) => {
                // if (i == 0) console.log('Updating nodes position', nodes.length);
                // Calculate triangle vertices for each node
                const baseIndex = i * 6; // 3 vertices per triangle, 2 coordinates per vertex
                const size = 5; // Adjust size of the triangle representing each node
                positions[baseIndex] = node.x;
                positions[baseIndex + 1] = node.y;
                positions[baseIndex + 2] = node.x + size;
                positions[baseIndex + 3] = node.y + size;
                positions[baseIndex + 4] = node.x + size;
                positions[baseIndex + 5] = node.y - size;
            });

            // Update the position buffer with the new positions
            // this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.buffers.position);
            // this.gl.bufferSubData(this.gl.ARRAY_BUFFER, 0, positions);
        },
        drawScene(gl, programInfo, buffers) {
            // Clear the canvas
            gl.clearColor(0.0, 0.0, 0.0, 1.0); // Set clear color to black, fully opaque
            gl.clearDepth(1.0);                // Clear everything
            gl.enable(gl.DEPTH_TEST);          // Enable depth testing
            gl.depthFunc(gl.LEQUAL);           // Near things obscure far things

            // Clear the canvas before we start drawing on it.
            gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

            // Tell WebGL to use our program when drawing
            gl.useProgram(programInfo.program);

            // Attach the buffer object
            gl.bindBuffer(gl.ARRAY_BUFFER, buffers.position);

            // Point an attribute to the currently bound VBO
            gl.vertexAttribPointer(
                programInfo.attribLocations.vertexPosition,
                2,          // Number of components per attribute (x and y)
                gl.FLOAT,   // Type of the components
                false,      // Normalization
                0,          // Stride
                0           // Offset
            );
            gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);

            // Adjust this value based on your simulation setup or data structure
            const numComponents = 2; // x and y values per vertex
            const type = gl.FLOAT; // the data in the buffer is 32 bit floats
            const normalize = false; // don't normalize
            const stride = 0; // how many bytes to get from one set of values to the next
            const offset = 0; // how many bytes inside the buffer to start from

            // Draw the scene
            for (let i = 0; i < this.patients.length; i++) {
                // Since each patient is represented by a triangle (3 vertices), we need to draw them individually
                // Adjust the starting index for each patient's vertices
                gl.drawArrays(gl.TRIANGLES, i * 3, 3); // Draw the triangle for each patient
            }
        },
        forceSimulation() {
            // this.nodes = this.patients.map(p => ({ ...p, x: 0.5 * this.width, y: 0.5 * this.height, r: 5 }));
            // calculate charge strength based on number of simulatedNodes
            var chargeStrength = -30;
            return forceSimulation(this.simulatedNodes)
                .force("center", forceCenter(this.width / 2, this.height / 2))
                .force("charge", forceManyBody().strength(chargeStrength))
                // keep the nodes within the canvas
                .force('x', forceX().strength(0.2).x(d => this.width / 2))
                .force('y', forceY().strength(0.2).y(d => this.height / 2))

                .on('tick', this.ticked);
        },
        addNodesToSimulation(newNodes) {
            // Assuming you have a simulation instance
            this.nodes = [...this.nodes, ...newNodes];
            this.nodesAdded = true;
            // this.simulation.nodes(this.simulatedNodes);
            // var nodeCount = this.simulatedNodes.length;
            // determine alpha based on days away from current date
            // if(this.currentDate - this.simulatedDate > 0) {
            //     this.simulation.alpha(0.5).restart();
            // }
            // chargestrength should start at -30 and increase to -10 as nodes are added
            // var chargeStrength = -30 + Math.min(20, nodeCount / 50);
            // this.simulation
            //     .force("charge", forceManyBody().strength(chargeStrength))
            //     .alpha(0.5).restart();
            // console.log('Adding nodes to simulation', this.nodes.length);
            // this.updateBuffers(this.nodes);
            // this.updateNodesPosition(this.nodes);
            // this.drawScene(this.gl, this.programInfo, this.buffers);


        },
        ticked() {
            this.tickN++;
            // add one hour to the simulated date
            if (this.simulatedDate < this.currentDate && this.tickN > 100)
                this.simulatedDate.setHours(this.simulatedDate.getHours() + 3);
            // console.log(this.simulation.alpha(), this.simulatedDate, this.currentDate);
            // find difference between simulated date and current date
            var nodeCount = this.simulatedNodes.length;
            let diff = this.currentDate - this.simulatedDate;
            diff = Math.floor(diff / (1000 * 60 * 60 * 24));
            var chargeStrength = -30 + Math.min(20, nodeCount / 50);

            // console.log('Ticked', this.simulatedDate, this.simulatedNodes.length, chargeStrength, diff, this.simulation.alpha());
            // if (this.simulation.alpha() < 0.1 && diff > 10) {
            //     // if the simulation has settled and the simulated date is more than 10 days away from the current date
            //     this.simulation
            //         .force("charge", forceManyBody().strength(chargeStrength))
            //     this.simulation.alpha(0.5).restart();
            //     this.simulation.nodes(this.simulatedNodes);
            //     // this.updateNodesPosition(this.simulatedNodes);
            // } else {
            // this.simulation.nodes(this.simulatedNodes);
            // this.simulation.alpha(0.5).restart();

            // }

            // Update the position of the nodes

            // Draw the scene
            // this.drawScene(this.gl, this.programInfo, this.buffers);
        },
        countryHex(node) {
            if (this.dashboard.data) {

                var CountryData = this.dashboard.data.countryData;
                countryData.map(c => {
                    return c.json.hex;
                });
            }
        }


    },
    watch: {
        patients(newPatients, oldPatients) {
            if (newPatients.length > oldPatients.length) {
                // console.log('New patients added', newPatients.length, oldPatients.length, newPatients.length - oldPatients.length);
                // New nodes have been added
                const newNodes = newPatients.slice(oldPatients.length).map(p => ({ ...p, x: Math.random() * this.width, y: Math.random() * this.height, r: 5 }));;

                this.addNodesToSimulation(newNodes);
            }
            if (newPatients.length == 0) {
                this.initSimulation()
            }
        },
        visibleNodes(newNodes, oldNodes) {
            if (newNodes.length > oldNodes.length) {
                var chargeStrength = -30 + Math.min(20, newNodes.length / 50);
                this.simulation.nodes(newNodes);
                this.simulation
                    .force("charge", forceManyBody().strength(chargeStrength))
                    .alpha(0.5).restart();
            }
        },
    }
};
</script>

<style lang="scss" scoped></style>