import Cookies from 'js-cookie'
import i18n from '~/plugins/i18n'

const { locale, locales } = window.config

// state
export const state = {
  locale: getLocale(locales, locale),
  locales: locales,
  messages: {}
}

// getters
export const getters = {
  locale: state => state.locale,
  locales: state => state.locales
}

// mutations
export const mutations = {
  SET_LOCALE(state, { locale }) {
    state.locale = locale
  },
  ADD_MESSAGES(state, { messages, locale, prefix }) {
    if (!state.messages[locale]) state.messages[locale] = {}
    if (prefix) {
      state.messages[locale][prefix] = messages
    } else {
      state.messages[locale] = { ...state.messages[locale], ...messages }
    }
    i18n.mergeLocaleMessage(locale, state.messages[locale])
  }
}

// actions
export const actions = {
  setLocale({ commit }, { locale }) {
    commit('SET_LOCALE', { locale })

    Cookies.set('locale', locale, { expires: 365 })
  },
  addMessages({ commit }, { messages, locale, prefix }) {
    commit('ADD_MESSAGES', { messages, locale, prefix })
  }
}

/**
 * @param  {String[]} locales
 * @param  {String} fallback
 * @return {String}
 */
function getLocale(locales, fallback) {
  const locale = Cookies.get('locale')

  if (Object.prototype.hasOwnProperty.call(locales, locale)) {
    return locale
  } else if (locale) {
    Cookies.remove('locale')
  }

  return fallback
}
export const namespaced = true;
