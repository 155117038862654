import {factory} from "../utils/ClassLoader"
let ValidationRule = class extends factory.classes.AttributeBag {
	constructor(attributes)  {
		var parts = attributes.rule.split(':');
		var ruleName = parts[0]
		var params = parts.splice(1).join(":");
		super({...attributes, ruleName, params});
	}
	setDataitem(dataitem) {
		this.dataitem = dataitem;
	}
	toJSON() {
		var object = {...this}
		delete object.dataitem;
		return object;
	}
	getRuleKey(options) {
		if (!options) options = {};
        if (this.dataitem.section&&!options.flat) {
            // needs some code to support all section types and depth
            return this.dataitem.section + '.*.' + this.dataitem.name
        } else {
            return this.dataitem.name
        }
    }
}

export {
	ValidationRule
}