<template>
    <g>
        <ellipse v-for="node in nodes" :key="node.id" v-bind="nodeBindings(node)"
            :style="{ 'fill-opacity': 0.3 + (node.count / 100) * .6 }"></ellipse>
    </g>
</template>
<script>
import { area, style } from "d3"
import { curveStepBefore } from "d3-shape"
export default {
    name: 'scatter',
    inject: ['subjects'],

    computed: {
        nodes() { return this.$parent.nodes },
        range() { return this.$parent.range },

    },
    methods: {
        nodeBindings(node) {
            return {
                cx: this.range.x(node[this.subjects.x]),
                cy: this.range.y(node[this.subjects.y]),
                ry: node.count,
                rx: (this.range.x(1) - this.range.x(0)) / 2,
            }
        }
    }
}
</script>