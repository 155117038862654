import store from '~/store'
const sequenceJson = import.meta.globEager('./sequences/*.json')

export const sequences = {}
for (const path in sequenceJson) {
  var sequence = sequenceJson[path]
  var newPath = path.replace('./sequences/', '').replace(/(^.\/)|(\.json$)/g, '')

  sequences[newPath] = sequence.default
}
// {
//   registration: {
//     register: { isLink: false, route: { path: '/register', params: {} } },
//   }
// }
export function getSequence(name) {
  return sequences[name]
}
export function getNextKeyInSequence(sequence, current) {
  if (sequence == null) return null
  var keys = Object.keys(sequences[sequence])
  if (keys.includes(current)) {
    var index = keys.findIndex((k) => k == current)
    if (keys.length >= index + 1) {
      return keys[index + 1]
    }
  }
}
var defaultRoute = { path: "/home", name: "home" }
export function checkRoute(to, from) {
  if (store.getters['auth/check'] && store.getters['auth/isParticipant']) {
    var user = store.getters['auth/user']

    if (user.patient) {
      var status = user.patient.status
      var sequenceName = store.getters['auth/inSequence']
      if (sequenceName && status) {
        var sequence = getSequence(sequenceName)
        var keys = Object.keys(sequence)
        var expectedRoute = sequence[status].route;
        // where navigation is sending us
        var intendedIndex = keys.findIndex((k) => { if (sequence[k].route) return sequence[k].route.path == to.path })
        var intendedRoute = sequence[keys[intendedIndex]];
        // where the sequence thinks they should be
        var expectedIndex = keys.findIndex((k) => k == status)
        if (!expectedRoute) {
          expectedRoute = defaultRoute
        }
        if (intendedIndex == -1) {
          return { code: "unknown", expectedRoute, intendedRoute }
        }

        if (intendedIndex < expectedIndex) {
          /* User has requested an earlier (or current) page in the sequence - allow it */
          return { code: "before", expectedRoute, intendedRoute }
        } else if (intendedIndex == expectedIndex) {
          return { code: "current", expectedRoute, intendedRoute }
        } else {
          return { code: "future", expectedRoute, intendedRoute }
        }
        /* else redirect to expected route */


      } else {
        return { code: "null", expectedRoute: defaultRoute };
      }
    } else {
      return { code: "null", expectedRoute: defaultRoute };
    }
  } else {
    return { code: "null", expectedRoute: defaultRoute };
  }
}