<template>
    <svg :width="width" :height="height" style="max-width: 800px; border: 1px solid #ccc;">
        <g ref="links" class="links">
            <path v-for="(link, n) in links" :key="'link' + n"
                :d="`M${link.source.x},${link.source.y}L${link.target.x},${link.target.y}`" :stroke-width="2"
                class="line" @click="clickLink(link)">
            </path>
        </g>
        <g ref="nodes" class="nodes">
            <rect v-for="(node, n) in nodes" :key="'node' + n" :x="node.x - node.width / 2"
                :y="node.y - node.height / 2" :width="node.width" :height="node.height" class="node" :data-id="node.id"
                :title="node.id" @click="clickNode($event, node)">
            </rect>
        </g>
    </svg>
</template>

<script>
import { forceSimulation, forceLink, forceManyBody, forceCenter, forceCollide } from 'd3-force';

export default {
    props: {
        data: {
            type: Object,
            required: true,
            // example structure: {model:'User', relationships: [{model:'Post', type:'hasMany', foreign_key:'user_id', relationships: [{model:'Comment', type:'hasMany', foreign_key:'post_id'}]}]}
        }
    },
    data() {
        return {
            width: 800,
            height: 600,
            nodeWidth: 100,  // Set a reasonable default width for nodes
            nodeHeight: 50,  // Set a reasonable default height for nodes
            nodes: [],       // Reactive array for nodes
            links: [],       // Reactive array for links
        }
    },
    mounted() {
        this.initializeData();  // Initialize nodes and links from the provided data
        this.startSimulation(); // Start the D3 force simulation
    },
    methods: {
        initializeData() {
            // Flatten data into nodes and links
            const flattened = this.flattenData(this.data);
            console.log(flattened);
            this.nodes = flattened.nodes.map(node => ({
                ...node,
                width: this.nodeWidth,
                height: this.nodeHeight,
                x: this.width / 2,
                y: this.height / 2,
            }));
            this.links = flattened.links;
        },
        startSimulation() {
            const simulation = forceSimulation(this.nodes)
                .force('link', forceLink(this.links).id(d => d.id).distance(200))
                .force('charge', forceManyBody().strength(-500))
                .force('center', forceCenter(this.width / 2, this.height / 2))
                .force('collision', forceCollide().radius(d => Math.max(d.width, d.height) / 2 + 10)) // Adds padding for collision
                .on('tick', this.ticked);  // Call ticked function on each simulation tick

            // Store the simulation for future reference if needed
            this.sim = simulation;
        },
        ticked() {
            // Vue reactivity ensures DOM updates; just adjust node positions in data
            this.nodes = this.nodes.map(node => ({
                ...node,
                // Constrain nodes within the SVG bounds
                x: Math.max(node.width / 2, Math.min(this.width - node.width / 2, node.x)),
                y: Math.max(node.height / 2, Math.min(this.height - node.height / 2, node.y)),
            }));
        },
        clickNode(e, node) {
            alert(`Model: ${node.id}`);
        },
        clickLink(link) {
            alert(`Relationship: ${link.type}, Foreign Key: ${link.foreign_key}`);
        },
        flattenData(data) {
            const nodes = [];
            const links = [];

            function recurse(model, parent = null) {
                nodes.push({ id: model.model });
                if (parent) {
                    links.push({
                        source: parent.model,
                        target: model.model,
                        type: model.type,
                        foreign_key: model.foreign_key,
                    });
                }
                if (model.relationships) {
                    model.relationships.forEach(rel => recurse(rel, model));
                }
            }

            recurse(data);
            return { nodes, links };
        },
    }
};
</script>

<style lang="scss" scoped>
.links path {
    stroke: #1b1b1b;
    stroke-opacity: 0.6;
    fill: none;
}

.nodes rect {
    stroke: #fff;
    stroke-width: 1.5px;
    fill: #4682b4;
    /* Example fill color */
    cursor: pointer;
}
</style>
